import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import ReservationChart from "../../../components/charts/reservationChart.jsx";
import SalesChart from "../../../components/charts/salesChart";
import SalesServices from "../../../ApiServices/SalesServices";
import { toast } from "react-toast";
import moment from "moment";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllTests } from "../../../redux/test/actions";
import { getAllLocations } from "../../../redux/location/actions";
import { getAllPackages } from "../../../redux/package/actions";
import PackageChart from "./PackageChart";
import TestChart from "./TestChart";
import RegionChart from "./regionChart";
import { getLoginUserInfo } from "../../../redux/userInfo/actions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FolderIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../ApiServices/MasterServices";

const dateFilters = [
  {
    label: "Today",
    value: 1,
  },
  {
    label: "Yesterday",
    value: 2,
  },
  {
    label: "Last Month",
    value: 3,
  },
  {
    label: "This Month",
    value: 4,
  },
  {
    label: "Last Week",
    value: 5,
  },
  {
    label: "This Week",
    value: 6,
  },
  {
    label: "Custom",
    value: 7,
  },
];

const initialSalesChartFilters = {
  start_date: moment(new Date()).format("YYYY-MM-DD"),
  end_date: moment(new Date()).format("YYYY-MM-DD"),
  pay_mode_id: "1",
  package_ids: [],
  test_ids: [],
  location_ids: [],
};

const initialPSalesChartFilters = {
  start_date: moment(new Date()).format("YYYY-MM-DD"),
  end_date: moment(new Date()).format("YYYY-MM-DD"),
  package_ids: [],
  location_id: "",
};

const initialTSalesChartFilters = {
  start_date: moment(new Date()).format("YYYY-MM-DD"),
  end_date: moment(new Date()).format("YYYY-MM-DD"),
  test_ids: [],
  location_id: "",
};

const initialRSalesChartFilters = {
  start_date: moment(new Date()).format("YYYY-MM-DD"),
  end_date: moment(new Date()).format("YYYY-MM-DD"),
};

const initialCardFilters = {
  start_date: moment(new Date()).format("YYYY-MM-DD"),
  end_date: moment(new Date()).format("YYYY-MM-DD"),
  location_id: "",
  custom_dates: false,
};

const stats = [
  // Sample
  { name: "Samples Analyzed", stat: "0" },
  { name: "Samples in processing", stat: "0" },
  { name: "Samples Rejected", stat: "0" },
  { name: "Samples Pending", stat: "0" },
  // S - End

  // Reservation
  { name: "Reservations Confirmed", stat: "0" },
  { name: "Reservations Paid", stat: "0" },
  { name: "Reservations Refunded", stat: "0" },
  { name: "Reservations Samples Generated ", stat: "0" },
  { name: "Reservations Result Generated ", stat: "0" },
  { name: "Reservations Under Processing", stat: "0" },
  // R - End

  // Collections
  { name: 'Total Collection', stat: '0' },
  { name: 'Cash Collected', stat: '0' },
  { name: 'Card Collected', stat: '0' },
  // { name: 'Credit Collection', stat: '0' },
];

const Home = () => {
  const pages = [{ title: "Dashboard", href: "/" }];
  const dispatch = useDispatch();

  const {
    test: { loading: tLoading, list: tList },
    package: { loading: pLoading, list: pList },
    location: { loading: lLoading, list: l_List },
  } = useSelector((state) => state);

  const [locationList, setLocationList] = useState(false);
  const [testList, setTestList] = useState(false);
  const [packList, setPackList] = useState(false);

  const [favs, setFavs] = useState([]);
  const [analyzedSample, setAnalyzedSample] = useState(false);
  const [statsList, setStatsList] = useState(stats);

  const [salesData, setSalesData] = useState(false);
  const [packageSalesData, setPackageSalesData] = useState(false);
  const [testSalesData, setTestSalesData] = useState(false);
  const [regionSalesData, setRegionSalesData] = useState(false);

  const [nors, setNors] = useState(false);
  const [norps, setNorps] = useState(false);
  const [norts, setNorts] = useState(false);
  const [norrs, setNorrs] = useState(false);

  const [salesLabel, setSalesLabel] = useState(false);
  const [packageSalesLabel, setPackageSalesLabel] = useState(false);
  const [testSalesLabel, setTestSalesLabel] = useState(false);
  const [regionSalesLabel, setRegionSalesLabel] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isPackageDataLoading, setIsPackageDataLoading] = useState(true);
  const [isTestDataLoading, setIsTestDataLoading] = useState(true);
  const [isRegionDataLoading, setIsRegionDataLoading] = useState(true);

  const [salesDataLoader, setSalesDataLoader] = useState(false);

  const [selectedLocationsSalesChart, setSelectedLocationsSalesChart] =
    useState(false);
  const [selectedLocationPSalesChart, setSelectedLocationPSalesChart] =
    useState(l_List[0]);
  const [selectedLocationTSalesChart, setSelectedLocationTSalesChart] =
    useState(l_List[0]);

  const [salesChartFilters, setSalesChartFilters] = useState(
    initialSalesChartFilters
  );
  const [pSalesChartFilters, setPSalesChartFilters] = useState(
    initialPSalesChartFilters
  );
  const [tSalesChartFilters, setTSalesChartFilters] = useState(
    initialTSalesChartFilters
  );
  const [rSalesChartFilters, setRSalesChartFilters] = useState(
    initialRSalesChartFilters
  );

  const [showCustomDates, setShowCustomDates] = useState(false);

  const [cardFilters, setCardFilters] = useState(initialCardFilters);
  const [cardDataLoading, setCardDataLoading] = useState(true);

  useEffect(() => {
    document.title = "Labib | Dashboard";
    setIsLoading(true);
    let favs = JSON.parse(localStorage.getItem("favourites"));
    if (favs?.length > 0) {
      // setFavs(favs);
    }

    setIsLoading(false);
    getStatsData();

    getSalesData();
    dispatch(getAllTests());
    dispatch(getAllLocations());
    dispatch(getAllPackages("active"));
    dispatch(getLoginUserInfo("All"));
    getUserBasedAllFav();
  }, []);

  useEffect(() => {
    let locations = [{ label: "ALL", value: "all", }];
    if (l_List?.length > 0) {
      l_List.forEach((element) => {
        let obj = {
          ...element,
          label: element.label,
          value: element.value,
          m_user_loc_id: 0,
        }

        locations.push(obj);
      })
      setLocationList(locations);
    }
  }, [l_List]);

  useEffect(() => {
    let tests = [{ label: "ALL", value: "all", }];
    if (tList?.length > 0) {
      tList.forEach((element) => {
        let obj = {
          ...element
        }

        tests.push(obj);
      })
      setTestList(tests);
    }
  }, [tList]);

  useEffect(() => {
    let packages = [{ label: "ALL", value: "all", }];
    if (pList?.length > 0) {
      pList.forEach((element) => {
        let obj = {
          ...element
        }

        packages.push(obj);
      })
      setPackList(packages);
    }
  }, [pList]);

  const handleDateFilterChange = (e, chart) => {
    if (
      e.target.value == 1 &&
      e.target[e.target.value - 1].innerHTML == "Today"
    ) {
      if (chart == "Card") {
        setCardFilters((prev) => {
          return {
            ...prev,
            start_date: moment(new Date()).format("YYYY-MM-DD"),
            end_date: moment(new Date()).format("YYYY-MM-DD"),
            custom_dates: false,
          };
        });
      } else {
        setShowCustomDates(false);
        setSalesChartFilters((prev) => {
          return {
            ...prev,
            start_date: moment(new Date()).format("YYYY-MM-DD"),
            end_date: moment(new Date()).format("YYYY-MM-DD"),
          };
        });
      }
    } else if (
      e.target.value == 2 &&
      e.target[e.target.value - 1].innerHTML == "Yesterday"
    ) {
      if (chart == "Card") {
        setCardFilters((prev) => {
          return {
            ...prev,
            start_date: moment(new Date())
              .subtract(1, "days")
              .format("YYYY-MM-DD"),
            end_date: moment(new Date())
              .subtract(1, "days")
              .format("YYYY-MM-DD"),
            custom_dates: false,
          };
        });
      } else {
        setShowCustomDates(false);
        setSalesChartFilters((prev) => {
          return {
            ...prev,
            start_date: moment(new Date())
              .subtract(1, "days")
              .format("YYYY-MM-DD"),
            end_date: moment(new Date())
              .subtract(1, "days")
              .format("YYYY-MM-DD"),
          };
        });
      }
    } else if (
      e.target.value == 3 &&
      e.target[e.target.value - 1].innerHTML == "Last Month"
    ) {
      if (chart == "Card") {
        setCardFilters((prev) => {
          return {
            ...prev,
            start_date: moment(new Date())
              .subtract(1, "months")
              .startOf("month")
              .format("YYYY-MM-DD"),
            end_date: moment(new Date())
              .subtract(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD"),
            custom_dates: false,
          };
        });
      } else {
        setShowCustomDates(false);
        setSalesChartFilters((prev) => {
          return {
            ...prev,
            start_date: moment(new Date())
              .subtract(1, "months")
              .startOf("month")
              .format("YYYY-MM-DD"),
            end_date: moment(new Date())
              .subtract(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD"),
          };
        });
      }
    } else if (
      e.target.value == 4 &&
      e.target[e.target.value - 1].innerHTML == "This Month"
    ) {
      if (chart == "Card") {
        setCardFilters((prev) => {
          return {
            ...prev,
            start_date: moment(new Date())
              .startOf("month")
              .format("YYYY-MM-DD"),
            end_date: moment(new Date()).endOf("month").format("YYYY-MM-DD"),
            custom_dates: false,
          };
        });
      } else {
        setShowCustomDates(false);
        setSalesChartFilters((prev) => {
          return {
            ...prev,
            start_date: moment(new Date())
              .startOf("month")
              .format("YYYY-MM-DD"),
            end_date: moment(new Date()).endOf("month").format("YYYY-MM-DD"),
          };
        });
      }
    } else if (
      e.target.value == 5 &&
      e.target[e.target.value - 1].innerHTML == "Last Week"
    ) {
      if (chart == "Card") {
        setCardFilters((prev) => {
          return {
            ...prev,
            start_date: moment(new Date())
              .subtract(1, "week")
              .startOf("week")
              .subtract(1, "day")
              .format("YYYY-MM-DD"),
            end_date: moment(new Date())
              .subtract(1, "week")
              .endOf("week")
              .subtract(1, "day")
              .format("YYYY-MM-DD"),
            custom_dates: false,
          };
        });
      } else {
        setShowCustomDates(false);
        setSalesChartFilters((prev) => {
          return {
            ...prev,
            start_date: moment(new Date())
              .subtract(1, "week")
              .startOf("week")
              .subtract(1, "day")
              .format("YYYY-MM-DD"),
            end_date: moment(new Date())
              .subtract(1, "week")
              .endOf("week")
              .subtract(1, "day")
              .format("YYYY-MM-DD"),
          };
        });
      }
    } else if (
      e.target.value == 6 &&
      e.target[e.target.value - 1].innerHTML == "This Week"
    ) {
      if (chart == "Card") {
        setCardFilters((prev) => {
          return {
            ...prev,
            start_date: moment(new Date())
              .startOf("week")
              .subtract(1, "day")
              .format("YYYY-MM-DD"),
            end_date: moment(new Date())
              .endOf("week")
              .subtract(1, "day")
              .format("YYYY-MM-DD"),
            custom_dates: false,
          };
        });
      } else {
        setShowCustomDates(false);
        setSalesChartFilters((prev) => {
          return {
            ...prev,
            start_date: moment(new Date())
              .startOf("week")
              .subtract(1, "day")
              .format("YYYY-MM-DD"),
            end_date: moment(new Date())
              .endOf("week")
              .subtract(1, "day")
              .format("YYYY-MM-DD"),
          };
        });
      }
    } else if (
      e.target.value == 7 &&
      e.target[e.target.value - 1].innerHTML == "Custom"
    ) {
      if (chart == "Card") {
        setCardFilters((prev) => {
          return {
            ...prev,
            custom_dates: true,
          };
        });
      } else {
        setShowCustomDates(true);
      }
    }
  };

  const salesDataModificationForChart = (salesDataFromAPI) => {
    setSalesDataLoader(true);
    // for Reservation Chart
    let labels = [];
    let nors = [];

    // for Sales Chart
    let sales_paid = [];
    let sales_pending = [];

    if (salesDataFromAPI && salesDataFromAPI?.length > 0) {
      salesDataFromAPI.forEach((el) => {
        // labels.push(moment(el.date).format('YYYY-MM-DD'))
        // labels.push(moment(el.date).format('ll'))
        labels.push(moment(el.date).format("MMM Do YY"));
        nors.push(el.number_of_reservations);

        sales_paid.push(el.total_sales?.payment_amount_paid);
        sales_pending.push(el.total_sales?.payment_amount_pending);
      });
    }
    setNors(nors);
    setSalesLabel(labels);
    setSalesData({
      paidAmountData: sales_paid,
      pendingAmountData: sales_pending,
    });
    setSalesDataLoader(false);
  };

  const packageSalesDataModificationForChart = (salesDataFromAPI) => {
    setIsPackageDataLoading(true);
    // for Reservation Chart
    let labels = [];
    let nors = [];

    if (salesDataFromAPI && salesDataFromAPI?.length > 0) {
      salesDataFromAPI.forEach((el) => {
        labels.push(moment(el.date).format("MMM Do YY"));
        nors.push(el.number_of_packages_sold);
      });
    }

    setNorps(nors);
    setPackageSalesLabel(labels);

    setPackageSalesData(salesDataFromAPI);
    setIsPackageDataLoading(false);
  };

  const getSalesData = () => {
    setSalesDataLoader(true);
    SalesServices.getSalesDataForChart(salesChartFilters)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            salesDataModificationForChart(data.data);
          } else {
            toast.error("Sales Data Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setSalesDataLoader(false);
      })
      .catch((error) => {
        const msg = "Error in Getting Sales Data"; //error.response.data.message;
        toast.error(msg);
        setSalesDataLoader(false);
      });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const newCards = Array.from(favs);
    const [reorderedCard] = newCards.splice(result.source.index, 1);
    newCards.splice(result.destination.index, 0, reorderedCard);
    updateFevs(newCards);
    setFavs(newCards);
  };

  const updateFevs = (newCards) => {
    MasterServices.updateFavourite(newCards)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          getUserBasedAllFav();
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const msg = "Failed catch Error";
        toast.error(msg);
      });
  };

  const getUserBasedAllFav = () => {
    MasterServices.getUserBasedAllFav()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          setFavs(data.data);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const msg = "Failed catch Error";
        toast.error(msg);
      });
  };

  const regionDataModificationForChart = (salesDataFromAPI) => {
    setIsRegionDataLoading(true);
    // for Reservation Chart
    let labels = [];
    let obj = {};
    // let region_names

    if (salesDataFromAPI && salesDataFromAPI?.length > 0) {
      salesDataFromAPI.forEach((el, i) => {
        // labels.push(moment(el.date).format('YYYY-MM-DD'))
        // labels.push(moment(el.date).format('ll'))

        labels.push(moment(el.date).format("MMM Do YY"));

        let all_region_names = Object.keys(el.regions_data);
        all_region_names.forEach((reg_name) => {
          if (obj[reg_name] == undefined) {
            obj[reg_name] = [el.regions_data[reg_name]];
          } else {
            obj[reg_name] = [...obj[reg_name], el.regions_data[reg_name]];
          }
        });
      });
    }
    setRegionSalesLabel(labels);

    setRegionSalesData(obj);
    setIsRegionDataLoading(false);
  };

  const getRSalesData = () => {
    setIsRegionDataLoading(true);
    SalesServices.getRegionSalesData(rSalesChartFilters)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            regionDataModificationForChart(data.data);
          } else {
            toast.error("Region Sales Data Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setIsRegionDataLoading(false);
      })
      .catch((error) => {
        const msg = "Error in Getting Region Sales Data"; //error.response.data.message;
        toast.error(msg);
        setIsRegionDataLoading(false);
      });
  };

  const getPSalesData = () => {
    setIsPackageDataLoading(true);
    let package_ids = [];
    pSalesChartFilters.package_ids.forEach((p) =>
      package_ids.push(p.package_id)
    );
    SalesServices.getPackagesSoldDataForChart({
      ...pSalesChartFilters,
      location_id: selectedLocationPSalesChart?.loc_id,
      package_ids,
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            packageSalesDataModificationForChart(data.data);
          } else {
            toast.error("Package Sales Data Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setIsPackageDataLoading(false);
      })
      .catch((error) => {
        const msg = "Error in Getting Package Sales Data"; //error.response.data.message;
        toast.error(msg);
        setIsPackageDataLoading(false);
      });
  };

  const getTSalesData = () => {
    setIsTestDataLoading(true);
    let test_ids = [];
    tSalesChartFilters.test_ids.forEach((t) => test_ids.push(t.test_id));
    SalesServices.getTestsSoldDataForChart({
      ...tSalesChartFilters,
      location_id: selectedLocationTSalesChart?.loc_id,
      test_ids,
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            testSalesDataModificationForChart(data.data);
          } else {
            toast.error("Test Sales Data Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setIsTestDataLoading(false);
      })
      .catch((error) => {
        const msg = "Error in Getting Test Sales Data"; //error.response.data.message;
        toast.error(msg);
        setIsTestDataLoading(false);
      });
  };

  const testSalesDataModificationForChart = (salesDataFromAPI) => {
    setIsTestDataLoading(true);
    // for Reservation Chart
    let labels = [];
    let nors = [];

    // for Sales Chart
    // let sales_paid = [];
    // let sales_pending = [];

    if (salesDataFromAPI && salesDataFromAPI?.length > 0) {
      salesDataFromAPI.forEach((el) => {
        labels.push(moment(el.date).format("MMM Do YY"));
        nors.push(el.number_of_tests_sold);

        // sales_paid.push(el.total_sales?.payment_amount_paid)
        // sales_pending.push(el.total_sales?.payment_amount_pending)
      });
    }

    setNorts(nors);
    setTestSalesLabel(labels);

    // setSalesData({
    //   paidAmountData: sales_paid,
    //   pendingAmountData: sales_pending
    // })

    setTestSalesData(salesDataFromAPI);
    setIsTestDataLoading(false);
  };

  const handleMultiLocations = (event) => {

    let location_ids = [];
    let allLoc = [];
    if (event.some(item => item.label === "ALL")) {
      // Filter out items with label 'ALL' from locationList
      allLoc = locationList.filter(item => item.label !== 'ALL');

      for (let i = 0; i < allLoc.length; i++) {
        location_ids.push(allLoc[i].value);
      }
      setSelectedLocationsSalesChart(allLoc);
    } else {
      for (let i = 0; i < event.length; i++) {
        location_ids.push(event[i].value);
      }
      setSelectedLocationsSalesChart(event);
      // If no 'ALL' label, use the event array as is
      // for (let i = 0; i < event.length; i++) {
      //   location_ids.push(event[i].value);
      // }
      // location_ids = JSON.stringify(event.value);
    }
    location_ids = location_ids;
    // location_ids = JSON.stringify(location_ids);
    setSalesChartFilters((prev) => {
      return {
        ...prev,
        location_ids,
      };
    });
  };

  const getStatsData = () => {
    setCardDataLoading(true);
    SalesServices.getCardStats(cardFilters)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            let Sample = data?.data;
            Sample.forEach((element, key) => {
              stats.forEach((element1, key1) => {
                if (key == key1) {
                  element1.stat = element;
                }
              });
            });
            setStatsList(stats);
            setAnalyzedSample(data.data);
          }
        }
        setCardDataLoading(false);
      })
      .catch((error) => {
        const msg = "Error in Getting Card Stats Data";
        toast.error(msg);
        setCardDataLoading(false);
      });
  };

  return isLoading ? (
    <FallingLinesLoader />
  ) : (
    <div>
      <Breadcrumb pages={pages} />

      {favs?.length > 0 ? (
        <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-5">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Favourites
            </h3>
          </div>
          <div className="border-t border-gray-200 px-4 py-5">
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="favs" direction="horizontal">
                {(provided) => (
                  <ul
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="grid grid-cols-1 gap-4 sm:grid-cols-3 mt-5"
                  >
                    {favs.map((card, index) => (
                      <Draggable
                        key={card?.sys_module_relation?.module_id}
                        draggableId={card?.sys_module_relation?.module_id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Link to={card?.sys_module_relation?.module_key}>
                              <div
                                key={card?.sys_module_relation?.module_id}
                                className="relative flex items-center space-x-3 rounded-lg border border-gray-300 hover:border-cyan-800 bg-white px-6 py-5 shadow-sm card-sec"
                              >
                                <div className="flex-shrink-0">
                                  <FolderIcon className="h-8 w-8 text-cyan-800" />
                                </div>
                                <div className="min-w-0 flex-1">
                                  <p className="text-sm font-medium text-cyan-900">
                                    {card?.sys_module_relation?.module_name}
                                  </p>
                                  {/* <p className="truncate text-sm text-cyan-500">{card?.sys_module_relation?.role}</p> */}
                                </div>
                              </div>
                            </Link>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      ) : null}

      {/* Stats Cards Start */}

      {/* Stats Cards Filters Start */}
      <div>
        <div className="basis-1/3">
          <label
            htmlFor="date_filter"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Date Range
          </label>
          <select
            className="h-9 py-1 rounded-md border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50 w-60"
            name="date_filter"
            id="date_filter"
            // value={}
            defaultValue={1}
            onChange={(e) => {
              //   setFilter(e.target.value || undefined);
              handleDateFilterChange(e, "Card");
            }}
          >
            {dateFilters.map((option, i) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mt-5 flex gap-3 items-center">
        {/* start date */}
        <div className="basis-1/3 sm:mt-0">
          <input
            disabled={cardDataLoading || !cardFilters.custom_dates}
            className={`block rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-full sm:text-sm ${cardFilters.custom_dates
              ? "border-green-500 text-gray-600"
              : "border-gray-300 text-gray-400"
              }`}
            type="date"
            name="c_stat_start_date"
            id="c_stat_start_date"
            value={cardFilters.start_date}
            onChange={(e) => {
              setCardFilters((prev) => {
                if (prev.end_date < e.target.value) {
                  return {
                    ...prev,
                    end_date: e.target.value,
                    start_date: prev.end_date,
                  };
                }
                return {
                  ...prev,
                  start_date: e.target.value,
                };
              });
            }}
          />
        </div>

        {/* end date */}
        <div className="basis-1/3 sm:mt-0">
          <input
            disabled={cardDataLoading || !cardFilters.custom_dates}
            className={`block rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-full sm:text-sm ${cardFilters.custom_dates
              ? "border-green-500 text-gray-600"
              : "border-gray-300 text-gray-400"
              }`}
            type="date"
            name="c_stat_end_date"
            id="c_stat_end_date"
            value={cardFilters.end_date}
            onChange={(e) => {
              setCardFilters((prev) => {
                if (prev.start_date > e.target.value) {
                  return {
                    ...prev,
                    end_date: prev.start_date,
                    start_date: e.target.value,
                  };
                }
                return {
                  ...prev,
                  end_date: e.target.value,
                };
              });
            }}
          />
        </div>

        <button
          disabled={salesDataLoader}
          onClick={() => {
            getStatsData();
          }}
          className={`ml-3 h-10 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${salesDataLoader ? "opacity-40" : "opacity-100"
            }`}
        >
          Get Data
        </button>
        {/* <span className="text-base font-medium text-black-900 ml-2">{"(" +state.user.list.length+ ")"}</span> */}
      </div>
      {/* Stats Cards Filters End */}

      {cardDataLoading ? (
        <FallingLinesLoader />
      ) : (
        <div className="mt-5">
          {/* <h3 className="text-base font-semibold leading-6 text-gray-900">Last 30 days</h3> */}
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            {statsList.map((item, index) => (
              <div
                key={index}
                className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
              >
                <dt className="truncate text-sm font-medium text-gray-500">
                  {item.name}
                </dt>
                <dd className="mt-1 text-2xl font-semibold tracking-tight text-gray-900">
                  {item.stat}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      )}

      {/* Stats Cards End */}

      {/* Sales Chart - Abhi */}
      {!isLoading ? (
        <div
          className={`overflow-hidden bg-white shadow sm:rounded-lg mt-5 ${salesDataLoader ? "text-gray-300" : "text-gray-500"
            }`}
        >
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Sales and Reservations
            </h3>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <div className="px-4 sm:px-6">
              <div className="flex gap-3">
                {/* Location Multiselect */}
                <div className="basis-1/3">
                  <label
                    htmlFor="fk_location_ids"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Locations
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      // defaultValue={testList[0]}
                      isMulti
                      name="fk_location_ids"
                      id="fk_location_ids"
                      value={selectedLocationsSalesChart}
                      onChange={(e) => handleMultiLocations(e)}
                      options={locationList}
                      className="basic-multi-select"
                      classNamePrefix="Select Test"
                    />
                  </div>
                </div>

                <div className="basis-1/3">
                  <label
                    htmlFor="date_filter"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Date Range
                  </label>
                  <select
                    className="h-9 py-1 rounded-md border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50 w-60"
                    name="date_filter"
                    id="date_filter"
                    // value={}
                    defaultValue={1}
                    onChange={(e) => {
                      //   setFilter(e.target.value || undefined);
                      handleDateFilterChange(e);
                    }}
                  >
                    {dateFilters.map((option, i) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* <div className="basis-1/2"> */}
              <div className="mt-5 flex gap-3 items-center">
                {/* start date */}
                <div className="basis-1/3 sm:mt-0">
                  <input
                    disabled={salesDataLoader || !showCustomDates}
                    className={`block rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-full sm:text-sm ${showCustomDates
                      ? "border-green-500 text-gray-500"
                      : "border-gray-300 text-gray-300"
                      }`}
                    type="date"
                    name="start_date"
                    id="start_date"
                    value={salesChartFilters.start_date}
                    onChange={(e) => {
                      setSalesChartFilters((prev) => {
                        if (prev.end_date < e.target.value) {
                          return {
                            ...prev,
                            end_date: e.target.value,
                            start_date: prev.end_date,
                          };
                        }
                        return {
                          ...prev,
                          start_date: e.target.value,
                        };
                      });
                    }}
                  />
                </div>

                {/* end date */}
                <div className="basis-1/3 sm:mt-0">
                  <input
                    disabled={salesDataLoader || !showCustomDates}
                    className={`block rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-full sm:text-sm ${showCustomDates
                      ? "border-green-500 text-gray-500"
                      : "border-gray-300 text-gray-300"
                      }`}
                    type="date"
                    name="end_date"
                    id="end_date"
                    value={salesChartFilters.end_date}
                    onChange={(e) => {
                      setSalesChartFilters((prev) => {
                        if (prev.start_date > e.target.value) {
                          return {
                            ...prev,
                            end_date: prev.start_date,
                            start_date: e.target.value,
                          };
                        }
                        return {
                          ...prev,
                          end_date: e.target.value,
                        };
                      });
                    }}
                  />
                </div>

                <button
                  disabled={salesDataLoader}
                  onClick={() => {
                    getSalesData();
                  }}
                  className={`ml-3 h-10 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${salesDataLoader ? "opacity-40" : "opacity-100"
                    }`}
                >
                  Get Data
                </button>
                {/* <span className="text-base font-medium text-black-900 ml-2">{"(" +state.user.list.length+ ")"}</span> */}
              </div>
              {/* </div> */}
            </div>

            {/* Reservation Chart */}
            {salesData && !salesDataLoader ? (
              <div className="flex flex-row text-center p-8">
                <div className="basis-1/2">
                  <div className="w-30 mx-auto">
                    <ReservationChart
                      numberOfReservationsData={nors}
                      salesLabel={salesLabel}
                    />
                  </div>
                  <div className="mt-5 flex justify-center">
                    <label className="text-base font-medium text-black-900">
                      Reservations
                    </label>
                    {/* <span className="text-base font-medium text-black-900 ml-2">{"(" +state.user.list.length+ ")"}</span> */}
                  </div>
                </div>

                <div className="basis-1/2">
                  <div className="w-30 mx-auto">
                    <SalesChart salesData={salesData} salesLabel={salesLabel} />
                  </div>
                  <div className="mt-5 flex justify-center">
                    <label className="text-base font-medium text-black-900">
                      Sales
                    </label>
                    {/* <span className="text-base font-medium text-black-900 ml-2">{"(" +state.user.list.length+ ")"}</span> */}
                  </div>
                </div>
              </div>
            ) : (
              <FallingLinesLoader />
            )}
          </div>
        </div>
      ) : (
        <FallingLinesLoader />
      )}

      <PackageChart
        selectedLocationPSalesChart={selectedLocationPSalesChart}
        setSelectedLocationPSalesChart={setSelectedLocationPSalesChart}
        isPackageDataLoading={isPackageDataLoading}
        pSalesChartFilters={pSalesChartFilters}
        setPSalesChartFilters={setPSalesChartFilters}
        getPSalesData={getPSalesData}
        l_List={locationList}
        pList={packList}
        packageSalesData={packageSalesData}
        packageSalesLabel={packageSalesLabel}
        norps={norps}
        isLoading={isLoading}
        dateFilters={dateFilters}
      />

      <TestChart
        selectedLocationTSalesChart={selectedLocationTSalesChart}
        setSelectedLocationTSalesChart={setSelectedLocationTSalesChart}
        isTestDataLoading={isTestDataLoading}
        tSalesChartFilters={tSalesChartFilters}
        setTSalesChartFilters={setTSalesChartFilters}
        getTSalesData={getTSalesData}
        l_List={locationList}
        tList={testList}
        testSalesData={testSalesData}
        testSalesLabel={testSalesLabel}
        norts={norts}
        isLoading={isLoading}
        dateFilters={dateFilters}
      />

      <RegionChart
        isRegionDataLoading={isRegionDataLoading}
        rSalesChartFilters={rSalesChartFilters}
        setRSalesChartFilters={setRSalesChartFilters}
        getRSalesData={getRSalesData}
        regionSalesData={regionSalesData}
        regionSalesLabel={regionSalesLabel}
        norrs={norrs}
        isLoading={isLoading}
        dateFilters={dateFilters}
      />
    </div>
  );
};

export default Home;
