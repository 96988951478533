import React, { useEffect } from 'react'
import Breadcrumb from '../../../components/Breadcrumb';
import { useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { clinicRefferalSchema } from "../../../schemas";
import MasterServices from '../../../ApiServices/MasterServices';
import { toast } from 'react-toast';
import PhoneInputWithCountry, { isValidPhoneNumber, parsePhoneNumber, getCountryCallingCode } from 'react-phone-number-input'


let initialValues = {
  resv_ref_name: "",
  resv_ref_phone: "",
  resv_ref_contact_address: "",
  resv_ref_discount_number: "",
};

const AddClinicRefferal = () => {
  const pages = [{ title: "Add Clinic Refferal", href: "/add-clinic-refferal", module_id: 26 }];
  const [loading, setLoading] = useState(false);
  const { resv_ref_id: RRID } = useParams();

  const [formState, setFormState] = useState(initialValues);
  const [mobileNumber, setMobileNumber] = useState('');
	const [userMobileNumber, setUserMobileNumber] = useState('');
	const [mobileNumberValid, setMobileNumberValid] = useState(true);
	const [mobileCountryCode, setMobileCountryCode] = useState('')
	const [initialRender, setInitialRender] = useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    if (RRID) getClinicRefferalByID(RRID);

  }, [RRID]);


  //Mobile number validation in patient
  useEffect(() => {
    if(!initialRender) {
      // console.log(mobileNumber.toString())
      if (mobileNumber && isValidPhoneNumber(mobileNumber.toString())) {
      const phoneNumber = parsePhoneNumber(mobileNumber)
      console.log(phoneNumber)
      if (phoneNumber) {
        setMobileCountryCode(getCountryCallingCode(phoneNumber.country));
        setUserMobileNumber(phoneNumber.nationalNumber);
      }
      setMobileNumberValid(true);
      } else {
      setMobileNumberValid(false)
      }
    } else {
      setInitialRender(false)
    }
  }, [mobileNumber])

  const onMobileNumberChange = (value) => {
		if(value) {
			setMobileNumber(value)
		}
	}

  const getClinicRefferalByID = (id) => {
    setLoading(true);
    MasterServices.getSingleClinicRefferal(id)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setFormState({
            resv_ref_name: data.data.resv_ref_name,
            resv_ref_phone: data.data.resv_ref_phone,
            resv_ref_contact_address: data.data.resv_ref_contact_address,
            resv_ref_discount_number: data.data.resv_ref_discount_number
          })
          setMobileNumber(`+${data.data.resv_ref_dial_code}${data.data.resv_ref_phone}`)
          console.log(data.data)
          // toast.success("Items Fetched Successfully");
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };

  const addClinicRefferal = async (payload) => {
    try {
      const {data, status} = await MasterServices.saveClinicRefferal(payload);
      if (status === 200) {
        console.log(data.data)
        toast.success("Clinic Refferal added successfully");
        setLoading(false);
      } else {
        toast.error("Fatal Error Please Contact Admin");
      }
    } catch(err) {
        const msg = err.data.message;
        toast.error(msg);
        setLoading(false);
    }
  };

  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      validationSchema: clinicRefferalSchema,
      onSubmit: async (values, action) => {
        if(!mobileNumberValid) {
          return false;
        }
        const body = { ...values };
        body.resv_ref_dial_code = mobileCountryCode
        body.resv_ref_phone = userMobileNumber
        if(RRID) {
          body.resv_ref_id = RRID;
        }
        console.log(body)
        await addClinicRefferal(body);
        navigate("/clinic-refferal");
      },
    });
    

  return (
    <div>
      <Breadcrumb pages={pages} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
        className="space-y-8 divide-y divide-gray-200"
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">

          <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
            <div>
              <h3 className="text-base font-semibold leading-6 text-gray-900">Add Clinic Refferal</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
               add clinic Refferal
              </p>
            </div>
            <div className="flex sm:border-t sm:border-gray-200 sm:pt-5 gap-5">
              <div className="basis-1/3">
                <label htmlFor="account-name" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Clinic Refferal Name
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0 w-80">
                  <input
                    type="text"
                    name="resv_ref_name"
                    id="resv_ref_name"
                    autoComplete="ref"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    value={values.resv_ref_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.resv_ref_name && touched.resv_ref_name ? (
                    <p className="text-red-600 text-sm">{errors.resv_ref_name}</p>
                  ) : null}
                </div>
              </div>
            </div>
              <div className="basis-1/3">
                <label htmlFor="account-name" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Phone Number
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0 w-80">
                  <PhoneInputWithCountry
                    defaultCountry="SA"
                    value={mobileNumber}
                    placeholder="Enter phone number"
                    onChange={e => {
                      values.resv_ref_phone = e
                      onMobileNumberChange(e)}
                    }
                  />
                  {errors.resv_ref_phone && touched.resv_ref_phone ? (
                    <p className="text-red-600 text-sm">{errors.mclr_name}</p>
                  ) : null}
                  {
                    !mobileNumberValid ? (
                    <p className="text-red-600 text-sm">Mobile Number is invalid</p>
                    ) : null
                  }
                </div>
              </div>
              <div className="basis-1/3">
                <label htmlFor="account-name" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Clinic Refferal Address
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0 w-80">
                  <input
                    type="text"
                    name="resv_ref_contact_address"
                    id="resv_ref_contact_address"
                    autoComplete="contact address"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    value={values.resv_ref_contact_address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.resv_ref_contact_address && touched.resv_ref_contact_address ? (
                    <p className="text-red-600 text-sm">{errors.resv_ref_contact_address}</p>
                  ) : null}
                </div>
            </div>
            <div className="basis-1/3">
                <label htmlFor="account-name" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Discount (%)
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0 w-80">
                  <input
                    type="number"
                    name="resv_ref_discount_number"
                    id="resv_ref_discount_number"
                    autoComplete="clinic resv_ref_discount_number"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    value={values.resv_ref_discount_number}
                    onChange={(e) => {
                      if(e.target.value > 100) {
                        e.target.value = 100;
                      }
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                  />
                  {errors.resv_ref_discount_number && touched.resv_ref_discount_number ? (
                    <p className="text-red-600 text-sm">{errors.resv_ref_discount_number}</p>
                  ) : null}
                </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end gap-x-3">
            <button
              type="button"
              onClick={e => navigate('/clinic-refferal')}
              className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={() => {
                values.resv_ref_phone = mobileNumber
                if(mobileNumber == '') {
                  setMobileNumberValid(false);
                }
              }}
              className="inline-flex justify-center rounded-md bg-cyan-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
            >
              {RRID ? 'Edit' : 'Save'}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AddClinicRefferal