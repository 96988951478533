import React from "react";

const defaultProps = {
  variant: "primary",
  size: "small",
  type: "button",
  loading: false,
  disabled: false,
};

const Button = ({
  className,
  loading,
  variant,
  size,
  type,
  children,
  disabled,
  onClick,
  ...props
}) => {
  const onClickHandler = (event) => {
    if (disabled || loading) return;
    onClick && onClick(event);
  };

  return (
    <button
      onClick={onClickHandler}
      className="inline-flex w-full justify-center rounded-md border border-transparent bg-cyan-800 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:text-sm items-center"
      disabled={disabled}
      type={type}
      aria-label={type}
      {...props}
    >
      {!loading && children}
      {loading && (
        <div className="flex justify-center items-center">
          <div
            className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full"
            role="status"
          ></div>
        </div>
      )}
    </button>
  );
};

Button.defaultProps = defaultProps;

export default Button;
