export const GET_ALL_HOSPITAL_BEGIN = "GET_ALL_HOSPITAL_BEGIN"
export const GET_ALL_HOSPITAL_SUCCESS = "GET_ALL_HOSPITAL_SUCCESS"
export const GET_ALL_HOSPITAL_ERROR = "GET_ALL_HOSPITAL_ERROR"

export const ADD_HOSPITAL_BEGIN = "ADD_HOSPITAL_BEGIN"
export const ADD_HOSPITAL_SUCCESS = "ADD_HOSPITAL_SUCCESS"
export const ADD_HOSPITAL_ERROR = "ADD_HOSPITAL_ERROR"

export const GET_HOSPITAL_BEGIN = "GET_HOSPITAL_BEGIN"
export const GET_HOSPITAL_SUCCESS = "GET_HOSPITAL_SUCCESS"
export const GET_HOSPITAL_ERROR = "GET_HOSPITAL_ERROR"

export const UPDATE_HOSPITAL_BEGIN = "UPDATE_HOSPITAL_BEGIN"
export const UPDATE_HOSPITAL_SUCCESS = "UPDATE_HOSPITAL_SUCCESS"
export const UPDATE_HOSPITAL_ERROR = "UPDATE_HOSPITAL_ERROR"

export const DELETE_HOSPITAL_BEGIN = "DELETE_HOSPITAL_BEGIN"
export const DELETE_HOSPITAL_SUCCESS = "DELETE_HOSPITAL_SUCCESS"
export const DELETE_HOSPITAL_ERROR = "DELETE_HOSPITAL_ERROR"