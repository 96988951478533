import Breadcrumb from '../../../components/Breadcrumb';
import $ from "jquery"; //Load jquery
import React, { Component, createRef, useEffect, useState } from "react"; //For react component
import { getAllQuestionnaires } from '../../../redux/questionnaire/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { FallingLinesLoader } from '../../../components/spinners/Spinner';

window.jQuery = $; //JQuery alias
window.$ = $; //JQuery alias

require("jquery-ui-sortable"); //For FormBuilder Element Drag and Drop
// require("formBuilder");// For FormBuilder
require("formBuilder/dist/form-render.min.js")

const QuestionnaireRender = () => {
    const pages = [{ title: "Questionnaire", href: "/questionnaire/render", module_id: 25 }];
    const dispatch = useDispatch();
    const {
      loading,
      questionnaire: { list: questionnaireList }
    } = useSelector((state) => state);
    const fb = createRef();
    let formRender;

    useEffect(() => {
      if(Array.isArray(questionnaireList) && questionnaireList.length > 0) {
        console.log(questionnaireList[0].qstn_json)
        console.log(formRender)
        if(!formRender){
          let formData = questionnaireList[0].qstn_json;
          formRender = $(fb.current).formRender({ formData });
        }
      } else {
        (async function() {
          await dispatch(getAllQuestionnaires());
        })()
      }
    }, [questionnaireList])

    useEffect(() => {
      console.log(fb)
    }, [])
    function saveData() {
      console.log($(fb.current).formRender("userData"))
    }
    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <Breadcrumb pages={pages} />
            {loading ? (<FallingLinesLoader />) : <>
            <form id="fb-render" ref={fb}></form>
            <button
                onClick={saveData}
                type="button"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              >
                Save
              </button>
            </>}
        </div>
    )
}

export default QuestionnaireRender