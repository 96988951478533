import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import { incentive_dashboard } from '../../../components/tables/tableheader';
import Table from '../../../components/tables/table';
import MasterServices from '../../../ApiServices/MasterServices';
import { toast } from 'react-toast';

const IncentiveDashboard = () => {
    const pages = [{ title: "Incentive Dashboard", href: "/incentive-dashboard", module_id: 0 }];
    const [loading, setLoading] = useState(false)
    const [incentiveLogDetails, setIncentiveLogDetails ] = useState(false)

    useEffect(()=>{
        getIncentiveLogs()
    },[])

    const getIncentiveLogs = async () =>{
        setLoading(true)
        try{
            let res = await MasterServices.getAllIncentiveLogs()

            if(res.status == 200){
                setIncentiveLogDetails(res.data.data)
            }
        }catch(e){
            toast.error(e.message)
        }
        setLoading(false)
    }

    return (
        <div>
            <Breadcrumb pages={pages} />
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Incentive Dashboard</h1>
                <p className="mt-2 text-sm text-gray-700">A list of all the Incentive Dashboard.</p>
            </div>
            {loading  ? (<FallingLinesLoader />) : (
                <Table
                    columns={incentive_dashboard()}
                    data={incentiveLogDetails && incentiveLogDetails.length > 0 ? incentiveLogDetails : []}
                />
            )}
        </div>
    )
}

export default IncentiveDashboard