import React, { useState } from "react";
import { PencilIcon, ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { BASEURL } from "../../helper/constant.js";
import { toast } from "react-toast";
import SampleServices from "../../ApiServices/SampleServices.js";
//import { singleUploadFilesWithUpdate } from "../../helper/multiUploadHelper";

const CustomResultDropDownResult = ({inputs_value, value, id, originalCell, saveUpdateFunction }) => {


  const handleDDResultChange = async (payload) => {
    console.log("handleDDResultChange payload", { payload, id, originalCell });

    let { sample_id, reservation_id, test_id, ttp_id, test_result } = originalCell;
    console.log("originalCell File Upload", originalCell);

    let fk_subtest_id = null;
    if (ttp_id) { fk_subtest_id = ttp_id;}

    if (sample_id && test_id && reservation_id) {
      const data = {
        ssp_text_value: payload,
        fk_sample_id: sample_id,
        fk_test_id: test_id,
        fk_reservation_id: reservation_id,
        ssp_id:
          test_result.length > 0
            ? test_result[0]?.ssp_id
            : null,
        fk_subtest_id,
        allData: originalCell,
      };

      
        let res = await SampleServices.customSelectSampleTestResult(data)
        if (res.status == 200) {
          toast.success(res.data.message);
          saveUpdateFunction();
        } else {
          toast.error(res.data.message);
        }

    }

  };

  return (
    <>
      <select
        className="block max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
        name={`color_name_${id}`}
        id={`color_name_${id}`}
        onChange={(e) => {
          handleDDResultChange(e.target.value);
        }}
      >
        <option selected disabled>
          {" "}
          Select Type{" "}
        </option>
        {inputs_value.map((data, i) => (
          <option
            selected={data.name === value ? "selected" : ""}
            key={i}
            value={data.name}
          >
            {data.name}
          </option>
        ))}
      </select>
    </>
  );
};

export default CustomResultDropDownResult;
