import { useEffect } from 'react';
import { Movement_columns } from '../../../components/tables/tableheader';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Modal from '../../../components/Modal';
import InputBox from '../../../components/InputBox';
import Button from '../../../components/Button';
import Breadcrumb from '../../../components/Breadcrumb';
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import TableEmpty from '../../../components/tables/tableEmpty';
import MasterServices from '../../../ApiServices/MasterServices';
import { toast } from 'react-toast';
import Select from 'react-select';
import { FallingLines } from 'react-loader-spinner';

import ConfirmationModal from '../../../components/ConfirmationModal';
const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    from: '',
    to: '',
    item_id: '',
    quantity: '',
    type: '',
  },
};

function Movement() {
  const pages = [{ title: 'Movement', href: '/movements', module_id: 31 }];
  const [loading, setLoading] = useState(false);
  const [loading_button, set_loading_button] = useState(false);

  const [max_from, set_max_from] = useState(999999999999);
  const [from_to, set_from_to] = useState({ from: null, to: null });
  const [packageList, setPackageList] = useState(false);
  const [controlList, setControlList] = useState([]);
  const [openComments, setOpenComments] = useState(false);
  const [comment, setComment] = useState();
  const [selected_box, set_selected_box] = useState({
    from: null,
    to: null,
    item: null,
    type: null,
  });

  const [modal, setModal] = useState(initialModalState);
  const [itemsList, setItemsList] = useState([]);
  const [storageLocations, setStorageLocations] = useState({
    loading: false,
    data: [],
  });
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    mo_id: null,
  });
  const [movementlist, setMovementlist] = useState({
    loading: false,
    data: [],
  });
  useEffect(() => {
    getAllMovement();
    getAllStorageLocations();
    getAllItems();
    document.title = 'New Labib | Movement';
  }, []);
  useEffect(() => {
    if (modal.edit_id !== '') {
      getMovementById(modal.edit_id);
    }
  }, [modal.edit_id]);
  const getAllItems = async () => {
    setLoading(true);
    await MasterServices.getStok()
      .then((response) => {
        const { data, status } = response;
        if (status === 200) {
          setItemsList(data.data);
          setLoading(false);
        } else {
          toast.error('Fatal Error Please Contact Admin');
        }
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
    setLoading(false);
  };
  const getAllMovement = async () => {
    setMovementlist((prev) => ({ ...prev, loading: true }));
    await MasterServices.getMovementList()
      .then((res) => {
        const { data, status } = res;
        console.log(res);
        if (status === 200) {
          setMovementlist({
            loading: false,
            data: data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllStorageLocations = async () => {
    setStorageLocations((prev) => ({ ...prev, loading: true }));
    await MasterServices.getAllStorageLocation()
      .then((res) => {
        const { data, status } = res;
        console.log(res);
        if (status === 200) {
          setStorageLocations({
            loading: false,
            data: data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const createMovement = async (body) => {
    set_selected_box({
      from: null,
      to: null,
      item: null,
      type: null,
    });
    setModal((prev) => ({ ...prev, data: {}, state: false }));
    await MasterServices.createMovementList(body)
      .then((res) => {
        const { data, status } = res;
        if (status === 201) {
          getAllMovement();
          toast.success('Movement Added Successfully');
          return true;
        } else if (status === 400) {
          toast.error(data.message);
        }
      })
      .catch((err) => {
        toast.error(err.data.message);
      });
  };
  const getMovementById = async (id) => {
    await MasterServices.getOneMovement(id)
      .then((res) => {
        const { data, status } = res;
        console.log(res);
        if (status === 200) {
          setModal((prev) => ({
            ...prev,
            type: 'edit',
            data: {
              from: data.data.from,
              to: data.data.to,
              item_id: data.data.item_id,
              quantity: data.data.quantity,
              type: data.data.type,
            },
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateMovements = async (id, body) => {
    await MasterServices.updateMovement(id, body)
      .then((res) => {
        const { data, status } = res;
        if (status === 201) {
          getAllMovement();
          toast.success('Movement Updated Successfully');
          setModal((prev) => ({
            ...prev,
            data: {},
            type: '',
            edit_id: '',
            state: false,
          }));
        }
      })
      .catch((err) => {
        toast.error(err.data.message);
        console.log(err);
      });
  };
  const onDeleteOpen = (id) => {
    setConfirmationModal({ mo_id: id, status: true });
  };
  const onDeleteMovement = async (id) => {
    await MasterServices.deleteMovement(id)
      .then((res) => {
        const { data, status } = res;
        if (status === 201) {
          getAllMovement();
          toast.success('Movement Deleted Successfully');
          setConfirmationModal({ sl_id: null, status: false });
        }
      })
      .catch((err) => {
        toast.error(err.data.message);
        console.log(err);
      });
  };
  const renderModal = () => {
    const { type, state, data, edit_id } = modal;

    return (
      <Formik
        initialValues={data}
        validationSchema={Yup.object({
          from_id: Yup.string().required('Please select the from'),
          to_id: Yup.string().when(['type'], {
            is: (type) => type == `TRANSFER`,
            then: Yup.string().required('Please select the to'),
          }),
          item_id: Yup.string().required('Please select the item'),
          quantity: Yup.number()
            .required('Please Enter the quantity')
            .max(max_from, `Not enough quantity in storage`),
          type: Yup.string().required('Please select the type'),
        })}
        enableReinitialize={true}
        onSubmit={(values, action) => {
          set_loading_button(true);

          const body = {
            from: parseInt(values.from_id),
            to: typeof values.to ? parseInt(values.to_id) : null,
            item_id: parseInt(values.item_id),
            quantity: parseInt(values.quantity),
            type: values.type,
            mo_comment: comment
          };
          if (modal.type === 'edit') {
            updateMovements(modal.edit_id, body);
          } else {
            if (createMovement(body)) {
              action.resetForm();
            }
          }
          set_max_from(999999999999);
          action.setSubmitting(false);
          set_loading_button(false);
          // setModal((prev) => ({ ...prev, state: false }));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Modal
            title={`${modal.type === 'edit' ? 'Update' : 'Add'} Movement`}
            open={state}
            setOpen={() => {
              setFieldValue('type', '');
              setModal((prev) => ({ ...prev, state: false }));
              set_max_from(999999999999);
            }}
          >
            <form onSubmit={handleSubmit}>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                {max_from < 999999999999 ? (
                  <label>Availabale stock of selected item is {max_from}</label>
                ) : (
                  ''
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="analyzer_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Type
                </label>
                <Select
                  name="type"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  defaultValue={values?.type}
                  onChange={(e) => {
                    setFieldValue('type', e.value);
                    values.to = values.type === 'WASTAGE' ? null : values.to
                    values.to_id = values.type === 'WASTAGE' ? null : values.to_id
                  }}
                  options={[
                    { value: 'WASTAGE', label: 'WASTAGE' },
                    { value: 'TRANSFER', label: 'TRANSFER' },
                  ]}
                />
                {errors.type && (
                  <p className="text-red-700 error_msg">{errors.type}</p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="analyzer_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  From
                </label>
                <Select
                  name="from_id"
                  defaultValue={values?.from}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  onChange={(e) => {
                    setFieldValue('from_id', e.value);

                    set_from_to({ ...from_to, from: e.value });
                  }}
                  options={storageLocations.data
                    .filter((loc, index) => {
                      if (loc.sl_id != from_to.to) {
                        return loc;
                      }
                    })
                    .map((loc, index) => {
                      return { value: loc.sl_id, label: loc.name };
                    })}
                />
                {errors.from_id && (
                  <p className="text-red-700 error_msg">{errors.from_id}</p>
                )}
              </div>
              {values.type !== 'WASTAGE' && (
                <>
                  <div className="text-left mt-4">
                  <div className="mt-4"></div>
                  <label
                    htmlFor="analyzer_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    To
                  </label>
                  <Select
                    name="to_id"
                    defaultValue={values?.to}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    onChange={(e) => {
                      set_from_to({ ...from_to, to: e.value });

                      setFieldValue('to_id', e.value);
                    }}
                    options={storageLocations.data
                      .filter((loc, index) => {
                        if (loc.sl_id != from_to.from) {
                          return loc;
                        }
                      })
                      .map((loc, index) => {
                        return { value: loc.sl_id, label: loc.name };
                      })}
                  />

                  {errors.to_id && (
                    <p className="text-red-700 error_msg">{errors.to_id}</p>
                  )}              
                  </div>
                </>
              )}

              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="item_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  item
                </label>
                <Select
                  name="item_id"
                  defaultValue={values?.to}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  onChange={(e) => {
                    setFieldValue('item_id', e.value);
                    let get_quant = itemsList.filter((item) => {
                      if (
                        item.item_id == e.value &&
                        from_to.from == item.storage_loc_id
                      ) {
                        return item;
                      }
                    });
                    if (get_quant.length > 0) {
                      // set_max_from(get_quant[0].quantity);
                    }
                  }}
                  options={itemsList
                    .filter((item, index) => {
                      if (from_to.from == item.storage_loc_id) {
                        return item;
                      }
                    })
                    .map((it, index) => {
                      return {
                        value: it.item_id,
                        label: it.item_storage_relation.witem_name_en,
                      };
                    })}
                />

                {<p className="text-red-700 error_msg">{errors.item_id}</p>}
              </div>

              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <InputBox
                  name="quantity"
                  label="Quantity"
                  type="number"
                  value={values.quantity}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  placeholder="Quantity"
                />
                {errors.quantity && (
                  <>
                    <p className="text-red-700 error_msg">{errors.quantity}</p>
                  </>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <p>
                  Comments:{" "}
                  <span
                    className="text-orange-600 cursor-pointer"
                    onClick={() => setOpenComments(openComments ? false : true)}
                  >
                    Empty
                  </span>
                </p>
                {/* <Timer/> */}
                {((openComments) ? (
                  <textarea
                    type="text"
                    value={comment}
                    placeholder="Please Enter Comment"
                    className="input-w-class block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    onInput={(e) =>
                      setComment(e.target.value)
                    }
                  />) : null)
                }
              </div>
              <div className="mt-4"></div>
              <Button disabled={isSubmitting} type="submit">
                {loading_button ? (
                  <FallingLines color="white" width="30" visible={true} />
                ) : (
                  `${modal.type === 'edit' ? 'Update' : 'Add'} Movement`
                )}
              </Button>
            </form>
          </Modal>
        )}
      </Formik>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      {renderModal()}
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteMovement(confirmationModal.mo_id)}
      />

      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Movement</h1>
      </div>
      <div className="mt-4 flex">
        <button
          type="button"
          onClick={() =>{
              setComment('');
              setModal((prev) => ({
                ...prev,
                data: {},
                type: '',
                edit_id: '',
                state: true,
              }))
            }
          }
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </button>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : movementlist.data.length > 0 ? (
        <Table
          columns={Movement_columns({ onDeleteOpen, setModal })}
          data={movementlist.data}
        />
      ) : (
        <TableEmpty />
      )}
    </div>
  );
}
export default Movement;
