import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb';
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import { vat_columns } from '../../../components/tables/tableheader';

const Vat = () => {
    const pages = [{ title: "Vat List", href: "/vat", module_id: 14 }];
	const [isLoading, setIsLoading] = useState(false)
    
    const vatList = [
        {
            vat_id: 1,
            country: "India",
            hospital: "Hospital-1",
            percentage: "30%",
            status: true,
        },
        {
            vat_id: 2,
            country: "UAE",
            hospital: "Hospital-2",
            percentage: "20%",
            status: true,
        },
        {
            vat_id: 3,
            country: "Riyad",
            hospital: "Hospital-3",
            percentage: "25%",
            status: true,
        },
        {
            vat_id: 4,
            country: "India",
            hospital: "Hospital-1",
            percentage: "30%",
            status: true,
        },
        {
            vat_id: 5,
            country: "UAE",
            hospital: "Hospital-2",
            percentage: "20%",
            status: true,
        },
        {
            vat_id: 6,
            country: "Riyad",
            hospital: "Hospital-3",
            percentage: "25%",
            status: true,
        }
    ]

    return (
        <div>
            <Breadcrumb pages={pages} />
            <div className="">
				<h1 className="text-xl font-semibold text-gray-900">Vat</h1>
				<p className="mt-2 text-sm text-gray-700">A list of all the Vat.</p>
			</div>
			<div className="mt-4 flex">
				<Link
					to="/add-vat"
					type="button"
					className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
				>
					Add Vat
				</Link>
			</div>

			{isLoading ? (
				<FallingLinesLoader />
			) : (
				<Table
					columns={vat_columns()}
					data={vatList}
				/>
			)}

        </div>
    )
}

export default Vat