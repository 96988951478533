import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { hospitalSchema } from "../../../schemas";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { getAllHospitals } from "../../../redux/hospital/actions";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { out_source_reservation_columns } from "../../../components/tables/tableheader";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { Switch } from "@headlessui/react";
import MasterServices from '../../../ApiServices/MasterServices';
import ReservationServices from '../../../ApiServices/ReservationServices';
import { toast } from 'react-toast'
import queryString from 'query-string';
import Select from "react-select";

import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import moment from "moment";
function className(...location) {
  return location.filter(Boolean).join(" ");
}

const bookingType = [
  { name: "Normal", id: "1" },
  { name: "OPD", id: "2" },
  { name: "Emergancy", id: "3" },
  { name: "ICU", id: "4" },
];
const filterInitialValues = {
  start_date: '',
  end_date: '',
  mc_ids: '',
}
const OutSourceReservationList = () => {
  const pages = [{ title: "Outsource Resrvation List", href: "/outsourced-clinic-logs", module_id: 8 }];

  const [filterFlag, setFilterFlag] = useState(false);
  const [reservationList, setReservationList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [clinicList, setClinicList] = useState([])
  const [totalAmountPaid, setTotalAmountPaid] = useState(0);
  const [reportFilters, setReportFilters] = useState(filterInitialValues)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {hospital: { list: hospitalList }} = useSelector((state) => state);

  useEffect(() => {
    document.title = "Outsource Resrvation List";
    dispatch(getAllHospitals());
  }, []);
  useEffect(() => {
    
  });

  const generateInvoiceReport = async (payload) => {
    let res = await ReservationServices.generateInvoiceReport(payload)
    if (res.status == 200) {
      toast.success(res.data.message)
      getReservationList();
    } else {
      toast.error(res.data.message)
    }
  }

  useEffect(() => {
    getReservationList();
    getClinicList();
  }, []);

  const getClinicList = (payload) => {
    // setIsLoading(true);
    console.log("params payload=>", payload)
    MasterServices.getClinicList(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Clinic List Found!");
          setClinicList(data?.data);
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setIsLoading(false);
      });
  }

  const getReservationList = (payload) => {
    setIsLoading(true);
    console.log("params payload=>", payload)
    ReservationServices.getAllOutSourceReservationList(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Reservation List Found!");
          if(Array.isArray(data?.data)) {
            setReservationList(data?.data);
            const sum = data?.data.reduce((accumulator, object) => {
              return accumulator + parseFloat(object.rev_payable_amount);
            }, 0);
            setTotalAmountPaid(sum)
          } else {
            setReservationList([]);
          }        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setIsLoading(false);
      });
  }

  const getFilterdData = async () => {
    // setStorageLocations((prev) => ({ ...prev, loading: true }));
    let payload = {
      start_date: reportFilters.start_date || '1949-05-26',
      end_date: reportFilters.end_date || (moment().add(1,'days').format('YYYY-MM-DD')),
      mc_ids: Array.isArray(reportFilters.mc_ids) ? reportFilters.mc_ids.map(v => v.value) : []
    }
    console.log(payload);
    console.log("params payload=>", payload)
    ReservationServices.getOutSourceReservationFilteredList(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Reservation List Found!");
          if(Array.isArray(data?.data)) {
            setReservationList(data?.data);
            const sum = data?.data.reduce((accumulator, object) => {
              return accumulator + parseFloat(object.rev_payable_amount);
            }, 0);
            setTotalAmountPaid(sum)
          } else {
            setReservationList([]);
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setIsLoading(false);
      });
  }

  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    reservation_id: null,
    reservation_is_active: false,
  });

  const onDeleteOpen = () => {
  };

  const onDeleteReservation = () => {
    
  };

  const editReservation = (rs_val) => {
    console.log("rs_val=>", rs_val);
    localStorage.setItem("edit_booking_reservation_id", rs_val.reservation_id);
    localStorage.setItem("edit_booking_reservation_user_details", JSON.stringify(rs_val));
    navigate("/bookingConfirm");
  };


  return (
    <>
      <div className="px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
        <div className="sales-booking-head">
          <Breadcrumb pages={pages} />
        </div>
      </div>
      <div className="mt-5 flex gap-3 items-center">
      {/* start date */}
      <div className=" sm:mt-0">
          <label htmlFor="fk_location_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Date From</label>

          <input
              className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
              type="date"
              name="start_date"
              id="start_date"
              value={reportFilters.start_date}
              onChange={(e) => {
                  setReportFilters((prev) => {
                      // if (prev.end_date < e.target.value) {
                      //     return {
                      //         ...prev,
                      //         end_date: e.target.value,
                      //         start_date: prev.end_date
                      //     }
                      // }
                      return {
                          ...prev,
                          start_date: e.target.value,
                      }
                  })
              }
              }
          />
      </div>

      {/* end date */}
      <div className=" sm:mt-0">
          <label htmlFor="fk_location_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Date To</label>

          <input
              className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
              type="date"
              name="end_date"
              id="end_date"
              value={reportFilters.end_date}
              onChange={(e) => {
                  setReportFilters((prev) => {
                      // if (prev.start_date > e.target.value) {
                      //     return {
                      //         ...prev,
                      //         start_date: prev.start_date,
                      //         end_date: e.target.value
                      //     }
                      // }
                      return {
                          ...prev,
                          end_date: e.target.value,
                      }
                  })
              }
            }
          />
      </div>
      <div className="">
          <label
              htmlFor="mc_ids"
              className="w-60 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Select Location</label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Select
                  // defaultValue={testList[0]}
                  isMulti
                  name="mc_ids"
                  id="mc_ids"
                  value={reportFilters.mc_ids}
                  onChange={(e) => setReportFilters((prev) => ({...prev, mc_ids: e}))}
                  className="basic-multi-select"
                  classNamePrefix="Select Test"
                  options={Array.isArray(clinicList) && clinicList?.map((sin) => {
                    return { value: sin.mc_id, label: sin.mc_name };
                  })}
              />
          </div>
      </div>
      <button
          onClick={() => {
              getFilterdData()
          }}
          className={`ml-3 h-10 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 mt-7 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
      >
          Filter
      </button>
      </div>
      <div className="mt-4 flex">
          <p
              // onClick={() => handleDrawer("add", "", {})}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-dark shadow-sm  ml-auto"
          >
              Total Paid Amount: {totalAmountPaid}
          </p>
      </div>
      <div className= "px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none">
        <div className="flex flex-col">
          <div>

            {
              isLoading ? (
                <FallingLinesLoader />
              ) : ((reservationList.length > 0) ? (
                <Table
                  zoom='zoom-075'
                  columns={out_source_reservation_columns({ onDeleteOpen, editReservation, generateInvoiceReport })}
                  data={reservationList}
                />
              ) : <TableEmpty />)
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default OutSourceReservationList;
