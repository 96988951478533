import React, { useEffect, useState, Fragment, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { categorySchema } from "../../../schemas";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { category_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition, } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { toast } from 'react-toast'
import MasterServices from '../../../ApiServices/MasterServices'


function CategoryList() {
  const pages = [{ title: "Category List", href: "/category", module_id: 5  }];
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [category_id, setCategoryid] = useState('');
  const [controlList, setControlList] = useState([])
  const [loading, setLoading] = useState(true);
  const imageInputRef = useRef("");
  const [content, setCategoryLogo] = useState(false);
  const [categoryList, setCategoryList] = useState(false)
  const [uploadedMediaPath, setUploadedMediaPath] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    category_id: null,
    category_is_active: false,
  });
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState({
    status: false,
    category_id: null,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();




  useEffect(() => {
    document.title = "Labib | Categorys";
    getAllCategoryList();
    // getAllControlList();
  }, []);

  console.log("categoryList", categoryList)
  const initialValues = {
    category_id: "",
    category_name_ar: "",
    category_name_en: "",
    category_is_active: "",
  };

  const getAllCategoryList = () => {
    setLoading(true);
    MasterServices.getCategoryList('All')
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setCategoryList(data.data);
          } else {
            toast.error("CategoryList Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = "getAllCategoryList catch Error"; //error.response.data.message;
        toast.error(msg);
        setLoading(false);
      });
  }

  const [formState, setFormState] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    if (type === "edit", id) {
      setCategoryid(id)
      const initialValues = {
        category_id: obj.category_id,
        category_name_ar: obj.category_name_ar,
        category_name_en: obj.category_name_en,
        category_is_active: obj.category_is_active,
      };
      setFormState(initialValues);
    } else {
      setCategoryid("")
      setFormState(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onDeleteOpen = (category_id, category_is_active) => {
    setDeleteConfirmationModal({ category_id, category_is_active, status: true });
  };

  const onStatusChangeOpen = (category_id, category_is_active) => {
    setConfirmationModal({ category_id, category_is_active, status: true });
  };

  const onStatusChange = (category_id, category_is_active) => {
    let Newcategory_is_active = false;
    Newcategory_is_active = category_is_active == true ? false : true;
    let text = "Activated";
    if (Newcategory_is_active == true) {
      text = "Activated";
    } else {
      text = "In-Activated";
    }
    MasterServices.setCategoryStatus({
      category_id,
      category_is_active: Newcategory_is_active
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Category " + text + " Successfully!")
          setConfirmationModal({ category_id: null, status: false });
          getAllCategoryList();
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error.response.data.message;
        toast.error(msg)
      });
    setConfirmationModal({ category_id: null, status: false });
  };

  const onDeleteCategory = (category_id) => {
    MasterServices.deleteCategory({
      category_id,
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Category Deleted Successfully!")
          setDeleteConfirmationModal({ category_id: null, status: false });
          getAllCategoryList();
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error.response.data.message;
        toast.error(msg)
      });
    setDeleteConfirmationModal({ category_id: null, status: false });
  };
  
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      validationSchema: categorySchema,
      onSubmit: (values, action) => {
        let body = {
          category_id: category_id,
          category_is_active: true,
          category_name_ar: values.category_name_ar,
          category_name_en: values.category_name_en,
        }

        MasterServices.saveCategory(body)
          .then((response) => {
            const { data, status } = response;
            if (status == 200) {
              if (category_id != 0 || category_id != '') {
                toast.success("Category Updated Successfully");
              } else {
                toast.success("Category Added Successfully");
              }
              getAllCategoryList();
            } else {
              toast.error("Fatal Error Please Contact Admin")
            }
          })
          .catch((error) => {
            const msg = "saveCategory Catch Error" //error.response.data.message;
            toast.error(msg)
          });
        action.resetForm();
        if (modalOpenFlage === true) {
          setmodalOpenFlage(false);
        };
      },

    });
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        title={"Are you sure you want to " + (confirmationModal.category_is_active ? "Disable " : "Activate") + " this Category?"}
        confirmationButtonText="Yes"
        // description={"Do you really want to " + (confirmationModal.category_is_active ? "In-Activate" : "Activate") + " Category"}
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onStatusChange(confirmationModal.category_id, confirmationModal.category_is_active)}
      />
      <ConfirmationModal
        title={"Are you sure you want to delete this Category?"}
        confirmationButtonText="Yes"
        // description={"Do you really want to " + (confirmationModal.category_is_active ? "In-Activate" : "Activate") + " Category"}
        open={deleteConfirmationModal.status}
        setOpen={setDeleteConfirmationModal}
        onDelete={() => onDeleteCategory(deleteConfirmationModal.category_id)}
      />
      <Breadcrumb pages={pages} />
      <div className=""> 
        <h1 className="text-xl font-semibold text-gray-900">Category List</h1>
      </div>
      <div className="mt-4 flex">
        <Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </Link>
      </div>
      {
        loading ? (
          <FallingLinesLoader />
        ) : ((categoryList.length > 0) ? (
          <Table
            columns={category_columns({ onDeleteOpen, handleDrawer, onStatusChangeOpen })}
            data={categoryList}
          />
        ) : <TableEmpty />)
      }

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form onSubmit={handleSubmit} className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">

                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-cyan-700 py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {category_id ? "Update" : "Add"} Category
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="divide-y divide-gray-200 p-4 sm:p-6">
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    Category EN Name
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.category_name_en}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Category EN Name"
                                    name="category_name_en"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.category_name_en && touched.category_name_en ? (
                                    <p className="text-red-600 text-sm">{errors.category_name_en}</p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    Category AR Name
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.category_name_ar}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Category AR Name"
                                    name="category_name_ar"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.category_name_ar && touched.category_name_ar ? (
                                    <p className="text-red-600 text-sm">{errors.category_name_ar}</p>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false)
                            }
                            }
                          >
                            Cancel
                          </button>
                          <button
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            {category_id ? 'Update' : 'Add'}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div >
  );
}
export default CategoryList;