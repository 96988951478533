import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { hospitalSchema } from "../../../schemas";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import {
  deleteHospital, getAllHospitals, addHospital,
  updateHospital, getHospitalWithId
} from "../../../redux/hospital/actions";
import { Switch } from "@headlessui/react";

import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import Breadcrumb from "../../../components/Breadcrumb";
import { singleUploadFiles } from "../../../helper/multiUploadHelper";
import { exportToExcel } from "../../../helper/commonHelper";
import { FileUploader } from "react-drag-drop-files";
function className(...location) {
  return location.filter(Boolean).join(' ')
}

const fileTypes = ["JPEG", "PNG", "GIF"];

const AddHospital = () => {
  const pages = [
    { title: "Lab", href: "/lab" },
  ];
  const [progress, setProgress] = useState();
  const [stampProgress, setStampProgress] = useState();
  const [fileName, setFile] = useState(false);
  const [stampFileName, setStampFileName] = useState(false);
  const [fileNamePath, setFilePath] = useState('');
  const [stampFileNamePath, setStampFilePath] = useState('');
  const [cashStatus, setcashStatus] = useState(false)
  const [prepaidStatus, setprepaidStatus] = useState(false)
  const [locationStatus, setlocationStatus] = useState(false)
  const [smsInvStatus, setsmsInvStatus] = useState(false)
  const [manualHesnStatus, setmanualHesnStatus] = useState(false)
  const [branchStatus, setbranchStatus] = useState(false)
  const [primaryStatus, setprimaryStatus] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(false)
  const { hos_id: hos_id } = useParams();
  const {
    hospital: {loading},
    hospital: { list: hospitalList, hospitalData },

  } = useSelector((state) => state);
  useEffect(() => {
    document.title = "Labib | Labs";
    dispatch(getAllHospitals());
  }, []);
  useEffect(() => {
    console.log("Roles data=>", "Test")
  });


  let initialValues = {
    hos_name_ar: "",
    hos_name_en: "",
    hos_logo_url: "",

    hos_lat: "",
    hos_long: "",
    hos_vat_no: "",
    hos_hesn_no: "",

    hos_is_cash: false,
    hos_is_prepaid: false,
    hos_is_location: false,
    hos_is_sms_inv: false,
    hos_is_manual_hesn: false,
    hos_is_branch: false,
    hos_is_primary: false,
  };

  const [formState, setFormState] = useState(initialValues);

  useEffect(() => {
    console.log("hos_id=>", hos_id);
    if (hos_id) dispatch(getHospitalWithId(hos_id));
  }, [hos_id]);

  // const handleFileSubmit = async (e) => {

  // const response = await singleUploadFiles(fileName,'hospitals');
  //   console.log("response=",response)

  //   console.log("initialValues.hos_logo_url=",initialValues.hos_logo_url)
  //   setFilePath(response);
  //   setProgress(response.progress);
  //   return initialValues.hos_logo_url = response;
  // };
  const handleFileChange = async (field, file) => {
    const response = await singleUploadFiles(file, 'hospitals');
    if (field == 'image') {
      setFilePath(response.data);
      setProgress(response.progress);
    }
    if (field == 'stamp') {
      setStampFilePath(response.data)
      setStampProgress(response.progress);
    }
  };

  useEffect(() => {
    console.log("Happy=>", hospitalData)
    document.title = "Labib | Add-Lab";
    if (hos_id && hospitalData) {
      const newFormState = {
        hos_name_ar: hospitalData.hos_name_ar,
        hos_name_en: hospitalData.hos_name_en,
        hos_logo_url: hospitalData.hos_logo_url,
        hos_lat: hospitalData.hos_lat,
        hos_long: hospitalData.hos_long,
        hos_vat_no: hospitalData.hos_vat_no,
        hos_hesn_no: hospitalData.hos_hesn_no,
        hos_is_cash: hospitalData.hos_is_cash,
        hos_is_prepaid: hospitalData.hos_is_prepaid,
        hos_is_location: hospitalData.hos_is_location,
        hos_is_sms_inv: hospitalData.hos_is_sms_inv,
        hos_is_manual_hesn: hospitalData.hos_is_manual_hesn,
        hos_is_branch: hospitalData.hos_is_branch,
        hos_is_primary: hospitalData.hos_is_primary,
      };
      setcashStatus(hospitalData.hos_is_cash)
      setprepaidStatus(hospitalData.hos_is_prepaid)
      setlocationStatus(hospitalData.hos_is_location)
      setsmsInvStatus(hospitalData.hos_is_sms_inv)
      setmanualHesnStatus(hospitalData.hos_is_manual_hesn)
      setbranchStatus(hospitalData.hos_is_branch)
      setprimaryStatus(hospitalData.hos_is_primary)
      setFormState(newFormState);
      setFilePath(hospitalData.hos_logo_url ? hospitalData.hos_logo_url : '')
      setStampFilePath(hospitalData.hos_stamp ? hospitalData.hos_stamp : '')
      console.log("manualHesnStatus=>", manualHesnStatus)
    }
  }, [hos_id, hospitalData]);

  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      validationSchema: hospitalSchema,
      onSubmit: async (values, action) => {

        let fileRes
        let fileStampRes
        if (fileName) {
          fileRes = await singleUploadFiles(fileName, 'hospitals');
        }
        if (stampFileName) {
          fileStampRes = await singleUploadFiles(stampFileName, 'hospitals');
        }
        console.log(values);
        console.log("fileNamePath=>", fileNamePath);
        const body = { ...values };
        body.hos_id = hos_id;
        body.hos_is_cash = cashStatus;
        body.hos_is_prepaid = prepaidStatus;
        body.hos_is_location = locationStatus;
        body.hos_is_sms_inv = smsInvStatus;
        body.hos_is_manual_hesn = manualHesnStatus;
        body.hos_is_branch = branchStatus;
        body.hos_is_primary = primaryStatus;
        body.hos_logo_url = fileRes ? fileRes : fileNamePath;
        body.hos_stamp = fileStampRes ? fileStampRes : stampFileNamePath;
        console.log("body=>", body);
        if (!hos_id) dispatch(addHospital(body));
        else dispatch(updateHospital(hos_id, body));
        action.resetForm();
        navigate("/lab");
      },
    });

  return (
    <div >
      {hos_id ? null : (
        <div>
          <Breadcrumb pages={pages} />
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
          >
            <span className="w-5 mr-2">
              <ArrowUturnLeftIcon size={18} />
            </span>{" "}
            Back
          </button>
        </div>
      )}
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          <div className="sm:flex-auto">
            <h1 className="text-lg font-medium leading-6 text-gray-900">
              {hos_id ? "Update Lab" : "Add Lab"}
            </h1>
          </div>
        </div>
        {loading ? (
          <FallingLinesLoader />
        ) : (
          // onSubmit={handleSubmit}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              console.log(e);
              handleSubmit(e);
            }}
          >
            <div className="flex flex-row mt-5 gap-5">
              <div className="basis-1/2 ">
                <label htmlFor="hos_name_en" className="block text-sm font-medium text-gray-700">Lab Name(EN)</label>
                <div className="mt-1">
                  <input
                    placeholder="Enter Lab Name(EN)"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    type="text"
                    name="hos_name_en"
                    id="hos_name_en"
                    value={values.hos_name_en}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.hos_name_en && touched.hos_name_en ? (
                    <p className="text-red-600 text-sm">{errors.hos_name_en}</p>
                  ) : null}
                </div>
              </div>
              <div className="basis-1/2">
                <label htmlFor="hos_name_ar" className="block text-sm font-medium text-gray-700">Lab Name(AR)</label>
                <div className="mt-1">
                  <input
                    placeholder="Enter Lab Name(AR)"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    type="text"
                    name="hos_name_ar"
                    id="hos_name_ar"
                    value={values.hos_name_ar}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.hos_name_ar && touched.hos_name_ar ? (
                    <p className="text-red-600 text-sm">{errors.hos_name_ar}</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="flex flex-row mt-2 gap-5">
              <div className="basis-1/2">
                <label htmlFor="hos_lat" className="block text-sm font-medium text-gray-700">Latitude</label>
                <div className="mt-1">
                  <input
                    placeholder="Enter Lab Latitude"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    type="text"
                    name="hos_lat"
                    id="hos_lat"
                    value={values.hos_lat}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.hos_lat && touched.hos_lat ? (
                    <p className="text-red-600 text-sm">{errors.hos_lat}</p>
                  ) : null}
                </div>
              </div>


              <div className="basis-1/2">
                <label htmlFor="hos_long" className="block text-sm font-medium text-gray-700">Longitude</label>
                <div className="mt-1 ">
                  <input
                    placeholder="Enter Lab Longitude"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    type="text"
                    name="hos_long"
                    id="hos_long"
                    value={values.hos_long}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.hos_long && touched.hos_long ? (
                    <p className="text-red-600 text-sm">{errors.hos_long}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex flex-row mt-2 gap-5">
              <div className="basis-1/2">
                <label htmlFor="hos_vat_no" className="block text-sm font-medium text-gray-700">Vat No</label>
                <div className="mt-1">
                  <input
                    placeholder="Enter Lab Vat No"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    type="number"
                    name="hos_vat_no"
                    id="hos_vat_no"
                    value={values.hos_vat_no}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.hos_vat_no && touched.hos_vat_no ? (
                    <p className="text-red-600 text-sm">{errors.hos_vat_no}</p>
                  ) : null}
                </div>
              </div>

              <div className="basis-1/2">
                <label htmlFor="hos_hesn_no" className="block text-sm font-medium text-gray-700">HESN No</label>
                <div className="mt-1">
                  <input
                    placeholder="Enter Lab HESN No"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    type="number"
                    name="hos_hesn_no"
                    id="hos_hesn_no"
                    value={values.hos_hesn_no}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.hos_hesn_no && touched.hos_hesn_no ? (
                    <p className="text-red-600 text-sm">{errors.hos_hesn_no}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex flex-row mt-2 gap-5">
              <div className="basis-1/2 file_uploader">
                <label htmlFor="hos_long" className="block text-sm font-medium text-gray-700">
                  Image
                </label>
                <FileUploader
                  multiple={false}
                  types={fileTypes}
                  handleChange={file => handleFileChange('image', file)}
                  name="file"
                />
                {fileNamePath ? (<img width={40} height={20} src={fileNamePath} alt={values.hos_name_en} />) : (<></>)}
                {progress ? (<><div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2">
                  <div className="bg-cyan-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full mt-1"> {`${progress}%`}</div>
                </div></>) : (<></>)}
              </div>
              <div className="basis-1/2 file_uploader">
                <label htmlFor="hos_long" className="block text-sm font-medium text-gray-700">
                  Stamp
                </label>
                <FileUploader
                  multiple={false}
                  types={fileTypes}
                  handleChange={file => handleFileChange('stamp', file)}
                  name="file"
                />
                {stampFileNamePath ? (<img width={40} height={20} src={stampFileNamePath} alt={values.hos_name_en} />) : (<></>)}
                {stampProgress ? (<><div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2">
                  <div className="bg-cyan-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full mt-1"> {`${stampProgress}%`}</div>
                </div></>) : (<></>)}
              </div>
            </div>

            {/* <div className="flex flex-row mt-2  pt-5">
              <div className="basis-3/4">
                <label htmlFor="cashStatus" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-3">Cash On Delivery</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">

                  <Switch
                    checked={cashStatus}
                    onChange={setcashStatus}
                    className={className(
                      cashStatus ? "bg-cyan-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                    )}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={className(
                        cashStatus ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </div>
              </div>

              <div className="basis-3/4">
                <label htmlFor="cashStatus" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-3">Prepaid</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">

                  <Switch
                    checked={prepaidStatus}
                    onChange={setprepaidStatus}
                    className={className(
                      prepaidStatus ? "bg-cyan-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                    )}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={className(
                        prepaidStatus ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </div>
              </div>

              <div className="basis-3/4">
                <label htmlFor="locationStatus" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-3">Locations</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">

                  <Switch
                    checked={locationStatus}
                    onChange={setlocationStatus}
                    className={className(
                      locationStatus ? "bg-cyan-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                    )}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={className(
                        locationStatus ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </div>
              </div>

              <div className="basis-3/4">
                <label htmlFor="smsInvStatus" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-3">SMS Invoices</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">

                  <Switch
                    checked={smsInvStatus}
                    onChange={setsmsInvStatus}
                    className={className(
                      smsInvStatus ? "bg-cyan-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                    )}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={className(
                        smsInvStatus ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </div>
              </div>

              <div className="basis-3/4">
                <label htmlFor="manualHesnStatus" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-3">HESN {manualHesnStatus}</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">

                  <Switch
                    checked={manualHesnStatus}
                    onChange={setmanualHesnStatus}
                    className={className(
                      manualHesnStatus ? "bg-cyan-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                    )}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={className(
                        manualHesnStatus ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </div>
              </div>

              <div className="basis-3/4">
                <label htmlFor="branchStatus" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-3">Branch</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">

                  <Switch
                    checked={branchStatus}
                    onChange={setbranchStatus}
                    className={className(
                      branchStatus ? "bg-cyan-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                    )}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={className(
                        branchStatus ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </div>
              </div>

              <div className="basis-3/4">
                <label htmlFor="primaryStatus" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-3">Primary</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">

                  <Switch
                    checked={primaryStatus}
                    onChange={setprimaryStatus}
                    className={className(
                      primaryStatus ? "bg-cyan-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                    )}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={className(
                        primaryStatus ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </div>
              </div>
            </div> */}
            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddHospital;
