import Breadcrumb from '../../../components/Breadcrumb';
import $ from "jquery"; //Load jquery
import React, { Component, createRef, useEffect, useState } from "react"; //For react component
import { addQuestionnaire, getAllQuestionnaires, getSingleQuestionnaire } from '../../../redux/questionnaire/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_QUESTIONNAIRE_SUCCESS } from '../../../redux/questionnaire/constants';

window.jQuery = $; //JQuery alias
window.$ = $; //JQuery alias

require("jquery-ui-sortable"); //For FormBuilder Element Drag and Drop
require("formBuilder");// For FormBuilder
// require("formBuilder/dist/form-render.min.js") // Use this for render form.

export const ResQuestionnaire = ({ questionsArr, setQuestionsArr }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fb = createRef();
  let [formBuilder, setFormBuilder] = useState(null);
  let [requestDone, setRequestDone] = useState(false);
  let [qstn_name, setQstnName] = useState('');

  useEffect(() => {
    //  setFormBuilder($(fb.current).formBuilder({
    //     formData: []
    // }));
    if (questionsArr.length > 0) {
      if (questionsArr) {
        console.log("PS=>", questionsArr)
        //To create form
        if (!formBuilder?.formData) {
          setFormBuilder($(fb.current).formBuilder({
            formData: questionsArr
          })
          );
        }
      } else {
        setFormBuilder($(fb.current).formBuilder({
          formData: []
        })
        );
      }
    } else {
      if (questionsArr && !requestDone) {
        (async function () {
          setRequestDone(true)
        })()
      } else {
        //To create form
        if (!formBuilder?.formData) {
          setFormBuilder($(fb.current).formBuilder({
            formData: []
          })
          );
        }
      }
    }
  }, [])
  async function saveData() {
    setQuestionsArr(formBuilder.formData);
    console.log("formBuilder.formData=>",formBuilder.formData)
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">

      <div className="pt-5">
        <div className='border border-gray-300 rounded-md px-8 py-8 mb-5'>
          <div id="fb-editor" ref={fb} />
        </div>
        <div className="flex justify-center">

          <button
            onClick={saveData}
            type="button"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
          >
            {'Save'}
          </button>
        </div>
      </div>
      <style>{"\
        .form-wrap.form-builder .frmb-control .icon-autocomplete, .form-wrap.form-builder .frmb-control .icon-button, .form-wrap.form-builder .frmb-control .icon-checkbox-group, .form-wrap.form-builder .frmb-control .icon-file, .form-wrap.form-builder .frmb-control .icon-hidden, .form-wrap.form-builder .frmb-control .icon-paragraph ,.icon-header,.icon-date,.icon-number,.icon-radio-group,.icon-select,.icon-text,.icon-textarea {\
          display: none;\
        }\
        .form-wrap.form-builder .form-actions.btn-group .clear-all,.btn.btn-default.get-data,.btn.btn-primary.save-template{\
          display: none;\
        }\
        .form-wrap.form-builder .frmb.stage-wrap.pull-left.ui-sortable .field-actions{\
          display: none;\
        }\
        .form-wrap.form-builder .frmb .prev-holder input[type=number] {\
           width: 100%; \
        }\
        .form-wrap.form-builder .frmb .prev-holder input[type=date] {\
          width: 100%; \
      }\
        .form-wrap.form-builder .stage-wrap {\
          width: calc(95% - 5px);\
      }\
      .form-elements{\
        display: none;\
      }\
      .form-field{\
        border-style: none;\
      }\
      .form-wrap.form-builder .frmb>li:hover {\
      box-shadow: none;\
    }\
      "}</style>
    </div>

  )
}