import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Table from "../../../../components/tables/table";
import TableEmpty from "../../../../components/tables/tableEmpty";
import { qc_analyzer_columns } from "../../../../components/tables/tableheader";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import { Formik } from "formik";
import * as Yup from "yup";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import MasterServices from "../../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import Multiselect from "multiselect-react-dropdown";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import Select from "react-select";
import makeAnimated from 'react-select/animated';

const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    lab_analyzer_ids: "",
    lab_analyzer_id:"",
  },
};



function QCAnalyzer() {
  const pages = [{ title: "Control List", href: "/qc/control-list" }];
  const [loading, setLoading] = useState(false);
  const [analyzerList, setAnalyzerList] = useState([]);
  const [QcanalyzerList, setQcAnalyzerList] = useState([]);
  const [filterQcanalyzerList, setFilterQcAnalyzerList] = useState([]);
  console.log("filterQcanalyzerList=>",filterQcanalyzerList);
  const [selected, setSelected] = useState({ label: '', value: '' });
  const animatedComponents = makeAnimated();
  const [packageList, setPackageList] = useState([
    {
      id: 1,
      name: "CBC",
    },
  ]);
  const { id: qcId } = useParams();
  const [modal, setModal] = useState(initialModalState);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    qcla_id: null,
    AnalyserName:null,
  });

  const navigate = useNavigate();
  useEffect(() => {
    getAllTestAnalyser();
    getAllQcAnalyser();
    document.title = "Labib | Control List";
  }, []);

  useEffect(() => {
    if(analyzerList.length > 0) {
      console.log(analyzerList,analyzerList.map(p => p.lab_analyzer_id))
      let templist =analyzerList.filter( v =>  !QcanalyzerList.map(p => p.fk_lab_analyzer_id).includes(v.lab_analyzer_id))
      setFilterQcAnalyzerList(templist)
    }
    

  },[analyzerList, QcanalyzerList])

  const getAllTestAnalyser = () => {
    MasterServices.getAllTestAnalyzer()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          let analyzerListData = data.data;
          analyzerListData.forEach((val, key) => {
            val.label = val.lab_analyzer_name_en;
            val.value = val.lab_analyzer_id;
          })
          // analyzerListData.map((item) => ({
          //   label: item.lab_analyzer_name_en,
          //   value: item.lab_analyzer_id,
          // }));
          console.log("analyzerListData=>",analyzerListData);
          setAnalyzerList(analyzerListData);
          // setAnalyzerList(data.data);
         
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };
  const getAllQcAnalyser = () => {
    setLoading(true);
    MasterServices.getTestAnalyzerList(qcId)
      .then((response) => {
        const { data, status } = response;

        console.log("data here", response);
        if (status == 200) {
          setQcAnalyzerList(data.data);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  const AddQcAnalyzer = (payload) => {
    setLoading(true)
    MasterServices.addAnalyzer(payload).then((response) => {
      const { data, status } = response;
      if (status == 200) {
        getAllQcAnalyser();
        toast.success("Analyzer is Added Successfully");
      } else {
        toast.error("Fatal Error Please Contact Admin");
      }
      setLoading(false);
    });
  };

  const deleteQCAnalyzer = (qcla_id) => {
    setLoading(true)
    MasterServices.deleteAnalyzer({qcla_id}).then((response) => {
      const { data, status } = response;
      if (status == 200) {
        getAllQcAnalyser();
        toast.success("Analyzer is Deleted Successfully");
      } else {
        toast.error("Fatal Error Please Contact Admin");
      }
      setTimeout(() => {
        setLoading(false);
      },10000)
    });
  };

  const onDeleteQCAnalyzer = (item) => {
    console.log("Data Item = ",item);
    // return false;
    setConfirmationModal({
      status:true,
      qcla_id: item.qcla_id,
      AnalyserName:(item?.lt_lab_analyzer_relation?.lab_analyzer_name_en)?(item?.lt_lab_analyzer_relation?.lab_analyzer_name_en):null,
    })
  }

  const renderModal = () => {
    const { type, state, data, edit_id } = modal;

    return (
      <Formik
        initialValues={data}
        enableReinitialize={true}
        validationSchema={Yup.object({
          lab_analyzer_ids: Yup.string().required("Please Select Analyzer"),
          // lab_analyzer_ids: Yup.array().min(1).required("Please Select Analyzer"),
        })}
        onSubmit={(values) => {
          const body = {
            fk_qc_id: parseInt(qcId),
            fk_lab_analyzer_ids:(values?.fk_lab_analyzer_ids)?(values?.fk_lab_analyzer_ids):null,
            lab_analyzer_id:(values?.lab_analyzer_id)?(values?.lab_analyzer_id):null,
            // fk_lab_analyzer_ids: Array.isArray(values.lab_analyzer_ids) ? values.lab_analyzer_ids.map(v => v.lab_analyzer_id) : [], 
          };
          AddQcAnalyzer(body);
          setModal((prev) => ({ ...prev, state: false }));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Modal
            title="Add Analyzer"
            open={state}
            setOpen={() =>{
              values.lab_analyzer_ids = []
              setModal((prev) => ({ ...prev, state: false }))}
            }
          >
            <form onSubmit={handleSubmit}>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="lab_analyzer_ids"
                  className="block text-sm font-medium text-gray-700"
                >
                  Analyzers
                </label>
                {filterQcanalyzerList.length > 0 ? (
                  <>
              <div className="text-left mt-4 z-[999]">
              <Select
                  name="lab_analyzer_name_en"
                  id="lab_analyzer_name_en"
                  placeholder="Select Analyzers"
                  value={selected}
                  // value={values.lab_analyzer_id}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  onChange={(e) => {

                    console.log(e)
                    setSelected(e)
                    values.lab_analyzer_ids=e.value;
                    values.lab_analyzer_id = e.value;
                    values.lab_analyzer_name_en = e.label;

                  }}
                  // onBlur={handleBlur}
                  components={animatedComponents}
                  options={filterQcanalyzerList}
                  classNamePrefix="select"
                  className="basic-multi-select block w-full shadow-sm focus:border-[1px] focus:border-cyan-500 sm:text-sm"
                />
                {/* <Multiselect
                  dataKey="lab_analyzer_id"
                  selectedValues={values.lab_analyzer_ids}
                  onSelect={(list) => {
                    values.lab_analyzer_ids = list
                  }}
                  onRemove={(list) => {
                    values.lab_analyzer_ids = list
                  }}
                  displayValue="lab_analyzer_name_en"
                  options={filterQcanalyzerList}
                  /> */}
                </div>

                  {touched.lab_analyzer_ids && (
                    <p className="text-red-700 error_msg">
                    {errors.lab_analyzer_ids}
                    </p>
                    )}
                  </>
                ) 
                : "No Data Found"}
              </div>
              <div className="mt-8"></div>

              <div className="flex items-end">              
                <Button type="submit">Save</Button>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        title={"Are you sure you want to delete" + " ID: " + confirmationModal.AnalyserName + " ?"}
        confirmationButtonText="yes"
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => deleteQCAnalyzer(confirmationModal.qcla_id)}
      />
      <div className="mt-4 flex">
        {QcanalyzerList.length <= 0?(
        <button
          type="button"
          onClick={() => setModal((prev) => ({ ...prev, state: true }))}
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </button>):(<></>)}
      </div>
      {renderModal()}
      {loading ? (
        <FallingLinesLoader />
      ) : QcanalyzerList.length > 0 ? (
        <Table columns={qc_analyzer_columns({onDeleteQCAnalyzer})} data={QcanalyzerList} />
      ) : (
        <TableEmpty />
      )}
    </div>
  );
}
export default QCAnalyzer;
