export const GET_ALL_PACKAGE_BEGIN = "GET_ALL_PACKAGE_BEGIN"
export const GET_ALL_PACKAGE_SUCCESS = "GET_ALL_PACKAGE_SUCCESS"
export const GET_ALL_PACKAGE_ERROR = "GET_ALL_PACKAGE_ERROR"

export const ADD_PACKAGE_BEGIN = "ADD_PACKAGE_BEGIN"
export const ADD_PACKAGE_SUCCESS = "ADD_PACKAGE_SUCCESS"
export const ADD_PACKAGE_ERROR = "ADD_PACKAGE_ERROR"

export const GET_PACKAGE_BEGIN = "GET_PACKAGE_BEGIN"
export const GET_PACKAGE_SUCCESS = "GET_PACKAGE_SUCCESS"
export const GET_PACKAGE_ERROR = "GET_PACKAGE_ERROR"

export const UPDATE_PACKAGE_BEGIN = "UPDATE_PACKAGE_BEGIN"
export const UPDATE_PACKAGE_SUCCESS = "UPDATE_PACKAGE_SUCCESS"
export const UPDATE_PACKAGE_ERROR = "UPDATE_PACKAGE_ERROR"

export const DELETE_PACKAGE_BEGIN = "DELETE_PACKAGE_BEGIN"
export const DELETE_PACKAGE_SUCCESS = "DELETE_PACKAGE_SUCCESS"
export const DELETE_PACKAGE_ERROR = "DELETE_PACKAGE_ERROR"