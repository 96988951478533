import Breadcrumb from '../../../components/Breadcrumb';
import $ from "jquery"; //Load jquery
import React, { Component, createRef, useEffect, useState } from "react"; //For react component
import { addQuestionnaire, getAllQuestionnaires, getSingleQuestionnaire } from '../../../redux/questionnaire/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_QUESTIONNAIRE_SUCCESS } from '../../../redux/questionnaire/constants';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';

window.jQuery = $; //JQuery alias
window.$ = $; //JQuery alias

require("jquery-ui-sortable"); //For FormBuilder Element Drag and Drop
require("formBuilder");// For FormBuilder
// require("formBuilder/dist/form-render.min.js") // Use this for render form.

const Questionnaire = () => {
  const pages = [{ title: "Questionnaire", href: "/questionnaire", module_id: 25 }];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { qstn_id: qstn_id } = useParams();

  const {
    loading,
    questionnaire: { list: questionnaireList }
  } = useSelector((state) => state);
  const fb = createRef();
  let [formBuilder, setFormBuilder] = useState(null);
  let [requestDone, setRequestDone] = useState(false);
  let [qstn_name, setQstnName] = useState('');

  useEffect(() => {
    // console.log(questionnaireList)
    if (!questionnaireList.qstn_json && requestDone) {
      navigate('/questionnaire-list');
    }
    if (questionnaireList.qstn_json) {
      setQstnName(questionnaireList.qstn_name);
      // console.log(questionnaireList)
      // console.log(formBuilder?.formData)
      //To create form
      if (!formBuilder?.formData) {
        setFormBuilder($(fb.current).formBuilder({
          disabledActionButtons: ['data', 'clear', 'save'],
          formData: questionnaireList.qstn_json
        })
        );
      }
    } else {
      if (qstn_id && !requestDone) {
        (async function () {
          await dispatch(getSingleQuestionnaire(qstn_id));
          setRequestDone(true)
        })()
      } else {
        //To create form
        if (!formBuilder?.formData) {
          setFormBuilder($(fb.current).formBuilder({
            disabledActionButtons: ['data', 'clear', 'save'],
            formData: []
          })
          );
        }
      }
    }
  }, [questionnaireList])
  async function saveData() {
    // console.log(formBuilder.formData);
    await dispatch(await addQuestionnaire({ qstn_id: qstn_id ? qstn_id : undefined, qstn_name, qstn_json: formBuilder.formData }))
    navigate('/questionnaire-list')
  }
  function clearData() {
    formBuilder.actions.clearFields();
  }
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <button
        type="button"
        onClick={() => navigate(-1)}
        className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
      >
        <span className="w-5 mr-2">
          <ArrowUturnLeftIcon size={18} />
        </span>{" "}
        Back
      </button>
      {loading ? (<FallingLinesLoader />) : <>
        <div id="fb-editor" ref={fb} />
        <div className="pt-5">
          <div className='border border-gray-300 rounded-md px-8 py-8 mb-5'>
            <div className='flex items-center gap-5' >
              <div className='w-full flex items-center'>
                <label htmlFor="role" className="block text-sm font-medium text-gray-700 mr-5">
                  FORM NAME
                </label>
                <div className="mt-1 w-full">
                  <input
                    type="text"
                    name="qstn_name"
                    id="qstn_name"
                    value={qstn_name}
                    onChange={event => setQstnName(event.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    placeholder="Form Name"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              onClick={clearData}
              type="button"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              Clear
            </button>
            <button
              onClick={saveData}
              type="button"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
            >
              {qstn_id ? 'Edit' : 'Save'}
            </button>
          </div>
        </div>
      </>}
    </div>
  )
}

export default Questionnaire