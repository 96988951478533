import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SampleServices from "../../../ApiServices/SampleServices";
import { toast } from "react-toast";
import MasterServices from "../../../ApiServices/MasterServices";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import moment from "moment";
import { ArrowUturnLeftIcon, CheckIcon, HandThumbUpIcon, UserIcon } from '@heroicons/react/20/solid'
import Breadcrumb from "../../../components/Breadcrumb";
import { convertToAnotherTimezone, convertUTStoIST } from "../../../helper/commonHelper";


const SampleDetails = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [LogTimeLine, setSampleLogTimeLine] = useState(false);
    const [userDetails, setUserDetails] = useState(false);
    const [sampleDetails, setSampleDetails] = useState(false);
    const [locationList, setLocationList] = useState(false)
    const [selectedLoc, setSelectedLoc] = useState(false)
    const { sample_id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getSampleLogDetails(sample_id);
        setUserDetails(JSON.parse(localStorage.getItem("edit_booking_reservation_user_details")));
        getAllLocationList(sample_id)
        getSampleDetails()
    }, []);

    useEffect(() => {
        if (sampleDetails?.sample) {
            setSelectedLoc(sampleDetails.sample.fk_loc_id)
        }
    }, [sampleDetails])

    const pages = [
        { title: "Sample Details", href: `/samplelist/${sample_id}` },
    ];
    const getSampleLogDetails = (sample_id) => {
        if (sample_id) {
            SampleServices.getSampleLogDetails(sample_id)
                .then((response) => {
                    const { data, status } = response;
                    if (status == 200) {
                        if (data?.data) {
                            setSampleLogTimeLine(data.data);
                        }
                    } else {
                        toast.error("Fatal Error Please Contact Admin")
                    }
                })
                .catch((error) => {
                    const msg = "getSampleLogDetails catch Error"; //error.response.data.message;
                    toast.error(msg);
                });
        }
    }

    const getAllLocationList = (sample_id) => {
        setIsLoading(true)
        MasterServices.getAllTestBaseLocationList(sample_id)
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    if (data?.data?.length > 0) {
                        setLocationList(data.data);
                    } else {
                        toast.error(data.message)
                    }
                } else {
                    toast.error("Fatal Error Please Contact Admin")
                }
                setIsLoading(false);
            })
            .catch((error) => {
                const msg = "getAllLocationList catch Error";
                toast.error(msg);
                setIsLoading(false);
            });
    }

    const handleLocChange = async (e) => {
        setIsLoading(true)
        let payload =
        {
            loc_id:
                e.target.value
        }
        let res = await SampleServices.setSampleLocation(sample_id, payload)
        if (res.status == 200) {
            setSelectedLoc(e.target.value)
            setIsLoading(false)
            toast.success(res.data.message)
        }
    }

    const getSampleDetails = async () => {
        try {
            setIsLoading(true)
            const details = await SampleServices.getSampleDetails(sample_id)
            if (details.status == 200) {
                setSampleDetails(details.data.data)
                // getSampleLogDetails(sample_id);
            }
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            toast.error("Couldn't fetch Sample Details")
        }
    }
    useEffect(() => {
        console.log("timeline 2=>", LogTimeLine);
    });

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <>
            <Breadcrumb pages={pages} />
            <button
                type="button"
                onClick={() => navigate(-1)}
                className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
            >
                <span className="w-5 mr-2">
                    <ArrowUturnLeftIcon size={18} />
                </span>{" "}
                Back
            </button>
            {!isLoading ? (<div>
                <div className="px-3 py-5">
                    <div className="sales-booking-head-s">
                        <h1 className="text-xl font-semibold text-gray-900">
                            Sample No. {sample_id}
                        </h1>
                    </div>
                </div>

                {
                    !isLoading && sampleDetails ?
                        <div className="px-3 py-3">
                            <div className="sales-booking-head-s">
                                <div className="flex flex-row gap-5">
                                    <div className="basis-1/2">
                                        <div className="booking-user-details-s">
                                            <div className="sample-main-div">
                                                <p className="font-semibold mb-2">{userDetails?.user_first_name}</p>
                                                <p className="font-semibold text-sm text-gray-600 sample-details-head">
                                                    Sample Details :
                                                </p>
                                                <div className="text-gray-600 text-sm leading-6">
                                                    <div className="flex items-center">

                                                        <p className="ml-2">Sample Name : {sampleDetails.sample?.sample_name_en || "N/A"}</p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="ml-2">Status : {sampleDetails.sample?.m_sample_status_relation
                                                            .sp_status_name_en || "N/A"}</p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="ml-2">Status Updated At: {moment(convertToAnotherTimezone(sampleDetails.sample?.m_sample_status_relation
                                                            .sp_status_updated_at)).format("Do-MMM-YYYY HH:mm:ss") || "N/A"}</p>
                                                    </div>
                                                    <div className="flex items-center">

                                                        <p className="ml-2">Is Urgent : {sampleDetails.sample?.sample_is_urgent || "N/A"}</p>
                                                    </div>
                                                    <div className="flex items-center">

                                                        <p className="ml-2">Sample Type : {sampleDetails.sample?.m_sample_type_relation.sample_type_name_en
                                                        }</p>
                                                    </div>
                                                    <div className="flex items-center">

                                                        <p className="ml-2">Sample Color : {sampleDetails.sample?.m_sample_type_relation.sample_type_color
                                                            || "N/A"}</p>
                                                    </div>
                                                    <div className="flex items-center">

                                                        <p className="ml-2">Sample Comment : {sampleDetails.sample?.sample_comment || "N/A"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="booking-user-details-s mt-5">
                                            <div className="sample-main-div">
                                                {/* <p className="font-semibold mb-2">{userDetails?.user_first_name}</p> */}
                                                <p className="font-semibold text-sm mb-2 text-gray-600 sample-details-head">
                                                    Reservation Details :
                                                </p>

                                                <div className="text-gray-600 text-sm leading-6">
                                                    <div className="flex items-center">

                                                        <p className="ml-2">Reservation ID: {sampleDetails.sampleGenerated?.fk_reservation_id || "N/A"}</p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="ml-2">Package / Test : {sampleDetails.sampleGenerated?.sp_is_package ? "Package" : "Test"}</p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="ml-2">Booking Type: {sampleDetails.sampleGenerated?.t_reservation_relation.booking_type}</p>
                                                    </div>
                                                    <div className="flex items-center">

                                                        <p className="ml-2">Payment Completed: {sampleDetails.sampleGenerated?.t_reservation_relation.is_payment_completed ? "Paid" : "Pending"}</p>
                                                    </div>
                                                    <div className="flex items-center">

                                                        <p className="ml-2">Payable Amount : {
                                                            sampleDetails.sampleGenerated?.t_reservation_relation.rev_payable_amount}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="booking-user-details-s mt-5">
                                            <div className="sample-main-div">
                                                {/* <p className="font-semibold mb-2">{userDetails?.user_first_name}</p> */}
                                                <p className="font-semibold text-sm mb-2 text-gray-600 sample-details-head">
                                                    Test Details :
                                                </p>
                                                <div className="text-gray-600 text-sm leading-6">
                                                    <div className="flex items-center">

                                                        <p className="ml-2">Test Name: {sampleDetails.sampleGenerated?.t_test_relation.test_name_en}</p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="ml-2">Test Hospital Price : {sampleDetails.sampleGenerated?.t_test_relation.hos_price
                                                        }</p>
                                                    </div>
                                                    {/* <div className="flex items-center">
                                                        <p className="ml-2">Booking Type: {sampleDetails.sampleGenerated[0]?.t_reservation_relation.booking_type}</p>
                                                    </div>
                                                    <div className="flex items-center">

                                                        <p className="ml-2">Payment Completed: {sampleDetails.sampleGenerated[0]?.t_reservation_relation.is_payment_completed ? "Paid" : "Pending"}</p>
                                                    </div>
                                                    <div className="flex items-center">

                                                        <p className="ml-2">Payable Amount : {
                                                            sampleDetails.sampleGenerated[0]?.t_reservation_relation.rev_payable_amount}</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="basis-1/2">
                                        <div className="flow-root p-5">
                                            <ul role="list" className="-mb-8">
                                                {LogTimeLine?.map((event, eventIdx) => (
                                                    <li key={event.id}>
                                                        <div className="relative pb-8">
                                                            {eventIdx !== LogTimeLine?.length - 1 ? (
                                                                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                                            ) : null}
                                                            <div className="relative flex space-x-3">
                                                                <div>
                                                                    <span
                                                                        className={classNames(
                                                                            event?.iconBackground,
                                                                            'bg-green-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                                                                        )}
                                                                    >
                                                                        <CheckIcon className="h-5 w-5 text-white " aria-hidden="true" />
                                                                        {/* <event.icon className="h-5 w-5 text-white" aria-hidden="true" /> */}
                                                                    </span>
                                                                </div>
                                                                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                                                    <div>
                                                                        <p className="text-sm text-gray-500">
                                                                            {event?.content}{" "}
                                                                            <a href={event?.href} className="font-medium text-gray-900">
                                                                                {/* {event?.target} */}
                                                                            </a>
                                                                        </p>
                                                                    </div>
                                                                    <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                                                        {event?.sl_time_log && (<>
                                                                            {(eventIdx === 0 || eventIdx === 1) ?
                                                                                <time dateTime={moment(convertToAnotherTimezone(convertUTStoIST(event?.sl_time_log))).format("Do-MMM-YYYY HH:mm:ss a")}>{moment(convertToAnotherTimezone(convertUTStoIST(event?.sl_time_log))).format("Do-MMM-YYYY HH:mm:ss a")}</time>
                                                                                :
                                                                                <time dateTime={moment(convertToAnotherTimezone(event?.sl_time_log)).format("Do-MMM-YYYY HH:mm:ss a")}>{moment(convertToAnotherTimezone(event?.sl_time_log)).format("Do-MMM-YYYY HH:mm:ss a")}</time>
                                                                            }
                                                                        </>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {
                                    locationList && locationList.length > 0 ?
                                        <div className="mt-6">

                                            <p className="font-semibold text-sm mb-2 text-gray-600">
                                                Lab Location Assigned
                                            </p>
                                            <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                                <select
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                                    name="loc_id"
                                                    id="loc_id"
                                                    value={selectedLoc ? selectedLoc : ""}
                                                    onChange={(e) => {
                                                        handleLocChange(e)

                                                    }}
                                                >
                                                    <option> Select Location </option>
                                                    {locationList.map((location, i) => (
                                                        <option
                                                            selected={
                                                                location.loc_id ===
                                                                    selectedLoc
                                                                    ? "selected"
                                                                    : ""
                                                            }
                                                            key={i}
                                                            value={location.loc_id}
                                                        >
                                                            {location.loc_name_en}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        : null
                }
            </div>) : <FallingLinesLoader />}
        </>
    );
};

export default SampleDetails;
