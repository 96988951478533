import { Provider } from "react-redux";
import RoutesConfig from "./routes/Routes";
import store from "./redux/store";
import 'leaflet/dist/leaflet.css'

const App = () => {
  
  console.log('API URL:', process.env.REACT_APP_API_URL);

  return (
    <Provider store={store}>
        <RoutesConfig />
    </Provider>
  );
};

export default App;
