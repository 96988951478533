import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toast';
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import MasterServices from '../../../ApiServices/MasterServices';
import Breadcrumb from "../../../components/Breadcrumb";
import AddTest from "../tests/addTest";
import SubTestList from "../subtest/SubTestList";

let initialTabs = [
  { name: 'Test', href: '#test', current: true, is_sub_test: true },
  { name: 'Test-Parameter', href: '#subtest', current: false, is_sub_test: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function TestTabDetails() {
  const { test_id } = useParams();
  const [tabs, setTabs] = useState([...initialTabs]);
  const navigate = useNavigate();
  const [testData, setTestData] = useState({});
  const [tabHistory, setTabHistory] = useState([]); // Maintain tab History
  let [isTabChanged, setIsTabChanged] = useState(1);

  useEffect(() => {
    if (test_id) {
      MasterServices.getSingleTest(test_id)
        .then((response) => {
          const { data, status } = response;
          if (status === 200) {
            setTestData(data.data);
            const updatedTabs = tabs.map((tab) => ({
              ...tab,
              is_sub_test: tab.name === "Test-Parameter" ? data.data.is_sub_test : true,
            }));
            setTabs(updatedTabs);
          } else {
            toast.error("Fatal Error Please Contact Admin");
          }
        })
        .catch((error) => {
          const msg = error.response?.data?.message || "An error occurred";
          toast.error(msg);
        });
    }

    // Reset tabs when the component unmounts
    return () => setTabs([...initialTabs]);
  }, [test_id]);

  const pages = [
    { title: "Update Test", href: `/test-tab/${test_id}` },
  ];

  const ChangeTabs = (tab, index) => {
    const updatedTabs = tabs.map((ele, ind) => ({
      ...ele,
      current: ind === index,
    }));
    setIsTabChanged(isTabChanged + 1);
    setTabs(updatedTabs);
    setTabHistory((prevHistory) => [...prevHistory, tabs.find(t => t.current)]); // Store the current tab in history
  };

  const handleBackClick = () => {
    if (tabHistory.length > 0) {
      const previousTab = tabHistory[tabHistory.length - 1];
      const updatedTabs = tabs.map((tab) => ({
        ...tab,
        current: tab.name === previousTab.name,
      }));
      setTabs(updatedTabs);
      setTabHistory((prevHistory) => prevHistory.slice(0, -1)); // Remove the last entry from the history
    } else {
      navigate(-1); // Navigate back if there's no tab history
    }
  };

  return (
    <div>
      <Breadcrumb pages={pages} />
      <button
        type="button"
        onClick={(e) => { e.preventDefault(); handleBackClick(); }}
        className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
      >
        {/* navigate(-isTabChanged) */}
        <span className="w-5 mr-2"><ArrowUturnLeftIcon size={18} /></span>
        Back vv
      </button>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="mb-5 flex space-x-8" aria-label="Tabs">
            {tabs.map((tab, index) => (
              tab.is_sub_test ? (
                <a
                  key={tab.name}
                  href={tab.href}
                  onClick={() => ChangeTabs(tab, index)}
                  className={classNames(
                    tab.current
                      ? 'border-cyan-500 text-cyan-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap p-1 border-b-2 font-medium text-sm'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                </a>
              ) : null
            ))}
          </nav>
          {tabs.map((tab) => (
            <div key={tab.name}>
              {tab.href === "#test" && tab.current && <AddTest />}
              {tab.href === "#subtest" && tab.current && testData.is_sub_test && <SubTestList />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TestTabDetails;
