import {
    GET_ALL_NOTIFICATION_BEGIN,
    GET_ALL_NOTIFICATION_SUCCESS,
    GET_ALL_NOTIFICATION_ERROR,
    ADD_NOTIFICATION_BEGIN,
    ADD_NOTIFICATION_SUCCESS,
    ADD_NOTIFICATION_ERROR,
    UPDATE_NOTIFICATION_STATUS_BEGIN,
    UPDATE_NOTIFICATION_STATUS_SUCCESS,
    UPDATE_NOTIFICATION_STATUS_ERROR
  } from "./constants";
  
  const initState = {
    list: [],
    loading: false,
    error: null,
  };
  
  const NotificationReducer = (state = initState, action) => {
    const { type, data, error } = action;
    switch (type) {
      case GET_ALL_NOTIFICATION_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case GET_ALL_NOTIFICATION_SUCCESS:
        return {
          ...state,
          list: data,
          loading: false,
          data: [...state.list, ...data]
        };
      case GET_ALL_NOTIFICATION_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case ADD_NOTIFICATION_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case ADD_NOTIFICATION_SUCCESS:
        return {
          ...state,
          loading: false,
          list: [...state.list, ...data],
        };
      case ADD_NOTIFICATION_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case UPDATE_NOTIFICATION_STATUS_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case UPDATE_NOTIFICATION_STATUS_SUCCESS:
        return {
          ...state,
          loading: false,
          list: [...data],
        };
      case UPDATE_NOTIFICATION_STATUS_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default NotificationReducer;
  