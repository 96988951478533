import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { userSchema } from "../../../schemas";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import {
	deleteUser,
	getAllUsers,
	addUser,
	updateUser,
	getUserWithId,
} from "../../../redux/user/actions";
// import {
//   getAllCountrys
// } from "../../../redux/country/actions";
// import {
//   getAllStates
// } from "../../../redux/state/actions";
import { getAllRoles } from "../../../redux/role/actions";
import { getAllLocations } from "../../../redux/location/actions";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import Breadcrumb from "../../../components/Breadcrumb";

const AddUser = () => {
	const pages = [{ title: "Users", href: "/user-list" }];
	const referral_code = (length = 5) => {
		var result = "";
		var characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result + Date.now();
	};
	const doclist = [
		{ title: "Select Document Type" },
		{ title: "Emirates ID", value: "Emirates ID" },
		{ title: "Passport ID", value: "Passport ID" },
		{ title: "Nationality", value: "Nationality" },
		{ title: "Email ID", value: "Email ID" },
		{ title: "Mobile No", value: "Mobile No" },
		{ title: "DOB", value: "DOB" },
		// { title: "PAN No", value: "PAN No" },
		// { title: "Aadhaar No", value: "Aadhaar No" },
	];
	const genderList = [
		{
			id: 1,
			name: "Male",
			value: "M",
		},
		{
			id: 2,
			name: "Female",
			value: "F",
		},
		{
			id: 3,
			name: "Transgender",
			value: "T",
		},
	];
	const mainItems = [
		{
			id: Date.now(),
			doc_type: "",
			doc_value: "",
		},
	];
	const [getDocArr, setDocArr] = useState(mainItems);
	// add
	const addMore = () => {
		//const initVal = [...getDocArr];
		// const now = Date.now();
		const obj = {
			id: Date.now(),
			doc_type: "",
			doc_value: "",
		};
		// getDocArr.push(obj);
		setDocArr([...getDocArr, obj]);
		console.log("add : ", getDocArr);
	};
	const removeFields = (index) => {
		// console.log("pos: ", index);
		const data = [...getDocArr];
		data.splice(index, 1);
		setDocArr(data);
		console.log("Priyanshu SIngh=>", data);
	};

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { user_id: user_id } = useParams();
	const {
		loading,
		country,
		state,
		location: { list: locationList },
		user: { list: userList, userData },
		role: { list: roleList },
		role,
	} = useSelector((state) => state);
	useEffect(() => {
		document.title = "Labib | Users";
		dispatch(getAllUsers());
		dispatch(getAllRoles());
	}, []);
	useEffect(() => {
		console.log("Roles data=>", role);
	});

	const initialValues = {
		user_first_name: "",
		user_last_name: "",
		user_email: "",
		user_mobile: "",
		user_password: "",
		conf_password: "",
		user_location_id: "",
		user_role: "",
		user_status: true,
		user_gender: "",
		user_referral_code: referral_code(),
		doc_details: getDocArr,
	};

	const [formState, setFormState] = useState(initialValues);

	useEffect(() => {
		console.log("user_id=>", user_id);
		console.log(locationList);
		if (user_id) dispatch(getUserWithId(user_id));
		if (!locationList.length) {
			dispatch(getAllLocations());
		}
	}, [user_id, locationList]);

	useEffect(() => {
		console.log("Happy=>", userData);
		document.title = "Labib | Add-User";
		if (user_id && userData) {
			const newFormState = {
				user_first_name: userData.user_first_name,
				user_last_name: userData.user_last_name,
				user_email: userData.user_email,
				user_mobile: userData.user_mobile,
				user_password: userData.user_password,
				conf_password: userData.user_password,
				user_location_id: userData.user_location_id,
				user_role: userData.user_role,
				user_status: userData.user_status,
				user_gender: userData.gender,
				user_referral_code: userData.referral_code,
				doc_details: JSON.parse(userData.doc_details),
			};
			setFormState(newFormState);

			console.log("Arraylentgh=>", newFormState.doc_details.length);
			// getDocArr = [];
			// getDocArr = JSON.parse(newFormState.doc_details);
			setDocArr(newFormState.doc_details);
		}
	}, [user_id, userData]);

	const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
		useFormik({
			enableReinitialize: true,
			initialValues: formState,
			validationSchema: userSchema,
			onSubmit: (values, action) => {
				console.log(values);
				const body = { ...values };
				body.user_location_id = parseInt(values.user_location_id);
				body.doc_details = getDocArr;
				console.log("getDocArr=>", getDocArr);
				delete body.conf_password;
				if (!user_id) dispatch(addUser(body));
				else dispatch(updateUser(user_id, body));
				action.resetForm();
				navigate("/user-list");
			},
		});

	return (
		<div className="px-4 sm:px-6 lg:px-8">
			<Breadcrumb pages={pages} />
			<button
				type="button"
				onClick={() => navigate(-1)}
				className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
			>
				<span className="w-5 mr-2">
					<ArrowUturnLeftIcon size={18} />
				</span>{" "}
				Back
			</button>
			<div className="space-y-6 sm:space-y-5">
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="text-lg font-medium leading-6 text-gray-900">
							{user_id ? "Update User" : "Add User"}
						</h1>
						<p className="mt-1 max-w-2xl text-sm text-gray-500">
							This information will be displayed publicly so be careful what you
							share.
						</p>
					</div>
				</div>
				{loading ? (
					<FallingLinesLoader />
				) : (
					// onSubmit={handleSubmit}
					<form
						onSubmit={(e) => {
							e.preventDefault();
							console.log(e);
							handleSubmit(e);
						}}
						className="space-y-6 divide-y divide-gray-200"
					>
						<div className="flex flex-row mt-5">
							<div className="basis-3/4">
								{/* <div className="col-span-3"> */}
								<label
									htmlFor="user_first_name"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
								>
									First Name
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<input
										placeholder="Enter First Name"
										className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
										type="text"
										name="user_first_name"
										id="user_first_name"
										value={values.user_first_name}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									{errors.user_first_name && touched.user_first_name ? (
										<p className="text-red-600 text-sm">
											{errors.user_first_name}
										</p>
									) : null}
								</div>
								{/* </div> */}
							</div>
							<div className="basis-3/4">
								<label
									htmlFor="user_last_name"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
								>
									Last name
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<input
										placeholder="Enter Last Name"
										className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
										type="text"
										name="user_last_name"
										id="user_last_name"
										value={values.user_last_name}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									{errors.user_last_name && touched.user_last_name ? (
										<p className="text-red-600 text-sm">
											{errors.user_last_name}
										</p>
									) : null}
								</div>
							</div>

							<div className="basis-3/4">
								<label
									htmlFor="user_last_name"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
								>
									Email ID
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<input
										placeholder="Enter Email ID"
										className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
										type="email"
										name="user_email"
										id="user_email"
										autoComplete="off"
										value={values.user_email}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									{errors.user_email && touched.user_email ? (
										<p className="text-red-600 text-sm">{errors.user_email}</p>
									) : null}
								</div>
							</div>
						</div>

						<div className="flex flex-row mt-5">
							<div className="basis-3/4">
								<label
									htmlFor="user_mobile"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
								>
									User Mobile
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<input
										value={values.user_mobile}
										onChange={handleChange}
										onBlur={handleBlur}
										type="number"
										placeholder="Enter User Mobile"
										name="user_mobile"
										autoComplete="off"
										className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
									/>
									{errors.user_mobile && touched.user_mobile ? (
										<p className="text-red-600 text-sm">{errors.user_mobile}</p>
									) : null}
								</div>
							</div>

							<div className="basis-3/4">
								<label
									htmlFor="user_password"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
								>
									Password
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<input
										placeholder="Password"
										className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
										type="Password"
										name="user_password"
										id="phone"
										autoComplete="off"
										value={values.user_password}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									{errors.user_password && touched.user_password ? (
										<p className="text-red-600 text-sm">
											{errors.user_password}
										</p>
									) : null}
								</div>
							</div>

							<div className="basis-3/4">
								<label
									htmlFor="user_password"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
								>
									Confirm Password
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<input
										placeholder="Confirm Password"
										className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
										type="Password"
										name="conf_password"
										id="conf_password"
										autoComplete="off"
										value={values.conf_password}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									{errors.conf_password && touched.conf_password ? (
										<p className="text-red-600 text-sm">
											{errors.conf_password}
										</p>
									) : null}
								</div>
							</div>
						</div>

						<div className="flex flex-row mt-5">
							<div className="basis-3/4">
								<label
									htmlFor="user_location_id"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
								>
									Location
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<select
										className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
										name="user_location_id"
										id="user_location_id"
										value={values.user_location_id}
										onChange={handleChange}
										onBlur={handleBlur}
									>
										<option> Select Location </option>
										{locationList.map((location, i) => (
											<option key={i} value={location.location_id}>
												{location.location_name}
											</option>
										))}
									</select>

									{errors.user_location_id && touched.user_location_id ? (
										<div className="text-sm text-red-600">
											{errors.user_location_id}
										</div>
									) : null}
								</div>
							</div>

							<div className="basis-3/4">
								<label
									htmlFor="user_role"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
								>
									Role
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<select
										className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
										name="user_role"
										id="user_role"
										value={values.user_role}
										onChange={handleChange}
										onBlur={handleBlur}
									>
										<option> Select Role </option>
										{roleList.map((rl, i) => (
											<option key={i} value={rl.role_id}>
												{rl.role_name}
											</option>
										))}
									</select>
									{errors.user_role && touched.user_role ? (
										<div className="text-sm text-red-600">
											{errors.user_role}
										</div>
									) : null}
								</div>
							</div>

							<div className="basis-3/4">
								<label
									htmlFor="user_role"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
								>
									Gender
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<select
										value={values.user_gender}
										onChange={handleChange}
										onBlur={handleBlur}
										id="user_gender"
										name="user_gender"
										className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
									>
										<option value="" disabled>
											Select
										</option>
										{genderList.map((gender, key) => (
											<option
												selected={
													gender.name === values.user_gender ? "selected" : ""
												}
												key={key}
												value={gender.value}
											>
												{gender.name}
											</option>
										))}
									</select>
									{errors.user_gender && touched.user_gender ? (
										<p className="text-red-600 text-sm">{errors.user_gender}</p>
									) : null}
								</div>
							</div>
						</div>

						<div className="flex flex-row mt-5">
							<div className="basis-3/4">
								<label
									htmlFor="user_role"
									className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
								>
									Referral Code
								</label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<input
										value={values.user_referral_code}
										onChange={handleChange}
										onBlur={handleBlur}
										name="user_referral_code"
										autoComplete="off"
										type="text"
										className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
										disabled={true}
									/>
									{errors.user_referral_code && touched.user_referral_code ? (
										<p className="text-red-600 text-sm">
											{errors.user_referral_code}
										</p>
									) : null}
								</div>
							</div>
							<div className="basis-3/4">
								<table className="w-full divide-y divide-gray-300">
									<thead className="bg-gray-50">
										<tr>
											<th
												scope="col"
												className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
											>
												Document Type
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
											>
												Document Value
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
											>
												Action
												<button
													type="button"
													onClick={addMore}
													className="inline-flex mt-4 items-center justify-center rounded-sm border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
												>
													Add
												</button>
											</th>
										</tr>
									</thead>
									<tbody className="bg-white">
										{getDocArr.map((item, itemIndex) => (
											<tr
												key={itemIndex}
												className={
													itemIndex % 2 === 0 ? undefined : "bg-gray-50"
												}
											>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
													<select
														className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
														name="user_location_id"
														id="user_location_id"
														value={item.doc_type}
														onInput={(e) => {
															// item.doc_type = e.
															item.doc_type =
																e.target.options[e.target.selectedIndex].text;
															console.log(
																"Item=>",
																e.target.options[e.target.selectedIndex].text
															);
															console.log("Item=>", getDocArr);
															setDocArr([...getDocArr]);
															// setFormState(newFormState);
														}}
													>
														<option> Select Document Type </option>
														{doclist.map((doc, i) => (
															<option key={i} value={doc.value}>
																{doc.title}
															</option>
														))}
													</select>
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													<input
														type="text"
														name="title"
														id="title"
														className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
														value={item.doc_value}
														onInput={(e) => {
															// item.doc_type = e.
															item.doc_value = e.target.value;
															console.log("Item=>", item.doc_value);
															console.log("Item=>", getDocArr);
															setDocArr([...getDocArr]);
															// setFormState(newFormState);
														}}
													/>
												</td>
												<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
													<a
														onClick={() => removeFields(itemIndex)}
														className="inline-flex mt-4 items-center justify-center rounded-sm border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
													>
														Remove
													</a>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>

						<div className="pt-5">
							<div className="flex justify-end">
								<button
									type="submit"
									className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
								>
									Submit
								</button>
							</div>
						</div>
					</form>
				)}
			</div>
		</div>
	);
};

export default AddUser;
