import React from "react";
import PropTypes from "prop-types";

const CustomInput = ({
  label,
  backgroundColor = "bg-white",
  textColor = "text-black",
  placeholder = "",
  onChange,
  ...props
}) => {
  return (
    <div className="flex flex-col w-full">
      {label && <label className="mb-1 font-semibold">{label}</label>}
      <input
        onChange={onChange}
        placeholder={placeholder}
        className={`w-full px-4 py-2 rounded border focus:outline-none focus:ring-2 focus:ring-offset-2 ${backgroundColor} ${textColor}`}
        {...props}
      />
    </div>
  );
};

CustomInput.propTypes = {
  label: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default CustomInput;
