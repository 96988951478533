import moment from "moment";
import React from "react";
import Barcode from "react-barcode";
import {
  calculateAge,
  convertToAnotherTimezone,
} from "../../../helper/commonHelper";

// Please connect with Abhi before modifications

export default function BarcodePrint({
  multiSampleDataForPrint,
  SingleprintsampleRef,
  getHspLocIdForPrint,
}) {
  return (
    <div
      className="print-container overflow-hidden print-barcode-full text-left hidden"
      ref={SingleprintsampleRef}
    >
      {multiSampleDataForPrint?.length > 0
        ? multiSampleDataForPrint?.map((rs_sample, rs_key) => (
          <div key={rs_key}>

            <div className="barcode-container">
              <Barcode
                width={2}
                height={34}
                displayValue={false}
                value={
                  // 278 // 3 digit
                  // 99999 // 5 digit
                  // 876543217658 // 12 digit
                  // 8765432176589 // 13 digit
                  rs_sample?.fk_sample_id
                }
              />
            </div>

            <div className="print-barcode-details">

              <div className="flex-space-between">
                {/* <div>Sample ID: {rs_sample.fk_sample_id} </div> */}
                <div>{rs_sample.fk_sample_id} </div>
                <div>
                  {moment(
                    convertToAnotherTimezone(rs_sample?.sample_created_at)
                  ).format("YYYY-MM-DD HH:mm a")}
                </div>
              </div>

              <div>
                {/* {rs_sample.patient_id} */}
                {rs_sample.patient_first_name.toUpperCase() +
                  (rs_sample.patient_last_name
                    ? " " + rs_sample.patient_last_name.toUpperCase()
                    : "")}
                {",    "}
                {calculateAge(
                  moment(rs_sample?.patient_dob).format("YYYY-MM-DD")
                )}{" y / "}
                {rs_sample.gender_name_en == "Male" ? "M" : "F"}
              </div>

              {/* <div>Reservation ID: {rs_sample.reservation_id ?? ""}</div> */}

              <div className="flex-space-between">
                <div>
                  {rs_sample?.sample_type_name_en + " - " + rs_sample.reservation_id ?? ""}
                </div>
                {/* <div>
                      {moment(
                        convertToAnotherTimezone(rs_sample?.sample_collection_time)
                      ).format("YYYY-MM-DD h:mm a")}
                    </div> */}
                <div>
                  {moment(
                    convertToAnotherTimezone(rs_sample?.sample_created_at)
                  ).format("YYYY-MM-DD HH:mm a")}
                </div>
              </div>

              {/* <div>MRN: {rs_sample.patient_report_mrn ?? ""}</div> */}

              <div>{rs_sample.patient_report_mrn ?? ""}</div>

              {/* <div>Lab ID: {getHspLocIdForPrint(rs_sample.loc_hsp_id)}</div> */}
            </div>
          </div>
        ))
        : null}
    </div>
  );
}
