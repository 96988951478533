import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Table from "../../../../components/tables/table";
import TableEmpty from "../../../../components/tables/tableEmpty";
import { control_list_columns } from "../../../../components/tables/tableheader";
import { toast } from "react-toast";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import MasterServices from "../../../../ApiServices/MasterServices";
import { Formik } from "formik";
import * as Yup from "yup";
import Modal from "../../../../components/Modal";
import InputBox from "../../../../components/InputBox";
import Button from "../../../../components/Button";
import QCTestList from "./QCTestList";
import QCAnalyzer from "./QCAnalyzer";
import QCLot from "./QCLot";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";

const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    name: "",
    stability: "",
  },
};

function QCTest() {
  const pages = [{ title: "Control List", href: "/qc/control-list" }];
  const [loading, setLoading] = useState(true);
  const [packageList, setPackageList] = useState(false);
  const [testList, setTestList] = useState([]);
  const { id: qcId } = useParams();
  const [modal, setModal] = useState(initialModalState);
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    onPackageList();
    document.title = "Labib | Control List";
  }, []);
  const onPackageList = async () => {
    MasterServices.getPackageList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            // toast.success("Package Found");
            setPackageList(data.data);
          } else {
            toast.error("Package Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };
  const initialValues = {
    qc_name_ar: "",
    qc_name_en: "",
    qc_stability: "",
    fk_test_ids: [],
  };

  const [formState, setFormState] = useState(initialValues);
  const renderModal = () => {
    const { type, state, data, edit_id } = modal;

    return (
      <Formik
        initialValues={data}
        validationSchema={Yup.object({
          name: Yup.string().required("QC name is required"),
          stability: Yup.string().required("Stability is required"),
        })}
        enableReinitialize={true}
        onSubmit={(values) => {
          console.log({ values });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Modal
            title="Add Control List"
            open={state}
            setOpen={() => setModal((prev) => ({ ...prev, state: false }))}
          >
            <form onSubmit={handleSubmit}>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <InputBox
                  name="name"
                  label="QC Name"
                  type="text"
                  value={values.name}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={true}
                  placeholder="Enter QC Name"
                />
                {touched.name && (
                  <p className="text-red-700 error_msg">{errors.name}</p>
                )}
              </div>

              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <InputBox
                  name="stability"
                  label="Stability"
                  type="text"
                  value={values.stability}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={true}
                  placeholder="Enter Stability"
                />
                {touched.stability && (
                  <p className="text-red-700 error_msg">{errors.stability}</p>
                )}
              </div>
              <div className="mt-4"></div>
              <Button type="submit">Add Control List</Button>
            </form>
          </Modal>
        )}
      </Formik>
    );
  };

  const initialtabs = [
    {
      name: "QC Information",
      indicator: "qc_information",
      current: true,
    },
    {
      name: "QC Analyzer",
      indicator: "qc_analyzer",
      current: false,
    },
    { name: "QC Tests", indicator: "qc_tests", current: false },
    { name: "QC Lot", indicator: "qc_lot", current: false },
  ];

  const [tabs, setTabs] = useState(initialtabs);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleTabsChange = (tab) => {
    const newTabs = tabs.map((t) => {
      if (t.name === tab.name) {
        return { ...t, current: true };
      } else {
        return { ...t, current: false };
      }
    });
    console.log({ newTabs });
    setTabs(newTabs);
  };

  const UpdateQCtest = (payload) => {
    MasterServices.updateQcTest(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Test Updated");
          setModal((prev) => ({ ...prev, state: false }));
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };
  const [QcData, setQcData] = useState({});

  const getQcbyIDs = (qid) => {
    MasterServices.getQcById(qid)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          setQcData(response.data.data);
          console.log("data fetched");
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  console.log({ QcData });
  // To Modify data for multi select
  function transformData(list) {
    return list.map((item) => ({
      name: item.test_name_en,
      id: item.test_id,
    }));
  }

  // Modifing data for multi select
  const testListData = transformData(testList);

  // Remoding data for sending it to backend
  function transformDataForBackend(list) {
    return list.map((item) => ({ test_id: item.id }));
  }

  useEffect(() => {
    if (qcId) {
      getQcbyIDs(qcId);
    }
  }, [qcId]);
  useEffect(() => {
    if (qcId && QcData) {
      const newForm = {
        qc_name_ar: QcData?.qc?.qc_name_ar,
        qc_name_en: QcData?.qc?.qc_name_en,
        qc_stability: QcData?.qc?.qc_stability,
        fk_test_ids: QcData?.tests?.map((item) => ({ test_id: item.test_id })),
      };
      setFormState(newForm);
    }
  }, [QcData]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <button
        type="button"
        onClick={() => navigate(-1)}
        className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
      >
        <span className="w-5 mr-2"><ArrowUturnLeftIcon size={18} /></span> Back
      </button>
      {renderModal()}
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Control List</h1>
      </div>
      {/* <div className="mt-4 flex">
        <button
          type="button"
          onClick={() => setModal((prev) => ({ ...prev, state: true }))}
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </button>
      </div> */}
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name} onClick={() => handleTabsChange(tab)}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tab.current
                      ? "border-cyan-500 text-cyan-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "whitespace-nowrap py-4 cursor-pointer px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                  onClick={() => handleTabsChange(tab)}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
      {console.log(
        tabs.find((x) => x.indicator === "qc_tests" && x.current === true)
      )}
      <div className="mt-5">
        {tabs.find(
          (x) => x.indicator === "qc_information" && x.current === true
        ) && (
            <div className="w-1/2">
              <Formik
                initialValues={formState}
                enableReinitialize={true}
                onSubmit={(values, action) => {
                  const body = {
                    ...values,
                    qc_id: parseInt(qcId),
                    qc_name_en: values.qc_name_en,
                    qc_name_ar: values.qc_name_en,
                    qc_stability: values.qc_stability,
                  };
                  delete body.selected;
                  console.log(body);
                  UpdateQCtest(body);
                  action.setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="text-left mt-4">
                      <div className="mt-4"></div>
                      <InputBox
                        name="qc_name_en"
                        label="QC Name"
                        type="text"
                        value={values.qc_name_en}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        required={true}
                        placeholder="Enter QC Name"
                      />
                      {touched.qc_name_en && (
                        <p className="text-red-700 error_msg">
                          {errors.qc_name_en}
                        </p>
                      )}
                    </div>

                    <div className="text-left mt-4">
                      <div className="mt-4"></div>
                      <InputBox
                        name="qc_stability"
                        label="Stability"
                        type="text"
                        value={values.qc_stability}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        required={true}
                        placeholder="Enter Stability"
                      />
                      {touched.qc_stability && (
                        <p className="text-red-700 error_msg">
                          {errors.qc_stability}
                        </p>
                      )}
                    </div>
                    <div className="mt-4"></div>
                    <Button disabled={isSubmitting} type="submit">
                      {isSubmitting ? "Saving..." : "Add Control List"}
                    </Button>
                  </form>
                )}
              </Formik>
            </div>
          )}
        {tabs.find((x) => x.indicator === "qc_tests" && x.current === true) && (
          <QCTestList />
        )}

        {tabs.find(
          (x) => x.indicator === "qc_analyzer" && x.current === true
        ) && <QCAnalyzer />}

        {tabs.find((x) => x.indicator === "qc_lot" && x.current === true) && (
          <QCLot />
        )}
      </div>
    </div>
  );
}
export default QCTest;
