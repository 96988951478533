import axios from 'axios';
import { BASEURL } from "../helper/constant.js";
import { toast } from 'react-toast'

export default () => {
    const instance = axios.create({
        baseURL: BASEURL
    })
    if (localStorage.getItem('token')) {
        instance.defaults.headers.common["authorization"] = `${localStorage.getItem('token')}`;
    }
    instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

    instance.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            if (error.response.status == 401) {
                console.log("Your authorization token is invalid or expired");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                localStorage.removeItem("favourites");
                localStorage.removeItem("module");
                localStorage.removeItem("user_loc_menus");
                localStorage.removeItem("userLocData");
                localStorage.removeItem("TBB_role_id");
                localStorage.removeItem("user_permissions");
                // localStorage.clear();
                window.location.replace("/");
            } else if (error.response.status == 403) {
                //Redirect to forbidden Page   
            }
            return Promise.reject(error.response);
        }
    );
    return instance;
};

// const showErrorMessage = message => {
//     console.log('Error ', message);
//     toast.error("Error ",message);
//     // Show Some Notification
// };