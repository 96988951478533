import React from "react";
import CustomInput from "../common/CustomInput";
import Select from "react-select";
import { FileUploader } from "react-drag-drop-files";
import { useSelector } from "react-redux";

const UserForm = () => {
  const fileTypes = ["JPEG", "PNG", "GIF"];
  const {
    role: { list: roleList },
  } = useSelector((state) => state);

  return (
    <div className="w-[400px]">
      <div className="bg-cyan-700 py-6 px-4 sm:px-6 text-white font-semibold text-xl">
        Add User
      </div>

      <div className="flex flex-col items-center justify-center gap-5 px-4 py-5">
        <CustomInput label={"Employee ID"} />
        <CustomInput label={"First Name"} placeholder="First Name" />
        <CustomInput label={"Last Name"} placeholder="Last Name" />
        <CustomInput label={"User Email"} placeholder="Email" />
        <CustomInput label={"User Mobile"} placeholder="Enter Mobile Number" />
        <CustomInput
          label={"Password"}
          placeholder="Password"
          type="password"
          autocomplete="off"
        />

        <Select
          placeholder="Select Role"
          className="w-full"
          options={
            roleList
              ? roleList.map((role) => ({
                  value: role.role_id,
                  label: role.role_name_en,
                }))
              : []
          }
        />
        <Select placeholder="Select Locations" className="w-full" />
        <Select placeholder="Multiple Locations" className="w-full" />
        <FileUploader
          className="w-full"
          multiple={false}
          types={fileTypes}
          name="file"
        />
      </div>
    </div>
  );
};

export default UserForm;
