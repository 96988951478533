import React, { Fragment, useRef, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { sample_columns } from "../../../components/tables/tableheader";
import { getAllHospitals } from "../../../redux/hospital/actions";
import { Dialog, Transition, } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { toast } from 'react-toast'
import SampleServices from '../../../ApiServices/SampleServices';
import { uploadFiles } from "../../../helper/multiUploadHelper";
import queryString from 'query-string';
import MasterServices from "../../../ApiServices/MasterServices";

let filterInitialValues = {
  filterFrom: "",
  filterTo: "",
  filterStatus: "",
  filterHos: "",
  filterBkType: "",
  filterOrg: "",
  filterSampleId: "",
  filterPatientMob: "",
  filterPatientIdentification: "",
  filterPatientName: "",
  filterPatientPassport: "",
  filterTestType: "",
  filterPatientId: "",
  filterReservationId: "",
  filterMrn: "",
};
const bookingType = [
  { name: "Normal", id: "1" },
  { name: "OPD", id: "2" },
  { name: "Emergancy", id: "3" },
  { name: "ICU", id: "4" },
];
const sample_status_colors = {
  'Generated': 'text-teal-500',
  'In Transit': 'text-orange-500',
  'Received by Lab': 'text-yellow-500',
  'Results Awaiting Approval': 'text-blue-500',
  'Results Approved': 'text-green-700',
  'Results Awaiting Validation': 'text-green-500',
  'Collected': 'text-purple-500',
  'Rejected by Lab': 'text-red-500',
}
function SampleList() {
  const {
    hospital: { list: hospitalList },
  } = useSelector((state) => state);
  const pages = [{ title: "Sample List", href: `/samplelist`, module_id: 11 }];
  const [filterFrom, setFilterFrom] = useState('');
  const [filterTo, setFilterTo] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterHos, setFilterHos] = useState('');
  const [filterBkType, setFilterBkType] = useState('');
  const [filterOrg, setFilterOrg] = useState('');
  const [filterSampleId, setFilterSampleId] = useState('');
  const [filterPatientMob, setFilterPatientMob] = useState('');
  const [filterPatientIdentification, setFilterPatientIdentification] = useState('');
  const [filterPatientName, setFilterPatientName] = useState('');
  const [filterPatientPassport, setFilterPatientPassport] = useState('');
  const [filterTestType, setFilterTestType] = useState('');
  const [filterPatientId, setFilterPatientId] = useState('');
  const [filterReservationId, setFilterReservationId] = useState('');
  const [filterMrn, setFilterMrn] = useState('');


  const [filterFlag, setFilterFlag] = useState(false);

  const [loading, setLoading] = useState(true);
  const [multifiles, setFiles] = useState([]);
  const [multiAttachmentArr, setMultiAttachment] = useState([]);
  const [sampleList, setSampleList] = useState(false);
  const [sampleStatuses, setSampleStatuses] = useState(false);
  const [attachFileModal, setAttachFileModal] = useState(false);
  const [viewAttachFileModal, setViewAttachFileModal] = useState(false);
  const [attachmentArr, setAttachment] = useState(false);
  const [attachmentList, setAttachmentList] = useState(false);
  const [commentsModal, setCommentsModal] = useState(false);
  const [commentData, setCommentData] = useState();
  const [saveComment, setSaveComment] = useState(false);
  const cancelButtonRef = useRef(null);
  const [sample_id, setSampleid] = useState('');
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    sample_id: null,
    sample_is_active: false,
  });
  const [progress, setProgress] = useState();
  const handleFileSubmit = async (e) => {
    const response = await uploadFiles(multifiles);
    console.log("Happy Singh=>", response)
    // setMultiAttachment(response);
    setMultiAttachment(response.data);
    setProgress(response.progress);
  };
  const handleFileChange = (e) => {
    const newFiles = [...e.target.files];
    setFiles([...multifiles, ...newFiles]);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editReservation = (reservation_id) => {
    console.log("rs_val=>", reservation_id);
    // alert(rs_val.fk_reservation_id);
    localStorage.setItem("edit_booking_reservation_id", reservation_id);
    navigate("/bookingConfirm")
  };
  useEffect(() => {
    document.title = "Labib | Sample";
    getAllSampleList();
    getAllSampleStatuses();
  }, []);
  const onFilter = () => {
    filterInitialValues = {
      filterFlag: true,
      filterFrom: filterFrom ? filterFrom : '',
      filterTo: filterTo ? filterTo : '',
      filterStatus: filterStatus ? filterStatus : '',
      filterHos: filterHos ? filterHos : '',
      filterBkType: filterBkType ? filterBkType : '',
      filterOrg: filterOrg ? filterOrg : '',
      filterSampleId: filterSampleId ? filterSampleId : '',
      filterPatientMob: filterPatientMob ? filterPatientMob : '',
      filterPatientIdentification: filterPatientIdentification ? filterPatientIdentification : '',
      filterPatientName: filterPatientName ? filterPatientName : '',
      filterPatientPassport: filterPatientPassport ? filterPatientPassport : '',
      filterTestType: filterTestType ? filterTestType : '',
      filterPatientId: filterPatientId ? filterPatientId : '',
      filterReservationId: filterReservationId ? filterReservationId : '',
      filterMrn: filterMrn ? filterMrn : '',
    };

    console.log("filterInitialValues=>", filterInitialValues)
    getAllSampleListFilterList(queryString.stringify(filterInitialValues));
  }
  const onFilterClear = () => {
    setFilterFrom('');
    setFilterTo('');
    setFilterStatus('');
    setFilterHos('');
    setFilterBkType('');
    setFilterOrg('');
    setFilterSampleId('');
    setFilterPatientMob('');
    setFilterPatientIdentification('');
    setFilterPatientName('');
    setFilterPatientPassport('');
    setFilterTestType('');
    setFilterPatientId('');
    setFilterReservationId('');
    setFilterMrn('');
    getAllSampleList();
  }
  const getAllSampleListFilterList = (payload) => {
    console.log("params payload=>", payload)
    SampleServices.getAllSampleListFilterList(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Sample List Found!");
          setSampleList(data.data);
        } else {
          toast.success(data.message);
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg)
      });
  }
  const getAllSampleList = () => {
    SampleServices.getAllSampleList(0)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setSampleList(data.data);
          } else {
            toast.error("SampleList Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = "getAllSampleList catch Error"; //error.response.data.message;
        toast.error(msg);
        setLoading(false);
      });
  }
  const getAllSampleStatuses = () => {
    MasterServices.getAllSampleStatuses()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setSampleStatuses(data.data);
          }
        }
      })
      .catch((error) => {
        console.log(error)
      });
  }
  const getAllSampleAttachmentList = () => {
    if (sample_id != 0) {
      SampleServices.getAllSampleAttachmentList(sample_id)
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            if (data) {
              setAttachmentList(data.data);
            } else {
              toast.error("getAllReservationAttachmentList Not Found")
            }
          } else {
            toast.error("Fatal Error Please Contact Admin")
          }
        })
        .catch((error) => {
          const msg = "getAllReservationAttachmentList catch Error"; //error.response.data.message;
          toast.error(msg);
        });
    }
  }
  const uploadAttachment = () => {

    if (multiAttachmentArr.length > 0) {
      SampleServices.updateAttachmentToSample({
        sample_id: sample_id,
        attachment: multiAttachmentArr,
      })
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            toast.success("File Saved Successfully!");
            setAttachFileModal(false);
            getAllSampleList();
          } else {
            toast.error("Fatal Error Please Contact Admin")
          }
        })
        .catch((error) => {
          const msg = error;
          toast.error(msg)
        });
    } else {
      toast.error("Please Choose a File");
    }
  }

  const updateComment = () => {
    if (commentData) {
      SampleServices.addSampleComment({
        sample_id: sample_id,
        sample_comment: commentData,
      })
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            toast.success("Comment Added Successfully!");
            setCommentsModal(false)
            getAllSampleList();
          } else {
            toast.error("Fatal Error Please Contact Admin")
          }
        })
        .catch((error) => {
          const msg = error;
          toast.error(msg)
        });
    } else {
      setCommentsModal(true)
      toast.error("Please Enter Comment");
    }
  }

  useEffect(() => {
  });
  const initialValues = {
    sample_id: "",
    sample_comment: "",
  };

  const [formSample, setFormSample] = useState(initialValues);


  const handleDrawer = (type, id, obj) => {
    if (type === "edit", id) {
      setSampleid(id);
      const initialValues = {
        sample_id: obj.sample_id,
        sample_comment: obj.sample_comment,
      };
      setFormSample(initialValues);
    } else {
      setSampleid("")
      setFormSample(initialValues);
    }
    // if (modalOpenFlage === false) {
    //   setmodalOpenFlage(true);
    // }
  };
  const onDeleteOpen = (sample_id, sample_is_active) => {
    setConfirmationModal({ sample_id, sample_is_active, status: true });
  };

  const onDeleteSample = (sample_id, sample_is_active) => {
    let Newsample_is_active = false;
    Newsample_is_active = sample_is_active == true ? false : true;
    let text = "Activated";
    if (Newsample_is_active == true) {
      text = "Activated";
    } else {
      text = "In-Activated";
    }
    SampleServices.setSampleStatus({
      sample_id,
      sample_is_active: Newsample_is_active
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Sample " + text + " Successfully!")
          setConfirmationModal({ sample_id: null, status: false });
          getAllSampleList();
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error.response.data.message;
        toast.error(msg)
      });
    setConfirmationModal({ sample_id: null, status: false });
  };
  // const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
  //   useFormik({

  //     enableReinitialize: true,
  //     initialValues: formSample,
  //     validationSchema: sampleCommentSchema,
  //     updateComment: (values, action) => {
  //       let body = {
  //         sample_id: sample_id,
  //         sample_comment: values.sample_comment,
  //       }
  //       console.log("body=>", body)

  //       SampleServices.addSampleComment(body)
  //         .then((response) => {
  //           const { data, status } = response;
  //           if (status == 200) {
  //             if (sample_id != 0 || sample_id != '') {
  //               toast.success("Sample Comment Updated Successfully");
  //             } else {
  //               toast.success("Sample Comment Added Successfully");
  //             }
  //             getAllSampleList();
  //           } else {
  //             toast.error("Fatal Error Please Contact Admin")
  //           }
  //         })
  //         .catch((error) => {
  //           const msg = "addSampleComment Catch Error" //error.response.data.message;
  //           toast.error(msg)
  //         });
  //       action.resetForm();
  //       // if (modalOpenFlage === true) {
  //       //   setmodalOpenFlage(false);
  //       // };
  //     }
  //   });

  return (
    <div className="px-4 sm:px-6 lg:px-8 ">
      <div className="bg-white px-4 py-5 sm:px-6 rounded-md shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
        <div className="sales-booking-head">
          <Breadcrumb pages={pages} />
          {/* <h1 className="text-xl font-semibold text-gray-900">Reservations List</h1> */}
          <div className="flex justify-between py-3 pl-2">
            <div className="flex items-center space-x-2">
              <div className="relative">
                <button
                  onClick={() => {
                    setFilterFlag(filterFlag ? false : true);
                  }}
                  className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1">
                  <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-md sm:py-2">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-3 h-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                        />
                      </svg>
                    </div>
                    <div className="hidden sm:block">Filters {filterFlag ? 'On' : 'Off'}</div>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title={"Are you sure want to " + (confirmationModal.sample_is_active ? "In-Activate" : "Activate") + " Sample? "}
        confirmationButtonText={confirmationModal.sample_is_active ? "In-Activate" : "Activate"}
        description={"Do you really want to " + (confirmationModal.sample_is_active ? "In-Activate" : "Activate") + " Sample"}
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteSample(confirmationModal.sample_id, confirmationModal.sample_is_active)}
      />
      {filterFlag ? (
        <div className="bg-white px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
          <div className="space-y-6 sm:space-y-5">
            {loading ? (
              <FallingLinesLoader />
            ) : (

              <div className="grid grid-cols-3 gap-3 lg:grid-cols-3 lg:gap-3 md:grid-cols-3 md:gap-3 sm:grid-cols-12 sm:gap-0 mt-5">
                <div className="basis-3/4"></div>
                <div className="basis-3/4"></div>
                <button
                  type="button"
                  className="text-red-500 text-right hover:text-red-700 font-bold py-1 px-1 rounded" onClick={() => setFilterFlag(filterFlag ? false : true)}>X</button>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterFrom"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Reservation From
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="date"
                      name="filterFrom"
                      id="filterFrom"
                      value={filterFrom}
                      onChange={(e) => {
                        setFilterFrom(e.target.value);
                      }
                      }
                    />

                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterTo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Reservation To
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="date"
                      name="filterTo"
                      id="filterTo"
                      value={filterTo}
                      onChange={(e) => { setFilterTo(e.target.value); }
                      }
                    />
                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor=""
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Hospital
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      value={filterHos}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      name="fk_hos_id"
                      id="fk_hos_id"
                      onInput={(e) => { setFilterHos(e.target.value); }}
                    >
                      <option >Select Hospital</option>
                      {hospitalList.map((hospital, i) => (
                        <option selected={hospital.hos_id === filterInitialValues.filterHos ? "selected" : ""} key={i} value={hospital.hos_id}>
                          {hospital.hos_name_en}
                        </option>
                      ))}
                    </select>
                  </div>


                </div>

                <div className="basis-3/4">
                  <label
                    htmlFor=""
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Organization
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      value={filterOrg}
                      onInput={(e) => { setFilterOrg(e.target.value); }}
                      name="filterOrg"
                      id="filterOrg"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm">
                      <option>Select Organization</option>
                      <option>Organization 1</option>
                      <option>Organization 2</option>
                    </select>
                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor=""
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Reservation Type
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      value={filterBkType}
                      onInput={(e) => { setFilterBkType(e.target.value); }}
                      name="filterBkType"
                      id="filterBkType"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm">
                      <option>Select Reservation Type</option>
                      {bookingType.map((bkType, i) => (
                        <option selected={bkType.name === filterInitialValues.filterBkType ? "selected" : ""} key={i} value={bkType.name}>
                          {bkType.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {
                  sampleStatuses && sampleStatuses.length > 0 ? (
                    <div className="basis-3/4">
                      <label
                        htmlFor="filterStatus"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Sample Status
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <select
                          value={filterStatus}
                          onInput={(e) => { setFilterStatus(e.target.value); }}
                          name="filterStatus"
                          id="filterStatus"
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm">
                          <option>Select Status</option>
                          {sampleStatuses?.map((spStatus, i) => (
                            <option selected={spStatus.sp_status_id === filterInitialValues.filterStatus ? "selected" : ""} key={i} value={spStatus.sp_status_id}>
                              {spStatus.sp_status_name_en}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ) : null
                }

                <div className="basis-3/4">
                  <label
                    htmlFor="filterPatientName"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Name
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      value={filterPatientName}
                      onInput={(e) => { setFilterPatientName(e.target.value); }}
                      placeholder="Search By Name"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterPatientName"
                      id="filterPatientName"
                    />
                  </div>
                </div>

                <div className="basis-3/4">
                  <label
                    htmlFor="filterPatientPassport"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Passport
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Passport"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterPatientPassport"
                      id="filterPatientPassport"
                      value={filterPatientPassport}
                      onInput={(e) => { setFilterPatientPassport(e.target.value); }}
                    />
                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterPatientId"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Patient ID
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Patient ID"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterPatientId"
                      id="filterPatientId"
                      value={filterPatientId}
                      onInput={(e) => { setFilterPatientId(e.target.value); }}
                    />
                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterPatientMob"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Mobile No.
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Mobile No."
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterPatientMob"
                      id="filterPatientMob"
                      value={filterPatientMob}
                      onInput={(e) => { setFilterPatientMob(e.target.value); }}
                    />
                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterPatientIdentification"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Identification
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Identification"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterPatientIdentification"
                      id="filterPatientIdentification"
                      value={filterPatientIdentification}
                      onInput={(e) => { setFilterPatientIdentification(e.target.value); }}
                    />
                  </div>
                </div>

                <div className="basis-3/4">
                  <label
                    htmlFor="filterReservationId"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Reservation No.
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Reservation No."
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterReservationId"
                      id="filterReservationId"
                      value={filterReservationId}
                      onInput={(e) => { setFilterReservationId(e.target.value); }}
                    />
                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterSampleId"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Sample ID.
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Sample ID."
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterSampleId"
                      id="filterSampleId"
                      value={filterSampleId}
                      onInput={(e) => { setFilterSampleId(e.target.value); }}
                    />
                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterMrn"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    MRN.
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By MRN."
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterMrn"
                      id="filterMrn"
                      value={filterMrn}
                      onInput={(e) => { setFilterMrn(e.target.value); }}
                    />
                  </div>
                </div>

                <div className="basis-3/4 mt-7">
                  <button
                    type="button"
                    className={`rounded-md border bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                    onClick={() => {
                      onFilter();
                    }}
                    size="default"
                    variant="outlined"
                  >
                    Search
                  </button>

                  <button
                    type="button"
                    className={`rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-1`}
                    onClick={() => {
                      onFilterClear();
                    }}
                    size="default"
                    variant="outlined"
                  >
                    Clear
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (<></>)}

      {
        loading ? (
          <FallingLinesLoader />
        ) : ((sampleList.length > 0) ? (
          <Table
            zoom='zoom-09'
            columns={sample_columns({ onDeleteOpen, editReservation, handleDrawer, setCommentsModal, setAttachFileModal, setSampleid, setViewAttachFileModal, getAllSampleAttachmentList, sample_status_colors })}
            data={sampleList}
          />
        ) : <TableEmpty />)
      }


      <Transition.Root show={attachFileModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          initialFocus={cancelButtonRef}
          onClose={setAttachFileModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 booking-quotation-table-w sm:p-6">
                  <div>
                    <form className="space-y-8 divide-y divide-gray-200">
                      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="space-y-6 sm:space-y-5">
                          <div>
                            <h3 className="text-lg font-medium leading-6 text-gray-900">
                              Add Attachment
                            </h3>
                          </div>
                          <div className="flex items-center justify-between">
                            <div>
                              <form
                                onSubmit={handleFileSubmit}
                              >
                                <input type="file" multiple onChange={handleFileChange} />
                                <button onClick={handleFileSubmit} type="button" className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">Upload</button>
                                {progress ? (<><div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-1">
                                  <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full mt-2"> {`${progress}%`}</div>
                                </div></>) : (<></>)}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pt-5">
                        <div className="flex justify-end">
                          <button
                            onClick={() => setAttachFileModal(false)}
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => { uploadAttachment(); setAttachFileModal(false); }}
                          >
                            Done
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={viewAttachFileModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          initialFocus={cancelButtonRef}
          onClose={setViewAttachFileModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                {/* <Dialog.Panel className="relative transform overflow-hidden bg-white px-4 pt-5 pb-4 text-left shadow-lg transition-all sm:my-8 booking-quotation-table-w sm:p-6 "> */}
                <Dialog.Panel className="relative p-8 bg-white w-full max-w-4xl mx-auto my-6 rounded-lg shadow-lg ">
                  <div>
                    <form className="space-y-8 divide-y divide-gray-200">
                      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="space-y-6 sm:space-y-5">
                          <div className="flex justify-between items-center">
                            <h3 className="text-2xl font-bold">View Attachment List</h3>
                            <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                              <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                                onClick={() => setViewAttachFileModal(false)}
                              >
                                <span className="sr-only">Close</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                          <div className="flex items-center justify-between">
                            <div className="p-1.5 w-full inline-block align-middle">
                              <div className="overflow-hidden border rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                  <thead className="bg-gray-50">
                                    <tr>
                                      <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6">
                                        SN
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                        Name
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                        Size
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                        Link
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>

                                    {(attachmentList?.length > 0) ? (attachmentList?.map((ra_attach, ra_key) => (
                                      <tr>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                          {parseInt(ra_key + 1)}.
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          {ra_attach?.name}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          {ra_attach?.size}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          <span
                                            className="inline-flex px-2 text-xs leading-5 text-cyan-600 bg-cyan-100 rounded-full"
                                          >
                                            <Link
                                              to={`${ra_attach?.file_path}`} target={'_blank'}
                                              className="text-cyan-900 "
                                              size="default"
                                              variant="outlined"
                                            >
                                              Download
                                            </Link>
                                          </span>
                                        </td>
                                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <span
                                              className="inline-flex px-2 text-xs leading-5 text-red-600 bg-red-100 rounded-full"
                                            >
                                              <Link
                                                onClick={() => deleteAttachment(ra_attach, ra_key)}
                                                className="text-red-900 "
                                                size="default"
                                                variant="outlined"
                                              >
                                                Delete
                                              </Link>
                                            </span>
                                          </td> */}
                                      </tr>
                                    ))) : (<tr>
                                      <td colSpan={4} className="border border-grey-500 px-4 py-2 text-grey-600 font-medium">
                                        <center>Data Not Found</center>
                                      </td>
                                    </tr>)
                                    }

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="pt-5">
                          <div className="flex justify-end">
                            <button
                              onClick={() => setViewAttachFileModal(false)}
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            >
                              Close
                            </button>
                          </div>
                        </div> */}
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={commentsModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          initialFocus={cancelButtonRef}
          onClose={setCommentsModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 booking-quotation-table-w sm:p-6">
                  <div>
                    <form className="space-y-8 divide-y divide-gray-200">
                      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="space-y-6 pt-5 sm:space-y-5 sm:pt-5">
                          <div>
                            <h3 className="text-lg font-medium leading-6 text-gray-900">
                              Add Comment
                            </h3>
                          </div>
                          <div className="flex items-center justify-between">

                            <textarea
                              type="text"
                              value={commentData}
                              className="input-w-class block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                              onInput={(e) =>
                                setCommentData(e.target.value)
                              }
                            />
                            {/* onInput={(e) =>
                                setCommentData(e.target.value)
                              } */}
                          </div>
                        </div>
                      </div>

                      <div className="pt-5">
                        <div className="flex justify-end">
                          <button
                            onClick={() => setCommentsModal(false)}
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => { updateComment(); }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
export default SampleList;