import React, { useRef, useState } from "react";
import Button from "../Button";
import * as Yup from "yup";
import { Formik } from "formik";


const EditResultTooptip = ({ fromTable, fk_lab_analyzer_id, fieldName, originalCell, lotId, unitId, saveFunc, updateFunc, prevValue, resultFunc, inputIndex, isActive, handleInputBlur }) => {
    const [btnText, setBtnText] = useState("Save");
    const inputRef = useRef(null);
    const initialValues = {
        [fieldName]: prevValue ? prevValue : "",
    };
    // console.log("fk_lab_analyzer_id", fk_lab_analyzer_id, fromTable)
    const getValidationSchema = () => {
        const baseSchema = {
            [fieldName]: Yup.string().required(`${fieldName} is required`)
        };

        if (fromTable === "sample__processing_columns -> Result Column" && ['ssp_value', 'another_result_field'].includes(fieldName)) {
            baseSchema[fieldName] = Yup.number().min(0, `${fieldName} must be 0 or a positive number`).required(`${fieldName} is required`);
        }

        return Yup.object(baseSchema);
    };
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema()}
            enableReinitialize={true}
            onSubmit={(values) => {
                setBtnText("Saving...");

                if(fieldName === 'role_max_discount_value') {
                    
                    let body = {
                        role_id: originalCell.role_id,
                        [fieldName]: values[fieldName],   
                    }
                    saveFunc(body)
                    setBtnText("Saved !");
                    return
                }

                let { sample_id, reservation_id, test_id, ttp_id,test_result } = originalCell
                
                let fk_subtest_id = null;

                if (ttp_id) {
                    fk_subtest_id = ttp_id
                }

                if (sample_id && test_id && reservation_id) {
                    const body = {
                        [fieldName]: values[fieldName],
                        fk_sample_id: sample_id,
                        fk_test_id: test_id,
                        fk_reservation_id: reservation_id,
                        ssp_id: test_result.length>0?test_result[0]?.ssp_id:null,
                        fk_subtest_id,
                        allData:originalCell,
                        inputIndex: inputIndex ? inputIndex : 0,
                        result_val: values[fieldName],
                        fk_lab_analyzer_id: fk_lab_analyzer_id
                    };
                    
                    if(prevValue == null){
                        saveFunc(body);
                    }else{
                        updateFunc(body)
                    }

                    if (isActive) {
                        // Move to the next row's input by focusing it
                        if (inputRef.current.nextSibling) {
                            inputRef.current.nextSibling.firstChild.focus();
                        }
                    }

                    resultFunc(values[fieldName])
                    setBtnText("Saved !");
                }
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
            }) => (
                <div className="flex w-max gap-2 Z-[9999]">
                    <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
                        <div>
                            <input
                                className={` w-20 appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm`}
                                placeholder="enter here value"
                                id={inputIndex + fieldName}
                                name={fieldName}
                                value={values[fieldName]}
                                onChange={handleChange}
                                //onBlur={handleBlur}
                                onBlur={(e) => {
                                    handleBlur(e); // Call Formik's onBlur
                                    handleInputBlur(); // Call your custom onBlur
                                }}
                                type="text"
                                autoFocus
                                autoComplete="off"
                            />
                            {/* {!prevValue && errors[fieldName] && touched[fieldName] && (
                                <p className="text-red-500 text-sm" >
                                    Value required
                                </p>
                            )} */}
                            {!prevValue && errors[fieldName] && touched[fieldName] && (
                                <p className="text-red-500 text-sm" >
                                    {errors[fieldName]}
                                </p>
                            )}
                        </div>
                        {/* <Button type="submit">{btnText}</Button> */}
                    </form>
                </div>
            )}
        </Formik>
    );
};

export { EditResultTooptip};