import React from "react";

const CustomRadioGroup = ({
  options = [],
  optionLabel,
  optionValue,
  selectedValue,
  onChange,
  display = "block", // 'block' for full width, 'inline' for inline display
}) => {
  return (
    <div
      className={`space-y-2 ${
        display === "inline" ? "flex space-x-4" : "space-y-2"
      }`}
    >
      {options.map((option) => (
        <label
          key={option[optionValue]}
          className={`relative flex items-center cursor-pointer p-4 rounded-lg focus:outline-none 
          ${display === "block" ? "w-full" : "w-auto"}
          ${
            selectedValue[optionValue] === option[optionValue]
              ? "bg-blue-300 text-white"
              : "bg-white text-gray-900"
          }`}
        >
          <input
            type="radio"
            value={option[optionValue]}
            checked={selectedValue[optionValue] === option[optionValue]}
            onChange={() => onChange(option)}
            className="inline-block w-4 h-4 mr-4 rounded-full border-2"
          />
          {/* <span
            className={`inline-block w-4 h-4 mr-4 rounded-full border-2 
            ${
              selectedValue[optionValue] === option[optionValue]
                ? "bg-white border-blue-500"
                : "bg-transparent border-gray-400"
            }`}
          /> */}
          <span
            className={
              selectedValue[optionValue] === option[optionValue]
                ? "font-medium"
                : "font-normal"
            }
          >
            {option[optionLabel]}
          </span>
        </label>
      ))}
    </div>
  );
};

export default CustomRadioGroup;
