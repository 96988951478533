import React from "react";
import { Bar } from "react-chartjs-2";
import {
	Chart as ChartJS,
	BarElement,
	CategoryScale,
	LinearScale,
	LineElement,
	PointElement,
	Legend,
	Title,
	Tooltip,
	SubTitle,
} from "chart.js";

ChartJS.register(
	BarElement,
	LinearScale,
	CategoryScale,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	SubTitle,
	Tooltip,
	Title
);

export default function RegionResBarChart({ salesData, salesLabel, yText }) {
	// console.log({ salesData, salesLabel });
	const options = {
		// responsive: true,

		plugins: {
			legend: {
				position: "bottom",
			},
			title: {
				display: true,
				text: "Regional Data",
			},
		},

		scales: {
			y: {
				beginAtZero: true,
				// display: false,
				ticks: {
					display: true,
				},
				title: {
					display: true,
					//   text: yText,
				},
			},
			//       x: {
			//         title: {
			//           display: true,
			//           text: 'Host App',
			//         },
			//       },
		},
	};

	let labels = salesLabel;
	let region_names = Object.keys(salesData);

	let bg_colors = [
		"rgb(255, 99, 132)",
		"rgb(255, 159, 64)",
		"rgb(255, 205, 86)",
		"rgb(75, 192, 192)",
		"rgb(54, 162, 235)",
		"rgb(153, 102, 255)",
		"rgb(201, 203, 207)",
	];
    
	let b_colors = [
		"rgb(255, 99, 132)",
		"rgb(255, 159, 64)",
		"rgb(255, 205, 86)",
		"rgb(75, 192, 192)",
		"rgb(54, 162, 235)",
		"rgb(153, 102, 255)",
		"rgb(201, 203, 207)",
	];

	let dynamicDataSet = [];
	region_names.forEach((region, i) => {
		dynamicDataSet.push({
			label: region,
			data: salesData[region],
			borderColor: b_colors[i],
			backgroundColor: bg_colors[i],
		});
	});

	const data = {
		labels,
		datasets: dynamicDataSet,
	};

	return (
		<div className="chart-div">
			<Bar options={options} data={data} />
		</div>
	);
}

//   const data = {
//     // labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
//     labels: usageData.labels,
//     datasets: [
//       {
//         label: label,
//         data: usageData.labelUsage,
//         fill: false,
//         backgroundColor: [
//           'rgba(255, 99, 132, 0.6)',
//           'rgba(255, 159, 64, 0.6)',
//           'rgba(255, 205, 86, 0.6)',
//           'rgba(75, 192, 192, 0.6)',
//           'rgba(54, 162, 235, 0.6)',
//           'rgba(153, 102, 255, 0.6)',
//           'rgba(201, 203, 207, 0.6)',
//         ],
//         borderColor: [
//           'rgb(255, 99, 132)',
//           'rgb(255, 159, 64)',
//           'rgb(255, 205, 86)',
//           'rgb(75, 192, 192)',
//           'rgb(54, 162, 235)',
//           'rgb(153, 102, 255)',
//           'rgb(201, 203, 207)',
//         ],
//         borderWidth: 1,
//       },
//     ],
//   }

//   const options = {
//     scales: {
//       y: {
//         beginAtZero: true,
//         // display: false,
//         ticks: {
//           display: true,
//         },
//         title: {
//           display: true,
//           text: yText,
//         },
//       },
//       x: {
//         title: {
//           display: true,
//           text: 'Host App',
//         },
//       },
//     },
//   }
