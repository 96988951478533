import Breadcrumb from "../Breadcrumb";
const PageHeaderWithBreadcrumb = ({ pageDetails, title, subtitle }) => {
  return (
    <>
      {pageDetails && <Breadcrumb pages={pageDetails} />}
      {title && (
        <h1 className="text-xl font-semibold text-gray-900">{title}</h1>
      )}
      {subtitle && <p className="mt-2 text-sm text-gray-700">{subtitle}</p>}
    </>
  );
};

export default PageHeaderWithBreadcrumb;
