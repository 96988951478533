import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useNavigate } from 'react-router-dom';


const BarcodeScanner = () => {
  const pages = [
    { title: "BarcodeScanner", href: "/barcode-scanner" },
  ];
  const [data, setData] = useState(false);
  const [stopStream, setStopStream] = useState(false)
  const navigate = useNavigate();

  const sampleAccordion =() => {
    let x = document.getElementById("sample-details");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  useEffect(() => {
    console.log(stopStream)
  }, [stopStream])

  return (
    <div>
      <Breadcrumb pages={pages} />
      <div className='flex py-5'>
        <div className="role basis-1/2 text-center text-gray-700 font-bold">
          Role-1
        </div>
        <div className="role-type basis-1/2 text-center text-gray-700 font-bold">
          Driver/Technician
        </div>
      </div>
      <div className="scanner flex">
        <div className='rounded basis-1/2'>
          <div className="mb-4">
            <BarcodeScannerComponent
              width={500}
              height={600}
              onUpdate={(err, result) => {
                if (result) {
                  setData(result.text);
                  setStopStream(true)
                }
              }}
              stopStream={stopStream}
            />
          </div>

          {data ? <div className="my-4 sm:mt-0 sm:flex justify-center">
            <button
              type="button"
              // onClick={()=>{}}q
              className="block rounded-md bg-cyan-600 py-1.5 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
            >
              Start Scanner
            </button>
          </div> : null}
        </div>
        <div className="scan-data basis-1/2">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-base font-semibold leading-6 text-gray-900 text-center">Pickup/Recieve Details</h1>
              </div>
            </div>
            <div className="mt-8 border border-gray-300 rounded-lg">
              <div className="border-b border-gray-200">
                <button onClick={()=>{
                  console.log("Nitesh")
                  sampleAccordion()}} className="flex justify-between items-center w-full px-4 py-2 text-sm font-medium text-left text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 transition duration-150 ease-in-out">
                  <span>Sample 1</span>
                  <svg className="w-10 h-8 fill-current text-gray-500" viewBox="0 0 20 20">
                    <path d="M6.928 9.172a.5.5 0 0 1 .707 0L10 11.793l2.364-2.364a.5.5 0 0 1 .707.707l-2.5 2.5a.5.5 0 0 1-.707 0l-2.5-2.5a.5.5 0 0 1 0-.707z"></path>
                  </svg>
                </button>
              </div>
              <div className="px-4 py-2" id='sample-details'>
                <p className="text-gray-700">
                  <div className="flow-root">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-300">
                            <tbody className="divide-y divide-gray-200 bg-white">
                              <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  Sample ID
                                </td>
                                <td className="break-all px-3 py-4 text-sm text-gray-500">{data}</td>
                              </tr>
                              <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  Last Location
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Andheri (East)</td>
                              </tr>
                              <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  Current Location
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Malad (East)</td>
                              </tr>
                              <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  Sample Type
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Blood</td>
                              </tr>
                              <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  Sample Status
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Pickup</td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="my-4 sm:mt-0 sm:flex justify-center">
                            <button
                              type="button"
                              className="block rounded-md bg-cyan-600 py-1.5 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                            >
                              Pickup/Recieve Details
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>


    </div>
  )
}

export default BarcodeScanner