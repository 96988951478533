import React, { useEffect, useState } from "react";
import Table from "../../../components/tables/table";
import { patient_list_columns } from "../../../components/tables/tableheader";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ReservationServices from '../../../ApiServices/ReservationServices';
import { toast } from "react-toast";

function PatientList() {
	const pages = [{ title: "Patient List List", href: "/patient-list", module_id: 2 }];
	const [isLoading, setIsLoading] = useState(true);
	const [userPatientList, setPatientUserList] = useState([]);

	useEffect(() => {
		document.title = "Labib | Patients";
		setIsLoading(true)
		getAllPatientUserList();
		// setIsLoading(false)
	}, []);

	const getAllPatientUserList = () => {
		ReservationServices.getAllUserList()
		  .then((response) => {
			const { data, status } = response;
			if (status == 200) {
				setIsLoading(false);
			  if (data?.data?.length > 0) {
				setPatientUserList(data.data);
			  } else {
				setIsLoading(false);
				toast.error("PatientList Not Found")
			  }
			} else {
				setIsLoading(false);
			  toast.error("Fatal Error Please Contact Admin")
			}
		  })
		  .catch((error) => {
			setIsLoading(false);
			const msg = "getAllPatientUserList catch Error";
			toast.error(msg);
		  });
	  }

	const onDeleteOpen = () => {

	}

	const handleDrawer = () => {
		
	}

	return (
		// isLoading ? <FallingLinesLoader /> : (
			<div className="px-4 sm:px-6 lg:px-8">
				
				<Breadcrumb pages={pages} />
				<div className="">
					<h1 className="text-xl font-semibold text-gray-900">Patient List</h1>
					<p className="mt-2 text-sm text-gray-700">A list of all the Patients.</p>
				</div>

				{isLoading ? (
					<FallingLinesLoader />
				) : (
					<Table
						columns={patient_list_columns({ onDeleteOpen, handleDrawer })}
						data={userPatientList}
					/>
				)}

			</div>
			// )
	);
}
export default PatientList;