import {
  SP_CHECKBOXES_INFO_BEGIN,
    SP_CHECKBOXES_INFO_SUCCESS,
    SP_CHECKBOXES_INFO_ERROR,
  } from "./constants";
  
  const initState = {
    spcheckboxdata: [],
    loading: false,
    error: null,
  };
  
  const spCheckBoxReducer = (state = initState, action) => {
    const { type, data, error } = action;
    switch (type) {
      case SP_CHECKBOXES_INFO_BEGIN:
        return {
          ...state,
          loading: true,
          spcheckboxdata: [],
        };
      case SP_CHECKBOXES_INFO_SUCCESS:
        return {
          ...state,
          loading: false,
          spcheckboxdata: data ? data: [],
        };
      case SP_CHECKBOXES_INFO_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default spCheckBoxReducer;
  