import React, { useEffect } from 'react';


const CustomPagination = ({ meta, onPageChange }) => {
  // const { currentPage, totalPages } = meta;
  console.log('meta=>', meta)
  // Function to create an array of page numbers for rendering
  const getPages = () => {
    const pages = [];
    for (let i = 1; i <= meta?.totalPages; i++) {
      pages.push(i);
    }
    console.log('pages=>', pages)
    return pages;
  };

  useEffect(() => {
    getPages();
  }, [meta?.totalPages]);

  // Handler for page change
  const handlePageChange = (page) => {
    if (page >= 1 && page <= meta?.totalPages && page !== meta?.currentPage) {
      onPageChange(page);
    }
  };

  return (
    <div className="my-12">
      <nav className="pagination">
        <ul class="flex justify-center h-10 text-base gap-5">
          <li
            className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-white bg-cyan-600 border border-e-0 border-cyan-600 rounded-md hover:bg-cyan-600 hover:text-white dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer"
            onClick={() => handlePageChange(meta?.currentPage - 1)}
            disabled={meta?.currentPage === 1}
          >
            <svg class="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4" />
            </svg>
          </li>
          {/* <button
          className=''
          onClick={() => handlePageChange(meta?.currentPage - 1)}
          disabled={meta?.currentPage === 1}
        >
          Prev
        </button> */}

          {getPages().map((page) => (
            <li
              key={page}
              className={page === meta?.currentPage ? 'active flex items-center justify-center px-4 h-10 leading-tight text-white bg-cyan-700 border border-cyan-700 rounded-md hover:bg-cyan-700 hover:text-white dark:bg-cyan-700 dark:border-cyan-700 dark:text-white dark:hover:bg-cyan-700 dark:hover:text-white' : 'flex items-center justify-center px-4 h-10'}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </li>

          ))}

          <li
            className="flex items-center justify-center px-4 h-10 leading-tight text-white bg-cyan-600 border border-cyan-600 rounded-md hover:bg-cyan-600 hover:text-white dark:bg-cyan-600 dark:border-cyan-600 dark:text-white dark:hover:bg-cyan-700 dark:hover:text-white cursor-pointer"
            onClick={() => handlePageChange(meta?.currentPage + 1)}
            disabled={meta?.currentPage === meta?.totalPages}
          >
            <svg class="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
            </svg>
          </li>
          {/* <button
          onClick={() => handlePageChange(meta?.currentPage + 1)}
          disabled={meta?.currentPage === meta?.totalPages}
        >
          Next
        </button> */}
        </ul>
      </nav>
    </div>
  );
};

export default CustomPagination;