import React, { useState, useEffect } from 'react'
import Breadcrumb from '../../../../components/Breadcrumb';
import { FallingLinesLoader } from '../../../../components/spinners/Spinner';
import Table from '../../../../components/tables/table';
import TableEmpty from '../../../../components/tables/tableEmpty';
import { monthly_revenue_report } from '../../../../components/tables/tableheader';
import ReportServices from '../../../../ApiServices/ReportServices';
import MasterServices from '../../../../ApiServices/MasterServices';
import { toast } from 'react-toast'
import queryString from 'query-string';
import moment from 'moment';
import { utils, writeFile } from 'xlsx';
import { capitalise } from '../../../../utilities/utilities';
import { convertToAnotherTimezone } from '../../../../helper/commonHelper';
import { FaSpinner } from "react-icons/fa";
import makeAnimated from 'react-select/animated';
import Select from "react-select";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

let filterInitialValues = {
    filterFrom: "",
    filterTo: "",
    filterMonth: "",
    filterLocationId: null,
};
const monthNames = Array.from(
    { length: 12 },
    (_, monthIndex) =>
        new Date(0, monthIndex).toLocaleString(undefined, { month: 'long' })
);
const MonthlyRevenueReport = () => {
    const pages = [{ title: "Monthly Revenue Report", href: "/monthly-revenue-report", module_id: 0 }];
    const [locationList, setLocationList] = useState([])
    const [monthNameList, setMonthNameList] = useState([])
    const animatedComponents = makeAnimated();
    const printRef = React.useRef();

    const [filterFrom, setFilterFrom] = useState('');
    const [filterTo, setFilterTo] = useState('');
    const [filterMonth, setFilterMonth] = useState({ value: '', label: '' });
    const [filterLocationId, setFilterLocationId] = useState({ value: '', label: '' });

    const [isLoading, setIsLoading] = useState(true);
    const [filterFlag, setFilterFlag] = useState(false);
    const [reservationMonthlyRevenueReportList, setReservationMonthlyRevenueReportList] = useState([]);


    useEffect(() => {
      (async function() {
          // Get the localized month names
          let MonthArr = [];
          monthNames.map((val, key) => {
              let obj = {
                  label: val,
                  value: key,
              }
              MonthArr.push(obj);
          });
          setMonthNameList(MonthArr);
          // getReservationMonthlyRevenueReportList(queryString.stringify(filterInitialValues));
          const currentMonthIndex = new Date().getMonth();
          const currentMonthName = monthNames[currentMonthIndex];
          const now = new Date();
          const year = now.getFullYear();
          const month = new Date(currentMonthName + ' ' + year).getMonth();
  
          const firstDayOfMonth = new Date(year, month, 1);
          const lastDayOfMonth = new Date(year, month + 1, 0);
  
          const formattedFromDate = formatDate(firstDayOfMonth);
          const formattedToDate = formatDate(lastDayOfMonth);
          handleMonthChange({label: currentMonthName});
          setFilterMonth({ value: currentMonthIndex, label: currentMonthName })
          const userLocData = JSON.parse(localStorage.getItem("userLocData"));
          setFilterLocationId(userLocData[0])
          await getAllActiveLocationList();
          onFilter(userLocData[0].value, currentMonthIndex, formattedFromDate, formattedToDate);

      })()
    }, []);
    
    const getAllActiveLocationList = async () => {
        let payload = {
            search: 1
        };
        MasterServices.getAllLocationList(queryString.stringify(payload))
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    if (data?.data?.length > 0) {
                        let locationnew = data.data;
                        if (locationnew.length > 0) {
                            setLocationList(locationnew);
                        }
                    }
                }
                setIsLoading(false);
            })
            .catch((error) => {
                const msg = "getAllLocationTestList catch Error";
                toast.error(msg);
                setIsLoading(false);
            });
    }
    const onFilter = (locationid, monthid, from , to) => {
        filterInitialValues = {
            filterFlag: true,
            filterFrom: from || (filterFrom ? filterFrom : ''),
            filterTo: to || (filterTo ? filterTo : ''),
            filterMonth: monthid || (filterMonth ? filterMonth.value : ''),
            filterMonthName: filterMonth ? filterMonth.label : '',
            filterLocationId: locationid || (filterLocationId ? filterLocationId.value : null),
        };
        getReservationMonthlyRevenueReportList(queryString.stringify(filterInitialValues));
    }
    const onFilterClear = () => {
        setFilterFlag(false);
        setFilterFrom('');
        setFilterTo('');
        setFilterMonth({ value: '', label: '' });
        setFilterLocationId({ value: '', label: '' });
        filterInitialValues = {
            filterFlag: false,
            filterFrom: "",
            filterTo: "",
            filterMonth: "",
            filterMonthName: "",
            filterLocationId: null,
        };
        getReservationMonthlyRevenueReportList(queryString.stringify(filterInitialValues));
    }
    const getReservationMonthlyRevenueReportList = (payload) => {
        setIsLoading(true);
        ReportServices.getAllReservationMonthlyRevenue(payload)
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    if (data?.data?.RangeLocData) {
                        toast.success("Monthly Revenue List Found!");
                        setReservationMonthlyRevenueReportList(data?.data?.RangeLocData);
                    }
                }
                setIsLoading(false);
            })
            .catch((error) => {
                const msg = "Fatal Error Ousource Reservation Report List";
                toast.error(msg);
                setIsLoading(false);
            });
    }

    const handleExport = (excel_type) => {
        if (reservationMonthlyRevenueReportList?.length > 0) {
            let MonthlyRevenueReport = reservationMonthlyRevenueReportList;
            let newSheetdata = [];
            MonthlyRevenueReport.map((obj, index) => {

                let SN = parseInt(index) + parseInt(1);
                let Branch = (obj.loc_name_en);
                let Date = moment(convertToAnotherTimezone(obj.StartDateF)).format('DD-MM-YYYY') + " / " + moment(convertToAnotherTimezone(obj.EndDateF)).format('DD-MM-YYYY');
                let MonthName = moment(obj.StartDateF).toLocaleString(undefined, { month: 'long' });
                let Total_Cash = (obj.locCashAmtRoun);
                let Total_Credit = (obj.locCreditAmtRoun);
                let Total_Revenue = (obj.locCashCreditAmtRoun);
                const newData = {
                    SN: SN,
                    Branch: Branch,
                    Date: Date,
                    MonthName: MonthName,
                    Total_Cash: Total_Cash,
                    Total_Credit: Total_Credit,
                    Total_Revenue: Total_Revenue,
                }
                newSheetdata.push(newData);
            })
            let fileName = 'Locationwise_Monthly_Revenue_Report_' + '.' + excel_type;
            const headings = [['SN', 'Branch', 'Date', 'MonthName', 'Total_Cash', 'Total_Credit', 'Total_Revenue']];
            const wb = utils.book_new();
            const ws = utils.json_to_sheet([]);
            utils.sheet_add_aoa(ws, headings);
            utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
            utils.book_append_sheet(wb, ws, 'Report');
            writeFile(wb, fileName);
        } else {
            toast.error("Data Not Found!");
        }
    }

    const handleMonthChange = (event) => {
        const { value, label } = event;
        // setMonthName(value);

        const now = new Date();
        const year = now.getFullYear();
        const month = new Date(label + ' ' + year).getMonth();

        const firstDayOfMonth = new Date(year, month, 1);
        const lastDayOfMonth = new Date(year, month + 1, 0);

        const formattedFromDate = formatDate(firstDayOfMonth);
        const formattedToDate = formatDate(lastDayOfMonth);

        setFilterFrom(formattedFromDate);
        setFilterTo(formattedToDate);
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handlePdfGenerate = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);

        let fileName = 'Monthly_Revenue_Report.pdf';

        pdf.save(fileName);
    }

    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <Breadcrumb pages={pages} />
        <div className="flex">
          <h1 className="text-xl font-semibold text-gray-900">
            Monthly Revenue Report
          </h1>
        </div>
        <div className="px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
          <div className="space-y-6 sm:space-y-5">
            <div className="grid grid-cols-3 gap-3 lg:grid-cols-3 lg:gap-3 md:grid-cols-3 md:gap-3 sm:grid-cols-12 sm:gap-0 mt-5">
              {monthNameList && monthNameList.length > 0 ? (
                <div className="basis-3/4">
                  <label
                    htmlFor="filterTo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Month
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      name="monthName"
                      id="monthName"
                      placeholder="Select Months"
                      value={filterMonth}
                      onChange={(e) => {
                        setFilterMonth(e);
                        console.log("e=>", e);
                        handleMonthChange(e);
                      }}
                      components={animatedComponents}
                      options={monthNameList}
                      classNamePrefix="select"
                      className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    />
                  </div>
                </div>
              ) : null}

              <div className="basis-3/4">
                <label
                  htmlFor="filterFrom"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  From
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="date"
                    name="filterFrom"
                    id="filterFrom"
                    value={filterFrom}
                    onChange={(e) => {
                      setFilterFrom(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="basis-3/4">
                <label
                  htmlFor="filterTo"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  To
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="date"
                    name="filterTo"
                    id="filterTo"
                    value={filterTo}
                    onChange={(e) => {
                      setFilterTo(e.target.value);
                    }}
                  />
                </div>
              </div>

              {locationList &&
              locationList.length > 0 &&
              filterMonth.value != "" ? (
                <div className="basis-3/4">
                  <label
                    htmlFor="filterTo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Branch
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      name="loc_name_en"
                      id="loc_id"
                      placeholder="Select Locations"
                      value={filterLocationId}
                      onChange={(e) => {
                        setFilterLocationId(e);
                      }}
                      components={animatedComponents}
                      options={locationList}
                      classNamePrefix="select"
                      className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    />
                  </div>
                </div>
              ) : null}

              <div className="basis-3/4 mt-7">

              <button
                  type="button"
                  className={`mr-2 rounded-md border border-transparent bg-cyan-600 text-white py-2 px-4 text-sm font-medium  shadow-sm hover:bg-cyan-700  focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                  onClick={() => {
                    onFilter();
                  }}
                  size="default"
                  variant="outlined"
                >
                  Search
                </button>
                <button
                  type="button"
                  className={`rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-1`}
                  onClick={() => {
                    onFilterClear();
                  }}
                  size="default"
                  variant="outlined"
                >
                  Clear
                </button>
                
              </div>
              <div className="basis-3/4 mt-7 justify-self-end">

                {reservationMonthlyRevenueReportList?.length > 0 ? (
                  <>
                    <button
                      onClick={() => handleExport("xlsx")}
                      className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 justify-items-end"
                    >
                      Excel
                    </button>

                    <button
                      onClick={() => handlePdfGenerate()}
                      className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                    >
                      PDF
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        {isLoading ? (
          <FallingLinesLoader />
        ) : reservationMonthlyRevenueReportList.length > 0 ? (
          <Table
            printRef={printRef}
            columns={monthly_revenue_report()}
            data={reservationMonthlyRevenueReportList}
          />
        ) : (
          <TableEmpty />
        )}
      </div>
    );
}

export default MonthlyRevenueReport