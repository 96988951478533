import React from "react";

export default function SubReferenceRangePhasesComponent({
  symbols,
  allPhasesArr,
  setAllPhasesArr,
  onePhaseObj,
  newObjectTest,
}) {
  const handleJSONValueChange = (pIndex, pRRItemIndex, fIndex, new_value) => {
    let updatedArr = [...allPhasesArr];
    updatedArr[pIndex].value[pRRItemIndex][fIndex].value = new_value;
    updatedArr[pIndex].value[pRRItemIndex][fIndex].error = new_value
      ? false
      : true;
    setAllPhasesArr(updatedArr);

    console.log("from handleJSONValueChange setAllPhasesArr", updatedArr);
  };

  return (
    <div className="">
      {/* <div>Abhi SubReferenceRangePhasesComponent</div> */}
      <div>
        {Array.isArray(allPhasesArr) && allPhasesArr.length > 0 && allPhasesArr.map((phaseName, phaseIndex) => {
          return (
            <div key={phaseIndex} className="border-t-2 pt-2">
              {/* <h3>{phaseName.name} (Phase Name)</h3> */}
              <input
                type="text"
                value={phaseName.name}
                onChange={(e) => {
                  const newPhaseName = e.target.value;
                  let updatedPhasesArr = [...allPhasesArr];
                  console.log(updatedPhasesArr[0], phaseIndex);
                  updatedPhasesArr[phaseIndex] = {
                    ...updatedPhasesArr[phaseIndex],
                    name: newPhaseName,
                  };
                  setAllPhasesArr(updatedPhasesArr);
                }}
                placeholder="Enter Phase Name"
                className="text-gray-700 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
              />

              {phaseName && phaseName.value.map(
                (phaseRefRangeItem, phaseRefRangeItemIndex) => {
                  return (
                    <div key={phaseRefRangeItemIndex}>
                      <label className="block text-md font-large text-gray-900 sm:mt-px sm:pt-2 px-4">
                        {phaseRefRangeItem[0].value}
                      </label>

                      <div className="grid grid-cols-5 gap-5 lg:grid-cols-5 lg:gap-5 md:grid-cols-5 md:gap-5 sm:grid-cols-12 sm:gap-0 px-4 py-2">
                        {phaseRefRangeItem.map((field, fieldIndex) => {
                          // console.log(
                          //   "value",
                          //   allPhasesArr[phaseIndex].value[
                          //     phaseRefRangeItemIndex
                          //   ][fieldIndex].value
                          // );
                          return (
                            <div key={fieldIndex}>
                              {/* <label htmlFor="">{field.input_type}</label> */}

                              {field.input_type === "select" ? (
                                <div className="">
                                  {/* <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">{lable}</label> */}
                                  <div className="mt-1 sm:col-span-2 sm:mt-1">
                                    <select
                                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                      name={
                                        "dd_" + field?.lable + "_" + fieldIndex
                                      }
                                      id={
                                        "dd_" + field?.lable + "_" + fieldIndex
                                      }
                                      onChange={(e) => {
                                        handleJSONValueChange(
                                          phaseIndex,
                                          phaseRefRangeItemIndex,
                                          fieldIndex,
                                          e.target.value
                                        );
                                      }}
                                      key={fieldIndex}
                                      value={field.value}
                                    >
                                      <option value="">Select Symbol</option>
                                      {symbols.map((d, i) => (
                                        <option key={i} value={d.symbol}>
                                          {d.symbol}
                                        </option>
                                      ))}
                                    </select>
                                  </div>

                                  {allPhasesArr[phaseIndex].value[
                                    phaseRefRangeItemIndex
                                  ][fieldIndex].value == null ||
                                  allPhasesArr[phaseIndex].value[
                                    phaseRefRangeItemIndex
                                  ][fieldIndex].value == undefined ||
                                  allPhasesArr[phaseIndex].value[
                                    phaseRefRangeItemIndex
                                  ][fieldIndex].value == "" ||
                                  allPhasesArr[phaseIndex].value[
                                    phaseRefRangeItemIndex
                                  ][fieldIndex].error == true ||
                                  allPhasesArr[phaseIndex].value[
                                    phaseRefRangeItemIndex
                                  ][fieldIndex].error == undefined ? (
                                    <p
                                      className="text-red-600 text-sm"
                                      key={fieldIndex}
                                    >
                                      Required *
                                    </p>
                                  ) : null}
                                </div>
                              ) : field.input_type === "number" ||
                                field.input_type === "text" ? (
                                <div className="">
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                      {field?.lable}
                                    </span>
                                    <input
                                      step={
                                        field?.input_type == "number"
                                          ? 0.01
                                          : ""
                                      }
                                      onChange={(e) => {
                                        handleJSONValueChange(
                                          phaseIndex,
                                          phaseRefRangeItemIndex,
                                          fieldIndex,
                                          e.target.value
                                        );
                                      }}
                                      type={field?.input_type}
                                      name={
                                        field?.input_type == "number"
                                          ? "number_"
                                          : "text_" +
                                            field?.lable +
                                            "_" +
                                            fieldIndex
                                      }
                                      id={
                                        field?.input_type == "number"
                                          ? "number_"
                                          : "text_" +
                                            field?.lable +
                                            "_" +
                                            fieldIndex
                                      }
                                      className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                      placeholder={"Enter " + field?.lable}
                                      key={fieldIndex}
                                      value={field.value}
                                    />
                                  </div>

                                  {allPhasesArr[phaseIndex].value[
                                    phaseRefRangeItemIndex
                                  ][fieldIndex].value == null ||
                                  allPhasesArr[phaseIndex].value[
                                    phaseRefRangeItemIndex
                                  ][fieldIndex].value == undefined ||
                                  allPhasesArr[phaseIndex].value[
                                    phaseRefRangeItemIndex
                                  ][fieldIndex].value == "" ||
                                  allPhasesArr[phaseIndex].value[
                                    phaseRefRangeItemIndex
                                  ][fieldIndex].error == true ||
                                  allPhasesArr[phaseIndex].value[
                                    phaseRefRangeItemIndex
                                  ][fieldIndex].error == undefined ? (
                                    <p
                                      className="text-red-600 text-sm"
                                      key={fieldIndex}
                                    >
                                      Required *
                                    </p>
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          );
        })}
      </div>

      <div className="justify-center flex mt-2">
        <button
          type="button"
          className="ml-3  rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 w-80"
          onClick={() => {
            setAllPhasesArr((prev) => {
              return [
                ...prev,
                {
                  id: prev.length + 1,
                  name: `Phase ${prev.length + 1}`,
                  value: [
                    [
                      {
                        error: false,
                        lable: "AR",
                        value: "Low",
                        input_type: "text",
                        placeholder: "",
                      },
                      {
                        error: false,
                        lable: "EN",
                        value: "Low",
                        input_type: "text",
                        placeholder: "",
                      },
                      {
                        error: false,
                        lable: "MIN",
                        value: "",
                        input_type: "number",
                        placeholder: "",
                      },
                      {
                        error: false,
                        lable: "DropDown",
                        value: "",
                        input_type: "select",
                        placeholder: "",
                      },
                      {
                        error: false,
                        lable: "MAX",
                        value: "",
                        input_type: "number",
                        placeholder: "",
                      },
                    ],
                    [
                      {
                        error: false,
                        lable: "AR",
                        value: "Normal",
                        input_type: "text",
                        placeholder: "",
                      },
                      {
                        error: false,
                        lable: "EN",
                        value: "Normal",
                        input_type: "text",
                        placeholder: "",
                      },
                      {
                        error: false,
                        lable: "MIN",
                        value: "",
                        input_type: "number",
                        placeholder: "",
                      },
                      {
                        error: false,
                        lable: "DropDown",
                        value: "",
                        input_type: "select",
                        placeholder: "",
                      },
                      {
                        error: false,
                        lable: "MAX",
                        value: "",
                        input_type: "number",
                        placeholder: "",
                      },
                    ],
                    [
                      {
                        error: false,
                        lable: "AR",
                        value: "High",
                        input_type: "text",
                        placeholder: "",
                      },
                      {
                        error: false,
                        lable: "EN",
                        value: "High",
                        input_type: "text",
                        placeholder: "",
                      },
                      {
                        error: false,
                        lable: "MIN",
                        value: "",
                        input_type: "number",
                        placeholder: "",
                      },
                      {
                        error: false,
                        lable: "DropDown",
                        value: "",
                        input_type: "select",
                        placeholder: "",
                      },
                      {
                        error: false,
                        lable: "MAX",
                        value: "",
                        input_type: "number",
                        placeholder: "",
                      },
                    ],
                  ],
                }
              ];
            });
          }}
        >
          Add Phase
        </button>
        <button
          type="button"
          className="ml-3  rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 w-80"
          onClick={() => {
            setAllPhasesArr((prev) => {
              prev.pop();
              let arr = [...prev];
              return arr
            });
            console.log(allPhasesArr);
          }}
        >
          Remove Phase
        </button>
      </div>
    </div>
  );
}
