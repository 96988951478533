import React, { useEffect, useState } from "react";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Table from "../../../../components/tables/table";
import { ot_test_report_columns } from "../../../../components/tables/tableheader";
import Breadcrumb from "../../../../components/Breadcrumb";
import ReservationServices from "../../../../ApiServices/ReservationServices";
import moment from "moment";
import { utils, writeFile } from "xlsx";
import { toast } from "react-toast";
import { capitalise } from "../../../../utilities/utilities";
import { convertToAnotherTimezone } from "../../../../helper/commonHelper";

const initialFilters = {
  start_date: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
  end_date: moment(new Date()).format("YYYY-MM-DD"),
  users: []
}
const InsuOTTestReport = () => {
  const pages = [
    { title: "Report List", href: "/reservationlist", module_id: 8 },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [resOTTestReportFilters, setresOTTestReportFilters] = useState(initialFilters)
  const [resOTTestReportData, setresOTTestReportData] = useState([])

  const printRef = React.useRef();

  useEffect(() => {
    document.title = "Labib | OT Test Report List";
    getOTTestReport()
  }, []);

  async function getOTTestReport() {
    setIsLoading(true)
    try {
      let payload = {
        start_date: resOTTestReportFilters.start_date || '1949-05-26',
        end_date: resOTTestReportFilters.end_date || (moment().add(1, 'days').format('YYYY-MM-DD')),
        mc_ids: []
        // mc_ids: [30]
      }
      const { data, status } = await ReservationServices.getOutSourceTestReport(payload);
      if (status === 200) {
        setresOTTestReportData(data?.data)
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const handleExport = (excel_type) => {
    if (resOTTestReportData?.length > 0) {
      let newSheetdata = [];
      resOTTestReportData.map((obj, index) => {
        
        const newData = {
          SN: parseInt(index)+1,
          test_name_en: obj.test_name_en,
          test_code: obj.test_code,
          bupa_test_name: obj.bupa_test_name,
          bupa_service_code: obj.bupa_service_code,
          tp_selling_price: obj.tp_selling_price,
          test_count: obj?.t_res_patient_test_pack_map?.length,
          insu_tp_selling_price: obj?.insu_tp_selling_price,
          insu_deductible_perc_amount: obj?.insu_deductible_perc_amount,
          bupa_libility: (parseFloat(obj?.insu_tp_selling_price) - parseFloat(obj?.insu_deductible_perc_amount)),
          rev_vat_amount: obj?.rev_vat_amount,
          final_amt: ((parseFloat(obj?.insu_tp_selling_price) - parseFloat(obj?.insu_deductible_perc_amount)) + parseFloat(obj?.insu_vat_amt)),
        }
        newSheetdata.push(newData);
      })
      let fileName = 'OT_Test_Report_From_' + moment(resOTTestReportFilters.start_date).format("DD-MM-YYYY") + "_To_" + moment(resOTTestReportFilters.end_date).format("DD-MM-YYYY") + '.' + excel_type;
   
      const headings = [
        [
          'SN',
          'Test Name',
          'Test Code',
          'BUPA Test Code',
          'Bupa Service Code',
          'Per Unit Price',
          'Quantity',
          'Total',
          'Deductible Collected',
          'BUPA Liability',
          'VAT',
          'Total Liability',
        ]];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  }

  return (
    <>
      <div className="px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
        <div className="sales-booking-head">
          <Breadcrumb pages={pages} />
          <h1 className="text-xl font-semibold text-gray-900">
            OT Test Report
          </h1>
        </div>
      </div>

      <div className="px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
        <div className="mt-5 flex gap-3 items-center">
          {/* start date */}
          <div className=" sm:mt-0">
            <label
              htmlFor="fk_location_ids"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Date From
            </label>

            <input
              disabled={isLoading}
              className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
              type="date"
              name="start_date"
              id="start_date"
              value={resOTTestReportFilters.start_date}
              onChange={(e) => {
                setresOTTestReportFilters((prev) => {
                  if (prev.end_date < e.target.value) {
                    return {
                      ...prev,
                      end_date: e.target.value,
                      start_date: prev.end_date,
                    };
                  }
                  return {
                    ...prev,
                    start_date: e.target.value,
                  };
                });
              }}
            />
          </div>

          {/* end date */}
          <div className=" sm:mt-0">
            <label
              htmlFor="fk_location_ids"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Date To
            </label>

            <input
              disabled={isLoading}
              className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
              type="date"
              name="end_date"
              id="end_date"
              value={resOTTestReportFilters.end_date}
              onChange={(e) => {
                setresOTTestReportFilters((prev) => {
                  if (prev.start_date > e.target.value) {
                    return {
                      ...prev,
                      end_date: prev.start_date,
                      start_date: e.target.value,
                    };
                  }
                  return {
                    ...prev,
                    end_date: e.target.value,
                  };
                });
              }}
            />
          </div>


          <button
            disabled={isLoading}
            onClick={() => {
              getOTTestReport();
            }}
            className={`ml-3 h-10 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 mt-7 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${isLoading ? "opacity-40" : "opacity-100"
              }`}
          >
            Get Data
          </button>
        </div>

        <div className="mt-5 flex gap-1 items-center">
          <button
            onClick={() => handleExport("csv")}
            className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
          >
            CSV
          </button>

          <button
            onClick={() => handleExport("xlsx")}
            className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
          >
            Excel
          </button>

        </div>
      </div>

      <div className="px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none">
        <div className="flex flex-col">
          <div>

            {isLoading ? (
              <FallingLinesLoader />
            ) : (
              <Table
                zoom='zoom-09'
                printRef={printRef}
                columns={ot_test_report_columns()}
                data={resOTTestReportData}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InsuOTTestReport;
