import React, { useEffect, useState } from "react";
import { Await, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { getAllHospitals } from "../../../redux/hospital/actions";
import Table from "../../../components/tables/tableSampleProcess";
import { reservation_columns } from "../../../components/tables/tableheader";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import ReservationServices from "../../../ApiServices/ReservationServices";
import { toast } from "react-toast";
import queryString from "query-string";

import { reservation_status_colors } from "./reservationStatusColorCodes";

function className(...location) {
  return location.filter(Boolean).join(" ");
}

let filterInitialValues = {
  filterFrom: "",
  filterTo: "",
  filterStatus: "",
  filterHos: "",
  filterBkType: "",
  filterOrg: "",
  filterSampleId: "",
  filterPatientMob: "",
  filterPatientIdentification: "",
  filterPatientName: "",
  filterPatientPassport: "",
  filterTestType: "",
  filterPatientId: "",
  filterReservationId: "",
  filterMrn: "",
  pdfFlag: false,
};

const bookingType = [
  { name: "Normal", id: "1" },
  { name: "OPD", id: "2" },
  { name: "Emergancy", id: "3" },
  { name: "ICU", id: "4" },
];

const AddReservation = () => {
  const pageSize = 30;
  const pages = [
    { title: "Reservation List", href: "/reservationlist", module_id: 10 },
  ];

  const [filterFrom, setFilterFrom] = useState("");
  const [filterTo, setFilterTo] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterHos, setFilterHos] = useState("");
  const [filterBkType, setFilterBkType] = useState("");
  const [filterOrg, setFilterOrg] = useState("");
  const [filterSampleId, setFilterSampleId] = useState("");
  const [filterPatientMob, setFilterPatientMob] = useState("");
  const [filterPatientIdentification, setFilterPatientIdentification] =
    useState("");
  const [filterPatientName, setFilterPatientName] = useState("");
  const [filterPatientPassport, setFilterPatientPassport] = useState("");
  const [filterTestType, setFilterTestType] = useState("");
  const [filterPatientId, setFilterPatientId] = useState("");
  const [filterReservationId, setFilterReservationId] = useState("");
  const [filterMrn, setFilterMrn] = useState("");
  const [filterFlag, setFilterFlag] = useState(false);
  const [reservationList, setReservationList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [pageArr, setPageArr] = useState("");
  const [currentPage, setCurrentPage] = useState("");
  const [maxVisiblePages, setMaxVisiblePages] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    hospital: { list: hospitalList },
  } = useSelector((state) => state);
  useEffect(() => {
    document.title = "Labib | Reservation List";
    dispatch(getAllHospitals());
  }, []);
  useEffect(() => {
    console.log("Roles data=>", "Test");
  });

  const getSingleReservationDetails = async (payload) => {
    console.log("payload=>", payload);
    return await ReservationServices.getSingleReservation(
      payload?.reservation_id
    )
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          return data.data;
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const msg = "getAllPatientList catch Error"; //error.response.data.message;
        // toast.error(msg);
      });
  };

  const generateInvoiceReport = async (payload) => {
    filterInitialValues.pdfFlag = true;
    let singleReservationDetails = await getSingleReservationDetails(payload);
    payload.singleReservationDetails = singleReservationDetails;
    payload.collection_loc_relation = null;
    payload.m_ot_as_ref_clinic_relation = null;
    // console.log("singleReservationDetails=>", singleReservationDetails); return false;
    let res = await ReservationServices.generateInvoiceReport(payload);
    if (res.status == 200) {
      toast.success(res.data.message);
      console.log("singleReservationDetails res.data=>", res.data.data);
      window.open(res.data.data.file_path, "_blank");
      getReservationList(1);
    } else {
      toast.error(res.data.message);
    }
  };

  const initialValues = {
    hos_name_ar: "",
    hos_name_en: "",
    hos_logo_url: "",

    hos_lat: "",
    hos_long: "",
    hos_vat_no: "",
    hos_hesn_no: "",

    hos_is_cash: false,
    hos_is_prepaid: false,
    hos_is_location: false,
    hos_is_sms_inv: false,
    hos_is_manual_hesn: false,
    hos_is_branch: false,
    hos_is_primary: false,
  };

  const [formState, setFormState] = useState(initialValues);

  useEffect(() => {
    getReservationList(1);
  }, []);

  const onFilter = () => {
    filterInitialValues = {
      filterFlag: true,
      filterFrom: filterFrom ? filterFrom : "",
      filterTo: filterTo ? filterTo : "",
      filterStatus: filterStatus ? filterStatus : "",
      filterHos: filterHos ? filterHos : "",
      filterBkType: filterBkType ? filterBkType : "",
      filterOrg: filterOrg ? filterOrg : "",
      filterSampleId: filterSampleId ? filterSampleId : "",
      filterPatientMob: filterPatientMob ? filterPatientMob : "",
      filterPatientIdentification: filterPatientIdentification
        ? filterPatientIdentification
        : "",
      filterPatientName: filterPatientName ? filterPatientName : "",
      filterPatientPassport: filterPatientPassport ? filterPatientPassport : "",
      filterTestType: filterTestType ? filterTestType : "",
      filterPatientId: filterPatientId ? filterPatientId : "",
      filterReservationId: filterReservationId ? filterReservationId : "",
      filterMrn: filterMrn ? filterMrn : "",
    };

    console.log("filterInitialValues=>", filterInitialValues);
    getAllReservationFilterList(queryString.stringify(filterInitialValues));
  };
  const getAllReservationFilterList = (payload) => {
    setIsLoading(true);
    console.log("params payload=>", payload);
    ReservationServices.getAllReservationFilterList(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Reservation List Found!");
          setReservationList(data.data.getAllReservation);
          // setReservationList(data.data.reservationDetails);
        } else {
          toast.success(data.message);
          toast.error("Fatal Error Please Contact Admin");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setIsLoading(false);
      });
  };
  const onFilterClear = () => {
    setFilterFrom("");
    setFilterTo("");
    setFilterStatus("");
    setFilterHos("");
    setFilterBkType("");
    setFilterOrg("");
    setFilterSampleId("");
    setFilterPatientMob("");
    setFilterPatientIdentification("");
    setFilterPatientName("");
    setFilterPatientPassport("");
    setFilterTestType("");
    setFilterPatientId("");
    setFilterReservationId("");
    setFilterMrn("");
    getReservationList(1);
  };
  const editReservation = (rs_val) => {
    console.log("rs_val=>", rs_val);
    // alert(rs_val.fk_reservation_id);
    localStorage.setItem("edit_booking_reservation_id", rs_val.reservation_id);
    localStorage.setItem(
      "edit_booking_reservation_user_details",
      JSON.stringify(rs_val)
    );
    navigate("/bookingConfirm");
  };
  const getReservationList = (index) => {
    if (filterInitialValues.pdfFlag == false) {
      setIsLoading(true);
    }
    let payload = {
      page: index,
      pageSize: pageSize,
    };
    console.log("params payload=>", payload);

    ReservationServices.getAllReservationList(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.getAllReservation?.length > 0) {
            toast.success("Reservation List Found!");
            setReservationList(data.data?.getAllReservation);
            setMaxVisiblePages(data.data?.maxVisiblePages);
            setPageArr(data.data?.paginationArray);
            setCurrentPage(data.data?.currentPage);
          }
          // setReservationList(data.data.reservationDetails);
        }
        filterInitialValues.pdfFlag = false;
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setIsLoading(false);
      });
  };
  // Function to handle page change in the table component
  const handlePageChange = (index) => {
    // Call the fetchUserData function with the updated page
    let payload = {
      page: index,
      pageSize: pageSize,
    };
    ReservationServices.getAllReservationList(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.getAllReservation?.length > 0) {
            setReservationList(data.data?.getAllReservation);
            setMaxVisiblePages(data.data?.maxVisiblePages);
            setPageArr(data.data?.paginationArray);
            setCurrentPage(data.data?.currentPage);
            navigate(-1);
          } else {
            toast.error("ReservationList Not Found");
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = "getAllReservationList catch Error"; //error.response.data.message;
        toast.error(msg);
        setIsLoading(false);
      });
  };

  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    reservation_id: null,
    reservation_is_active: false,
  });

  const onDeleteOpen = (reservation_id, reservation_is_active) => {
    setConfirmationModal({
      reservation_id,
      reservation_is_active,
      status: true,
    });
  };

  const onDeleteReservation = (reservation_id, reservation_is_active) => {
    // alert(reservation_id)
    let Newreservation_is_active = false;
    Newreservation_is_active = reservation_is_active == true ? false : true;
    let text = "Activated";
    if (Newreservation_is_active == true) {
      text = "Activated";
    } else {
      text = "In-Activated";
    }
    ReservationServices.setReservationStatus({
      reservation_id,
      reservation_is_active: Newreservation_is_active,
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Reservation " + text + " Successfully!");
          setConfirmationModal({ reservation_id: null, status: false });
          getReservationList(1);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = error.response.data.message;
        toast.error(msg);
      });
    setConfirmationModal({ reservation_id: null, status: false });
  };

  return (
    <>
      <div className="px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
        <div className="sales-booking-head">
          <Breadcrumb pages={pages} />
          {/* <h1 className="text-xl font-semibold text-gray-900">Reservations List</h1> */}
          <div className="flex items-center space-x-2">
            <div className="relative">
              <button
                onClick={() => {
                  setFilterFlag(filterFlag ? false : true);
                }}
                className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1"
              >
                <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-md sm:py-2">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-3 h-3"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                      />
                    </svg>
                  </div>
                  <div className="hidden sm:block">
                    Filters {filterFlag ? "On" : "Off"}
                  </div>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title={
          "Are you sure you want to " +
          (confirmationModal.reservation_is_active ? "Disable " : "Activate") +
          " this Reservation?"
        }
        confirmationButtonText="Yes"
        // description={"Do you really want to " + (confirmationModal.reservation_is_active ? "In-Activate" : "Activate") + " Reservation"}
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() =>
          onDeleteReservation(
            confirmationModal.reservation_id,
            confirmationModal.reservation_is_active
          )
        }
      />
      {filterFlag ? (
        <div className="px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
          <div className="space-y-6 sm:space-y-5">
            <div className="grid grid-cols-3 gap-3 lg:grid-cols-3 lg:gap-3 md:grid-cols-3 md:gap-3 sm:grid-cols-12 sm:gap-0 mt-5">
              <div className="basis-3/4"></div>
              <div className="basis-3/4"></div>
              <button
                type="button"
                className="text-red-500 text-right hover:text-red-700 font-bold py-1 px-1 rounded"
                onClick={() => setFilterFlag(filterFlag ? false : true)}
              >
                X
              </button>
              <div className="basis-3/4">
                <label
                  htmlFor="filterFrom"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Reservation From
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="date"
                    name="filterFrom"
                    id="filterFrom"
                    value={filterFrom}
                    onChange={(e) => {
                      setFilterFrom(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="basis-3/4">
                <label
                  htmlFor="filterTo"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Reservation To
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="date"
                    name="filterTo"
                    id="filterTo"
                    value={filterTo}
                    onChange={(e) => {
                      setFilterTo(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="basis-3/4">
                <label
                  htmlFor=""
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Hospital
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    value={filterHos}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    name="fk_hos_id"
                    id="fk_hos_id"
                    onInput={(e) => {
                      setFilterHos(e.target.value);
                    }}
                  >
                    <option>Select Hospital</option>
                    {hospitalList.map((hospital, i) => (
                      <option
                        selected={
                          hospital.hos_id === filterInitialValues.filterHos
                            ? "selected"
                            : ""
                        }
                        key={i}
                        value={hospital.hos_id}
                      >
                        {hospital.hos_name_en}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="basis-3/4">
                <label
                  htmlFor=""
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Organization
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    value={filterOrg}
                    onInput={(e) => {
                      setFilterOrg(e.target.value);
                    }}
                    name="filterOrg"
                    id="filterOrg"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                  >
                    <option>Select Organization</option>
                    <option>Organization 1</option>
                    <option>Organization 2</option>
                  </select>
                </div>
              </div>
              <div className="basis-3/4">
                <label
                  htmlFor=""
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Reservation Type
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    value={filterBkType}
                    onInput={(e) => {
                      setFilterBkType(e.target.value);
                    }}
                    name="filterBkType"
                    id="filterBkType"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                  >
                    <option>Select Reservation Type</option>
                    {bookingType.map((bkType, i) => (
                      <option
                        selected={
                          bkType.name === filterInitialValues.filterBkType
                            ? "selected"
                            : ""
                        }
                        key={i}
                        value={bkType.name}
                      >
                        {bkType.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="basis-3/4">
                <label
                  htmlFor="filterStatus"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Status
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    value={filterStatus}
                    onInput={(e) => {
                      setFilterStatus(e.target.value);
                    }}
                    name="filterStatus"
                    id="filterStatus"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                  >
                    <option>Select Status</option>
                    <option value="1">Active</option>
                    <option value="2">In-Active</option>
                  </select>
                </div>
              </div>

              <div className="basis-3/4">
                <label
                  htmlFor="filterPatientName"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Name
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    value={filterPatientName}
                    onInput={(e) => {
                      setFilterPatientName(e.target.value);
                    }}
                    placeholder="Search By Name"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="filterPatientName"
                    id="filterPatientName"
                  />
                </div>
              </div>

              <div className="basis-3/4">
                <label
                  htmlFor="filterPatientPassport"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Passport
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Search By Passport"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="filterPatientPassport"
                    id="filterPatientPassport"
                    value={filterPatientPassport}
                    onInput={(e) => {
                      setFilterPatientPassport(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="basis-3/4">
                <label
                  htmlFor="filterPatientId"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Patient ID
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Search By Patient ID"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="filterPatientId"
                    id="filterPatientId"
                    value={filterPatientId}
                    onInput={(e) => {
                      setFilterPatientId(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="basis-3/4">
                <label
                  htmlFor="filterPatientMob"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Mobile No.
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Search By Mobile No."
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="filterPatientMob"
                    id="filterPatientMob"
                    value={filterPatientMob}
                    onInput={(e) => {
                      setFilterPatientMob(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="basis-3/4">
                <label
                  htmlFor="filterPatientIdentification"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Identification
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Search By Identification"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="filterPatientIdentification"
                    id="filterPatientIdentification"
                    value={filterPatientIdentification}
                    onInput={(e) => {
                      setFilterPatientIdentification(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="basis-3/4">
                <label
                  htmlFor="filterReservationId"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Reservation No.
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Search By Reservation No."
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="filterReservationId"
                    id="filterReservationId"
                    value={filterReservationId}
                    onInput={(e) => {
                      setFilterReservationId(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="basis-3/4">
                <label
                  htmlFor="filterSampleId"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Sample ID.
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Search By Sample ID."
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="filterSampleId"
                    id="filterSampleId"
                    value={filterSampleId}
                    onInput={(e) => {
                      setFilterSampleId(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="basis-3/4">
                <label
                  htmlFor="filterMrn"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  MRN.
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Search By MRN."
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="filterMrn"
                    id="filterMrn"
                    value={filterMrn}
                    onInput={(e) => {
                      setFilterMrn(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="basis-3/4">
                <button
                  type="button"
                  className={`rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                  onClick={() => {
                    onFilter();
                  }}
                  size="default"
                  variant="outlined"
                >
                  Search
                </button>
                <button
                  type="button"
                  className={`rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-1`}
                  onClick={() => {
                    onFilterClear();
                  }}
                  size="default"
                  variant="outlined"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none">
        <div className="flex flex-col">
          <div>
            <div className="flex justify-between py-3 pl-2">
              {/* <div className="relative max-w-xs">
                <label htmlFor="hs-table-search" className="sr-only">
                  Search
                </label>
                <input
                  type="text"
                  name="hs-table-search"
                  id="hs-table-search"
                  className="block w-full p-3 pl-10 text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500 bg-gray-200 border-gray-200 text-gray-200"
                  placeholder="Search..."
                />
                <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                  <svg
                    className="h-3.5 w-3.5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </div>
              </div> */}
            </div>

            {isLoading ? (
              <FallingLinesLoader />
            ) : (
              <Table
                zoom="zoom-100"
                maxVisiblePages={maxVisiblePages}
                onPageChange={handlePageChange}
                currentPage={currentPage}
                pageArr={pageArr}
                columns={reservation_columns({
                  onDeleteOpen,
                  editReservation,
                  generateInvoiceReport,
                  reservation_status_colors,
                })}
                data={reservationList}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddReservation;
