import Breadcrumb from '../../../components/Breadcrumb';
import $ from "jquery"; //Load jquery
import React, { Component, createRef, useEffect, useState } from "react"; //For react component
import { addQuestionnaire, getAllQuestionnaires, getSingleQuestionnaire } from '../../../redux/questionnaire/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_QUESTIONNAIRE_SUCCESS } from '../../../redux/questionnaire/constants';
import { toast } from 'react-toast'
import ReservationServices from '../../../ApiServices/ReservationServices';
window.jQuery = $; //JQuery alias
window.$ = $; //JQuery alias

require("jquery-ui-sortable"); //For FormBuilder Element Drag and Drop
require("formBuilder");// For FormBuilder

const ResQuestionnairePrev = () => {
  const { reservation_id: reservation_id } = useParams();
  const pages = [{ title: "Questionnaire", href: "/res-questionnaire/" + reservation_id, module_id: 25 }];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fb = createRef();
  let [formBuilder, setFormBuilder] = useState(null);
  let [questionsArr, setQuestionsArr] = useState(null);
  let [requestDone, setRequestDone] = useState(false);
  let [qstn_name, setQstnName] = useState('');
  const [loading, setLoading] = useState(true);
  const [reservationData, setReservationData] = useState([]);

  useEffect(() => {
    setLoading(true);
    (async function() {
     await sleep(5000);
     let questionsArr = localStorage.getItem("edit_booking_reservation_questionnaire") ? localStorage.getItem("edit_booking_reservation_questionnaire") : null;

     if (questionsArr.length > 0) {
 
       if (questionsArr) {
         console.log("PS=>", questionsArr)
         //To create form
         if (!formBuilder?.formData) {
          console.log("PS=>", JSON.parse(questionsArr))
           setFormBuilder($(fb.current).formBuilder({
             formData: JSON.parse(questionsArr)
           })
           );
           setLoading(false);
         }
       } else {
         setFormBuilder($(fb.current).formBuilder({
           formData: JSON.parse(questionsArr)
         })
         );
         setLoading(false);
       }
     } else {
       if(reservation_id && !requestDone) {
         (async function() {
           await getSingleReservation(reservation_id);
           setRequestDone(true)
         })()
       } else {
         //To create form
         if(!formBuilder?.formData){
           setFormBuilder($(fb.current).formBuilder({ 
               disabledActionButtons: ['data', 'clear', 'save'],
               formData: []
             })
           );
         }
       }
     }
    })()

  }, [questionsArr])
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const getSingleReservation = (reservation_id) => {

    ReservationServices.getSingleReservation(reservation_id)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data) {
            setReservationData(data.data);
          } else {
            toast.error("getSingleReservation Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "getSingleReservation catch Error"; //error.response.data.message;
        toast.error(msg);
      });
  }
  async function saveData() {
    setQuestionsArr(formBuilder.formData);
  }
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      {loading ? (<FallingLinesLoader />) : <>
        <div className="px-4 sm:px-6 lg:px-8">

          <div className="pt-5">
            <div className='border border-gray-300 rounded-md px-8 py-8 mb-5'>
              <div id="fb-editor" ref={fb} />
            </div>
            <div className="flex justify-center">

              {/* <button
                onClick={saveData}
                type="button"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              >
                {'Save'}
              </button> */}
            </div>
          </div>
          <style>{"\
  .form-wrap.form-builder .frmb-control .icon-autocomplete, .form-wrap.form-builder .frmb-control .icon-button, .form-wrap.form-builder .frmb-control .icon-checkbox-group, .form-wrap.form-builder .frmb-control .icon-file, .form-wrap.form-builder .frmb-control .icon-hidden, .form-wrap.form-builder .frmb-control .icon-paragraph ,.icon-header,.icon-date,.icon-number,.icon-radio-group,.icon-select,.icon-text,.icon-textarea {\
    display: none;\
  }\
  .form-wrap.form-builder .form-actions.btn-group .clear-all,.btn.btn-default.get-data,.btn.btn-primary.save-template{\
    display: none;\
  }\
  .form-wrap.form-builder .frmb.stage-wrap.pull-left.ui-sortable .field-actions{\
    display: none;\
  }\
  .form-wrap.form-builder .frmb .prev-holder input[type=number] {\
     width: 100%; \
  }\
  .form-wrap.form-builder .frmb .prev-holder input[type=date] {\
    width: 100%; \
}\
  .form-wrap.form-builder .stage-wrap {\
    width: calc(95% - 5px);\
}\
.form-elements{\
  display: none;\
}\
.form-field{\
  border-style: none;\
}\
.form-wrap.form-builder .frmb>li:hover {\
box-shadow: none;\
}\
"}</style>
        </div>
      </>}
    </div>
  )
}

export default ResQuestionnairePrev