import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Table from "../../../../components/tables/table";
import TableEmpty from "../../../../components/tables/tableEmpty";
import {
  qc_analyzer_columns,
  qc_lot_columns,
  qc_test_list_lot,
} from "../../../../components/tables/tableheader";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import { Formik } from "formik";
import * as Yup from "yup";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import InputBox from "../../../../components/InputBox";
import { Switch } from "@headlessui/react";
import Multiselect from "multiselect-react-dropdown";
import MasterServices from "../../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import QcServices from "../../../../ApiServices/QcServices";
import queryString from 'query-string';


const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    lot_name_en: "",
  },
};

const initialModalStateTest = {
  type: "",
  state: false,
  index: null,
  data: [
    {
      test_id: null,
      test_name_en: "",
    },
  ],
};

function QCLot() {
  const pages = [{ title: "Control List", href: "/qc/control-list" }];
  const animatedComponents = makeAnimated();
  const { id: qcId } = useParams();
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [qCLotDetails, setQCLotDetails] = useState(true);
  const [testList, setTestList] = useState([]);
  const [testDetails, setTestDetails] = useState(false);
  const [testParamsList, setTestParamsList] = useState([]);
  const [packageList, setPackageList] = useState([
    {
      id: 1,
      name: "CBC",
    },
  ]);
  const [lotList, setLotList] = useState([]);
  const [modal, setModal] = useState(initialModalState);
  const [modalLot, setModalLot] = useState(initialModalStateTest);
  const [submitted, setSubmitted] = useState(false);
  // const [selected, setSelected] = useState([]);
  const [selected, setSelected] = useState({ label: '', value: '' });
  const [qcTestList, setQcTestList] = useState([]);
  const [QctestListArr, setQcTestListArr] = useState([]);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const toggleStatus = (id, activeStatus) => {
    if (activeStatus == true) {
      var sendStatus = false;
    } else {
      sendStatus = true;
    }
    const body = {
      lot_id: id,
      lot_is_active: sendStatus,
    };
    MasterServices.toggleLotActiveStatus(body)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Status Changed Successfully");
          getAllLots(qcId);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  const getAllLots = (id) => {
    setLoading(true);
    MasterServices.getAllLotsByQcId(id)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          setLotList(data.data);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };
  function transformData(list) {
    return list.map((item) => ({
      name: item.test_name_en,
      id: item.test_id,
    }));
  }
  const removeDuplicateObjects = (array1, array2, key) => {

    return array2.filter(item => {
      for (let i = 0; i < array1.length; i++) {
        console.log(array1[i].id == item.id)
        if (array1[i].id == item.id) {
          return false;
        }
      }
      return true;
    })
  }

  const CreateLot = (payload) => {
    console.log("Create Lot=>", payload);
    // return false;
    MasterServices.addLot(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Lot Created Successfully");
          getAllLots(qcId);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  const updateLotTestList = (payload) => {
    console.log("payload PHS=>",payload);
    let newPayload = {
      fk_lot_id:payload.qc_data.fk_lot_id,
      fk_test_id:payload.qc_data.fk_test_id,
      fk_test_params_id:payload.qc_data.fk_test_params_id,
      fk_qc_id:payload.qc_data.fk_qc_id,
      qcltv_max:(payload?.qcltv_max)?(payload?.qcltv_max):(payload?.qc_data?.qcltv_max),
      qcltv_mean:(payload?.qcltv_mean)?(payload?.qcltv_mean):(payload?.qc_data?.qcltv_mean),
      qcltv_sd:(payload?.qcltv_sd)?(payload?.qcltv_sd):(payload?.qc_data?.qcltv_sd),
      qcltv_min:(payload?.qcltv_min)?(payload?.qcltv_min):(payload?.qc_data?.qcltv_min),
      qcltv_id:payload.qc_data.qcltv_id,
      qcltv_is_active:payload.qc_data.qcltv_is_active,
      qcltv_delete:payload.qc_data.qcltv_delete,
      ttp_id:payload.qc_data.ttp_id,
      ttp_name_en:payload.qc_data.ttp_name_en,
    };
    // return false;
    MasterServices.updateLotTeslist(newPayload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Lot Test List Updated Successfully");
          getAllLots(qcId);
          // getAllTestList(qcId);
          getAllTestList(qCLotDetails);
          getAllQcTestList(qCLotDetails);
          getAllQCLotTestParams(qCLotDetails);

        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  }



  const getAllTestList = (id) => {
    setLoading(true);
    console.log("IDS=>", id);
    // return false;
    // MasterServices.getQcById(id)
    MasterServices.getQcLotById(id)
      .then((response) => {
        const { data, status } = response;
        console.log("data", response.data);
        if (status == 200) {
          setTestList(data.data.tests);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  // Added By Priyanshu SIngh
  const getAllQcTestList = (qcId) => {
    if (qcId > 0) {
      MasterServices.getQcById(qcId)
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            if (data.data && Array.isArray(data.data.tests) && data.data.tests.length > 0) {
              let newTests = transformData(data.data.tests)
              if (newTests.length > 0) {
                newTests.forEach((val, key) => {
                  val.label = val.name;
                  val.value = val.id;
                })
              }
              setQcTestList(newTests);
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          const msg = error;
          toast.error(msg);
          setLoading(false);
        });
    } else {
      console.log("QC Test Data Not Found")
    }
  };

  const getAllQCLotTestParams = (payload) => {
    setLoading(true);
    QcServices.getAllQCLotTestParams(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          setTestParamsList([]);
          setTestDetails(data?.data?.qc_testslotval);
          if (data?.data?.qc_testsParams.length > 0) {
            setTestParamsList(data?.data?.qc_testsParams);
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };



  const onSelect = (selectedList, selectedItem) => {
    setSelected(selectedList)
  }

  const onRemove = (selectedList, removedItem) => {
    setSelected(selectedList)
  }

  const navigate = useNavigate();
  useEffect(() => {
    getAllLots(qcId);
    getAllQcTestList(qcId);
    document.title = "New Labib | Control List";
  }, []);

  const renderModal = () => {
    const { type, state, data, edit_id } = modal;

    return (
      <Formik
        initialValues={data}
        validationSchema={Yup.object({
          lot_name_en: Yup.string().required("QC name is required"),
        })}
        enableReinitialize={true}
        onSubmit={(values, action) => {
          const body = {
            ...values,
            lot_name_en: values.lot_name_en,
            lot_name_ar: values.lot_name_en,
            fk_q_qc_id: parseInt(qcId),
            lot_is_active: enabled,
          };
          CreateLot(body);
          action.setSubmitting(false);
          action.resetForm();
          setModal((prev) => ({ ...prev, state: false }));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Modal
            title="Add QC Lot"
            open={state}
            setOpen={() => setModal((prev) => ({ ...prev, state: false }))}
          >
            <form onSubmit={handleSubmit}>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <InputBox
                  name="lot_name_en"
                  label="QC Lot Name"
                  type="text"
                  value={values.lot_name_en}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={true}
                  placeholder="Enter QC Lot Name"
                />
                {touched.lot_name_en && (
                  <p className="text-red-700 error_msg">{errors.lot_name_en}</p>
                )}
              </div>

              <div className="text-left flex flex-col  mt-4">
                <label htmlFor="">Status</label>
                <Switch
                  checked={enabled}
                  onChange={setEnabled}
                  className={classNames(
                    enabled ? "bg-cyan-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enabled ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </div>
              <div className="text-left mt-4 z-[999] h-52">
                <label htmlFor="" className="block text-sm font-medium text-gray-700">QC Test</label>
                <Select
                  name="qc_test_name"
                  id="qc_test_name"
                  placeholder="Select Test"
                  value={selected}
                  // value={values.test_id}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  onChange={(e) => {

                    console.log(e)
                    setSelected(e)
                    values.test_id = e.value;
                    values.test_name = e.label;

                  }}
                  // onBlur={handleBlur}
                  components={animatedComponents}
                  options={qcTestList}
                  classNamePrefix="select"
                  className="basic-multi-select block w-full shadow-sm focus:border-[1px] focus:border-cyan-500 sm:text-sm"
                />
                {/* <Multiselect
                  dataKey="id"
                  selectedValues={selected}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  displayValue="name"
                  options={qcTestList}
                /> */}
                {submitted === true && selected.length < 1 && (
                  <p className="text-red-600 text-sm">Please select the test</p>
                )}
              </div>

              <div className="mt-4"></div>
              <Button disabled={isSubmitting} type="submit">
                {isSubmitting ? "Saving..." : "Add Lot"}
              </Button>
            </form>
          </Modal>
        )}
      </Formik>
    );
  };

  const toggleModal = (data) => {
    console.log("Priyanshu=>", data)
    let payload = {
      qc_id: data.fk_q_qc_id,
      lot_id: data.lot_id,
    };
    setQCLotDetails(payload);
    // getAllTestList(qcId);
    getAllTestList(payload);
    getAllQCLotTestParams(payload);
    console.log("working...");
    setModalLot((prev) => ({ ...prev, state: true }));
  };

  //Test M

  const renderTestModel = () => {
    const { data, index, state, type } = modalLot;

    return (
      <Modal
        title={`Test Params List - ${testDetails?.t_test_relation?.test_name_en}`}
        open={state}
        size="max-w-3xl relative"
        setOpen={() => setModalLot((prev) => ({ ...prev, state: false }))}
      >
        {loading ? (
          <FallingLinesLoader />
        ) : (
          // <Table columns={qc_test_list_lot({ updateLotTestList, qcId })} data={testList} />
          <Table columns={qc_test_list_lot({ updateLotTestList, qcId })} data={testParamsList} />
        )}
      </Modal>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-4 flex">
        <button
          type="button"
          onClick={() => setModal((prev) => ({ ...prev, state: true }))}
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </button>
      </div>
      {renderModal()}
      {renderTestModel()}
      {loading ? (
        <FallingLinesLoader />
      ) : lotList.length > 0 ? (
        <Table
          columns={qc_lot_columns({ toggleStatus, toggleModal })}
          data={lotList}
        />
      ) : (
        <TableEmpty />
      )}
    </div>
  );
}
export default QCLot;
