import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { AntibioticResultTypeSchema } from "../../../schemas";
import Table from "../../../components/tables/table";
import { antibiotic_rt_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toast";
import MasterServices from "../../../ApiServices/MasterServices";
import OrganismAntiServices from "../../../ApiServices/OrganismAntiServices";

function AntibioticResultTypes() {
  const pages = [
    { title: "Antibiotic Result Type List", href: "/antibiotic-result-types", module_id: null },
  ];
  
  const [antibioticRTList, setAntibioticRTList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpenFlag, setmodalOpenFlag] = useState(false);
  const [mtrt_id, setAntibioticResultTypeId] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    mtrt_id: null,
  });
  
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Labib | Antibiotic Result Types";
    getAllAntibioticResultTypes(3);
  }, []);

  const getAllAntibioticResultTypes = async (payload) => {
    try {
      setIsLoading(true);
      const { data } = await OrganismAntiServices.getAllAntibioticResultTypeList(payload)
      // const { data } = await MasterServices.getAllAntibioticResultTypes()
      console.log("All Antibiotic Result Types Data", data);
      if (data.status) {
        setAntibioticRTList(data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const deleteAntibioticResultType = async (payload) => {
    try {
      setIsLoading(true);
      const { data } = await MasterServices.deleteAntibioticResultType(payload)
      if (data.status) {
        getAllAntibioticResultTypes(3);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const addAntibioticResultType = async () => {
    try {
      setIsLoading(true);
      console.log("Add AntibioticResultType");
      let body = {
        mtrt_id,
        mtrt_name_en: values.mtrt_name_en,
        mtrt_name_ar: values.mtrt_name_ar,
      };
      if (!mtrt_id) {
        delete body.mtrt_id;
      }
      const res = await MasterServices.saveAntibioticResultType(body)
      if (res.status) {
        toast.success(res?.data?.message);
        getAllAntibioticResultTypes(3);
      }
      console.log("Add Antibiotic Result Type Response", res);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const initialValues = {
    mtrt_id: "",
    mtrt_name_en: "",
    mtrt_name_ar: "",
  };

  const [formAntibioticResultType, setFormAntibioticResultType] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    if ((type === "edit", id)) {
      setAntibioticResultTypeId(id);

      const initialValues = {
        mtrt_id: obj.mtrt_id,
        mtrt_name_en: obj.mtrt_name_en,
        mtrt_name_ar: obj.mtrt_name_ar,
      };
      setFormAntibioticResultType(initialValues);
    } else {
      // setSelectedLocationId('')
      setAntibioticResultTypeId("");
      setFormAntibioticResultType(initialValues);
    }
    if (modalOpenFlag === false) {
      setmodalOpenFlag(true);
    }
  };
  const onDeleteOpen = (mtrt_id) => {
    setConfirmationModal({ mtrt_id, status: true });
  };

  const onDeleteAntibioticResultType = (mtrt_id) => {
    setConfirmationModal({ mtrt_id: null, status: false });
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formAntibioticResultType,
      validationSchema: AntibioticResultTypeSchema,
      onSubmit: (values, action) => {
        console.log("Submit Clicked", values);
        addAntibioticResultType();

        action.resetForm();
        if (modalOpenFlag === true) {
          setmodalOpenFlag(false);
        }
        navigate("/antibiotic-result-types");
      },
    });
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => {
          onDeleteAntibioticResultType(confirmationModal.mtrt_id);
          deleteAntibioticResultType({ mtrt_id: confirmationModal.mtrt_id, mtrt_is_active: false });
        }}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Antibiotic Result Types</h1>
        <p className="mt-2 text-sm text-gray-700">
          A list of all the Antibiotic Result Types.
        </p>
      </div>
      <div className="mt-4 flex">
        <Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add Antibiotic Result Type
        </Link>
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={antibiotic_rt_columns({ onDeleteOpen, handleDrawer })}
          data={antibioticRTList?.length > 0 && !isLoading ? antibioticRTList : []}
        />
      )}

      {isLoading ? null : (
        <div>
          <Transition.Root show={modalOpenFlag} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-30"
              onClose={setmodalOpenFlag}
            >
              <div className="fixed inset-0" />
              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(e);
                          }}
                          className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                        >
                          <div className="h-0 flex-1 overflow-y-auto">
                            <div className="bg-cyan-700 py-6 px-4 sm:px-6">
                              <div className="flex items-center justify-between">
                                <Dialog.Title className="text-lg font-medium text-white">
                                  {mtrt_id ? "Update" : "Add"} Antibiotic Result Type
                                </Dialog.Title>
                                <div className="ml-3 flex h-7 items-center">
                                  <button
                                    type="button"
                                    className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
                                    onClick={() => setmodalOpenFlag(false)}
                                  >
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 sm:p-6">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="mtrt_name_en"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Antibiotic Result Type Name English
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.mtrt_name_en}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Antibiotic Result Type Name En"
                                      name="mtrt_name_en"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.mtrt_name_en &&
                                    touched.mtrt_name_en ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.mtrt_name_en}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="mtrt_name_en"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Antibiotic Result Type Name Arabic
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.mtrt_name_ar}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Antibiotic Result Type Name Ar"
                                      name="mtrt_name_ar"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.mtrt_name_ar &&
                                    touched.mtrt_name_ar ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.mtrt_name_ar}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            <button
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              onClick={() => {
                                setmodalOpenFlag(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            >
                              {mtrt_id ? "Update" : "Add"}
                            </button>
                          </div>
                        </form>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      )}
    </div>
  );
}
export default AntibioticResultTypes;
