import { useEffect, useRef } from "react";
import {
  goods_received_columns,
} from "../../../components/tables/tableheader";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Modal from "../../../components/Modal";
import InputBox from "../../../components/InputBox";
import Button from "../../../components/Button";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import ConfirmationModal from "../../../components/ConfirmationModal";
import moment from "moment";
import { convertToAnotherTimezone } from "../../../helper/commonHelper";
import { ArrowUturnLeftIcon, XMarkIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import Barcode from "react-barcode";
import makeAnimated from 'react-select/animated';
import Select from "react-select";
const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    purchase_order_id: "",
    storage_location_id: "",
    quantity: "",
    item_id: "",
    batch_number: "",
    expire_date: "",
  },
};
const initialModalBarcode = {
  state: false,
  data: [],
}
const currentDate = moment().format('YYYY-MM-DD') // get current date
const currentTime = moment().format('HH:mm') // get current time

function SingleGoodsReceived() {
  const pages = [{ title: 'GRN (Goods Received)', href: '/goods-received', module_id: 32 }];
  const [loading, setLoading] = useState(false);
  const animatedComponents = makeAnimated();
  const [modal, setModal] = useState(initialModalState);
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [selectedItemId, setSelectedItemId] = useState('');
  const [itemsList, setItemsList] = useState([]);
  const [maxQuantity, setMaxQuantity] = useState(0)
  const SingleprintsampleRef = useRef();
  const [modalBarcode, setModalBarcode] = useState(initialModalBarcode);
  const [purchaseorderList, setPurchaseOrderList] = useState([]);
  const [isCompleted, setIsCompleted] = useState(true);
  const [showError, setShowError] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    grn_id: null,
  });
  const [storageLocations, setStorageLocations] = useState({
    loading: false,
    data: [],
  });
  const [grns, setGrn] = useState({
    loading: false,
    data: [],
  });
  const { id: OrderId } = useParams();

  const navigate = useNavigate();
  const [inputList, setInputList] = useState([]);
  const handleInputChange = (e, key, index) => {
    let list = [...inputList];
    if(e.value) {
      list[index][key] = e;
    } else {
      list[index][e.target.name] = e.target.value;
    }
    setInputList(list);
  };
  const handleRemoveClick = (index) => {
    let list = inputList;
    const finalList = inputList.filter((ele, i) => i !== index);
    console.log(finalList, "finalList");
    // list.splice(index, 0);
    setInputList([...finalList]);
  };
  const handleAddClick = (quantity) => {
    const newBarcodeFields = [];
    for(let i=0; i<quantity; i++) {
      newBarcodeFields.push(
        {
          [`barcode_id_${i+1}`]: "",
        },
      )
    }
    setInputList(newBarcodeFields);
  };

  useEffect(() => {
    console.log(maxQuantity)
  }, [maxQuantity])

  useEffect(() => {
    getAllItems(OrderId);
    getAllPurchaseOrder();
    getAllGRNs({ po_id: OrderId });
    getAllStorageLocations();
    document.title = "New Labib | GRN (Goods Received)";
  }, [OrderId]);
  useEffect(() => {
    if (modal.edit_id !== "") {
      getGRNById(modal.edit_id);
    }
  }, [modal.edit_id]);
  const getAllItems = async (payload) => {
    await MasterServices.getSinglePurchaseOrder(payload)
      .then((response) => {
        const { data, status } = response;
        if (status === 200) {
          console.log(data.data)
          let totalQuantity = 0;
          if (data.data?.w_purchase_order_items && Array.isArray(data.data?.w_purchase_order_items) && data.data?.w_purchase_order_items.length > 0) {
            totalQuantity = data.data?.w_purchase_order_items.reduce((accumulator, object) => {
              return accumulator + parseInt(object.wpoi_quantity);
            }, 0);
          }
          let remainingQunatity = 0;
          if (data.data?.wpo_gr_relation && Array.isArray(data.data?.wpo_gr_relation) && data.data?.wpo_gr_relation.length > 0) {
            remainingQunatity = data.data?.wpo_gr_relation.reduce((accumulator, object) => {
              return accumulator + parseInt(object.quantity);
            }, 0);
          }
          setIsCompleted(totalQuantity !== 0 && totalQuantity === remainingQunatity);
          if (data.data && Array.isArray(data.data.w_purchase_order_items)) {
            setItemsList(data.data.w_purchase_order_items);
          }
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
    setLoading(false);
  };

  const createGRN = async (body) => {
    await MasterServices.createGrnGoodsReceived(body)
      .then((res) => {
        const { data, status } = res;
        if (status === 201) {
          getAllItems(OrderId);
          getAllGRNs({ po_id: OrderId });
          toast.success("GRN Goods received Added Successfully");
          setModal((prev) => ({ ...prev, data: {}, state: false }));
        }
      })
      .catch((err) => {
        toast.error(err.data.message);
        console.log(err);
      });
  };
  const getAllPurchaseOrder = () => {
    setLoading(true)
    MasterServices.getPurchaseOrder().then((response) => {
      const { data, status } = response;
      if (status == 200) {
        if (data?.data?.length > 0) {
          setPurchaseOrderList(data.data);
          console.log("Purchase Order List", data.data);
        } else {
          toast.error("PurchaseOrder Not Found");
        }
      } else {
        toast.error("Fatal Error Please Contact Admin");
      }
      setLoading(false);
    });
  };
  const getAllStorageLocations = async () => {
    setStorageLocations((prev) => ({ ...prev, loading: true }));
    await MasterServices.getAllStorageLocation()
      .then((res) => {
        const { data, status } = res;
        console.log("Storage Location", data.data);
        if (status === 200) {
          setStorageLocations({
            loading: false,
            data: data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllGRNs = async (payload) => {
    setGrn((prev) => ({ ...prev, loading: true }));
    await MasterServices.getGrnGoodsReceivedByPurchaseOrder(payload)
      .then((res) => {
        const { data, status } = res;
        console.log(data.data);
        if (status === 200) {
          setGrn({
            loading: false,
            data: data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const getGRNById = async (id) => {
    await MasterServices.getOneGrnGoodsReceived(id)
      .then((res) => {
        const { data, status } = res;
        console.log(res);
        if (status == 200) {
          setModal((prev) => ({
            ...prev,
            type: "edit",
            data: {
              purchase_order_id: data.data.purchase_order_id,
              storage_location_id: data.data.storage_location_id,
              quantity: data.data.quantity,
              item_id: data.data.item_id,
              batch_number: data.data.batch_number,
              expire_date: data?.data?.expire_date && moment(data.data.expire_date).format('YYYY-MM-DD'),
            },
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateGrn = async (id, body) => {
    await MasterServices.updateGrnGoodsReceived(id, body)
      .then((res) => {
        const { data, status } = res;
        if (status === 201) {
          getAllGRNs({ po_id: OrderId });
          getAllItems(OrderId);
          toast.success("GRN Goods Received Updated Successfully");
          setModal((prev) => ({
            ...prev,
            data: {},
            type: "",
            edit_id: "",
            state: false,
          }));
        }
      })
      .catch((err) => {
        toast.error(err.data.message);
        console.log(err);
      });
  };
  const onDeleteOpen = (id) => {
    setConfirmationModal({ grn_id: id, status: true });
  };

  const handleSinglePrint = (grnData) => {
    // alert(singleSampleData.length);
    if (Array.isArray(grns.data) && grns.data.length > 0) {
      let fileName = 'GRN_PURCHASE_ORDER_' + grnData.grn_id + '_.pdf';
      const printWindow = window.open('', fileName);
      const tableHtml = SingleprintsampleRef.current.outerHTML;
      printWindow.document.write(`<html><head><title>${fileName}</title></head><body>${tableHtml}</body></html>`);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    }
  }

  const printSampleBarCode = async (grnData, e) => {
    await handleSinglePrint(grnData);
    e.preventDefault();
  }

  const onDeleteGRN = async (id) => {
    await MasterServices.deleteGrnGoodsReceived(id)
      .then((res) => {
        const { data, status } = res;
        if (status === 201) {
          getAllGRNs({ po_id: OrderId });
          toast.success("GRN Deleted Successfully");
          setConfirmationModal({ grn_id: null, status: false });
        }
      })
      .catch((err) => {
        toast.error(err.data.message);
        console.log(err);
      });
  };

  const renderBarcodeModal = () => {
    const { state, data } = modalBarcode;

    return (
      <Modal
        title="BARCODE"
        open={state}
        size="max-w-md"
        setOpen={() => setModalBarcode((prev) => ({ ...prev, state: false }))}
      >
        <div className="flex justify-center">
          <Barcode height={60} displayValue={false} value={data?.grn_id} />
        </div>
      </Modal>
    );
  };

  const openBarcodeModal = (data) => {
    setModalBarcode({
      data: data,
      state: true
    })
  }

  const renderModal = () => {
    const { type, state, data, edit_id } = modal;


    return (
      <Formik
        initialValues={data}
        validationSchema={Yup.object({
          purchase_order_id: Yup.string(),
          storage_location_id: Yup.string().required(
            "Please select the storage location"
          ),
          batch_number: Yup.string().required('please enter batch number'),
          expire_date: Yup.string().required('Please select date'),
          quantity: Yup.number()
            .integer()
            .min(1).required("Please enter the quantity"),
          item_id: Yup.string().required("Please select the item"),
        })}
        enableReinitialize={true}
        onSubmit={(values, action) => {
          //Checking item barcode ..
          console.log(inputList, inputList.map((v, i) => ({barcode_id: v[`barcode_id_${i+1}`]})))
          for(let i=0; i<inputList.length; i++) {
            let barcodeItem = inputList[i];
            console.log(barcodeItem)
            if(!barcodeItem[`barcode_id_${i+1}`]) {
              setShowError(true);
              console.log('handle from barcode')
              return false
            }
          }
          const body = {
            purchase_order_id: parseInt(OrderId),
            storage_location_id: parseInt(values.storage_location_id),
            quantity: parseInt(values.quantity),
            item_id: parseInt(values.item_id),
            batch_number: values.batch_number,
            expire_date: moment(values.expire_date),
            barcode_ids: inputList.map((v, i) => ({barcode_id: v[`barcode_id_${i+1}`]}))
          };
          if (modal.type === "edit") {
            updateGrn(modal.edit_id, body);
          } else {
            createGRN(body);
            action.resetForm();
          }
          action.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Modal
            title={`${modal.type === "edit" ? "Update" : "Add"
              } GRN (Goods Received)`}
            open={state}
            setOpen={() => {
              setSelectedItemId("")
              setSelectedLocationId("");
              setInputList([]);
              values.expire_date = "";
              values.quantity = "";
              values.batch_number = "";
              setModal((prev) => ({ ...prev, state: false }))
            }}
          >
            <form onSubmit={handleSubmit}>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="analyzer_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Purchase Order
                </label>
                <select
                  disabled
                  id="purchase_order_id"
                  name="purchase_order_id"
                  onChange={handleChange}
                  //   onChange={(e) => {
                  //     getAllItems(e.target.value)
                  //     handleChange(e)
                  //   }}
                  onBlur={handleBlur}
                  value={OrderId}
                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                >
                  <option value="">Select the Order</option>
                  {
                    purchaseorderList.map((order, index) => (
                      <option key={index} value={order.wpo_id}>
                        {order.wpo_vendor_relation.vendor_account_name} - {moment(convertToAnotherTimezone(order.wpo_created_at)).format('do-MM-YYYY HH:mm:ss')}
                      </option>
                    ))
                  }
                </select>
                {touched.purchase_order_id && (
                  <p className="text-red-700 error_msg">
                    {errors.purchase_order_id}
                  </p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="storage_location_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Storage Location
                </label>
                {/* <select
                  id="storage_location_id"
                  name="storage_location_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.storage_location_id}
                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                >
                  <option value="">Select the location</option>
                  {Array.isArray(storageLocations?.data) && storageLocations?.data.length > 0 && storageLocations.data.map((item, index) => (
                    <option key={index} value={item.sl_id}>
                      {item.name}
                    </option>
                  ))}
                </select> */}
                <Select
                  name="storage_location_id"
                  id="storage_location_id"
                  placeholder="Select Locations"
                  value={selectedLocationId}
                  onChange={(e) => {
                    values.storage_location_id = e.value;
                    setSelectedLocationId(e);
                  }}
                  // onBlur={handleBlur}
                  components={animatedComponents}
                  options={Array.isArray(storageLocations?.data) && storageLocations?.data.length > 0 ? storageLocations.data.map(v => ({ value: v.sl_id, label: v.name })) : []}
                  classNamePrefix="select"
                  className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                />
                {errors.storage_location_id && (
                  <p className="text-red-700 error_msg">
                    {errors.storage_location_id}
                  </p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="item_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  item
                </label>
                {/* <select
                  id="item_id"
                  name="item_id"
                  onChange={(e) => {
                    if(Array.isArray(itemsList) && itemsList.length > 0 && e.target.value != "") {
                      console.log(itemsList.filter((v) => e.target.value == v.w_item_relation.witem_id)[0].total_received)
                      values.quantity=0
                      setMaxQuantity((itemsList.filter((v) => e.target.value == v.w_item_relation.witem_id)[0].wpoi_quantity - itemsList.filter((v) => e.target.value == v.w_item_relation.witem_id)[0].total_received))
                    }
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  value={values.item_id}
                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                  defaultValue="Canada"
                >
                  <option value="">Select the item</option>
                  {itemsList.length > 0 && itemsList.map((item, index) => {
                    return (
                      <option key={index} value={item.w_item_relation.witem_id}>
                        {item.w_item_relation.witem_name_en} (Total Qty: {item.wpoi_quantity}) (received Qty: {item.total_received})
                      </option>
                    );
                  })}
                </select> */}
                <Select
                  name="item_id"
                  id="item_id"
                  placeholder="Select Item"
                  value={selectedItemId}
                  onChange={(e) => {
                    values.item_id = e.value;
                    setSelectedItemId(e);
                    if (Array.isArray(itemsList) && itemsList.length > 0 && e.value != "") {
                      console.log(itemsList.filter((v) => e.value == v.w_item_relation.witem_id)[0].total_received)
                      values.quantity = 0
                      setMaxQuantity((itemsList.filter((v) => e.value == v.w_item_relation.witem_id)[0].wpoi_quantity - itemsList.filter((v) => e.value == v.w_item_relation.witem_id)[0].total_received))
                    }
                    // handleChange(e)
                  }}
                  // onBlur={handleBlur}
                  components={animatedComponents}
                  options={Array.isArray(itemsList) && itemsList.length > 0 ? itemsList.map(v => ({ value: v.w_item_relation.witem_id, label: `${v.w_item_relation.witem_name_en} (Total Qty: ${v.wpoi_quantity}) (received Qty: ${v.total_received})` })) : []}
                  classNamePrefix="select"
                  className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                />
                {errors.item_id && (
                  <p className="text-red-700 error_msg">{errors.item_id}</p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="batch_number"
                  className="block text-sm font-medium text-gray-700"
                >
                  Batch Number
                </label>
                <input
                  name="batch_number"
                  label="batch_number"
                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                  type="text"
                  value={values.batch_number}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Batch Number"
                />
                {errors.batch_number && (
                  <p className="text-red-700 error_msg">
                    {errors.batch_number}
                  </p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="expire_date"
                  className="block text-sm font-medium text-gray-700"
                >
                  Expire Date
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    value={values.expire_date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="date"
                    name="expire_date"
                    id="expire_date"
                    autoComplete="off"
                    min={currentDate}
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                  />
                  {
                    errors.expire_date &&
                    <p className="text-red-700 error_msg">
                      {errors.expire_date}
                    </p>
                  }
                </div>
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="item_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Quantity
                </label>
                <input
                  name="quantity"
                  label="Quantity"
                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                  type="number"
                  value={values.quantity}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    console.log(e.target.value, maxQuantity)
                    if (parseInt(e.target.value) > parseInt(maxQuantity)) {
                      e.target.value = maxQuantity
                      console.log(maxQuantity)
                      handleChange(e)
                    } else {
                      handleChange(e)
                    }
                    handleAddClick(e.target.value)
                  }}
                  placeholder="Quantity"
                />
                {errors.quantity && (
                  <p className="text-red-700 error_msg">{errors.quantity}</p>
                )}
              </div>
              {values.quantity && values.quantity > 0 ? (
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="barcode_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Item Barcode Id
                </label>
                <div>
                  {inputList.map((x, index) => (
                    <div
                      key={index}
                      className="mt-1 w-full flex sm:col-span-2 mb-3"
                    >
                      <div className="block w-full mr-4 max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm">
                        <input
                          type="text"
                          name={`barcode_id_${index+1}`}
                          id={`barcode_id_${index+1}`}
                          value={x[`barcode_id_${index+1}`]}
                          placeholder={"Enter Barcode Id " + (index+1)}
                          autoComplete="off"
                          onChange={(e) => handleInputChange(e, `barcode_id_${index+1}`, index)}
                          className="ml-2 block w-full mr-4 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                        {!inputList[index][`barcode_id_${index+1}`] && showError ? (
                          <p className="text-red-700">Please enter Barcode</p>
                        ) : null}
                      </div>
                      {/* <button
                        type="button"
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-"
                        onClick={() => handleRemoveClick(index)}
                      >
                        <XMarkIcon className="w-5 cursor-pointer" />
                      </button> */}
                    </div>
                  ))}
                  {/* <button
                    type="button"
                    className="ml-3 mt-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-"
                    onClick={handleAddClick}
                  >
                    <PlusCircleIcon className="w-5 cursor-pointer" />
                  </button> */}
                </div>
              </div>
              ): null}
              <div className="mt-4"></div>
              <Button type="submit">
                {modal.type === "edit" ? "Update" : "Add"} GRN (Goods Received)
              </Button>
            </form>
          </Modal>
        )}
      </Formik>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <button
        type="button"
        onClick={() => navigate(-1)}
        className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
      >
        <span className="w-5 mr-2">
          <ArrowUturnLeftIcon size={18} />
        </span>{" "}
        Back
      </button>
      {renderModal()}
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteGRN(confirmationModal.grn_id)}
      />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">
          GRN (Goods Received)
        </h1>
      </div>
      {!isCompleted && (
        <div className="mt-4 flex">
          <button
            type="button"
            onClick={() =>
              setModal((prev) => ({
                data: {},
                type: "",
                edit_id: "",
                state: true,
              }))
            }
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
          >
            Add
          </button>
        </div>
      )}
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <>
          {Array.isArray(grns.data) && grns.data.length > 0 ? (
            <Table
              columns={goods_received_columns({
                setModal,
                onDeleteOpen,
                getAllItems,
                SingleprintsampleRef,
                printSampleBarCode,
                openBarcodeModal
              })}
              data={grns.data}
            />
          ) : (<TableEmpty />)
          }
        </>
      )
      }
      {renderBarcodeModal()}
    </div>
  );
}
export default SingleGoodsReceived;
