import React, { useState } from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import { useFormik } from 'formik';
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import { User_update_report } from '../../../components/tables/tableheader';

const UserUpdateReport = () => {
    const pages = [{ title: "User Update Report", href: "/user-update-report" }];
    const initialValues = {
        date_from: "",
        date_to: "",
        hospital: "",
        status: "",
        user: "",
        updated_type: ""
    }
    const [formState, setFormState] = useState(initialValues);
    const [loading, setLoading] = useState(false)
    const userUpdateReport = []

    const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
        useFormik({
            enableReinitialize: true,
            // enableReinitializing,
            initialValues: formState,
            //   validationSchema: packageSchema,
            onSubmit: async (values, action) => {
                console.log(values, "values")
            },

        });
    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <Breadcrumb pages={pages} />
            <div className="mb-5">
                <h1 className="text-xl font-semibold text-gray-900">User Update Report</h1>
                <p className="mt-2 text-sm text-gray-700">A list of all the User Update Report.</p>
            </div>
            <div>
                <div className="flex sm:border-t sm:border-gray-200 sm:pt-5 gap-5">
                    <div className="basis-1/3">
                        <label htmlFor="date_from" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            Date From
                        </label>
                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <input
                                type="date"
                                name="date_from"
                                id="date_from"
                                autoComplete="off"
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                value={values.date_from}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </div>
                    </div>

                    <div className="basis-1/3">
                        <label htmlFor="date_to" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            Date To
                        </label>
                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <input
                                type="date"
                                name="date_to"
                                id="date_to"
                                autoComplete="phone"
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                value={values.date_to}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </div>
                    </div>
                    <div className="basis-1/3">
                        <label htmlFor="hospital" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            Hospital
                        </label>
                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <select
                                id="hospital"
                                name="hospital"
                                autoComplete="hospital"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.hospital}
                            >
                                <option disabled>Select hospital</option>
                                <option value="1">hospital-1</option>
                                <option value="2">hospital-2</option>
                                <option value="3">hospital-3</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="flex gap-5">
                    <div className="basis-1/3">
                        <label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            Status
                        </label>
                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <select
                                id="status"
                                name="status"
                                autoComplete="status"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.status}
                            >
                                <option disabled>Select status</option>
                                <option value="true">true</option>
                                <option value="false">false</option>
                            </select>
                        </div>
                    </div>
                    <div className="basis-1/3">
                        <label htmlFor="user" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            User
                        </label>
                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <select
                                id="user"
                                name="user"
                                autoComplete="user"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.user}
                            >
                                <option disabled>Select user</option>
                                <option value="nitesh">nitesh</option>
                                <option value="sudhir">sudhir</option>
                                <option value="priyanshu">priyanshu</option>
                            </select>

                        </div>
                    </div>
                    <div className="basis-1/3">
                        <label htmlFor="updated_type" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            Updated Type
                        </label>
                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <select
                                id="updated_type"
                                name="updated_type"
                                autoComplete="updated_type"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.updated_type}
                            >
                                <option disabled>Select Update type</option>
                                <option value="type-1">type-1</option>
                                <option value="type-2">type-2</option>
                                <option value="type-3">type-3</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='mt-5 text-right'>
                    <button
                        type="submit"
                        onClick={(e) => handleSubmit(e)}
                        className="inline-flex justify-center rounded-md bg-cyan-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                    >
                        Submit
                    </button>
                </div>
            </div>
            {
                loading ? (
                    <FallingLinesLoader />
                ) : (
                    <Table
                        columns={User_update_report()}
                        data={userUpdateReport}
                    />)
            }
        </div>
    )
}

export default UserUpdateReport