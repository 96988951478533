import React, { useRef } from "react";
import CustomButton from "./CustomButton";

const CustomImportButton = ({ onFileSelect }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && onFileSelect) {
      onFileSelect(file);
    }
    event.target.value = null;
  };

  return (
    <>
      <CustomButton onClick={handleButtonClick}>Import</CustomButton>

      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept=".xlsx, .xls"
        className="hidden"
      />
    </>
  );
};

export default CustomImportButton;
