/* eslint-disable */
import { toast } from 'react-toast'
import {
  GET_ALL_TEST_BEGIN,
  GET_ALL_TEST_SUCCESS,
  GET_ALL_TEST_ERROR
} from './constants'
import MasterServices from '../../ApiServices/MasterServices'

export const getAllTests = (payload) => async (dispatch) => {
  dispatch({
    type: GET_ALL_TEST_BEGIN
  })
  MasterServices.getTestListForReservation(payload)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {

        let updatedTests = data?.data

        updatedTests?.forEach((element) => {
          element.label = element.test_name_en
          element.value = element.test_id
        });

        if (data?.data?.length > 0) {
          dispatch({
            type: GET_ALL_TEST_SUCCESS,
            data: updatedTests?.length > 0 ? updatedTests : [],
          })
        } else {
          toast.error("Test Not Found")
        }
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error.response.data.message;
      toast.error(msg)
    });
}
