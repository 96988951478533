import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { roleSchema } from "../../../schemas";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
// import { useDispatch } from "react-redux";
import { role_columns } from "../../../components/tables/tableheader";
import {
	deleteRole,
	getAllRoles,
	addRole,
	updateRole,
} from "../../../redux/role/actions";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import { getLoginUserInfo } from "../../../redux/userInfo/actions";

function RoleList() {
	const pages = [{ title: "Role List", href: "/role-list", module_id: 3 }];
	const [modalOpenFlage, setmodalOpenFlage] = useState(false);
	const [role_id, setRoleid] = useState("");
	const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		role_id: null,
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { loading, list: roleList } = useSelector((state) => state.role);

	useEffect(() => {
		document.title = "Labib | Roles";
		dispatch(getAllRoles());
	}, []);

	const initialValues = {
		role_id: "",
		role_name_en: "",
		role_name_ar: "",
		role_status: "",
	};

	const [formRole, setFormRole] = useState(initialValues);

	const handleDrawer = (type, id, obj) => {
		if ((type === "edit", id)) {
			setRoleid(id);
			const initialValues = {
				role_id: obj.role_id,
				role_name_en: obj.role_name_en,
				role_name_ar: obj.role_name_ar,
				role_is_active: obj.role_is_active,
			};
			setFormRole(initialValues);
		} else {
			setRoleid("");
			setFormRole(initialValues);
		}
		if (modalOpenFlage === false) {
			setmodalOpenFlage(true);
		}
	};
	const onDeleteOpen = (role_id) => {
		setConfirmationModal({ role_id, status: true });
	};

	const onDeleteRole = (role_id) => {
		dispatch(deleteRole(role_id));
		setConfirmationModal({ role_id: null, status: false });
	};

	const saveMaxDiscountValue = async(body) => {
		console.log('max discount api', body)
		await MasterServices.saveRoleMaxDiscount(body)
		.then((res) => {
		  const { data, status } = res;
		  console.log(data)
		  if (status === 200 && data.data) {
			dispatch(getAllRoles());
			dispatch(getLoginUserInfo('All'));
			toast.success("Max Discount Updated");
		  }
		})
		.catch((err) => {
		  toast.error(err.data.message);
		  console.log(err);
		});
	}

	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			enableReinitialize: true,
			initialValues: formRole,
			validationSchema: roleSchema,
			onSubmit: (values, action) => {
				console.log("Submit Clicked", values);
				let body = {
					role_id: role_id,
					role_is_active: true,
					role_name_en: values.role_name_en,
					role_name_ar: values.role_name_ar,
				};
				if (role_id === undefined || role_id === null || role_id === "")
					dispatch(addRole(body));
				else dispatch(updateRole(role_id, body));
				action.resetForm();
				dispatch(getAllRoles());
				if (modalOpenFlage === true) {
					dispatch(getAllRoles());
					setmodalOpenFlage(false);
				}
				navigate("/role");
			},
		});
	return (
		<div className="px-4 sm:px-6 lg:px-8">
			<ConfirmationModal
				open={confirmationModal.status}
				setOpen={setConfirmationModal}
				onDelete={() => onDeleteRole(confirmationModal.role_id)}
			/>
			<Breadcrumb pages={pages} />
			<div className="">
				<h1 className="text-xl font-semibold text-gray-900">Role List</h1>
				<p className="mt-2 text-sm text-gray-700">A list of all the Roles.</p>
			</div>
			<div className="mt-4 flex">
				<Link
					onClick={() => handleDrawer("add", "", {})}
					type="button"
					className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
				>
					Add Role
				</Link>
			</div>

			{loading ? (
				<FallingLinesLoader />
			) : (
				<Table
					columns={role_columns({ onDeleteOpen, handleDrawer, saveMaxDiscountValue })}
					data={roleList}
				/>
			)}

			<div>
				<Transition.Root show={modalOpenFlage} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-30"
						onClose={setmodalOpenFlage}
					>
						<div className="fixed inset-0" />
						<div className="fixed inset-0 overflow-hidden">
							<div className="absolute inset-0 overflow-hidden">
								<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
									<Transition.Child
										as={Fragment}
										enter="transform transition ease-in-out duration-500 sm:duration-700"
										enterFrom="translate-x-full"
										enterTo="translate-x-0"
										leave="transform transition ease-in-out duration-500 sm:duration-700"
										leaveFrom="translate-x-0"
										leaveTo="translate-x-full"
									>
										<Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
											<form
												onSubmit={(e) => {
													e.preventDefault();
													console.log(e);
													handleSubmit(e);
												}}
												className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
											>
												<div className="h-0 flex-1 overflow-y-auto">
													<div className="bg-cyan-700 py-6 px-4 sm:px-6">
														<div className="flex items-center justify-between">
															<Dialog.Title className="text-lg font-medium text-white">
																{role_id ? "Update" : "Add"} Role
															</Dialog.Title>
															<div className="ml-3 flex h-7 items-center">
																<button
																	type="button"
																	className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
																	onClick={() => setmodalOpenFlage(false)}
																>
																	<span className="sr-only">Close panel</span>
																	<XMarkIcon
																		className="h-6 w-6"
																		aria-hidden="true"
																	/>
																</button>
															</div>
														</div>
													</div>
													<div className="flex flex-1 flex-col justify-between">
														<div className="divide-y divide-gray-200 p-4 sm:p-6">
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="role_name_en"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Role Name English
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.role_name_en}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Role Name English"
																		name="role_name_en"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.role_name_en &&
																		touched.role_name_en ? (
																		<p className="text-red-600 text-sm">
																			{errors.role_name_en}
																		</p>
																	) : null}
																</div>
															</div>

															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="role_name_ar"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Role Name Arabic
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.role_name_ar}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Role Name Arabic"
																		name="role_name_ar"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.role_name_ar &&
																		touched.role_name_ar ? (
																		<p className="text-red-600 text-sm">
																			{errors.role_name_ar}
																		</p>
																	) : null}
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="flex flex-shrink-0 justify-end px-4 py-4">
													<button
														type="button"
														className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
														onClick={() => {
															setmodalOpenFlage(false);
														}}
													>
														Cancel
													</button>
													<button
														type="submit"
														className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
													>
														{role_id ? "Update" : "Add"}
													</button>
												</div>
											</form>
										</Dialog.Panel>
									</Transition.Child>
								</div>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			</div>
		</div>
	);
}
export default RoleList;
