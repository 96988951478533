/* eslint-disable */
import { toast } from 'react-toast'
import {
  GET_ALL_PAYMENTMODE_BEGIN,
  GET_ALL_PAYMENTMODE_SUCCESS,
  GET_ALL_PAYMENTMODE_ERROR
} from './constants'
import MasterServices from '../../ApiServices/MasterServices'

export const getAllPaymentModes = (payload) => async (dispatch) => {
  dispatch({
    type: GET_ALL_PAYMENTMODE_BEGIN
  })
  MasterServices.getPaymentModeList(payload)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {

        if (data?.data?.length > 0) {
          dispatch({
            type: GET_ALL_PAYMENTMODE_SUCCESS,
            data: data.data ? data.data : []
          })
        } else {
          toast.error("Payment Mode Not Found")
        }
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error.response.data.message;
      toast.error(msg)
    });
}
