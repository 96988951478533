import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toast'
import MasterServices from '../../../ApiServices/MasterServices'
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner'
import Table from '../../../components/tables/table'
import TableEmpty from '../../../components/tables/tableEmpty'
import { incentive_master_columns } from '../../../components/tables/tableheader'
import { ArrowUturnLeftIcon } from '@heroicons/react/20/solid'

const IncentiveMaster = () => {
    const pages = [{ title: "Incentive", href: "/incentive" }];
    const [loading, setLoading] = useState(false);
    const [incentiveData, setIncentiveData] = useState(false)
    const [roleDetails, setRoleDetails] = useState(false)
	const navigate = useNavigate();

    const { role_id } = useParams()

    useEffect(() => {
        getIncentivesByRoleID()
        getRoleDetails()
    }, [])

    const getIncentivesByRoleID = async () => {
        setLoading(true)
        try {
            let res = await MasterServices.getAllIncentivesByRoleId(role_id)
            if (res.status == 200) {
                setIncentiveData(res.data.data)
                toast.success("Incentives Fetched Successfully")
            }
            setLoading(false)
        } catch (e) {
            console.log(e)
            toast.error("Incentives not fetched")
            setLoading(false)
        }
    }

    const getRoleDetails = async () => {
        setLoading(true)
        try {
            let res = await MasterServices.getRoleDetails(role_id)
            if (res.status == 200) {
                setRoleDetails(res.data.data)
            }
            setLoading(false)
        } catch (e) {
            console.log(e)
            setLoading(false)
        }
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <Breadcrumb pages={pages} />
            <button
				type="button"
				onClick={() => navigate(-1)}
				className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
			>
				<span className="w-5 mr-2">
					<ArrowUturnLeftIcon size={18} />
				</span>{" "}
				Back
			</button>
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Incentives for {roleDetails ? roleDetails.role_name_en : ""}</h1>
            </div>
            <div className="mt-4 flex">
                <Link
                    to={`/add-incentive-master/${role_id}`}
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
                >
                    Add Incentive
                </Link>
            </div>
            {loading ? (
                <FallingLinesLoader />
            ) : incentiveData?.length > 0 ? (
                <Table
                    columns={incentive_master_columns({role_id})}
                    data={incentiveData}
                />
            ) : null}
        </div>
    )
}

export default IncentiveMaster