import Breadcrumb from '../../../components/Breadcrumb'
import React, { useEffect, useState } from 'react'
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useNavigate } from 'react-router-dom';
import SampleServices from '../../../ApiServices/SampleServices';
import { toast } from 'react-toast';
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import { sample_columns_for_receiving } from '../../../components/tables/tableheader';

const rejectionReasons = [
    { reason: "Reason 1" }, { reason: "Reason 2" }
]

const ReceiveSamples = () => {
    const pages = [
        { title: "Sample Receiving", href: "/receive-samples", module_id: 13 },
    ];
    const [count, setCount] = useState(0)
    const [sampleId, setSampleId] = useState(false);
    const [textBoxSampleId, setTextBoxSampleId] = useState(false);
    const [stopStream, setStopStream] = useState(false)
    // const [sampleDetails, setSampleDetails] = useState(false)
    const [rejectSelected, setRejectSelected] = useState(false)
    const [rejectedReason, setRejectedReason] = useState(false)
    const [allSamples, setAllSamples] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [scannedSamplesData, setScannedSamplesData] = useState(JSON.parse(localStorage.getItem("scanned_samples")))
    const [cameraLoading, setCameraLoading] = useState(true)

    const navigate = useNavigate();

    const sampleAccordion = (s) => {
        let x = document.getElementById(s);
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }

    useEffect(() => {
        setCameraLoading(true)
        getAllSamples()

        navigator.mediaDevices.getUserMedia({ video: true })
            .then(() => {
                setCameraLoading(false);
            })
            .catch((error) => {
                setCameraLoading(false);
                console.error(error);
            });

    }, [])

    useEffect(() => {
        if (sampleId) {
            getSampleDetails(sampleId)
        }
    }, [sampleId])

    useEffect(() => {

        let ls = JSON.parse(localStorage.getItem("scanned_samples"));

        if (ls != false) {
            setTimeout(() => {
                if (ls?.length == 0) {
                    setScannedSamplesData(false)
                    localStorage.removeItem("scanned_samples")
                    navigate(0);
                } else {
                    setScannedSamplesData(ls)
                }

            }, 1000)
        }
    }, [count])



    const saveDisObjFromSampleDetailsToLS = (smp) => {
        let saveObj = {}

        let { sample, patientDetails, sampleGenerated } = smp
        saveObj.sample_id = sample?.sample_id
        saveObj.sample_type_name = sample?.m_sample_type_relation?.sample_type_name_en
        saveObj.test_name_en = sampleGenerated?.t_test_relation?.test_name_en
        saveObj.test_name_ar = sampleGenerated?.t_test_relation?.test_name_ar

        let prevData = JSON.parse(localStorage.getItem("scanned_samples"))
        // console.log(prevData)

        let newData

        if (prevData) {
            let present = false;
            prevData.forEach((s) => {
                if (smp.sample.sample_id == s.sample_id) {
                    present = true;
                }
            })
            if (!present) {
                newData = [...prevData, saveObj]

            } else {
                toast.error("Sample Already Scanned - Scan New Sample")
                newData = prevData
            }
        } else {
            newData = [saveObj]
        }
        setScannedSamplesData(newData)

        localStorage.setItem("scanned_samples", JSON.stringify(newData))
        setTimeout(() => {
            navigate(0)
        }, 1500)
    }

    const getSampleDetails = async (sample_id) => {
        try {
            SampleServices.getSampleDetails(sample_id).then((res) => {
                // console.log(res)
                if (res.status == 200) {
                    toast.success(res.data.message)
                    saveDisObjFromSampleDetailsToLS(res.data.data)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    const getAllSamples = async () => {
        try {
            setIsLoading(true)
            SampleServices.getAllSamplesForReceiving().then((res) => {
                // console.log(res)
                if (res.status == 200) {
                    setAllSamples(res.data.data)
                }
            })
            setIsLoading(false)
        } catch (e) {
            console.log(e)
            setIsLoading(false)
        }
    }

    const handleLSChange = async (res,) => {
        let ls = JSON.parse(localStorage.getItem("scanned_samples"))

        function checkSId(s) {
            if (s.sample_id != res.data?.data?.sample_id) {
                return s
            }
        }
        let newLs = ls.filter(checkSId)

        if (newLs && newLs.length != 0) {
            localStorage.setItem("scanned_samples", JSON.stringify(newLs))
            setCount(count+1)
        } else {
            // console.log("newLs", newLs)
            localStorage.removeItem("scanned_samples")
            setCount(count+1)
        }
    }

    const handleReceiveSample = async (s_id) => {
        try {
            SampleServices.receiveSampleAtLab(s_id).then((res) => {
                // console.log(res)
                if (res.status == 200) {
                    // setSampleDetails(res.data.data)
                    // console.log("Sample Received Successfully")
                    toast.success(res.data.message)
                    handleLSChange(res)
                }
            })
        } catch (e) {
            console.log(e)
            toast.error(e)
        }
    }

    const handleRejectSample = async (s_id) => {
        try {
            SampleServices.rejectSampleByLab(s_id).then((res) => {
                // console.log(res)
                if (res.status == 200) {
                    // console.log("Sample Rejected")
                    toast.success(res.data.message);
                    setRejectedReason(false)
                    setRejectSelected(false)
                    handleLSChange(res)
                    // console.log(res.data)
                    // let ls = JSON.parse(localStorage.getItem("scanned_samples"))

                    // let newLs = ls.filter(checkSId)

                    // function checkSId(s){
                    //     if(s.sample_id != res.data?.data?.sample_id){
                    //         return s
                    //     }
                    // }
                    // console.log("newLs", newLs)
                    // setRejectedReason(false)
                    // localStorage.setItem("scanned_samples", JSON.stringify(newLs))
                    //  // navigate(0);
                }
            })
        } catch (e) {
            console.log(e)
            toast.error(e)
        }
    }

    return (
        <div>
            <Breadcrumb pages={pages} />
            <div className="flex mt-1 sm:col-span-2 sm:mt-0">
                <input
                    autoFocus
                    value={textBoxSampleId}
                    onChange={(e) => {
                        setTextBoxSampleId(e.target.value);
                    }}
                    type="number"
                    placeholder="Enter Sample Id"
                    name="textbox_sample_id"
                    autoComplete="off"
                    className="block w-52 mb-3 max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:text-sm"
                />

                <div className="justify-end px-4">
                    <button
                        onClick={() => {
                            getSampleDetails(textBoxSampleId)
                        }}
                        type="button"
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                    >
                        Fetch Sample Details
                    </button>
                </div>
            </div>

            {cameraLoading ? <FallingLinesLoader /> :
                <div className='rounded basis-1/2'>
                    <div className="mb-4">
                        <BarcodeScannerComponent
                            width={500}
                            height={600}
                            onUpdate={(err, result) => {
                                if (result) {
                                    setSampleId(result.text);
                                    // console.log("result", result)
                                    setStopStream(true)
                                }
                            }}
                            stopStream={stopStream}
                        />
                    </div>

                    {/* {data ? <div className="my-4 sm:mt-0 sm:flex justify-center">
                    <button
                        type="button"
                        // onClick={()=>{}}q
                        className="block rounded-md bg-cyan-600 py-1.5 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                    >
                        Start Scanner
                    </button>
                </div> : null} */}
                </div>
            }

            <div className="scanner flex">
                <div className="scan-data basis-1/2">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h1 className="text-base font-semibold leading-6 text-gray-900 text-center">Sample Receiving</h1>
                            </div>
                        </div>

                        {scannedSamplesData && scannedSamplesData.length > 0 ? (
                            <>
                                {scannedSamplesData.map((ss) => {
                                    return (
                                        <div className="mt-4 border border-gray-300 rounded-lg">
                                            <div className="border-b border-gray-200">
                                                <button
                                                    onClick={() => {
                                                        let sam = `sample-details-${ss.sample_id}`
                                                        sampleAccordion(sam)
                                                    }} className="flex justify-between items-center w-full px-4 py-2 text-sm font-medium text-left text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 transition duration-150 ease-in-out">
                                                    <span>Sample {ss.sample_id} </span>
                                                    <svg className="w-10 h-8 fill-current text-gray-500" viewBox="0 0 20 20">
                                                        <path d="M6.928 9.172a.5.5 0 0 1 .707 0L10 11.793l2.364-2.364a.5.5 0 0 1 .707.707l-2.5 2.5a.5.5 0 0 1-.707 0l-2.5-2.5a.5.5 0 0 1 0-.707z"></path>
                                                    </svg>
                                                </button>
                                            </div>

                                            <div className="px-4 py-2 hidden" id={`sample-details-${ss.sample_id}`}>
                                                <p className="text-gray-700">
                                                    <div className="flow-root">
                                                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                                                    <table className="min-w-full divide-y divide-gray-300">
                                                                        <tbody className="divide-y divide-gray-200 bg-white">
                                                                            <tr>
                                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                                    Sample ID
                                                                                </td>
                                                                                <td className="break-all px-3 py-4 text-sm text-gray-500">{ss.sample_id}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                                    Sample Type
                                                                                </td>
                                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{ss.sample_type_name
                                                                                }</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                                    Test Name
                                                                                </td>
                                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{ss.test_name_en

                                                                                }</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                                    Test Name Arabic
                                                                                </td>
                                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{ss.test_name_ar
                                                                                }</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>


                                                                    {rejectSelected ?
                                                                        (<select
                                                                            className="ml-6 my-3 justify-center block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                                                            name="reason"
                                                                            id="reason"
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value)
                                                                                setRejectedReason(e.target.value)
                                                                            }}
                                                                        >
                                                                            <option value={null}> Select Reason </option>
                                                                            {rejectionReasons?.map((r, i) => (
                                                                                <option
                                                                                    key={i}
                                                                                    value={r.reason}
                                                                                >
                                                                                    {r.reason}
                                                                                </option>
                                                                            ))}
                                                                        </select>)
                                                                        :
                                                                        null}
                                                                    <div className="my-4 sm:mt-0 sm:flex justify-center">
                                                                        <button
                                                                            onClick={(e) => {
                                                                                if (e.target.innerText == "Confirm Rejection" && rejectedReason && rejectedReason != "Select Reason") {
                                                                                    handleRejectSample(ss.sample_id)
                                                                                } else {
                                                                                    setRejectSelected(true)
                                                                                }
                                                                            }}
                                                                            type="button"
                                                                            className="block rounded-md bg-red-500 py-1.5 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                                                                        >
                                                                            {rejectSelected ? "Confirm Rejection" : "Reject Sample"}
                                                                        </button>

                                                                        <button
                                                                            onClick={() => {
                                                                                handleReceiveSample(ss.sample_id)
                                                                            }}
                                                                            type="button"
                                                                            className="ml-4  block rounded-md bg-cyan-600 py-1.5 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                                                                        >
                                                                            Receive Sample
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        ) : null}
                    </div>
                </div>
            </div>

            {!allSamples && isLoading ? (
                <FallingLinesLoader />
            ) : (
                <Table
                    zoom='zoom-075'
                    columns={sample_columns_for_receiving()}
                    data={allSamples.length > 0 ? allSamples : []}
                />
            )}


        </div>
    )
}

export default ReceiveSamples