import { useState, useEffect } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon, PencilIcon } from '@heroicons/react/20/solid'
import { FaStar } from 'react-icons/fa';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AddressRadioGroup({ userAddresses, selectedAddress, setSelectedAddress, setOpenAddressDialog }) {
  useEffect(() => {
    if (userAddresses) {
      setSelectedAddress(userAddresses[0])
    }
  }, [])

  useEffect(() => {
    console.log("selectedAddress", selectedAddress )
  },[selectedAddress])

  return (
    <RadioGroup value={selectedAddress} onChange={setSelectedAddress}>
      <RadioGroup.Label className="mt-5 leading-6 block text-sm font-medium text-gray-700">
        Select an Address
      </RadioGroup.Label>

      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-x-4">
        {userAddresses.map((address) => (
          <RadioGroup.Option
            key={address.id}
            value={address}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'border-cyan-500 ring-2 ring-cyan-500' : '',
                'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                      Recipient : {address.ua_recipient}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                      <div className="text-gray-600 text-sm leading-6">
                        {/* <div className="flex items-center">
                          <p className="ml-2">Recipient : {address.ua_recipient}</p>
                        </div> */}
                        <div className="flex items-center">
                          <p className="ml-2">Building : {address.ua_building}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="ml-2">Neighbourhood : {address.ua_neighborhood}</p>
                        </div>
                        {/* <div className="flex items-center">
                          <p className="ml-2">Latitude : {address.ua_lat}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="ml-2">Longitude : {address.ua_long}</p>
                        </div> */}
                        <div className="flex items-center">
                          <p className="ml-2">Street : {address.ua_street}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="ml-2">Postal Code : {address.ua_postalCode}</p>
                        </div>
                        <div className="justify-items-end">
                          <PencilIcon
                            className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-cyan-600')}
                            aria-hidden="true"
                            onClick={()=>{
                              setOpenAddressDialog(true);
                            }}
                          />
                        </div>
                      </div>

                    </RadioGroup.Description>

                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-cyan-600')}
                  aria-hidden="true"
                />

                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-cyan-500' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}

const prevCode = (userAddresses) => {
  return (
    <div className="gap-5 mt-3">
      <div className="basis-1/3">
        <div className="grid grid-flow-row-dense gap-4 grid-cols-4 ...">
          {
            userAddresses.map((address, key) => {
              return (
                <div className="col-span-1">
                  <div className="booking-user-details-s flex justify-between">
                    <div className="sample-main-div">
                      <p className="font-semibold text-sm text-gray-600 sample-details-head mb-2">
                        Address {key + 1}
                      </p>
                      <div className="text-gray-600 text-sm leading-6">
                        <div className="flex items-center">
                          <p className="ml-2">Recipient : {address.ua_recipient}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="ml-2">Building : {address.ua_building}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="ml-2">Latitude : {address.ua_lat}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="ml-2">Longitude : {address.ua_long}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="ml-2">Street : {address.ua_street}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="ml-2">Postal Code : {address.ua_postalCode}</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              )
            })
          }

        </div>
      </div>
    </div>
  )
}
