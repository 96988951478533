import React from "react";

 function DropDownSelector({
  title = "select a the element",
  value,
  data = [],
  onChange,
  height = "h-28",
}) {
  const [state, setState] = React.useState(false);
  return (
    <fieldset id="dropdown" className="relative border-none z-10">
      <button
        type="button"
        onClick={() => setState(!state)}
        className="bg-white h-6 w-full border border-gray-300 py-4 rounded  text-black flex justify-center items-center"
        id="state-dropdown"
      >
        {value.length > 0
          ? value.length == data.length
            ? "All Selected "
            : value.map((item) => item.name).join(", ")
          : title}
      </button>
      {state && (
        <div
          className={`absolute bg-white rounded border mt-2 ${height} overflow-y-scroll border-gray-300 text-black p-2 w-full z-40`}
          id="panel"
        >
          {data.map((item, idx) => (
            <fieldset
              key={idx}
              className="flex flex-row mb-2 justify-start border border-gray-300 px-2 rounded py-2 items-center gap-4"
            >
              <input
                onChange={(e) => {
                  if (e.target.checked) {
                    onChange([
                      ...value,
                      {
                        id: e.target.value,
                        name: e.target.name,
                      },
                    ]);
                  } else {
                    onChange(
                      value.filter((item) => item.id !== e.target.value)
                    );
                  }
                }}
                type="checkbox"
                id={item.id}
                name={item.name}
                value={item.id}
                className=""
              />
              <label className="w-full" htmlFor={item.id}>{item.name}</label>
            </fieldset>
          ))}
        </div>
      )}
    </fieldset>
  );
}

export default DropDownSelector;