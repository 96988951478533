import React from "react";
import PropTypes from "prop-types";
import { classNames } from "./utils";

const CustomButton = ({
  children,
  backgroundColor = "bg-[#0891b2]",
  textColor = "text-white",
  onClick,
  ...props
}) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        backgroundColor,
        textColor,
        "px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-opacity-75"
      )}
      {...props}
    >
      {children}
    </button>
  );
};

CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default CustomButton;
