import React, { useState, useEffect } from 'react'
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import Breadcrumb from '../../../components/Breadcrumb';
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import MasterServices from "../../../ApiServices/MasterServices";
import makeAnimated from 'react-select/animated';
import { getAllRoles } from '../../../redux/role/actions';
import { useSelector, useDispatch } from 'react-redux';
import { getAllTests } from "../../../redux/test/actions";
import { getAllPackages } from "../../../redux/package/actions";
import { getAllLocations } from "../../../redux/location/actions.js"
import { useFormik } from 'formik';
import { incentiveSchema } from '../../../schemas';
import { toast } from 'react-toast';


const animatedComponents = makeAnimated();

const AddIncentiveMaster = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { role_id, inct_id } = useParams()
    // console.log("inct_id", inct_id)
    const pages = [{ title: inct_id ? `Update Incentive` : `Add Incentive`, href: `/add-incentive-master/${role_id}` }];

    const [incentiveId, setIncentiveId] = useState(false)
    const [roleDetails, setRoleDetails] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [incentiveDetails, setIncentiveDetials] = useState(false)

    const  [availablePackages, setAvailablePackages] = useState(false)
    const  [availableTests, setAvailableTests] = useState(false)

    const [packages, setPackages] = useState(false)
    const [locations, setLocations] = useState(false)
    const [tests, setTests] = useState(false)

    const {
        test: { loading: tLoading, list: tList },
        package: { loading: pLoading, list: pList },
        location: { loading: lLoading, list: l_List }
    } = useSelector((state) => state);

    useEffect(() => {
        document.title = inct_id ? "New Labib | Update Incentive" : "New Labib | Add Incentive";
        dispatch(getAllRoles());
        getRoleDetails();
        getAvailablePackages();
        getAvailableTests();
        dispatch(getAllLocations())
        // dispatch(getAllPackages('active'));
        // dispatch(getAllTests());
        if (role_id && inct_id) {
            getIncentiveDetails()
        }
    }, []);

    useEffect(() => {
        if (incentiveDetails && !incentiveDetails.modified) {
            handleIncentiveDetailsData()
        }
    }, [incentiveDetails])

    const getRoleDetails = async () => {
        setIsLoading(true)
        try {
            let res = await MasterServices.getRoleDetails(role_id)
            if (res.status == 200) {
                setRoleDetails(res.data.data)
                // console.log("Role Details", res.data.data)
            }
            setIsLoading(false)
        } catch (e) {
            console.log(e)
            setIsLoading(false)
        }
    }

    const getAvailablePackages = async () => {
        try {
            setIsLoading(true)
            console.log("Get Available Packages")
            const res = await MasterServices.getAvailablePackages(role_id)
            if (res.status == 200) {
                let avlPack = res.data?.data
                if (avlPack) {
                    avlPack.forEach((p)=>{
                        p.value = p.package_id
                        p.label = p.package_name_en
                    })
                    setAvailablePackages(avlPack)
                }
            }
            // console.log("addIncentive Response", res)
            setIsLoading(false)
        } catch (error) {
            console.log("getAvailablePackages", error);
            setIsLoading(false)
        }
    }

    const getAvailableTests = async () => {
        try {
            setIsLoading(true)
            console.log("Get Available Tests")
            const res = await MasterServices.getAvailableTests(role_id)
            if (res.status == 200) {
                let avlTests = res.data?.data
                if (avlTests) {
                    avlTests.forEach((t)=>{
                        t.value = t.test_id
                        t.label = t.test_name_en
                    })
                    setAvailableTests(avlTests)
                }
            }
            setIsLoading(false)
        } catch (error) {
            console.log("getAvailableTests", error);
            setIsLoading(false)
        }
    }

    const handleIncentiveDetailsData = () => {
        setIsLoading(true)

        let newData = { ...incentiveDetails, modified: true }

        for (let i = 0; i < newData.tests?.length; i++) {
            newData.tests[i].test_id = newData.tests[i].test_relation?.test_id
            newData.tests[i].value = newData.tests[i].test_relation?.test_id
            newData.tests[i].label = newData.tests[i].test_relation?.test_name_en
        }
        for (let i = 0; i < newData.packages?.length; i++) {
            newData.packages[i].package_id = newData.packages[i].package_relation?.package_id
            newData.packages[i].value = newData.packages[i].package_relation?.package_id
            newData.packages[i].label = newData.packages[i].package_relation?.package_name_en
        }
        for (let i = 0; i < newData.locations?.length; i++) {
            newData.locations[i].loc_id = newData.locations[i].location_relation?.loc_id
            newData.locations[i].value = newData.locations[i].location_relation?.loc_id
            newData.locations[i].label = newData.locations[i].location_relation?.loc_name_en
        }
        setIncentiveDetials(newData)
        setTests(newData.tests)
        setPackages(newData.packages)
        setLocations(newData.locations)

        setIsLoading(false)
    }

    let initialValues = {
        inct_value: incentiveDetails ? incentiveDetails.inct_value : "",
        inct_from_date: incentiveDetails ? incentiveDetails.inct_from_date.split("T")[0] : "",
        inct_to_date: incentiveDetails ? incentiveDetails.inct_to_date.split("T")[0] : "",
    };

    // const [formIncentives, setFormIncentives] = useState(initialValues);

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            enableReinitialize: true,
            initialValues: initialValues,
            validationSchema: incentiveSchema,
            onSubmit: (values, action) => {

                let body = {
                    ...values,
                    fk_package_ids: packages,
                    fk_location_ids: locations,
                    fk_test_ids: tests,
                    fk_role_id: role_id
                };
                if (role_id && inct_id) {
                    body.inct_id = inct_id
                    let incentiveUpdate = updateIncentive(body)
                } else {
                    let incentiveAdd = addIncentive(body)
                }

                action.resetForm();

                setTimeout(() => {
                    navigate(`/incentive/${role_id}`);
                }, 1000)
            },
        });

    const addIncentive = async (payload) => {
        try {
            setIsLoading(true)
            let body = payload
            const res = await MasterServices.addIncentive(body, incentiveId == undefined || incentiveId == null || incentiveId == "" ? "" : incentiveId)
            if (res.status) {
                toast.success("Incentive Saved Successfully")
            }
            setIsLoading(false)
        } catch (error) {
            console.log("addIncentive", error);
            setIsLoading(false)
        }
    };

    const updateIncentive = async (payload) => {
        try {
            setIsLoading(true)
            let body = payload
            const res = await MasterServices.updateIncentive(body)
            if (res.status) {
                toast.success("Incentive Updated Successfully")
            }
            setIsLoading(false)
        } catch (error) {
            console.log("updateIncentive", error);
            setIsLoading(false)
        }
    };

    const getIncentiveDetails = async () => {
        try {
            setIsLoading(true)
            const res = await MasterServices.getIncentiveDetails(role_id, inct_id)
            if (res.status == 200) {
                toast.success("Incentive Details Fetched Successfully")
                let inctDet = res.data?.data
                // console.log(inctDet, "inctDet")
                if (inctDet) {
                    // console.log("inctDet.inct_value", inctDet.inct_value)
                    setIncentiveDetials(inctDet)
                }
            }
            // console.log("addIncentive Response", res)
            setIsLoading(false)
        } catch (error) {
            console.log("addIncentive", error);
            setIsLoading(false)
        }
    };

    return (
        <div className="px-4 sm:px-6 lg:px-8 h-screen">
            <Breadcrumb pages={pages} />
                <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                >
                    <span className="w-5 mr-2">
                        <ArrowUturnLeftIcon size={18} />
                    </span>{" "}
                    Back
                </button>
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">
                        {inct_id ? `Update Incentive for ${roleDetails?.role_name_en}` : `Add Incentive for ${roleDetails?.role_name_en}`}
                    </h1>
                </div>
            {isLoading || tLoading || pLoading || lLoading ? (
                <FallingLinesLoader />
            ) : (
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                    }}
                    className="space-y-8"
                >
                    <div className="space-y-6 sm:space-y-5">
                        <div className="space-y-6 sm:space-y-5 sm:pt-5">
                            <div className="space-y-6 sm:space-y-5">
                                <div className="flex gap-5 mt-3">
                                    <div className='basis-1/2'>
                                        <div>
                                            <label
                                                htmlFor="inct_value"
                                                className="block text-sm font-medium text-gray-900"
                                            >
                                                Incentive Value
                                            </label>
                                        </div>
                                        <input
                                            type="number"
                                            name="inct_value"
                                            id="inct_value"
                                            placeholder="Enter Incentive Value"
                                            value={values.inct_value}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="block w-full mr-4 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                        />
                                        {errors.inct_value &&
                                            touched.inct_value ? (
                                            <p className="text-red-600 text-sm">
                                                {errors.inct_value}
                                            </p>
                                        ) : null}
                                    </div>
                                    <div className='basis-1/2'>
                                        <div>
                                            <label
                                                htmlFor="inct_from_date"
                                                className="block text-sm font-medium text-gray-900"
                                            >
                                                From Date
                                            </label>
                                        </div>
                                        <input
                                            type="date"
                                            name="inct_from_date"
                                            id="inct_from_date"
                                            placeholder="Enter From Date"
                                            value={values.inct_from_date}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="block w-full mr-4 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                        />
                                        {errors.inct_from_date &&
                                            touched.inct_from_date ? (
                                            <p className="text-red-600 text-sm">
                                                {errors.inct_from_date}
                                            </p>
                                        ) : null}
                                    </div>
                                    <div className='basis-1/2'>
                                        <div>
                                            <label
                                                htmlFor="inct_to_date"
                                                className="block text-sm font-medium text-gray-900"
                                            >
                                                To Date
                                            </label>
                                        </div>
                                        <input
                                            type="date"
                                            name="inct_to_date"
                                            id="inct_to_date"
                                            placeholder="Enter To Date"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.inct_to_date}
                                            className="block w-full mr-4 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                        />
                                        {errors.inct_to_date &&
                                            touched.inct_to_date ? (
                                            <p className="text-red-600 text-sm">
                                                {errors.inct_to_date}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="flex gap-5 mt-4">
                                    <div className='basis-1/2'>
                                        <div>
                                            <label
                                                htmlFor="loc_id"
                                                className="block text-sm font-medium text-gray-900"
                                            >
                                                Locations
                                            </label>
                                        </div>
                                        <Select
                                            name="locations"
                                            id="locations"
                                            placeholder="Select Locations"
                                            isMulti
                                            value={locations}
                                            onChange={(e) => setLocations(e)}
                                            //   onBlur={handleBlur}
                                            components={animatedComponents}
                                            options={l_List}
                                            classNamePrefix="select"
                                            className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                        />
                                    </div>
                                    <div className='basis-1/2'>
                                        <div>
                                            <label
                                                htmlFor="tests"
                                                className="block text-sm font-medium text-gray-900"
                                            >
                                                Tests
                                            </label>
                                        </div>
                                        <Select
                                            id="tests"
                                            name="tests"
                                            isMulti
                                            placeholder="Select Tests"
                                            value={tests}
                                            components={animatedComponents}
                                            onChange={(e) => setTests(e)}
                                            options={availableTests}
                                            className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                        />
                                    </div>
                                    <div className='basis-1/2'>
                                        <div>
                                            <label
                                                htmlFor="packages"
                                                className="block text-sm font-medium text-gray-900"
                                            >
                                                Packages
                                            </label>
                                        </div>
                                        <Select
                                            id="packages"
                                            name="packages"
                                            placeholder="Select Packages"
                                            isMulti
                                            value={packages}
                                            components={animatedComponents}
                                            onChange={(e) => setPackages(e)}
                                            options={availablePackages}
                                            className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-5">
                        <div className="flex justify-end">
                            <button
                                type="submit"
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            >
                                {role_id && inct_id ? "Update Incentive" : "Add Incentive"}
                            </button>
                        </div>
                    </div>
                </form>
            )}
            </div>
        </div>
    )
}

export default AddIncentiveMaster