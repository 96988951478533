import React, { useState } from 'react'
import Breadcrumb from '../../../components/Breadcrumb';
import { outSourcingReportSchema } from '../../../schemas';
import { useFormik } from 'formik';
import { outsourcing_report } from '../../../components/tables/tableheader';
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
const initialValues = {
    date_from: "",
    date_to: "",
    medical_complex: "",
    reservation_id: "",
}
const OutSourcingReport = () => {
    const pages = [{ title: "Outsourcing Report", href: "/outsourcing-report" }];
    const [formState, setFormState] = useState(initialValues);
    const [loading, setLoading] = useState(false)
    const outsourcingReport = [
        // {
        //     test_id: "2",
        //     test: "CBC",
        //     patient_name: "Nitesh",
        //     payment_method: "UPI",
        //     net_total: "2000",
        //     paid: "2000",
        //     cash: "0",
        //     visa: "0",
        //     remaining: "0",
        //     status: true,
        //     date: "23-10-2022",
        //     paid_by: "nitesh",
        //     open_close: "open"
        // }
    ]
    const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
        useFormik({
            enableReinitialize: true,
            initialValues: formState,
            validationSchema: outSourcingReportSchema,
            onSubmit: async (values, action) => {
                console.log(values, "values")
            },
        });

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <Breadcrumb pages={pages} />
            <div className="mb-5">
                <h1 className="text-xl font-semibold text-gray-900">Outsourcing Report</h1>
                <p className="mt-2 text-sm text-gray-700">A list of all the Outsourcing Report.</p>
            </div>
            <div>
                <div className="flex sm:border-t sm:border-gray-200 sm:pt-5 gap-5">
                    <div className="basis-1/3">
                        <label htmlFor="date_from" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            Date From
                        </label>
                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <input
                                type="date"
                                name="date_from"
                                id="date_from"
                                autoComplete="off"
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                value={values.date_from}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.date_from && touched.date_from ? (
                                <p className="text-red-600 text-sm">{errors.date_from}</p>
                            ) : null}
                        </div>
                    </div>

                    <div className="basis-1/3">
                        <label htmlFor="date_to" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            Date To
                        </label>
                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <input
                                type="date"
                                name="date_to"
                                id="date_to"
                                autoComplete="phone"
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                value={values.date_to}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.date_to && touched.date_to ? (
                                <p className="text-red-600 text-sm">{errors.date_to}</p>
                            ) : null}
                        </div>
                    </div>
                    <div className="basis-1/3">
                        <label htmlFor="medical_complex" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            Medical Complex
                        </label>
                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <input
                                type="text"
                                name="medical_complex"
                                id="medical_complex"
                                autoComplete="off"
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                value={values.medical_complex}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.medical_complex && touched.medical_complex ? (
                                <p className="text-red-600 text-sm">{errors.medical_complex}</p>
                            ) : null}
                        </div>
                    </div>
                    <div className="basis-1/3">
                        <label htmlFor="reservation_id" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            Reservation ID
                        </label>
                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <input
                                type="text"
                                name="reservation_id"
                                id="reservation_id"
                                autoComplete="off"
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                value={values.reservation_id}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.reservation_id && touched.reservation_id ? (
                                <p className="text-red-600 text-sm">{errors.reservation_id}</p>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className='mt-5 text-right'>
                    <button
                        type="submit"
                        onClick={(e) => handleSubmit(e)}
                        className="inline-flex justify-center rounded-md bg-cyan-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                    >
                        Submit
                    </button>
                </div>
            </div>
            {
                loading ? (
                    <FallingLinesLoader />
                ) : (
                    <Table
                        columns={outsourcing_report()}
                        data={outsourcingReport}
                    />)
            }
        </div>
    )
}

export default OutSourcingReport