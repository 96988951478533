
import Api from './Api';

export default {

	async getAllAnalyzerTestList(payload) {
		const response = await Api().get(`api/dashboard/masters/test/all/analyzer/testlist/?${payload}`);
		return response;
	},

	async getAllQCLotTestParams(payload) {
		const response = await Api().post(`api/dashboard/quality-control/lot/lot-test-params/`,payload);
		return response;
	},
}