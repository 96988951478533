/* eslint-disable import/no-anonymous-default-export */
import Api from './Api';

export default {
    async getSalesDataForChart(payload) {
        let { start_date, end_date, pay_mode_id, location_ids} = payload
        let data = {location_ids}
        const response = await Api().post(`api/dashboard/sales/range?start_date=${start_date}&end_date=${end_date}&pay_mode_id=${pay_mode_id}`, data);
        return response;
    },
    async getPackagesSoldDataForChart(payload) {
        let { start_date, end_date, location_id, package_ids} = payload
        let data = {location_id, package_ids}
        const response = await Api().post(`api/dashboard/sales/package?start_date=${start_date}&end_date=${end_date}`, data);
        return response;
    },

    async getTestsSoldDataForChart(payload){
        let { start_date, end_date, location_id, test_ids} = payload
        let data = {location_id, test_ids}
        const response = await Api().post(`api/dashboard/sales/test?start_date=${start_date}&end_date=${end_date}`, data);
        return response;
    },

    async getRegionSalesData(payload){
        let {start_date, end_date} = payload
        const response = await Api().post(`api/dashboard/sales/region?start_date=${start_date}&end_date=${end_date}`);
        return response;
    },

    async getCardStats(payload){
        const response = await Api().post(`api/dashboard/sales/getCardStats`, payload);
        return response;
    }
};
