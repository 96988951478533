import React from 'react'
import { Line } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Legend,
    Title,
    Tooltip,
    SubTitle,
} from 'chart.js'

ChartJS.register(
    BarElement,
    LinearScale,
    CategoryScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    SubTitle,
    Title
)

export default function TestSalesChart({ numberOfReservationsData, salesLabel, yText }) {

    const options = {
        // responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: 'Tests Sold',
            },
        },

        scales: {
            y: {
                beginAtZero: true,
                // display: false,
                ticks: {
                    display: true,
                },
                title: {
                    display: true,
                    //   text: yText,
                },
            },
            //       x: {
            //         title: {
            //           display: true,
            //           text: 'Host App',
            //         },
            //       },
        },

    };

    let labels = salesLabel;

    const data = {
        labels,
        datasets: [
            {
                label: 'Number of Tests Sold',
                data: numberOfReservationsData,

                borderColor: 'rgb(255, 192, 23)',
                backgroundColor: 'rgba(255, 192, 23, 0.4)',
            },
        ],
    };

    return (
        <div className="chart-div">
            <Line options={options} data={data} />
        </div>
    )
}