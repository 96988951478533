import React from "react";

export default function ReferenceRangeAddMultiple({
  symbols,
  allPhasesArr,
  setAllPhasesArr,
  onePhaseObj,
  newObjectTest,
}) {

  return (
    <div className="">
      {/* <div>Abhi SubReferenceRangePhasesComponent</div> */}
      <div>
        {Array.isArray(allPhasesArr) && allPhasesArr.length > 0 && allPhasesArr.map((phaseName, phaseIndex) => {
          return (
            <div key={phaseIndex} className="border-t-2 pt-2">
              {/* <h3>{phaseName.name} (Phase Name)</h3> */}
              <input
                type="text"
                value={phaseName.name}
                onChange={(e) => {
                  const newPhaseName = e.target.value;
                  let updatedPhasesArr = [...allPhasesArr];
                  console.log(updatedPhasesArr[0], phaseIndex);
                  updatedPhasesArr[phaseIndex] = {
                    ...updatedPhasesArr[phaseIndex],
                    name: newPhaseName,
                  };
                  setAllPhasesArr(updatedPhasesArr);
                }}
                placeholder="Enter Phase Name"
                className="text-gray-700 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
              />
            </div>
          );
        })}
      </div>

      <div className="justify-center flex mt-2">
        <button
          type="button"
          className="ml-3  rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 w-80"
          onClick={() => {
            setAllPhasesArr((prev) => {
              return [
                ...prev,
                {
                  id: prev.length + 1,
                  name: `Option ${prev.length + 1}`,
                }
              ];
            });
          }}
        >
          Add Entry
        </button>
        <button
          type="button"
          className="ml-3  rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 w-80"
          onClick={() => {
            setAllPhasesArr((prev) => {
              prev.pop();
              let arr = [...prev];
              return arr
            });
            console.log(allPhasesArr);
          }}
        >
          Remove Entry
        </button>
      </div>
    </div>
  );
}
