import React, { useRef, useState, useEffect } from 'react'
import Breadcrumb from "../../../components/Breadcrumb";
// import { Editor } from '@tinymce/tinymce-react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';



const GlobalSetting = () => {
    const pages = [{ title: "Global Setting", href: "/settings", module_id: 38 }];

    const mainItems = [
        {
            id: Date.now(),
            tax_name: "",
            tax_type: "",
            tax_value: "",
        },
    ];
    const [tax, setTax] = useState(mainItems)
    const addMore = () => {
        console.log("nitesh - log");
        let obj = {
            id: Date.now(),
            tax_name: "",
            tax_type: "",
            tax_value: "",
        }
        setTax([...tax, obj])
    }

    const removeFields = (key) => {
        console.log(key);
        const data = [...tax];
        data.splice(key, 1);
        setTax(data);
    }

    const navigate = useNavigate();
    let initialValues = {
        setting_title: "",
        setting_desc: "",
        setting_phone_1: "",
        setting_phone_2: "",
        setting_email: "",
        setting_support_email: "",
        setting_address_1: "",
        setting_address_2: "",
        setting_tax: tax,
        setting_privacy_policy: "",
        setting_term_condition: "",
    };

    const [formState, setFormState] = useState(initialValues);

    const [termsCondition, setTermsCondition] = useState('')
    const [privacyPolicy, setPrivacyPolicy] = useState('')

    const termsRef = useRef(null);
    const termsValue = () => {
        if (termsRef.current) {
           const data = termsRef.current.getContent();
            setTermsCondition(data)
        }
    };
    const privacyRef = useRef(null);
    const privacyValue = () => {
        if (privacyRef.current) {
            const data = privacyRef.current.getContent();
            setPrivacyPolicy(data)
        }
    };
    const handleSubmit = () => {
        console.log("Nitesh")
    }
    return (
        <div>
            <Breadcrumb pages={pages} />
            <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Global Setting</h3>
                </div>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="grid grid-cols-6 gap-3">
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                                Title
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    type="text"
                                    name="setting_title"
                                    id="setting_title"
                                    autoComplete="given-name"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                />
                            </div>
                        </div>
                        
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="tax_name" className="block text-sm font-medium text-gray-700">
                                Tax Name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    // value={values.setting_phone_1}
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    type="text"
                                    name="tax_name"
                                    id="tax_name"
                                    autoComplete="tax_name"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                />
                            </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="tax_type" className="block text-sm font-medium text-gray-700">
                                Tax Type
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    // value={values.setting_phone_1}
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    type="text"
                                    name="tax_type"
                                    id="tax_type"
                                    autoComplete="tax_type"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                />
                            </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="tax_value" className="block text-sm font-medium text-gray-700">
                                Tax Value
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    // value={values.setting_phone_1}
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    type="text"
                                    name="tax_value"
                                    id="tax_value"
                                    autoComplete="tax_value"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="setting_phone_1" className="block text-sm font-medium text-gray-700">
                                Phone 1
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    // value={values.setting_phone_1}
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    type="number"
                                    name="setting_phone_1"
                                    id="setting_phone_1"
                                    autoComplete="given-name"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="setting_phone_2" className="block text-sm font-medium text-gray-700">
                                Phone 2
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    // value={values.setting_phone_2}
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    type="number"
                                    name="setting_phone_2"
                                    id="setting_phone_2"
                                    autoComplete="given-name"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="setting_email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    // value={values.setting_email}
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    id="setting_email"
                                    name="setting_email"
                                    type="email"
                                    autoComplete="setting_email"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="setting_support_email" className="block text-sm font-medium text-gray-700">
                                Support Email address
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    // value={values.setting_support_email}
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    id="setting_support_email"
                                    name="setting_support_email"
                                    type="email"
                                    autoComplete="setting_support_email"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="setting_address_1" className="block text-sm font-medium text-gray-700">
                                Address 1
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    // value={values.setting_address_1}
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    type="text"
                                    name="setting_address_1"
                                    id="setting_address_1"
                                    autoComplete="setting_address_1"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                />
                            </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="setting_address_2" className="block text-sm font-medium text-gray-700">
                                Address 2
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    // value={values.setting_address_2}
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    type="text"
                                    name="setting_address_2"
                                    id="setting_address_2"
                                    autoComplete="setting_address_2"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="setting_term_condition" className="block text-sm font-medium text-gray-700">
                                Terms and Condition
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <Editor
                                    onInit={(evt, editor) => {
                                        console.log("editor", editor)
                                        return termsRef.current = editor
                                    }}
                                    // value={values.setting_term_condition}
                                    // initialValue={values.setting_term_condition}
                                    // onChange={termsValue}
                                    // onBlur={handleBlur}
                                    // initialValue={values.setting_term_condition}
                                    init={{
                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                                <p className="mt-2 text-sm text-gray-500">Write a few sentences about yourself.</p>
                            </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="privacy-policy" className="block text-sm font-medium text-gray-700">
                                Privacy Policy
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <Editor
                                    // onChange={privacyValue}
                                    // onBlur={handleBlur}
                                    // onInit={(evt, editor) => privacyRef.current = editor}
                                    // initialValue={values.setting_privacy_policy}
                                    init={{
                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                                <p className="mt-2 text-sm text-gray-500">Write a few sentences about yourself.</p>
                            </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="setting_desc" className="block text-sm font-medium text-gray-700">
                                Description
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <textarea
                                    // value={values.setting_desc}
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    id="setting_desc"
                                    name="setting_desc"
                                    rows={3}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                // defaultValue={''}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="pt-5">
                        <div className="flex justify-end">
                            <button
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            >
                                Cancel
                            </button>
                            <button
                                // onClick={log}
                                type="submit"
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default GlobalSetting