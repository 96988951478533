import ReservationServices from '../ApiServices/ReservationServices';
import MasterServices from '../ApiServices/MasterServices';
import { toast } from 'react-toast'

export const uploadFiles = async (multifiles) => {
  const formData = new FormData();
  console.log("multifiles=>", multifiles);

  for (let i = 0; i < multifiles.length; i++) {
    console.log(multifiles[i], "multifiles[i]")
    formData.append('file', multifiles[i]);
  }

  return ReservationServices.addAttachmentToResv(formData)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        console.log("Happy data=>", data.data);
        console.log("Happy data json=>", data.data);
        toast.success("File Uploaded Successfully!");
        return data;
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = "addAttachment catch Error"; //error.response.data.message;
      toast.error(msg);
    });
};

export const singleUploadFiles = async (file,folderName) => {
  if (file && file.type.startsWith('image/')) {
  } else {
    toast.error("Please Select Correct Image File");
    return
  }
  const formData = new FormData();
  formData.append('file', file);
  formData.append('folderName', folderName);
  return MasterServices.addCommonAttachment(formData)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        console.log("Single Upload=>",data)
        // toast.success("File Uploaded Successfully!");
        return data;
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = "addAttachment catch Error"; //error.response.data.message;
      toast.error(msg);
    });
};

export const singleUploadFilesWithUpdate = async (data, file, folderName) => {
  console.log("data", data);
  if (file && file.type.startsWith('image/')) {
  } else {
    toast.error("Please Select Correct Image File");
    return
  }
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));
  formData.append('file', file);
  formData.append('folderName', folderName);
  return MasterServices.addCommonAttachment(formData)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        console.log("Single Upload=>",data)
        // toast.success("File Uploaded Successfully!");
        return data;
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = "addAttachment catch Error"; //error.response.data.message;
      toast.error(msg);
    });
};