import { useMemo, useRef, useState } from "react"
import { Marker, Popup } from "react-leaflet"

export default function DraggableMarker({ latLong, newIcon, setLatLong }) {
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    let { lat, lng } = marker.getLatLng()
                    setLatLong([lat.toFixed(6), lng.toFixed(6)])
                }
            },
        }),
        [],
    )

    return (
        <Marker
            draggable={true}
            eventHandlers={eventHandlers}
            position={latLong}
            icon={newIcon}
            ref={markerRef}>
            <Popup minWidth={90}>
                Latitude: {latLong[0]} <br />
                Longitude: {latLong[1]}
            </Popup>
        </Marker>
    )
}