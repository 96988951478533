export const GET_ALL_NOTIFICATION_BEGIN = "GET_ALL_NOTIFICATION_BEGIN"
export const GET_ALL_NOTIFICATION_SUCCESS = "GET_ALL_NOTIFICATION_SUCCESS"
export const GET_ALL_NOTIFICATION_ERROR = "GET_ALL_NOTIFICATION_ERROR"

export const ADD_NOTIFICATION_BEGIN = "ADD_NOTIFICATION_BEGIN"
export const ADD_NOTIFICATION_SUCCESS = "ADD_NOTIFICATION_SUCCESS"
export const ADD_NOTIFICATION_ERROR = "ADD_NOTIFICATION_ERROR"

export const GET_NOTIFICATION_BEGIN = "GET_NOTIFICATION_BEGIN"
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS"
export const GET_NOTIFICATION_ERROR = "GET_NOTIFICATION_ERROR"

export const UPDATE_NOTIFICATION_BEGIN = "UPDATE_NOTIFICATION_BEGIN"
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS"
export const UPDATE_NOTIFICATION_ERROR = "UPDATE_NOTIFICATION_ERROR"

export const UPDATE_NOTIFICATION_STATUS_BEGIN = "UPDATE_NOTIFICATION_BEGIN"
export const UPDATE_NOTIFICATION_STATUS_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS"
export const UPDATE_NOTIFICATION_STATUS_ERROR = "UPDATE_NOTIFICATION_ERROR"

export const DELETE_NOTIFICATION_BEGIN = "DELETE_NOTIFICATION_BEGIN"
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS"
export const DELETE_NOTIFICATION_ERROR = "DELETE_NOTIFICATION_ERROR"