import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toast";
import MasterServices from "../../../ApiServices/MasterServices";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import { Items_colums } from "../../../components/tables/tableheader";
import { Titems_colums } from "../../../components/tables/tableheader";
import Modal from "../../../components/Modal";
import ConfirmationModal from "../../../components/ConfirmationModal";

const initialModalStateCoupon = {
  state: false,
};

const ItemsList = () => {
  const [loading, setLoading] = React.useState(true);
  const [itemsList, setItemsList] = React.useState([]);
  const [modalTItem, setModalTItem] = useState(initialModalStateCoupon);
  const [viewItemsModel, setViewItemsModel] = useState({
    status: false,
    data: {},
});
const [confirmationModal, setConfirmationModal] = useState({
  status: false,
  item_id: null,
});

const pages = [{ title: "Items", href: "/items", module_id: 35 }];

  React.useEffect(() => {
    getAllItems();
    document.title = "Labib | Items";
  }, []);

  const getAllItems = async () => {
    setLoading(true);
    await MasterServices.getItemsList()
      .then((response) => {
        const { data, status } = response;
        if (status === 200) {
          setItemsList(data.data);
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
    setLoading(false);
  };
  const updateItemStatus = (id, payload) => {
    MasterServices.updateItemsStatus(id, payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Item Status Updated Successfully");
          getAllItems();
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };
  const viewTItems = (data) => {
    setModalTItem({
      state: true,
    })
    setViewItemsModel({
      status: true,
      data: data,
    })
  }

  const onDeleteOpen = (item_id) => {
		setConfirmationModal({ item_id, status: true });
	};


  const deleteItem = (payload) => {
    MasterServices.deleteItem(payload)
      .then((response) => {
        const { data, status } = response;
        setConfirmationModal({ item_id: null, status: false });
        if (status == 200) {
          toast.success("Item Deleted Successfully");
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        getAllItems();
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  const renderViewItemModel = () => {

    const {state} = modalTItem;

    return (
        <Modal
            title="Items"
            open={state}
            size="max-w-3xl relative"
            setOpen={() => setModalTItem((prev) => ({ ...prev, state: false }))}
        >
          <h1>{viewItemsModel.data.witem_name_en} List</h1>
          {loading ? (
            <FallingLinesLoader />
          ) : (
            <Table columns={Titems_colums({ })} data={viewItemsModel.data.t_item} />
          )}
        </Modal>
    );
};

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
          open={confirmationModal.status}
          setOpen={setConfirmationModal}
          onDelete={() => deleteItem({item_id: confirmationModal.item_id})}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Items List</h1>
      </div>
      <div className="mt-4 flex">
        <Link
          to="/add-items/"
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add Item
        </Link>
      </div>

      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table columns={Items_colums({ updateItemStatus, viewTItems, onDeleteOpen })} data={itemsList} />
      )}
      {renderViewItemModel()}
    </div>
  );
};

export default ItemsList;
