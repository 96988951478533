import React, { useEffect } from 'react'
import Breadcrumb from '../../../components/Breadcrumb';
import { useState } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { clinicRefferalSchema } from "../../../schemas";
import MasterServices from '../../../ApiServices/MasterServices';
import { toast } from 'react-toast';
import PhoneInputWithCountry, { isValidPhoneNumber, parsePhoneNumber, getCountryCallingCode } from 'react-phone-number-input'
import Table from '../../../components/tables/table';
import { clinic_refferal_reservations } from '../../../components/tables/tableheader';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/solid';


let initialValues = {
  resv_ref_name: "",
  resv_ref_phone: "",
  resv_ref_contact_address: "",
  resv_ref_discount_number: "",
};

const AddClinicRefferal = () => {
  const pages = [{ title: "Add Clinic Refferal", href: "/add-clinic-refferal", module_id: 26 }];
  const [loading, setLoading] = useState(false);
  const { resv_ref_id: RRID } = useParams();
  const navigate = useNavigate();
  const [clinicRefferalData, setClinicRefferalData] = useState({
    data: [],
    t_reservation: []
  });

  useEffect(() => {
    console.log(clinicRefferalData)
  }, [clinicRefferalData])

  useEffect(() => {
    if (RRID) getClinicRefferalByID(RRID);

  }, [RRID]);

  const getClinicRefferalByID = (id) => {
    setLoading(true);
    MasterServices.getSingleClinicRefferal(id)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setClinicRefferalData({...clinicRefferalData, data: data.data,  t_reservation: data.data.t_reservation.length > 0 ? data.data.t_reservation : [] })
          toast.success("Items Fetched Successfully");
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };

  function calculateFinalPayment(data) {
    let total = 0;
    data.rev_total_discount = data.rev_total_discount ? data.rev_total_discount : 0;
    data.rev_amount = data.rev_amount ? data.rev_amount : 0;
    data.rev_discounted_amount = data.rev_discounted_amount ? data.rev_discounted_amount : 0;
    data.rev_vat_amount = data.rev_vat_amount ? data.rev_vat_amount : 0;
    total = parseFloat((parseFloat(data.rev_amount) + parseFloat(data.rev_vat_amount)) - (parseFloat(data.rev_total_discount) + parseFloat(data.rev_discounted_amount))).toFixed(2);
    return total
  }
    

  return (
    <div>
      <Breadcrumb pages={pages} />
      <div>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
        <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold text-gray-900">Clinic Name: <span className='text-gray-600'>{clinicRefferalData.data.resv_ref_name}</span></h1>
        </div>
      </div>
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
            {clinicRefferalData.t_reservation.length > 0 ?
            <Table
              columns={clinic_refferal_reservations({calculateFinalPayment})}
              data={clinicRefferalData.t_reservation}
            />
            : <h4>Data Not Found!</h4>}
          </div>
        </div>
    </div>
  )
}

export default AddClinicRefferal