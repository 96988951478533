import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react"
import { Formik } from "formik";
import ReservationServices from "../../../../ApiServices/ReservationServices";
import AddressMap from "./AddressMap";
import { reservationAddressSchema } from "../../../../schemas";

const GEO_API = process.env.GEOAPIFY_KEY;

export const AddEditAddressDialog = ({ latLong, setLatLong, openAddressDialog, setOpenAddressDialog, closeAddressModal, addUserAddress, cancelButtonRef, selectedAddress, updateUserAddress }) => {

    const [loading, setLoading] = useState(false)
    const [address_id, setAddressId] =  useState(false)

    useEffect(() => {
        if (selectedAddress) {
            setLatLong([selectedAddress.ua_lat, selectedAddress.ua_long]);
            setAddressId(selectedAddress.ua_id)
        }
    }, [selectedAddress])

    const initialValues = {
        ua_recipient: selectedAddress ? selectedAddress.ua_recipient : "",
        ua_building: selectedAddress ? selectedAddress.ua_building : "",
        ua_street: selectedAddress ? selectedAddress.ua_street : "",
        ua_neighborhood: selectedAddress ? selectedAddress.ua_neighborhood : "",
        // ua_lat: selectedAddress ? selectedAddress.ua_lat : "",
        // ua_long: selectedAddress ? selectedAddress.ua_long : "",
        ua_postalCode: selectedAddress ? selectedAddress.ua_postalCode : "",

        ua_city: selectedAddress ? selectedAddress.ua_city : "",
        ua_country: selectedAddress ? selectedAddress.ua_country : "",
        ua_state: selectedAddress ? selectedAddress.ua_state : "",
    }

    const fetchLatLong = async (values) => {
        setLoading(true)
        const payload = {
            ua_building: values.ua_building,
            ua_street: values.ua_street,
            ua_neighborhood: values.ua_neighborhood,
            ua_postalCode: values.ua_postalCode,
            ua_city: values.ua_city,
            ua_country: values.ua_country,
            ua_state: values.ua_state,
        }
        let res = await ReservationServices.getLatLong(payload)
        if (res.status == 200) {
            setLatLong([res.data?.data.lat, res.data?.data.lon])
        }
        setLoading(false)
    }
    return (
        <Transition.Root show={openAddressDialog} as={Fragment}>
            <Dialog
                as="div"
                className="address-dialog relative z-30 bg-slate-500"
                initialFocus={cancelButtonRef}
                onClose={() => {
                    // setOpenAddressDialog(false);
                    closeAddressModal()
                    // setLatLong(false)
                }}
            >

                <Transition.Child
                    afterLeave={() => {
                        closeAddressModal()
                    }}
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <Formik
                    enableReinitialize={true}
                    enableReinitializing
                    onSubmit={async (values, action) => {

                        let addObj = {
                            ua_recipient: values.ua_recipient,
                            ua_building: values.ua_building,
                            ua_street: values.ua_street,
                            ua_neighborhood: values.ua_neighborhood,
                            // ua_lat: values.ua_lat,
                            // ua_long: values.ua_long,
                            ua_postalCode: values.ua_postalCode,
                            ua_city: values.ua_city,
                            ua_country: values.ua_country,
                            ua_state: values.ua_state,
                        }
                        // addUserPatient(addObj, action);
                        let res 
                        if(address_id){
                             res = await updateUserAddress({ ...addObj, ua_lat: +latLong[0], ua_long: +latLong[1]}, +address_id)
                        }else{
                             res = await addUserAddress({ ...addObj, ua_lat: +latLong[0], ua_long: +latLong[1] })
                        }
                        if (res.status == 200) {
                            closeAddressModal()
                            setLatLong([46.7318, 24.7573])
                        }
                        // closeModal();
                    }}
                    initialValues={initialValues}
                    validationSchema={reservationAddressSchema}
                >
                    {({
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setValues,
                        values,
                        touched,
                        isValid,
                        isSubmitting,
                        errors,
                        handleReset
                    }) => (
                        <div className="fixed  inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:p-6">
                                        <div className="w-1/1">
                                            <form noValidate
                                                onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                                                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                                    <div className="space-y-6 pt-5 sm:space-y-5 sm:pt-5">
                                                        <div>
                                                            <h3 className="text-lg font-medium leading-6 text-gray-900">
                                                                Add Address
                                                            </h3>
                                                        </div>


                                                        <div className="space-y-6 sm:space-y-5 flex ">
                                                            <div className="grid grid-cols-3 gap-4">
                                                                {/* Recipient */}
                                                                <div className="">
                                                                    <label
                                                                        htmlFor="patient-name"
                                                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                                                    >
                                                                        Recipient
                                                                    </label>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0 w-60">
                                                                        <input
                                                                            type="text"
                                                                            name="ua_recipient"
                                                                            id="ua_recipient"
                                                                            value={values.ua_recipient}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            placeholder={`Please Enter Recipient Name`}
                                                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                                                        />
                                                                        {
                                                                            errors.ua_recipient && touched.ua_recipient ? (
                                                                                <p className="text-red-600 text-sm">{errors.ua_recipient}</p>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                </div>


                                                                {/* Building */}
                                                                <div className="">
                                                                    <label
                                                                        htmlFor="patient-name"
                                                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                                                    >
                                                                        Building
                                                                    </label>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            type="text"
                                                                            name="ua_building"
                                                                            id="ua_building"
                                                                            value={values.ua_building}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            placeholder={`Please Enter Building Details`}
                                                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                                                        />
                                                                        {
                                                                            errors.ua_building && touched.ua_building ? (
                                                                                <p className="text-red-600 text-sm">{errors.ua_building}</p>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                </div>

                                                                {/* Street */}
                                                                <div className="">
                                                                    <label
                                                                        htmlFor="patient-name"
                                                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                                                    >
                                                                        Street
                                                                    </label>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            type="text"
                                                                            name="ua_street"
                                                                            id="ua_street"
                                                                            value={values.ua_street}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            placeholder={`Please Enter Street`}
                                                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                                                        />
                                                                        {
                                                                            errors.ua_street && touched.ua_street ? (
                                                                                <p className="text-red-600 text-sm">{errors.ua_street}</p>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                </div>

                                                                {/* Neighbourhood */}
                                                                <div className="">
                                                                    <label
                                                                        htmlFor="patient-name"
                                                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                                                    >
                                                                        Neighbourhood
                                                                    </label>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            type="text"
                                                                            name="ua_neighborhood"
                                                                            id="ua_neighborhood"
                                                                            value={values.ua_neighborhood}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            placeholder={`Please Enter Neighbourhood`}
                                                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                                                        />
                                                                        {
                                                                            errors.ua_neighborhood && touched.ua_neighborhood ? (
                                                                                <p className="text-red-600 text-sm">{errors.ua_neighborhood}</p>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                </div>

                                                                {/* City */}
                                                                <div className="">
                                                                    <label
                                                                        htmlFor="ua_city"
                                                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                                                    >
                                                                        City
                                                                    </label>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            type="text"
                                                                            name="ua_city"
                                                                            id="ua_city"
                                                                            value={values.ua_city}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            placeholder={`Please Enter City`}
                                                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                                                        />
                                                                        {
                                                                            errors.ua_city && touched.ua_city ? (
                                                                                <p className="text-red-600 text-sm">{errors.ua_city}</p>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                </div>

                                                                {/* State */}
                                                                <div className="">
                                                                    <label
                                                                        htmlFor="ua_state"
                                                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                                                    >
                                                                        State
                                                                    </label>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            type="text"
                                                                            name="ua_state"
                                                                            id="ua_state"
                                                                            value={values.ua_state}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            placeholder={`Please Enter State`}
                                                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                                                        />
                                                                        {
                                                                            errors.ua_state && touched.ua_state ? (
                                                                                <p className="text-red-600 text-sm">{errors.ua_state}</p>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="">
                                                                    <label
                                                                        htmlFor="ua_country"
                                                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                                                    >
                                                                        Country
                                                                    </label>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            type="text"
                                                                            name="ua_country"
                                                                            id="ua_country"
                                                                            value={values.ua_country}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            placeholder={`Please Enter Country`}
                                                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                                                        />
                                                                        {
                                                                            errors.ua_country && touched.ua_country ? (
                                                                                <p className="text-red-600 text-sm">{errors.ua_country}</p>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                </div>

                                                                {/* Postal Code */}
                                                                <div className="">
                                                                    <label
                                                                        htmlFor="ua_postalCode"
                                                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                                                    >
                                                                        Postal Code
                                                                    </label>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            value={values.ua_postalCode}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            type="number"
                                                                            name="ua_postalCode"
                                                                            id="ua_postalCode"
                                                                            autoComplete="off"
                                                                            placeholder="Please Enter Postal Code"
                                                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                                                        />
                                                                        {
                                                                            errors.ua_postalCode && touched.ua_postalCode ? (
                                                                                <p className="text-red-600 text-sm">{errors.ua_postalCode}</p>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                </div>

                                                                {/* Fetch Lat Long Button */}
                                                                <div className="mt-7">
                                                                    <button
                                                                        onClick={() => {
                                                                            fetchLatLong(values);
                                                                        }}
                                                                        name="fetch_lat_long"
                                                                        type="button"
                                                                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                                                    // disabled={loading ? true : false}
                                                                    >
                                                                        {loading ? "Loading.." : "Fetch Location"}
                                                                    </button>
                                                                </div>

                                                                <div className="mt-0 col-start-3 col-span-1 text-sm">
                                                                    (Fetch location to get refined position)
                                                                </div>

                                                                {
                                                                    latLong ? (
                                                                        <div className="mt-0 col-start-3 col-span-1 text-sm">
                                                                            <div>
                                                                                <div className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Latitude : {latLong ? latLong[0] : null}</div>
                                                                                <div className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Longitude : {latLong ? latLong[1] : null}</div>
                                                                            </div>
                                                                        </div>
                                                                    ) : null
                                                                }
                                                            </div>

                                                            <div>
                                                                <div className="p-2">
                                                                    <AddressMap latLong={latLong} setLatLong={setLatLong} />
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>



                                                <div className="pt-2">
                                                    <div className="flex justify-end">
                                                        <button
                                                            onClick={() => {
                                                                setOpenAddressDialog(false);
                                                                closeAddressModal();
                                                            }}
                                                            type="button"
                                                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>

                    )}
                </Formik>
            </Dialog>
        </Transition.Root>
    )
}