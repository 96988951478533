import { toast } from "react-toast";
import {
	GET_USERS_LIST_BEGIN,
	GET_USERS_LIST_SUCCESS,
	GET_USERS_LIST_ERROR,
} from "./constants";
import Api from "../../ApiServices/Api";
import ReservationServices from "../../ApiServices/ReservationServices";

export const getUsersList = (payload) => async (dispatch) => {
	try {
		dispatch({
			type: GET_USERS_LIST_BEGIN,
		});
        const { data } = await ReservationServices.getAllUserList()
        
		dispatch({
			type: GET_USERS_LIST_SUCCESS,
			data: data ? data?.data : {},
		});
	} catch (error) {
		console.log(error.response);
		const msg = error.response?.data.message;
		toast.error(msg);
		dispatch({
			type: GET_USERS_LIST_ERROR,
			error: msg,
		});
	}
};