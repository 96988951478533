import React from 'react'

// Define a default UI for filtering

function TableEmpty({ columns, message }) {

  // Render the UI for your table
  return (
    <>
      <div className="mt-4 flex flex-col pb-40">
        <div className="-my-2 overflow-x-auto transform -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table
                className="min-w-full "
              >
                <thead className="bg-gray-50">
                  <tr>
                    <th className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th><th className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                    <th className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th><th className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                  </tr>
                </thead>
                <tbody
                  className="bg-white divide-y divide-gray-200"
                >
                  <tr><td className="px-6 py-4" colSpan="4"><center className="text-sm text-gray-500">{message ? message : "Data Not Found"}</center></td></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TableEmpty
