import React, { useState, Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import { outsource_clinic_credit_logs_columns } from "../../../components/tables/tableheader";
import MasterServices from "../../../ApiServices/MasterServices";

const CreditList = ({ clinic_id }) => {
  const [loading, setLoading] = useState(true);
  const [outsourceClinicCreditLogList, setOutsourceClinicCreditLogList] =
    useState([]);
  const [addCreditAmout, setAddCreditAmount] = useState(0);

  useEffect(() => {
    getOutsourceClinicCreditLogList();
  }, []);

  async function getOutsourceClinicCreditLogList() {
    setLoading(true);
    setAddCreditAmount(0)
    try {
      const clinicsCreditLogsFromApi =
        await MasterServices.getAllCreditLogsByClinicId(clinic_id);
      setOutsourceClinicCreditLogList(clinicsCreditLogsFromApi.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const handleAddCredit = async () => {
    setLoading(true);
    try {
      const addCreditAmountToClinicRes =
        await MasterServices.addCreditAmountToClinic({
          mc_id: clinic_id,
          add_credit_amount: addCreditAmout,
        });
      getOutsourceClinicCreditLogList();
      // setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between">
          <h1 className="text-xl font-semibold text-gray-900">
            Credit Details List :
            <span className="ml-4 bg-green-200 py-2 px-4 rounded-lg">
              {" "}
              Balance -{" "}
              {
                outsourceClinicCreditLogList?.current_credit_balance
                  ?.mc_credit_limit
              }
            </span>
          </h1>

          <div className="flex">
            <div className="sm:col-span-2 sm:mt-0">
              <input
                type="number"
                name="mc_credit_limit"
                id="mc_credit_limit"
                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm`}
                value={addCreditAmout}
                onChange={(e) => {
                  setAddCreditAmount(e.target.value);
                }}
              />
            </div>
            <button
              disabled={addCreditAmout <= 0}
              onClick={handleAddCredit}
              className={`${
                addCreditAmout <= 0 ? "bg-gray-600" : "bg-cyan-600"
              } ml-4 inline-flex justify-center rounded-md bg-cyan-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600`}
            >
              Add Credit
            </button>
          </div>
        </div>

        {loading ? (
          <FallingLinesLoader />
        ) : (
          <Table
            columns={outsource_clinic_credit_logs_columns()}
            data={
              outsourceClinicCreditLogList.clinic_credit_logs?.length > 0
                ? outsourceClinicCreditLogList.clinic_credit_logs
                : []
            }
          />
        )}
      </div>
    </>
  );
};

export default CreditList;
