import React from 'react'
import Breadcrumb from '../../../components/Breadcrumb';
import { useNavigate  } from 'react-router-dom';

const AddVat = () => {
    const pages = [{ title: "Add Vat", href: "/add-vat", module_id: 14 }];
    const navigate = useNavigate();
    return (
        <div>
            <Breadcrumb pages={pages} />
            <div>
                <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex justify-center rounded-md bg-cyan-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                >
                    Back
                </button>
            </div>
            <form className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                        <div>
                            <h3 className="text-base font-semibold leading-6 text-gray-900">Add Vat</h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Use a permanent address where you can receive mail.</p>
                        </div>
                        <div className="space-y-6 sm:space-y-5">
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                    Country
                                </label>
                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                    <select
                                        id="country"
                                        name="country"
                                        autoComplete="country-name"
                                        className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    >
                                        <option>United States</option>
                                        <option>Canada</option>
                                        <option>Mexico</option>
                                    </select>
                                </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="hospital" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                    Hospital
                                </label>
                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                    <select
                                        id="hospital"
                                        name="hospital"
                                        autoComplete="hospital-name"
                                        className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    >
                                        <option>hospital-1</option>
                                        <option>hospital-2</option>
                                        <option>hospital-3</option>
                                    </select>
                                </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="percentage" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                    Percentage
                                </label>
                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                    <input
                                        type="number"
                                        name="percentage"
                                        id="percentage"
                                        autoComplete="percentage"
                                        className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-5">
                    <div className="flex justify-end gap-x-3">
                        <button
                            type="button"
                            className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="inline-flex justify-center rounded-md bg-cyan-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddVat