import { React, useEffect, useState } from 'react'
import { ArrowUturnLeftIcon, BellIcon, BellAlertIcon, BellSlashIcon } from '@heroicons/react/24/outline'
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb';
import { useDispatch } from 'react-redux';
import { getAllNotifications, updateNotificationStatus } from '../../../redux/notification/actions';
import { useSelector } from 'react-redux';
import { FaCheckDouble } from 'react-icons/fa'
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import moment from 'moment';

const tabs = [
    { name: 'All', href: '#', count: '0', current: true },
    { name: 'Read', href: '#', count: '0', icon: BellIcon, current: false },
    { name: 'Unread', href: '#', count: '0', icon: BellSlashIcon, current: false },
]

function Notification({onClose}) {
    const pages = [{ title: "Notifications", href: "/notifications" }];
    const navigate = useNavigate();
    const [isRead, setIsRead] = useState(false);
    const dispatch = useDispatch();
    const [getTabs, setActiveTab] = useState(tabs)
    const [getTabsIndex, setActiveTabIndex] = useState(0)
    const {
        loading,
        notification: { list: notificationList },
    } = useSelector((state) => state);


    const handleMarkAsRead = (notify_status, notify_id) => {
        if(!notify_status) {
            dispatch(updateNotificationStatus({notify_id}))
        }
    };
    
    function notificationChange(...notificationChangeclass) {
        return notificationChangeclass.filter(Boolean).join(' ')
    }


    function openReservation(reservation_id) {
        localStorage.setItem("edit_booking_reservation_id", reservation_id);
        navigate('/bookingConfirm')
    }

    const activeTabStatus = (tab, index) => {
        tabs.map((obj, key) => {
            if (key == index) {
                tabs[index].current = true
                console.log(tabs[index].current)
                setActiveTabIndex(index)
            } else {
                tabs[key].current = false
            }
        })
        setActiveTab(...tabs)
    }

    useEffect(() => {
        (async function () {
            dispatch(await getAllNotifications());
        })()
    }, [])
    return (
        <div>
            {loading ?
                ( <FallingLinesLoader /> 
                ) : (
            <div className="px-5 py-5">
                <div>
                    <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">
                            Select a tab
                        </label>
                        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                        <select

                            id="tabs"
                            name="tabs"
                            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                            defaultValue={tabs.find((tab) => tab.current).name}
                        >
                            {tabs.map((tab, key) => (
                                <option key={`${tab.name}${key}`}>{tab.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <div className="border-b border-gray-200">
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                {tabs.map((tab, index) => (
                                    <a
                                        onClick={() => activeTabStatus(tab, index)}
                                        key={`${tab.name}${index}`}
                                        href="#"
                                        className={notificationChange(
                                            tab.current
                                                ? 'border-cyan-500 text-cyan-600'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                            'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                        )}
                                        aria-current={tab.current ? 'page' : undefined}
                                    >

                                        {tab.icon ?
                                            <div>
                                                <tab.icon
                                                    className={notificationChange(
                                                        tab.current ? 'text-cyan-500' : 'text-gray-400 group-hover:text-gray-500',
                                                        '-ml-0.5 mr-2 h-5 w-5'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            : null
                                        }

                                        {tab.name}
                                        {tab.count ? (
                                            <span
                                                className={notificationChange(
                                                    tab.current ? 'bg-cyan-100 text-cyan-600' : 'bg-gray-100 text-gray-900',
                                                    'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                                )}
                                            >
                                                { Array.isArray(notificationList) && notificationList.length > 0 ? notificationList.filter(v => v.index.includes(index)).length : 0 }
                                            </span>
                                        ) : null}
                                    </a>

                                ))}
                            </nav>
                        </div>
                    </div>
                </div>


                {tabs.map((tab, index) => (
                    <div className={`${getTabsIndex == index ? 'mt-6 flow-root ' : 'hidden'}`}  key={`${tab.name}${index}`} style={{height: '300px', overflowY: 'scroll'}}>
                        <ul role="list" className="-my-5 divide-gray-200">
                            {Array.isArray(notificationList) && notificationList.length > 0 && notificationList.map((notify, key) => (
                                <li key={`${notify.notify_tittle} ${key}`}  
                                className={`${notify.index.indexOf(index) >= 0 ? 'p-4 border-b cursor-pointer' : 'hidden'} ${notify.notify_status ? 'bg-gray-100' : ''}`}>
                                <div className="flex items-center space-x-4">
                                    <div className="flex-shrink-0">
                                        <img className="h-8 w-8 rounded-full" src={notify.notify_from_relation.user_profile_image} alt="" />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p
                                            onClick={(e) => {
                                                openReservation(notify.fk_reservation_id) 
                                                onClose()}}
                                            className="truncate text-sm font-medium text-gray-900"
                                            style={{textDecoration: 'underline'}}
                                        >{notify.notify_tittle}</p>
                                        <p className="truncate text-sm text-gray-500">{'@' + notify.notify_from_relation.user_first_name}</p>
                                        <p className="truncate text-sm text-gray-500">{notify.notify_text}</p>
                                        {notify.notify_status_msg && 
                                            <p className="truncate text-sm text-gray-500">{notify.notify_status_msg}</p>
                                        }
                                        <p className="truncate text-sm text-gray-500"></p>
                                        <p className="truncate text-sm text-gray-500">{moment(notify.notify_created_at).startOf('minute').fromNow()}</p>
                                        <p className="truncate text-sm text-gray-500 flex">
                                            <FaCheckDouble color={notify.notify_status ? 'gray' : 'blue'} width={20} height={20}/>
                                            {!notify.notify_status &&
                                                <span 
                                                style={{marginLeft: 10, textDecoration: 'underline'}}
                                                onClick={e =>{
                                                    e.target.style.visibility = 'hidden'
                                                    handleMarkAsRead(notify.notify_status, notify.notify_id)
                                                    }
                                                }
                                                >Mark read</span>
                                            }
                                        </p>
                                    </div>
                                </div>
                            </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
                )
            }
            <div className="mt-6">
                <Link
                    onClick={e => onClose(e)}
                    to="/notification"
                    className="flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                >
                    View all
                </Link>
            </div>
        </div>
    )
}

export default Notification