/* eslint-disable */
import { toast } from 'react-toast'
import {
  GET_ALL_CLINIC_BEGIN,
  GET_ALL_CLINIC_SUCCESS,
  GET_ALL_CLINIC_ERROR,
  ADD_CLINIC_BEGIN,
  ADD_CLINIC_SUCCESS,
  ADD_CLINIC_ERROR,
} from './constants'
import MasterServices from '../../ApiServices/MasterServices'

export const getAllClinics = () => async (dispatch) => {
  dispatch({
    type: GET_ALL_CLINIC_BEGIN
  })
  MasterServices.getClinicList()
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        dispatch({
          type: GET_ALL_CLINIC_SUCCESS,
          data: data.data.length > 0 ? data.data : []
        })
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error;
      toast.error(msg)
      dispatch({
        type: GET_ALL_CLINIC_ERROR,
        error: msg
      })
    });
}

export const addClinic = (body) => async (dispatch) => {
  dispatch({
    type: ADD_CLINIC_BEGIN
  })
  try {
    const { data, status } = await MasterServices.saveClinic(body);
    if (status == 200) {
      dispatch({
        type: ADD_CLINIC_SUCCESS,
        data: data
      })
      if(body.mc_id) {
        toast.success("Clinic updated successfully!")
      } else {
        toast.success("Clinic added successfully!")
      }
    } else {
      toast.error("Fatal Error Please Contact Admin")
    }
  } catch {
    const msg = error;
    toast.error(msg)
    dispatch({
      type: ADD_CLINIC_ERROR,
      error: msg
    })
  }
}
