export const GET_ALL_CLINIC_BEGIN = "GET_ALL_CLINIC_BEGIN"
export const GET_ALL_CLINIC_SUCCESS = "GET_ALL_CLINIC_SUCCESS"
export const GET_ALL_CLINIC_ERROR = "GET_ALL_CLINIC_ERROR"

export const ADD_CLINIC_BEGIN = "ADD_CLINIC_BEGIN"
export const ADD_CLINIC_SUCCESS = "ADD_CLINIC_SUCCESS"
export const ADD_CLINIC_ERROR = "ADD_CLINIC_ERROR"

export const GET_CLINIC_BEGIN = "GET_CLINIC_BEGIN"
export const GET_CLINIC_SUCCESS = "GET_CLINIC_SUCCESS"
export const GET_CLINIC_ERROR = "GET_CLINIC_ERROR"

export const UPDATE_CLINIC_BEGIN = "UPDATE_CLINIC_BEGIN"
export const UPDATE_CLINIC_SUCCESS = "UPDATE_CLINIC_SUCCESS"
export const UPDATE_CLINIC_ERROR = "UPDATE_CLINIC_ERROR"

export const DELETE_CLINIC_BEGIN = "DELETE_CLINIC_BEGIN"
export const DELETE_CLINIC_SUCCESS = "DELETE_CLINIC_SUCCESS"
export const DELETE_CLINIC_ERROR = "DELETE_CLINIC_ERROR"