import React, { useEffect, useState } from "react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import { Vendors_Items_colums } from "../../../components/tables/tableheader";
import * as Yup from "yup";
import Button from "../../../components/Button";
import InputBox from "../../../components/InputBox";
import Modal from "../../../components/Modal";
import { Formik } from "formik";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import makeAnimated from 'react-select/animated';
import Select from "react-select";
import { useParams } from "react-router-dom";

const Vendor_items = () => {
  const [itemsList, setItemList] = useState([]);
  const [optitemsList, setOptItemList] = useState([]);
  const [loading, setLoading] = useState(false);
  const animatedComponents = makeAnimated();
  const [optunitsList, setOptUnitList] = useState([]);
  const { vendor_id: VId } = useParams();
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('');
  const [showError, setShowError] = useState(false);
  const initialModalState = {
    type: "",
    state: false,
    index: null,
    edit_id: "",
    data: {
      vd_item_price: "",
      vd_item_before_vat: "",
      fk_item_id: "",
      fk_unit_id: "",
      vd_item_lead_time_days: ""
    },
  };
  useEffect(() => {
    getAllItemsOptions(); // get all items for options while adding new item
    getAllUnitsOptions(); // get all units for options while adding new item
    getAllItems(VId);
  }, []);

  const getAllItemsOptions = async () => {
    setLoading(true);
    await MasterServices.getItemsList()
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setOptItemList(data.data);
          // toast.success("Items Fetched Successfully");
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };
  const getAllUnitsOptions = async () => {
    setLoading(true);
    await MasterServices.getUnitsList()
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setOptUnitList(data.data);
          // toast.success("Items Fetched Successfully");
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };
  const getAllItems = async (id) => {
    setLoading(true);
    await MasterServices.getVendorItemListById(id)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setItemList(data.data);
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };

  const AddVendorItem = (payload) => {
    setLoading(true);
    MasterServices.addVendorItem(payload)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          getAllItems(VId);
          toast.success(data.message);
          setLoading(false);
          setModal((prev) => ({ ...prev, state: false }));
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };

  const getItemById = async (id) => {
    await MasterServices.getOneVendorItem(id)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setModal((prev) => ({
            ...prev,
            state: true,
            data: data.data,
            type: "edit",
            edit_id: id,
          }));
          if(optitemsList.length > 0) {
            let item = optitemsList.filter(v => v.witem_id == data.data.fk_item_id)[0];
            if(item) {
              setSelectedItem({value: item.fk_item_id, label: item.witem_name_en});
            }
          }
          if(optunitsList.length > 0) {
            let item = optunitsList.filter(v => v.unit_id == data.data.fk_unit_id)[0];
            if(item) {
              setSelectedUnit({value: item.unit_id, label: item.unit_name_en});
            }
          }
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };
  const UpdateVendorItem = (id, payload) => {
    MasterServices.updateVendorItem(id, payload)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          toast.success(data.message);
          getAllItems(VId);
          setModal((prev) => ({ ...prev,edit_id:"", state: false }));
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };

  const [modal, setModal] = useState(initialModalState);
  const renderModal = () => {
    const { type, state, data, edit_id } = modal;

    return (
      <Formik
        initialValues={data}
        validationSchema={Yup.object({
          vd_item_price: Yup.string().required(
            "Please enter the price of item"
          ),
          vd_item_before_vat: Yup.string().required(
            "Please enter the price of item before vat"
          ),
          fk_item_id: Yup.string().required("Please select the item"),
          fk_unit_id: Yup.string().required("Please select the unit"),
          vd_item_lead_time_days: Yup.string().required("please enter lead time days")
        })}
        enableReinitialize={true}
        onSubmit={(values, action) => {
          const body = {
            vd_item_price: values.vd_item_price,
            vd_item_before_vat: values.vd_item_before_vat,
            fk_item_id: parseInt(values.fk_item_id),
            fk_unit_id: parseInt(values.fk_unit_id),
            fk_vendor_id: parseInt(VId),
            vd_item_lead_time_days: parseInt(values.vd_item_lead_time_days)
          };
          if (modal.edit_id) {
            UpdateVendorItem(modal.edit_id, body);
          } else {
            AddVendorItem(body);
          }
          action.setSubmitting(false);
          action.resetForm();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Modal
            title="Add Item"
            open={state}
            setOpen={() => {
              setSelectedItem('');
              setSelectedUnit('');
              setShowError(false);
              setModal((prev) => ({ ...prev, state: false }))}}
          >
            <form onSubmit={e => {
              setShowError(true)
              handleSubmit(e)
            }}>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="fk_item_id"
                  className="block  font-medium text-sm text-gray-700"
                >
                  Select Item
                </label>
                <Select
                  name="witem_name_en"
                  id="fk_item_id"
                  placeholder="Select Item"
                  value={selectedItem}
                  onChange={(e) => {
                    values.fk_item_id = e.value;
                    setSelectedItem(e);
                  }}
                  // onBlur={handleBlur}
                  components={animatedComponents}
                  options={optitemsList ? optitemsList.map(v => ({value: v.witem_id, label: v.witem_name_en})) : []}
                  classNamePrefix="select"
                  className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                />
                {showError && errors.fk_item_id && (
                  <p className="text-red-700 error_msg">{errors.fk_item_id}</p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="fk_unit_id"
                  className=" text-sm block  font-medium text-gray-700"
                >
                  Select Unit
                </label>
                <Select
                  name="unit_name_en"
                  id="fk_unit_id"
                  placeholder="Select Unit"
                  value={selectedUnit}
                  onChange={(e) => {
                    values.fk_unit_id = e.value;
                    setSelectedUnit(e);
                  }}
                  // onBlur={handleBlur}
                  components={animatedComponents}
                  options={optunitsList ? optunitsList.map(v => ({value: v.unit_id, label: v.unit_name_en})) : []}
                  classNamePrefix="select"
                  className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                />
                {showError && errors.fk_unit_id && (
                  <p className="text-red-700 error_msg">{errors.fk_unit_id}</p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <InputBox
                  name="vd_item_before_vat"
                  label="Price Before Vat"
                  type="text"
                  value={values.vd_item_before_vat}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  placeholder="Enter Price Before Vat"
                />
                {showError && errors.vd_item_before_vat && (
                  <p className="text-red-700 error_msg">
                    {errors.vd_item_before_vat}
                  </p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <InputBox
                  name="vd_item_lead_time_days"
                  label="Lead Time (Days)"
                  type="text"
                  value={values.vd_item_lead_time_days}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  placeholder="Enter Lead Time (Days)"
                />
                {showError && errors.vd_item_lead_time_days && (
                  <p className="text-red-700 error_msg">{errors.vd_item_lead_time_days}</p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <InputBox
                  name="vd_item_price"
                  label="Price"
                  type="text"
                  value={values.vd_item_price}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  placeholder="Enter Price"
                />
                {showError && errors.vd_item_price && (
                  <p className="text-red-700 error_msg">
                    {errors.vd_item_price}
                  </p>
                )}
              </div>

              <div className="mt-4"></div>
              <Button type="submit">
                {isSubmitting ? "Saving..." : "Add Item"}
              </Button>
            </form>
          </Modal>
        )}
      </Formik>
    );
  };

  return (
    <div>
      <div className="mt-4 flex">
        {renderModal()}
        <button
          type="button"
          onClick={() =>
            setModal((prev) => ({ ...prev, data: {},edit_id:"", state: true }))
          }
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add Item
        </button>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : loading == false ? (
        <Table
          columns={Vendors_Items_colums({ getItemById })}
          data={itemsList}
        />
      ) : null}
    </div>
  );
};

export default Vendor_items;
