import {
  GET_USERS_LIST_BEGIN,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_ERROR,
  } from "./constants";
  
  const initState = {
    usersListData: {},
    loading: false,
    error: null,
  };
  
  const UserListReducer = (state = initState, action) => {
    const { type, data, error } = action;
    switch (type) {
      case GET_USERS_LIST_BEGIN:
        return {
          ...state,
          loading: true,
          usersListData: {},
        };
      case GET_USERS_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          usersListData: data ? data: {},
        };
      case GET_USERS_LIST_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default UserListReducer;
  