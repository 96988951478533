import {
  GET_ALL_PACKAGE_BEGIN,
  GET_ALL_PACKAGE_SUCCESS,
  GET_ALL_PACKAGE_ERROR,
  ADD_PACKAGE_BEGIN,
  ADD_PACKAGE_SUCCESS,
  ADD_PACKAGE_ERROR,
  GET_PACKAGE_BEGIN,
  GET_PACKAGE_ERROR,
  GET_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_BEGIN,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_ERROR,
  DELETE_PACKAGE_BEGIN,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_ERROR,
} from "./constants";

const initState = {
  list: [],
  loading: false,
  error: null,
};

const PackageReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case GET_ALL_PACKAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_PACKAGE_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_ALL_PACKAGE_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case ADD_PACKAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, data],
      };
    case ADD_PACKAGE_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case GET_PACKAGE_BEGIN:
      return {
        ...state,
        loading: true,
        packageData: "",
      };
    case GET_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        packageData: data?data:[],
      };
    case GET_PACKAGE_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case UPDATE_PACKAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((ele) => {
          if (ele.pack_id === data.pack_id) {
            return { ...data };
          } else return ele;
        }),
      };
    case UPDATE_PACKAGE_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case DELETE_PACKAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((ele) => ele.pack_id !== data),
      };
    case DELETE_PACKAGE_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    default:
      return state;
  }
};

export default PackageReducer;
