import React, { useEffect, useState, Fragment, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { subtestSchema } from "../../../schemas";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
// import { useDispatch } from "react-redux";
import { subtest_columns } from "../../../components/tables/tableheader";
import {
  deleteSubTest, getAllSubTests, addSubTest,
  updateSubTest
} from "../../../redux/subtest/actions";
import { Dialog, Transition, } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import AddTest from "../tests/addTest";
import SubTestList from "./SubTestList";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import MasterServices from '../../../ApiServices/MasterServices'
import AddTestParameter from "./addSubTest";
import { toast } from "react-toast";
import AddRefrenceRange from "./AddRefrenceRange";
let tabs = [
  { name: 'Details', href: '#details', current: true, is_sub_test:true },
  { name: 'Reference Range', href: '#reference', current: false, is_sub_test:true  },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function SubTestTabDetails() {
  const { test_id: test_id } = useParams();
  const [tabDetails, setOpenTab] = useState([]);
  const navigate = useNavigate();
  const [testData, settestData] = useState({});
  let [isTabChanged, setIsTabChanged] = useState(1);
  useEffect(() => {
    if (test_id) {
      MasterServices.getSingleTest(test_id)
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            settestData(data.data);
            tabs.map((ele) => {
              if (ele.name == "Test-Parameter") {
                ele.is_sub_test = data.data.is_sub_test;
              }else{
                ele.is_sub_test = true;
              }
            });
          } else {
            toast.error("Fatal Error Please Contact Admin")
          }
        })
        .catch((error) => {
          const msg = error.response.data.message;
          toast.error(msg)
        });
    }

  }, [test_id]);
  const pages = [
    { title: "Update SubTest", href: "/sub-test-tab" },
  ];
  const ChangeTabs = (tab, index) => {
    tabs.forEach((ele, ind) => {
      ele.current = false;
    });
    console.log("Previous tab=>", tabs)
    tabs[index].current = true;

    setOpenTab(tabs);
    setIsTabChanged(isTabChanged+1);
    
    console.log("tab=>", tabDetails)
  }
  useEffect(() => {
    console.log("tab=>", testData.is_sub_test)
  });

  return (
    <div>
      <Breadcrumb pages={pages} />
      <button
        type="button"
        onClick={() => navigate(-isTabChanged)}
        className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
      >
        <span className="w-5 mr-2"><ArrowUturnLeftIcon size={18} /></span>   Back {testData.is_sub_test}
      </button>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className='h-screen'>
          <nav className="mb-5 flex space-x-8 border-b border-gray-200" aria-label="Tabs">
            {tabs.map((tab, index) => (
              (tab.is_sub_test == true) ? (
                <a
                  key={tab.name}
                  href={tab.href}
                  onClick={() => ChangeTabs(tab, index)}
                  className={classNames(
                    tab.current
                      ? 'border-cyan-500 text-cyan-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap p-1 border-b-2 font-medium text-sm'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                </a>) : (<></>)
            ))}
          </nav>
          {tabs.map((tab, index) => (
            <div className="hidden sm:block">
              <div >
                {(tab?.href === "#details" && tab?.current) ?
                  <AddTestParameter /> : null
                }
                {(tab?.href === "#reference" && tab?.current) ?
                  <AddRefrenceRange /> : null
                }
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default SubTestTabDetails;