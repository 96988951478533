import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik, Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
//import { testSchema } from "../../../schemas";
import * as Yup from "yup";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { Switch } from "@headlessui/react";
import { getAllHospitals } from "../../../redux/hospital/actions";
import { getAllCategorys } from "../../../redux/category/actions";
import { getAllSampleTypes } from "../../../redux/sampletype/actions";
import { toast } from 'react-toast'
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import Breadcrumb from "../../../components/Breadcrumb";
import { EditorState } from 'draft-js';
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MasterServices from '../../../ApiServices/MasterServices'
import { singleUploadFiles } from "../../../helper/multiUploadHelper";
import { getAllQuestionnaires } from "../../../redux/questionnaire/actions";
import { FileUploader } from "react-drag-drop-files";
import queryString from 'query-string';
import { Editor } from '@tinymce/tinymce-react';
import moment from "moment";


function className(...location) {
  return location.filter(Boolean).join(' ')
}

const fileTypes = ["JPEG", "PNG", "GIF"];
const AddTest = () => {
  const pages = [
    { title: "Tests", href: "/test" },
  ];
  const [referanceList, setReferanceList] = useState(false);
  const [testColorList, setTestColorList] = useState([]);
  const [methodologyList, setMethodologyList] = useState([]);
  // const [questionnaireList, setQuestionnaireList] = useState([]);
  const [enProgress, setenProgress] = useState();
  const [testCounter, setTestCounter] = useState();
  const [imgEnfileName, setEnFile] = useState(false);
  const [imgEnfileNamePath, setEnFilePath] = useState('');
  const [arProgress, setarProgress] = useState();
  const [imgArfileName, setArFile] = useState(false);
  const [imgArfileNamePath, setArFilePath] = useState('');
  const didYouKnowENEditorRef = useRef(null);
  const didYouKnowAREditorRef = useRef(null);
  const smartReportCommentENEditorRef = useRef(null);
  const smartReportCommentAREditorRef = useRef(null);
  const tipsENEditorRef = useRef(null);
  const tipsAREditorRef = useRef(null);
  const [isMicrobiology, setIsMicrobiology] = useState(false)

  let initialValues = {
    test_id: "",
    test_name_ar: "",
    test_name_en: "",
    test_img_en: "",
    test_img_ar: "",

    // test_min_price: "",
    test_code: "",
    test_smart_report_description_en: "",
    test_smart_report_description_ar: "",

    test_short_description_en: "",
    test_short_description_ar: "",
    test_tips_description_en: "",
    test_tips_description_ar: "",

    // fk_rr_id: "",
    fk_color_id: "",
    fk_question_id: "",
    fk_methodology_id: "",
    fk_hos_id: "",
    fk_category_id: "",
    fk_sample_type_id: "",
    hos_price: "",
    test_tat: "",
    test_stat: "",

    bupa_test_name: "",
    bupa_service_code: "",
  };

  const handleFileChange = async (field, file) => {
    const response = await singleUploadFiles(file, 'tests');
    console.log(response)
    if (field == 'enImage') {
      setEnFilePath(response?.data);
      setenProgress(response.progress);
    }
    if (field == 'arImage') {
      setArFilePath(response?.data)
      setarProgress(response.progress);
    }
  };

  /* const handleEnFileChange = async (file) => {
    const response = await singleUploadFiles(file, 'tests');
    setEnFilePath(response.data);
    setenProgress(response.progress);
    // setEnFile(e.target.files[0]);
  };
  const handleArFileChange = async (file) => {
    const response = await singleUploadFiles(file, 'tests');
    setArFilePath(response.data)
    setarProgress(response.progress);
    // setArFile(e.target.files[0]);
  }; */
  const [subTestStatus, setSubTestStatus] = useState(false)
  const [allHospitalList, setHospitalList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(false)
  const { test_id: test_id } = useParams();
  const [testList, setTestList] = useState(false)
  const {
    loading,
    hospital: { list: hospitalList },
    category: { list: categoryList },
    sampletype: { list: sampletypeList },
    questionnaire: { list: questionnaireList }
  } = useSelector((state) => state);
  useEffect(() => {
    document.title = "Labib | Tests";

    dispatch(getAllSampleTypes());
    dispatch(getAllHospitals());
    dispatch(getAllCategorys());
    dispatch(getAllQuestionnaires())
    // getReferenceRangeList();
    getTestColorList();
    getMethodologies();
    getHospitalList();
  }, []);


  const getHospitalList = () => {
    MasterServices.getAllStatusHospitals(queryString.stringify({ search: 1 }))
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setHospitalList(data.data);
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "getHospitalList catch Error";
        toast.error(msg);
      });
  }
  useEffect(() => {
  });
  const [smartReportDescriptionEn, setSmartReportDescriptionEn] = useState(
    () => EditorState.createEmpty(),
  );

  const [formState, setFormState] = useState(initialValues);
  const [testData, settestData] = useState({});

  const getReferenceRangeList = async () => {
    MasterServices.getAllReferenceRange()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setReferanceList(data.data);
          } else {
            toast.error("Reference Ranges Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "Error in Reference Ranges";
        toast.error(msg)
      });

  }

  const getTestColorList = async () => {
    MasterServices.getTestColorList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setTestColorList(data.data);
          } else {
            toast.error("Test Color List Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "Error in Test Color";
        toast.error(msg)
      });

  }

  const getMethodologies = async () => {
    MasterServices.getMethodology()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setMethodologyList(data.data);
          } else {
            toast.error("Methodology List Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "Error in Methodology";
        toast.error(msg)
      });

  }


  const getTestList = async () => {
    MasterServices.getTestList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            // toast.success("Test Found");
            setTestList(data.data);
          } else {
            toast.error("Test Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error.response.data.message;
        toast.error(msg)
      });

  }
  useEffect(() => {
    if (test_id) {
      MasterServices.getSingleTest(test_id)
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            settestData(data.data);
          } else {
            toast.error("Fatal Error Please Contact Admin")
          }
        })
        .catch((error) => {
          const msg = error.response.data.message;
          toast.error(msg)
        });
    }
  }, [test_id]);

  const getTestCounter = async () => {
    MasterServices.getTestCounter()
      .then((response) => {
        const { data, status } = response;
        // console.log("getTestCounter=>", data.data.test_counter);
        if (status == 200) {
          if (data?.data?.test_counter) {
            // console.log("getTestCounter=>", data.data);
            setTestCounter(parseInt(data.data.test_code) + parseInt(1));
            initialValues.test_code = (parseInt(data.data.test_code) + parseInt(1)).toString();
            // console.log("getTestCounter testCounter=>", testCounter);
          }
        }
      })
      .catch((error) => {
        const msg = error;
        console.log(msg)
      });

  }

  //preview smart report
  const onPreviewClick = () => {
    generateSmartReportPrevPdf();

  }

  const generateSmartReportPrevPdf = async () => {
    // setIsLoading(true)
    try {
      let srData = {
        test_name_en: values.test_name_en,
        test_name_ar: values.test_name_ar,
        bupa_test_name: values.bupa_test_name,
        bupa_service_code: values.bupa_service_code,
        test_short_description_en: didYouKnowENEditorRef.current.getContent(),
        test_short_description_ar: didYouKnowAREditorRef.current.getContent(),
        test_smart_report_description_en: smartReportCommentENEditorRef.current.getContent(),
        test_smart_report_description_ar: smartReportCommentAREditorRef.current.getContent(),
        test_tips_description_en: tipsENEditorRef.current.getContent(),
        test_tips_description_ar: tipsAREditorRef.current.getContent(),
      };
      let payload = {
        pdf_name: 'Smart_Report_Preview_' + moment().format("DD-MM-YYYY"),
        data: srData
      }
      const { data, status } = await MasterServices.generateSmartReportPrevPDF(payload);
      console.log(data)
      if (status === 200) {
        if (data?.data?.pdf_url != '') {
          // window.location.replace(data.data.pdf_url);
          window.open(data.data.pdf_url, '__blank');
        }
        // setResUserReportData(data.data)
      }
      // setIsLoading(false)
    } catch (error) {
      console.log(error)
      // setIsLoading(false)
    }
  }

  useEffect(() => {
    console.log("Happy=>", testData.test_name_ar)
    console.log("Happy referanceList=>", referanceList)
    document.title = "Labib | Add-Test";
    if (!test_id) {
      getTestCounter();
    }


    if (test_id && testData) {
      const newFormState = {
        test_name_ar: testData.test_name_ar,
        test_name_en: testData.test_name_en,
        test_img_en: testData.test_img_en,
        test_img_ar: testData.test_img_ar,

        bupa_test_name: testData.bupa_test_name,
        bupa_service_code: testData.bupa_service_code,

        // test_min_price: testData.test_min_price,
        test_code: testData.test_code,
        test_smart_report_description_en: testData.test_smart_report_description_en,
        test_smart_report_description_ar: testData.test_smart_report_description_ar,
        test_short_description_en: testData.test_short_description_en,
        test_short_description_ar: testData.test_short_description_ar,
        test_tips_description_en: testData.test_tips_description_en,
        test_tips_description_ar: testData.test_tips_description_ar,
        // fk_rr_id: testData.fk_rr_id !=null?testData.fk_rr_id:'',
        fk_color_id: testData.fk_color_id != null ? testData.fk_color_id : '',
        fk_question_id: testData.fk_question_id != null ? testData.fk_question_id : '',
        fk_hos_id: testData.fk_hos_id != null ? testData.fk_hos_id : '',
        fk_category_id: testData.fk_category_id != null ? testData.fk_category_id : '',
        fk_sample_type_id: testData.fk_sample_type_id != null ? testData.fk_sample_type_id : '',
        hos_price: testData.hos_price,
        test_tat: testData.test_tat,
        test_stat: testData.test_stat,
        fk_methodology_id: testData.fk_methodology_id != null ? testData.fk_methodology_id : '',
      };
      setSubTestStatus(testData.is_sub_test)
      setIsMicrobiology(testData.is_microbiology)
      setEnFilePath(testData.test_img_en ? testData.test_img_en : '');
      setArFilePath(testData.test_img_ar ? testData.test_img_ar : '');
      setFormState(newFormState);

    }
  }, [test_id, testData]);


  const testSchema = Yup.object({
    test_name_ar: Yup.string()
      .min(2)
      .max(225)
      .required("Please Enter the Test Name(AR)"),
    test_name_en: Yup.string()
      .min(2)
      .max(525)
      .required("Please Enter the Test Name(EN)"),
    // test_min_price: Yup.string().required("Please Enter Test Min Price"),
    test_code: Yup.string().required("Please Enter the Test Code"),
    // test_smart_report_description_en: Yup.string().required(
    //   "Please Enter the Smart Report Description(EN)"
    // ),
    // test_smart_report_description_ar: Yup.string().required(
    //   "Please Enter the Smart Report Description(AR)"
    // ),
    // test_short_description_en: Yup.string().required(
    //   "Please Enter the Short Description(EN)"
    // ),
    // test_short_description_ar: Yup.string().required(
    //   "Please Enter the Short Description(AR)"
    // ),
    // test_tips_description_en: Yup.string().required(
    //   "Please Enter the Tips(EN)"
    // ),
    // test_tips_description_ar: Yup.string().required(
    //   "Please Enter the Tips(AR)"
    // ),
    fk_color_id: Yup.string().required("Please Select the Test Color"),
    fk_methodology_id: Yup.string().required("please select the methodology"),
    // fk_question_id: Yup.string().required("Please Select the Questions"),
    //fk_rr_id: Yup.string().required("Please Select the Reference Range"),
    fk_hos_id: Yup.string().required("Please Select the Lab"),
    fk_category_id: Yup.string().required("Please Select the Category"),
    fk_sample_type_id: Yup.string().required("Please Select the Sample Type"),
    hos_price: Yup.number().required("Please Enter the Lab Price"),
    test_stat: Yup.number().required("Please Enter the STAT"),
    test_tat: Yup.number().required("Please Enter the TAT"),
    // fk_rr_id: !subTestStatus
    //     ? Yup.string().required('Please Select the Reference Range')
    //     : Yup.string(),
    bupa_test_name: Yup.string().required("Please Enter the Bupa Test Name"),
    bupa_service_code: Yup.string().required("Please Enter the Bupa Service Code"),
  });

  const { values, errors, handleBlur, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      validationSchema: testSchema,
      onSubmit: async (values, action) => {
        const body = { ...values };
        body.is_sub_test = subTestStatus;
        body.is_microbiology = isMicrobiology;
        body.test_short_description_en = didYouKnowENEditorRef.current.getContent();
        body.test_short_description_ar = didYouKnowAREditorRef.current.getContent();
        body.test_smart_report_description_en = smartReportCommentENEditorRef.current.getContent();
        body.test_smart_report_description_ar = smartReportCommentAREditorRef.current.getContent();
        body.test_tips_description_en = tipsENEditorRef.current.getContent();
        body.test_tips_description_ar = tipsAREditorRef.current.getContent();

        let fileResEn
        let fileResAr
        if (imgEnfileName) {
          fileResEn = await singleUploadFiles(imgEnfileName, 'tests');
        }
        if (imgArfileName) {
          fileResAr = await singleUploadFiles(imgArfileName, 'tests');
        }
        body.test_img_en = fileResEn ? fileResEn : imgEnfileNamePath;
        body.test_img_ar = fileResAr ? fileResAr : imgArfileNamePath;

        // body.test_smart_report_description_en=smartReportDescriptionEn;
        if (test_id != 0) {
          body.test_id = test_id;
        }
        MasterServices.saveTest(body)
          .then((response) => {
            const { data, status } = response;

            if (status == 200 && data.status) {
              if (test_id > 0) {
                toast.success("Test Updated Successfully");
              } else {
                toast.success("Test Inserted Successfully");
              }
              // getTestList();
              navigate("/test");
            } else {
              toast.error(data.message)
            }
          })
          .catch((error) => {
            const msg = error.response.data.message;
            toast.error(msg)
          });
        action.resetForm();
        navigate("/test");
      },
    });

  useEffect(() => {
    if (testCounter) {
      initialValues.test_code = testCounter;
    }

  });

  return (
    <div >
      {test_id ? null : (
        <Breadcrumb pages={pages} />)}
      {!test_id ? (
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2"><ArrowUturnLeftIcon size={18} /></span>   Back
        </button>) : (<></>)}
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-lg font-medium leading-6 text-gray-900">
              {test_id ? "Update Test" : "Add Test"}
            </h1>
          </div>
        </div>
        {loading ? (
          <FallingLinesLoader />
        ) : (
          // onSubmit={handleSubmit}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
            className="space-y-4 divide-gray-200"
          >
            <div className="flex flex-row gap-5">
              <div className="basis-3/4">
                <label htmlFor="fk_hos_id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Lab</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    value={values.fk_hos_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    name="fk_hos_id"
                    id="fk_hos_id"
                  >
                    <option >Select Lab</option>
                    {allHospitalList.length > 0 ? (allHospitalList.map((hospital, i) => (
                      <option selected={hospital.hos_id === values.fk_hos_id ? "selected" : ""} key={i} value={hospital.hos_id}>
                        {hospital.hos_name_en}
                      </option>
                    ))) : (<></>)}
                  </select>
                  {errors.fk_hos_id && touched.fk_hos_id ? (
                    <p className="text-red-600 text-sm">{errors.fk_hos_id}</p>
                  ) : null}
                </div>
              </div>

              <div className="basis-3/4">
                <label htmlFor="fk_category_id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Category</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    value={values.fk_category_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    name="fk_category_id"
                    id="fk_category_id"
                  >
                    <option >Select Category</option>
                    {categoryList.map((category, i) => (
                      <option selected={category.category_id === values.fk_category_id ? "selected" : ""} key={i} value={category.category_id}>
                        {category.category_name_en}
                      </option>
                    ))}
                  </select>
                  {errors.fk_category_id && touched.fk_category_id ? (
                    <p className="text-red-600 text-sm">{errors.fk_category_id}</p>
                  ) : null}
                </div>
              </div>

              <div className="basis-3/4">
                <label htmlFor="fk_sample_type_id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Sample Type</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select

                    value={values.fk_sample_type_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    name="fk_sample_type_id"
                    id="fk_sample_type_id"
                  >
                    <option >Select Sample Type</option>
                    {sampletypeList.map((sampletype, i) => (
                      <option selected={sampletype.sample_type_id === values.fk_sample_type_id ? "selected" : ""} key={i} value={sampletype.sample_type_id}>
                        {sampletype.sample_type_name_en}
                      </option>
                    ))}
                  </select>
                  {errors.fk_sample_type_id && touched.fk_sample_type_id ? (
                    <p className="text-red-600 text-sm">{errors.fk_sample_type_id}</p>
                  ) : null}
                </div>
              </div>

            </div>
            <div className="flex flex-row gap-5">
              <div className="basis-3/4">
                <label htmlFor="test_name_en" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Test Name(EN)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Enter Test Name(EN)"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="test_name_en"
                    id="test_name_en"
                    value={values.test_name_en}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.test_name_en && touched.test_name_en ? (
                    <p className="text-red-600 text-sm">{errors.test_name_en}</p>
                  ) : null}
                </div>
              </div>
              <div className="basis-3/4">
                <label htmlFor="test_name_ar" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Test Name(AR)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Enter Test Name(AR)"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="test_name_ar"
                    id="test_name_ar"
                    value={values.test_name_ar}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.test_name_ar && touched.test_name_ar ? (
                    <p className="text-red-600 text-sm">{errors.test_name_ar}</p>
                  ) : null}
                </div>
              </div>
              <div className="basis-3/4">
                <label htmlFor="fk_color_id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Test Color</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  {testColorList.length > 0 ? (
                    <select
                      value={values.fk_color_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      name="fk_color_id"
                      id="fk_color_id"
                    >
                      <option >Select Test Color</option>
                      {testColorList.map((testColorData, i) => (
                        <option style={{ backgroundColor: testColorData.mclr_hex_code }} key={testColorData.mclr_name} value={testColorData.mclr_id}>
                          {testColorData.mclr_name} <span >{testColorData.mclr_hex_code}</span>
                        </option>
                      ))}
                    </select>) : (<></>)}
                  {errors.fk_color_id && touched.fk_color_id ? (
                    <p className="text-red-600 text-sm">{errors.fk_color_id}</p>
                  ) : null}
                  {/* {testSchema.validateSyncAt('fk_rr_id', { fk_rr_id: values.fk_rr_id }).message && (
                    <div className="text-red-600 text-sm">{testSchema.validateSyncAt('fk_rr_id', { fk_rr_id: values.fk_rr_id }).message}</div>
                  )} */}
                </div>
              </div>
              <div className="basis-3/4">
                <label htmlFor="fk_methodology_id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Select Methodology</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  {methodologyList.length > 0 ? (
                    <select
                      value={values.fk_methodology_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      name="fk_methodology_id"
                      id="fk_methodology_id"
                    >
                      <option >Select Methodolgy</option>
                      {methodologyList.map((methodlogyData, i) => (
                        <option key={methodlogyData.methodology_name} value={methodlogyData.methodology_id}>
                          <span >{methodlogyData.methodology_name}</span>
                        </option>
                      ))}
                    </select>) : (<></>)}
                  {errors.fk_methodology_id && touched.fk_methodology_id ? (
                    <p className="text-red-600 text-sm">{errors.fk_methodology_id}</p>
                  ) : null}
                  {/* {testSchema.validateSyncAt('fk_rr_id', { fk_rr_id: values.fk_rr_id }).message && (
                    <div className="text-red-600 text-sm">{testSchema.validateSyncAt('fk_rr_id', { fk_rr_id: values.fk_rr_id }).message}</div>
                  )} */}
                </div>
              </div>
              <div className="basis-3/4">
                <label htmlFor="isMicrobiology" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-3">Is Microbiology</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">

                  <Switch
                    checked={isMicrobiology}
                    onChange={setIsMicrobiology}
                    className={className(
                      isMicrobiology ? "bg-cyan-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                    )}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={className(
                        isMicrobiology ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </div>
              </div>
              {/* <div className="basis-3/4">
                <label htmlFor="test_min_price" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Min Price</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Enter Test Min Price"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="number"
                    name="test_min_price"
                    id="test_min_price"
                    value={values.test_min_price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.test_min_price && touched.test_min_price ? (
                    <p className="text-red-600 text-sm">{errors.test_min_price}</p>
                  ) : null}
                </div>
              </div> */}
            </div>

            <div className="flex flex-row gap-5">
              <div className="basis-3/4">
                <label htmlFor="bupa_test_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Bupa Test Name</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Enter Bupa Test Name"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="bupa_test_name"
                    id="bupa_test_name"
                    value={values.bupa_test_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.bupa_test_name && touched.bupa_test_name ? (
                    <p className="text-red-600 text-sm">{errors.bupa_test_name}</p>
                  ) : null}
                </div>
              </div>
              <div className="basis-3/4">
                <label htmlFor="test_name_ar" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Bupa Service Code</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Enter Bupa Service Code"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="bupa_service_code"
                    id="bupa_service_code"
                    value={values.bupa_service_code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.bupa_service_code && touched.bupa_service_code ? (
                    <p className="text-red-600 text-sm">{errors.bupa_service_code}</p>
                  ) : null}
                </div>
              </div>
            </div>


            <div className="flex flex-row gap-5">
              <div className="basis-3/4">
                <label htmlFor="fk_question_id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> TEST FORM</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  {questionnaireList.length > 0 ? (
                    <select
                      value={values.fk_question_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      name="fk_question_id"
                      id="fk_question_id"
                    >
                      <option >Select Test Form</option>
                      {questionnaireList.map((question, i) => (
                        <option key={`${question.qstn_name}${i}`} value={question.qstn_id}>
                          {question.qstn_name}
                        </option>
                      ))}
                    </select>) : (<></>)}
                  {/* {errors.fk_question_id && touched.fk_question_id ? (
                    <p className="text-red-600 text-sm">{errors.fk_question_id}</p>
                  ) : null} */}
                  {/* {testSchema.validateSyncAt('fk_rr_id', { fk_rr_id: values.fk_rr_id }).message && (
                    <div className="text-red-600 text-sm">{testSchema.validateSyncAt('fk_rr_id', { fk_rr_id: values.fk_rr_id }).message}</div>
                  )} */}
                </div>
              </div>
              <div className="basis-3/4">
                <label htmlFor="test_tat" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">TAT</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Enter Test Code"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="number"
                    name="test_tat"
                    id="test_tat"
                    value={values.test_tat}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.test_tat && touched.test_tat ? (
                    <p className="text-red-600 text-sm">{errors.test_tat}</p>
                  ) : null}
                </div>
              </div>

              <div className="basis-3/4">
                <label htmlFor="test_stat" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">STAT</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Enter Test Code"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="number"
                    name="test_stat"
                    id="test_stat"
                    value={values.test_stat}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.test_stat && touched.test_stat ? (
                    <p className="text-red-600 text-sm">{errors.test_stat}</p>
                  ) : null}
                </div>
              </div>
            </div>

            {/* <div className="flex flex-row gap-5"> */}
            {/* <div className="basis-3/4">
                <label htmlFor="test_smart_report_description_en" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Did you know(EN) bb</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0"> */}
            {/* <Editor
                    editorState={smartReportDescriptionEn}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={setSmartReportDescriptionEn}
                    placeholder="Enter Smart Report Description(EN)"
                  /> */}
            {/* <textarea
                    placeholder="Enter Smart Report Description(EN)"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="test_smart_report_description_en"
                    id="test_smart_report_description_en"
                    value={values.test_smart_report_description_en}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  /> */}

            {/* <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => {
                      didYouKnowENEditorRef.current = editor
                    }}
                    initialValue={values.test_short_description_en}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />

                  {errors.test_short_description_en && touched.test_short_description_en ? (
                    <p className="text-red-600 text-sm">{errors.test_short_description_en}</p>
                  ) : null}
                </div>
              </div> */}

            {/* <div className="basis-3/4">
                <label htmlFor="test_smart_report_description_ar" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Did you know(AR)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0"> */}
            {/* <textarea
                    placeholder="Enter Smart Report Description(AR)"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="test_smart_report_description_ar"
                    id="test_smart_report_description_ar"
                    value={values.test_smart_report_description_ar}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  /> */}
            {/* <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => {
                      didYouKnowAREditorRef.current = editor
                    }}
                    initialValue={values.test_short_description_ar}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                  {errors.test_short_description_ar && touched.test_short_description_ar ? (
                    <p className="text-red-600 text-sm">{errors.test_short_description_ar}</p>
                  ) : null}
                </div>
              </div> */}

            {/* <div className="basis-3/4">
                <label htmlFor="test_short_description_en" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Smart Report Comment(EN)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0"> */}
            {/* <textarea
                    placeholder="Enter Test Short Description(EN)"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="test_short_description_en"
                    id="test_short_description_en"
                    value={values.test_short_description_en}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  /> */}
            {/* <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => {
                      smartReportCommentENEditorRef.current = editor;
                    }}
                    initialValue={values.test_smart_report_description_en}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                  {errors.test_smart_report_description_en && touched.test_smart_report_description_en ? (
                    <p className="text-red-600 text-sm">{errors.test_smart_report_description_en}</p>
                  ) : null}
                </div>
              </div> */}

            {/* </div> */}

            {/* <div className="flex flex-row gap-5"> */}
            {/* <div className="basis-3/4">
                <label htmlFor="test_short_description_ar" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Smart Report Comment(AR)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                 
                  <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => {
                      smartReportCommentAREditorRef.current = editor;
                    }}
                    initialValue={values.test_smart_report_description_ar}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                  {errors.test_smart_report_description_ar && touched.test_smart_report_description_ar ? (
                    <p className="text-red-600 text-sm">{errors.test_smart_report_description_ar}</p>
                  ) : null}
                </div>
              </div> */}
            {/* <div className="basis-3/4">
                <label htmlFor="test_tips_description_en" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Tips (EN)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => {
                      tipsENEditorRef.current = editor;
                    }}
                    onEditorChange={e => values.test_tips_description_en = e}
                    initialValue={values.test_tips_description_en}
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                  {errors.test_tips_description_en && touched.test_tips_description_en ? (
                    <p className="text-red-600 text-sm">{errors.test_tips_description_en}</p>
                  ) : null}
                </div>
              </div> */}
            {/* <div className="basis-3/4">
                <label htmlFor="test_tips_description_ar" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Tips (AR)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => {
                      tipsAREditorRef.current = editor;
                    }}
                    onEditorChange={e => values.test_tips_description_ar = e}
                    initialValue={values.test_tips_description_ar}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                  {errors.test_tips_description_ar && touched.test_tips_description_ar ? (
                    <p className="text-red-600 text-sm">{errors.test_tips_description_ar}</p>
                  ) : null}
                </div>
              </div> */}
            {/* </div> */}
            <div className="flex flex-row gap-5">
              <div className="basis-3/4">
                <label htmlFor="test_code" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Code </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    disabled
                    placeholder="Enter Test Code"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="test_code"
                    id="test_code"
                    value={values.test_code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.test_code && touched.test_code ? (
                    <p className="text-red-600 text-sm">{errors.test_code}</p>
                  ) : null}
                </div>
              </div>
              <div className="basis-3/4">
                <label htmlFor="hos_price" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Lab Price</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Enter Lab Price"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="number"
                    name="hos_price"
                    id="hos_price"
                    value={values.hos_price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.hos_price && touched.hos_price ? (
                    <p className="text-red-600 text-sm">{errors.hos_price}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-5">

              {/* <div className="basis-3/4">
                <label htmlFor="hos_long" className="block text-sm font-medium text-gray-700">
                  Image EN
                </label>
                <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-medium text-cyan-600 focus-within:outline-none focus-within:ring-2 focus-cyan:ring-cyan-500 focus-within:ring-offset-2 hover:text-cyan-500"
                      >
                        <span>Upload a file</span>
                        <input id="file-upload" name="test_img_en" type="file" onChange={handleEnFileChange} className="sr-only" />
                        {imgEnfileNamePath ? (
                          <img width={40} height={20} src={imgEnfileNamePath} alt={values.test_img_en} />) : (<></>)}
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                  </div>
                </div>

                {enProgress ? (<><div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2">
                  <div className="bg-cyan-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full mt-1"> {`${enProgress}%`}</div>
                </div></>) : (<></>)}
              </div>

              <div className="basis-3/4">
                <label htmlFor="hos_long" className="block text-sm font-medium text-gray-700">
                  Image AR
                </label>
                <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-medium text-cyan-600 focus-within:outline-none focus-within:ring-2 focus-cyan:ring-cyan-500 focus-within:ring-offset-2 hover:text-cyan-500"
                      >
                        <span>Upload a file</span>
                        <input id="file-upload" name="test_img_ar" type="file" onChange={handleArFileChange} className="sr-only" />
                        {imgArfileNamePath ? (
                          <img width={40} height={20} src={imgArfileNamePath} alt={values.test_img_ar} />) : (<></>)}

                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                  </div>
                </div>
                {arProgress ? (<><div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2">
                  <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full mt-1"> {`${arProgress}%`}</div>
                </div></>) : (<></>)}
              </div> */}



              <div className="basis-3/4 file_uploader">
                <label htmlFor="hos_long" className="block text-sm font-medium text-gray-700">
                  Image EN :
                </label>
                <FileUploader
                  multiple={false}
                  types={fileTypes}
                  handleChange={file => handleFileChange("enImage", file)}
                  name="file"
                />
                {imgEnfileNamePath ? (<img width={40} height={20} src={imgEnfileNamePath} alt={values.test_img_en} />) : (<></>)}
                {enProgress ? (<><div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2">
                  <div className="bg-cyan-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full mt-1"> {`${enProgress}%`}</div>
                </div></>) : (<></>)}

                {/* <label htmlFor="hos_long" className="block text-sm font-medium text-gray-700">Img EN : </label>
                <div className="mt-1 ">
                  <input type="file" accept="image/*" onChange={handleEnFileChange} />
                  {imgEnfileNamePath ? (
                    <img width={40} height={20} src={imgEnfileNamePath} alt={values.test_img_en} />) : (<></>)}

                  {enProgress ? (<><div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2">
                    <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full mt-1"> {`${enProgress}%`}</div>
                  </div></>) : (<></>)}
                </div> */}
              </div>

              <div className="basis-3/4 file_uploader">
                {/* <label htmlFor="hos_long" className="block text-sm font-medium text-gray-700">Img AR : </label>
                <div className="mt-1 ">
                  <input type="file" accept="image/*" onChange={handleArFileChange} />
                  {imgArfileNamePath ? (
                    <img width={40} height={20} src={imgArfileNamePath} alt={values.test_img_ar} />) : (<></>)}
                  {arProgress ? (<><div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2">
                    <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full mt-1"> {`${arProgress}%`}</div>
                  </div></>) : (<></>)}
                </div> */}
                <label htmlFor="test_img_ar" className="block text-sm font-medium text-gray-700">
                  Image AR :
                </label>
                <FileUploader
                  multiple={false}
                  types={fileTypes}
                  handleChange={file => handleFileChange("arImage", file)}
                  name="file"
                />
                {imgArfileNamePath ? (<img width={40} height={20} src={imgArfileNamePath} alt={values.test_img_ar} />) : (<></>)}
                {arProgress ? (<><div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2">
                  <div className="bg-cyan-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full mt-1"> {`${arProgress}%`}</div>
                </div></>) : (<></>)}
              </div>
              <h6 onClick={e => {
                onPreviewClick();
              }
              }
                className="underline cursor-pointer">Preview</h6>
              {/* <div className="basis-3/4">
                <div className="basis-3/4">
                  <label htmlFor="subTestStatus" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-3">Has Multiple Parameters{subTestStatus}</label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">

                    <Switch
                      checked={subTestStatus}
                      onChange={setSubTestStatus}
                      className={className(
                        subTestStatus ? "bg-cyan-600" : "bg-gray-200",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                      )}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className={className(
                          subTestStatus ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>

                  </div>
                </div>
              </div> */}

            </div>

            {/* {!subTestStatus && (
              <>
                <div className="flex flex-row mt-5 gap-5">
                  <div className="basis-3/4">
                    <label htmlFor="fk_rr_id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Reference Range</label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      {referanceList.length>0?(
                      <select
                        value={values.fk_rr_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                        name="fk_rr_id"
                        id="fk_rr_id"
                      >
                        <option >Select Reference Range</option>
                        {referanceList.map((referanceData, i) => (
                          <option selected={referanceData.mrr_id === values.fk_rr_id ? "selected" : ""} key={i} value={referanceData.mrr_id}>
                            {referanceData.mrr_name_en}
                          </option>
                        ))}
                      </select>):(<></>)}
                      {errors.fk_rr_id && touched.fk_rr_id ? (
                        <p className="text-red-600 text-sm">{errors.fk_rr_id}</p>
                      ) : null}
                    </div>
                  </div>
                
                </div>
              </>
            )} */}

            {/* <div className="flex flex-row mt-5 gap-5">
              <div className="basis-3/4">
                <label htmlFor="fk_rr_id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Reference Range</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  {referanceList.length>0?(
                  <select
                    value={values.fk_rr_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    name="fk_rr_id"
                    id="fk_rr_id"
                  >
                    <option >Select Reference Range</option>
                    {referanceList.map((referanceData, i) => (
                      <option selected={referanceData.mrr_id === values.fk_rr_id ? "selected" : ""} key={i} value={referanceData.mrr_id}>
                        {referanceData.mrr_name_en}
                      </option>
                    ))}
                  </select>):(<></>)}
                  {errors.fk_rr_id && touched.fk_rr_id ? (
                    <p className="text-red-600 text-sm">{errors.fk_rr_id}</p>
                  ) : null}
                </div>
              </div>
            </div> */}

            <hr />
            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div >
  );
};

export default AddTest;
