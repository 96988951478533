import React, {useEffect, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { toast } from 'react-toast';
import MasterServices from '../ApiServices/MasterServices';

const StarToggle = (module_id) => {
  const [isFavorited, setIsFavorited] = useState(false);

  const toggleFavorite = () => {
    console.log("module_id => ", module_id, isFavorited);
    setFavourite(module_id);
  };

  useEffect(()=>{
    const getFavs = JSON.parse(localStorage.getItem("favourites"));
    console.log("getFavs", getFavs, module_id);
    if(getFavs.length > 0){
      getFavs.map((data, index) => {
        if(data.module_id == module_id.module_id){
          setIsFavorited(data.fav_is_active);
        }
      })
    }
  }, [])

  const setFavourite = (module_id) => {
		MasterServices.setFavourite(module_id)
			.then((response) => {
				const { data, status } = response;
				if (status == 200) {
          console.log("ggxd", response.data);
          localStorage.setItem("favourites", JSON.stringify(response.data.data.favourites));
					toast.success(data.message)
          setIsFavorited(!isFavorited);
				} else {
					toast.error(data.message)
				}
			})
			.catch((error) => {
				const msg = "Failed catch Error";
				toast.error(msg);
			});
	}

  return (
    <div onClick={toggleFavorite}>
      <FaStar color={isFavorited ? 'gold' : 'grey'} />
    </div>
  );
};

export default StarToggle;