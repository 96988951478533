import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import { incentive_details } from '../../../components/tables/tableheader';
import { useParams } from 'react-router-dom';
import MasterServices from '../../../ApiServices/MasterServices';
import { toast } from 'react-toast';

const IncentiveDetails = () => {
    const pages = [{ title: "Incentive Details", href: "/incentive-details" }];
    const {m_user_id} = useParams()

    const [loading, setLoading] = useState(false)
    const [incentiveDetailsForUser, setIncentiveDetialsForUser] = useState(false)

    useEffect(()=>{
        getAllIncentiveLogsForMUser()
    },[])
    
    const getAllIncentiveLogsForMUser = async () =>{
        setLoading(true)
        try{
            let res = await MasterServices.getAllIncentiveLogsForMUser(m_user_id)
            if(res.status == 200){
                setIncentiveDetialsForUser(res.data.data)
            }
        }catch(e){
            toast.error(e.message)
        }
        setLoading(false)
    }

  return (
    <div>
        <div>
            <Breadcrumb pages={pages} />
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Incentive Details</h1>
                <p className="mt-2 text-sm text-gray-700">A list of all the Incentive Details.</p>
            </div>
            {loading ? (<FallingLinesLoader />) : (
                <Table
                    columns={incentive_details()}
                    data={incentiveDetailsForUser && incentiveDetailsForUser?.length > 0 ? incentiveDetailsForUser: []}
                />
            )}
        </div>
    </div>
  )
}

export default IncentiveDetails