import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { package_columns } from "../../../components/tables/tableheader";
import { toast } from 'react-toast'
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import MasterServices from '../../../ApiServices/MasterServices'
import { read, utils, writeFile } from 'xlsx';
import { FaSpinner } from "react-icons/fa";

function PackageList() {
  const pages = [{ title: "Package List", href: "/package", module_id: 7 }];
  const [loading, setLoading] = useState(true)
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    package_id: null,
    package_is_active: false,
  });
  const [packageList, setPackageList] = useState(false)
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState({
    status: false,
    package_id: null,
  });
  const [importLoader, setImportLoader] = useState(false);

  useEffect(() => {
    document.title = "Labib | Packages";
    setTimeout(() => {
      onPackageList('All');
    }, 500)
  }, []);

  const onPackageList = async (id) => {
    setLoading(true)
    MasterServices.getPackageList('All')
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setPackageList(data.data);
          } else {
            toast.error("Package Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });

  }

  const onChangeStatusOpen = (package_id, package_is_active) => {
    setConfirmationModal({ package_id, package_is_active, status: true });
  };

  const onDeleteOpen = (package_id) => {
    setDeleteConfirmationModal({ package_id, status: true });
  }


  const onStatusChange = async (package_id, package_is_active) => {
    let Newpackage_is_active = false;
    Newpackage_is_active = package_is_active == true ? false : true;
    let text = "Activated";
    if (Newpackage_is_active == true) {
      text = "Activated";
    } else {
      text = "In-Activated";
    }
    MasterServices.setPackageStatus({
      package_id,
      package_is_active: Newpackage_is_active
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Package " + text + " Successfully!")
          setConfirmationModal({ package_id: null, status: false });
          onPackageList();
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg)
      });
  }

  const onDeletePackage = async (package_id) => {
    MasterServices.deletePackage({
      package_id,
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Package Deleted Successfully!")
          setDeleteConfirmationModal({ package_id: null, status: false });
          onPackageList();
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error.meesage;
        toast.error(msg)
      });
  };

  const importPackagesAPICall = (data) => {
    const payload = { excel_data: data }
    // console.log("importPackagesAPICall", payload);
    // return false;
    MasterServices.importPackages(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          console.log("Imported package data:", data)
          toast.success("Packages Imported Successfully");
        } else {
          toast.error(data.message)
        }
        onPackageList('All');
        setImportLoader(false);
      })
      .catch((error) => {
        const msg = "Error while saving";
        toast.error(msg)
        setImportLoader(false);

      });
  }

  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      setImportLoader(true);
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          // console.log("handleImport in Package: ", rows);
          let newData = [];
          if (rows.length > 0) {
            rows.map((obj, index) => {
              if (obj.PACKAGE_NAME_EN != undefined || obj.PACKAGE_NAME_EN != null || obj.PACKAGE_NAME_EN != '') {
                const newObj = {
                  package_name_en: obj.PACKAGE_NAME_EN,
                  package_name_ar: obj.PACKAGE_NAME_AR ? obj.PACKAGE_NAME_AR : obj.PACKAGE_NAME_EN,
                  package_short_description_en: obj.PACKAGE_SHORT_DESCRIPTION_EN,
                  package_short_description_ar: obj.PACKAGE_SHORT_DESCRIPTION_AR,
                  package_long_description_en: obj.PACKAGE_LONG_DESCRIPTION_EN,
                  package_long_description_ar: obj.PACKAGE_LONG_DESCRIPTION_AR,
                  package_price: obj.PACKAGE_PRICE.toString(),
                  // package_sub_category: obj.package_sub_category,

                  // from_availability_date: obj.available_from_date,
                  // to_availability_date: obj.available_to_date, 
                  // package_test_names: obj.package_test_names
                  // fk_test_id: filterTest(obj.test_name_english.toLowerCase())
                };
                newData.push(newObj);
              }
            });
            // console.log("newArray: ", newData);
            // const filteredArray = newData.filter(obj => obj.fk_rr_id !== undefined && obj.fk_test_id !== undefined);
            // console.log("filteredArray: ", filteredArray);
            if (newData.length > 0) {
              // console.log("newData", newData)
              importPackagesAPICall(newData);
            } else {
              setImportLoader(false);
            }
          } else {
            toast.success("Excel is empty")
            setImportLoader(false);
          }
        }
      }
      reader.readAsArrayBuffer(file);
    }
  }

  function removeDuplicatesByTestName(arr) {
    const seen = new Set();
    const uniqueArray = arr.filter(item => {
      const duplicate = seen.has(item?.package_name_en);
      seen.add(item?.package_name_en);
      return !duplicate;
    });
    return uniqueArray;
  }

  const handleExport = (excel_type) => {
    console.log("packageList", packageList);
    if (packageList?.length > 0) {
      let newSheetdata = [];
      const uniqueArray = removeDuplicatesByTestName(packageList)
      uniqueArray.map((obj, index) => {

        let SN = parseInt(index) + parseInt(1);
        let PACKAGE_NAME_EN = (obj?.package_name_en);
        let PACKAGE_NAME_AR = (obj?.package_name_en);
        let PACKAGE_PRICE = (obj?.package_price);
        let PACKAGE_SHORT_DESCRIPTION_EN = (obj?.package_short_description_en);
        let PACKAGE_SHORT_DESCRIPTION_AR = (obj?.package_short_description_ar);
        let PACKAGE_LONG_DESCRIPTION_EN = (obj?.package_long_description_en);
        let PACKAGE_LONG_DESCRIPTION_AR = (obj?.package_long_description_en);

        // let TEST_SMART_REPORT_DESCRIPTION_EN = (obj?.t_test_relation?.test_smart_report_description_en);
        // let TEST_SMART_REPORT_DESCRIPTION_AR = (obj?.t_test_relation?.test_smart_report_description_ar);
        // let TEST_SHORT_DESCRIPTION_EN = (obj?.t_test_relation?.test_short_description_en);
        // let TEST_SHORT_DESCRIPTION_AR = (obj?.t_test_relation?.test_short_description_ar);
        // let TEST_TIPS_DESCRIPTION_EN = (obj?.t_test_relation?.test_tips_description_en);
        // let TEST_TIPS_DESCRIPTION_AR = (obj?.t_test_relation?.test_tips_description_ar);
        // let TEST_TAT = (obj?.t_test_relation?.test_tat);
        // let TEST_STAT = (obj?.t_test_relation?.test_stat);
        // let IS_MICROBIOLOGY = (obj?.t_test_relation?.is_microbiology);
        // // let Reference_Range = (obj?.reference_ranges_relation?.mrr_name_en);
        // // let Smart_Report_Description_EN = removeTagsFromString(obj?.ttp_smart_report_description_en);test_smart_report_description_en
        // // let Smart_Report_Description_AR = removeTagsFromString(obj?.ttp_smart_report_description_ar);
        // // let Short_Description_EN = removeTagsFromString(obj?.ttp_short_description_en);
        // // let Short_Description_AR = removeTagsFromString(obj?.ttp_short_description_ar);
        // // let Date = moment(convertToAnotherTimezone(obj.StartDateF)).format('DD-MM-YYYY') + " / " + moment(convertToAnotherTimezone(obj.EndDateF)).format('DD-MM-YYYY');
        // // let MonthName = moment(obj.StartDateF).toLocaleString(undefined, { month: 'long' });
        const newData = {
          SN: SN,
          PACKAGE_NAME_EN,
          PACKAGE_NAME_AR,
          PACKAGE_PRICE,
          PACKAGE_SHORT_DESCRIPTION_EN,
          PACKAGE_SHORT_DESCRIPTION_AR,
          PACKAGE_LONG_DESCRIPTION_EN,
          PACKAGE_LONG_DESCRIPTION_AR,
          // TEST_CODE: TEST_CODE,
          // TEST_COLOR: TEST_COLOR,
          // HOSPITAL_PRICE,
          // TEST_SMART_REPORT_DESCRIPTION_EN,
          // TEST_SMART_REPORT_DESCRIPTION_AR,
          // TEST_SHORT_DESCRIPTION_EN,
          // TEST_SHORT_DESCRIPTION_AR,
          // TEST_TIPS_DESCRIPTION_EN,
          // TEST_TIPS_DESCRIPTION_AR,
          // TEST_TAT,
          // TEST_STAT,
          // IS_MICROBIOLOGY
          // Reference_Range: Reference_Range,
          // Smart_Report_Description_EN: Smart_Report_Description_EN,
          // Smart_Report_Description_AR: Smart_Report_Description_AR,
          // Short_Description_EN: Short_Description_EN,
          // Short_Description_AR: Short_Description_AR,
        }
        newSheetdata.push(newData);
      })
      let fileName = 'ALL_Package_LIST_Report_' + '.' + excel_type;
      const headings = [['SN',
        'PACKAGE_NAME_EN', 'PACKAGE_NAME_AR',
        'PACKAGE_PRICE', 'PACKAGE_SHORT_DESCRIPTION_EN',
        'PACKAGE_SHORT_DESCRIPTION_AR', 'PACKAGE_LONG_DESCRIPTION_EN',
        'PACKAGE_LONG_DESCRIPTION_AR'
        // 'TEST_CODE', 'TEST_COLOR', 'HOSPITAL_PRICE', 
        // 'TEST_SMART_REPORT_DESCRIPTION_EN',
        // 'TEST_SMART_REPORT_DESCRIPTION_AR',
        // 'TEST_SHORT_DESCRIPTION_EN',
        // 'TEST_SHORT_DESCRIPTION_AR',
        // 'TEST_TIPS_DESCRIPTION_EN',
        // 'TEST_TIPS_DESCRIPTION_AR',
        // 'TEST_TAT',
        // 'TEST_STAT',
        // 'IS_MICROBIOLOGY'
      ]];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        title={"Are you sure you want to " + (confirmationModal.package_is_active ? "Disable " : "Activate") + " this Package?"}
        confirmationButtonText="Yes"
        // description={"Do you really want to " + (confirmationModal.package_is_active ? "In-Activate? " : "Activate?")+" Package"}
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onStatusChange(confirmationModal.package_id, confirmationModal.package_is_active)}
      />
      <ConfirmationModal
        title={"Are you sure you want to delete this Package?"}
        confirmationButtonText="Yes"
        // description={"Do you really want to " + (confirmationModal.package_is_active ? "In-Activate? " : "Activate?")+" Package"}
        open={deleteConfirmationModal.status}
        setOpen={setDeleteConfirmationModal}
        onDelete={() => onDeletePackage(deleteConfirmationModal.package_id)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Package List</h1>

      </div>
      <div className="mt-4 flex">
        {!importLoader ?
          <input type="file"
            onChange={handleImport}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
            placeholder="Import File"
          />
          :
          <>Importing ..<FaSpinner icon="spinner" className="spinner ml-2" /></>
        }
        <Link
          to="/add-package"
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </Link>
        {packageList?.length > 0 ? (
          <>
            <button
              onClick={() => handleExport('xlsx')}
              className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 justify-items-end">
              Generate Excel
            </button>

          </>

        ) : (<></>)}
      </div>
      {
        loading ? (
          <FallingLinesLoader />
        ) : ((packageList.length > 0) ? (
          <Table
            zoom='zoom-075'
            columns={package_columns({ onDeleteOpen, onChangeStatusOpen })}
            data={packageList}
          />
        ) : <TableEmpty />)
      }

    </div >
  );
}
export default PackageList;