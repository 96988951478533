import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { OrganismSchema } from "../../../schemas";
import Table from "../../../components/tables/table";
import { organism_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toast";
import MasterServices from "../../../ApiServices/MasterServices";
import OrganismAntiServices from "../../../ApiServices/OrganismAntiServices";
import { read, utils, writeFile } from "xlsx";
import CustomButton from "../../../components/common/CustomButton";
import ExcelServices from "../../../ApiServices/ExcelServices";

function Organisms() {
  const pages = [
    { title: "Organism List", href: "/organisms", module_id: null },
  ];

  const [organismList, setOrganismList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpenFlag, setmodalOpenFlag] = useState(false);
  const [org_id, setOrganismId] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    org_id: null,
  });
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Labib | Organisms";
    getAllOrganisms(3);
  }, []);

  const downloadSample = async () => {
    try {
      const response = await ExcelServices.getOrganismSampleExcel();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "LabAnalyzerSampleTemplate.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadOrganismExcel = async () => {
    try {
      const response = await ExcelServices.downloadOrganismExcel();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Organisms.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllOrganisms = async (payload) => {
    try {
      setIsLoading(true);
      // const { data } = await MasterServices.getAllOrganisms(payload);
      const { data } = await OrganismAntiServices.getAllOrganismList(payload);
      console.log("All Organisms Data", data);
      if (data.status) {
        setOrganismList(data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const deleteOrganism = async (payload) => {
    try {
      setIsLoading(true);
      const { data } = await MasterServices.deleteOrganism(payload);
      if (data.status) {
        getAllOrganisms(3);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const addOrganism = async () => {
    try {
      setIsLoading(true);
      console.log("Add Organism");
      let body = {
        org_id: org_id,
        org_name_en: values.org_name_en,
        org_name_ar: values.org_name_ar,
      };
      if (!org_id) {
        delete body.org_id;
      }
      const res = await MasterServices.saveOrganism(body);
      if (res.status) {
        toast.success(res?.data?.message);
        getAllOrganisms(3);
      }
      console.log("Add Organism Response", res);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const initialValues = {
    org_id: "",
    org_name_en: "",
    org_name_ar: "",
  };

  const [formOrganism, setFormOrganism] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    if ((type === "edit", id)) {
      setOrganismId(id);

      const initialValues = {
        org_id: obj.org_id,
        org_name_en: obj.org_name_en,
        org_name_ar: obj.org_name_ar,
      };
      setFormOrganism(initialValues);
    } else {
      // setSelectedLocationId('')
      setOrganismId("");
      setFormOrganism(initialValues);
    }
    if (modalOpenFlag === false) {
      setmodalOpenFlag(true);
    }
  };
  const onDeleteOpen = (org_id) => {
    setConfirmationModal({ org_id, status: true });
  };

  const onDeleteOrganism = (org_id) => {
    setConfirmationModal({ org_id: null, status: false });
  };

  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          console.log("Organism handleImport: ", rows);
          let newData = [];
          if (rows.length > 0) {
            rows.map((obj, index) => {
              if (obj.organism_name_english != undefined) {
                const newObj = {
                  org_name_en: obj.organism_name_english,
                  org_name_ar: obj.organism_name_arabic
                    ? obj.organism_name_arabic
                    : obj.organism_name_english,
                };

                newData.push(newObj);
              }
            });

            console.log("newArray: ", newData);

            if (newData.length > 0) {
              importOrganism(newData);
            }
          } else {
            toast.success("Excel is empty");
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const importOrganism = (data) => {
    const payload = { excel_data: data };
    MasterServices.importOrganism(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Organisms Imported Successfully");
          getAllOrganisms(3);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const msg = "Error while saving";
        toast.error(msg);
      });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formOrganism,
      validationSchema: OrganismSchema,
      onSubmit: (values, action) => {
        console.log("Submit Clicked", values);
        addOrganism();

        action.resetForm();
        if (modalOpenFlag === true) {
          setmodalOpenFlag(false);
        }
        navigate("/organisms");
      },
    });
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => {
          onDeleteOrganism(confirmationModal.org_id);
          deleteOrganism({
            org_id: confirmationModal.org_id,
            org_is_active: false,
          });
        }}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Organisms</h1>
        <p className="mt-2 text-sm text-gray-700">
          A list of all the Organisms.
        </p>
      </div>
      <div className="mt-4 flex"></div>

      <div className="mt-4 flex items-center justify-end gap-3">
        <input
          type="file"
          onChange={handleImport}
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
          placeholder="Import File"
        />

        {/* <Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add Organism
        </Link> */}

        <CustomButton onClick={() => handleDrawer("add", "", {})}>
          Add Organism
        </CustomButton>

        <CustomButton onClick={() => downloadOrganismExcel()}>Export</CustomButton>
        <CustomButton onClick={() => { }}>Import</CustomButton>
        <CustomButton onClick={() => downloadSample()}>
          Download Sample
        </CustomButton>
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={organism_columns({ onDeleteOpen, handleDrawer })}
          data={organismList?.length > 0 && !isLoading ? organismList : []}
        />
      )}

      {isLoading ? null : (
        <div>
          <Transition.Root show={modalOpenFlag} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-30"
              onClose={setmodalOpenFlag}
            >
              <div className="fixed inset-0" />
              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(e);
                          }}
                          className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                        >
                          <div className="h-0 flex-1 overflow-y-auto">
                            <div className="bg-cyan-700 py-6 px-4 sm:px-6">
                              <div className="flex items-center justify-between">
                                <Dialog.Title className="text-lg font-medium text-white">
                                  {org_id ? "Update" : "Add"} Organism
                                </Dialog.Title>
                                <div className="ml-3 flex h-7 items-center">
                                  <button
                                    type="button"
                                    className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
                                    onClick={() => setmodalOpenFlag(false)}
                                  >
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 sm:p-6">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="org_name_en"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Organism Name English
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.org_name_en}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Organism Name En"
                                      name="org_name_en"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.org_name_en &&
                                      touched.org_name_en ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.org_name_en}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="org_name_en"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Organism Name Arabic
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.org_name_ar}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Organism Name Ar"
                                      name="org_name_ar"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.org_name_ar &&
                                      touched.org_name_ar ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.org_name_ar}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            <button
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              onClick={() => {
                                setmodalOpenFlag(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            >
                              {org_id ? "Update" : "Add"}
                            </button>
                          </div>
                        </form>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      )}
    </div>
  );
}
export default Organisms;
