import React, { useState, Fragment, useRef, useEffect, } from 'react'
import { Link } from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import TableEmpty from '../../../components/tables/tableEmpty';
import { outsource_clinic_columns } from '../../../components/tables/tableheader';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getAllClinics } from '../../../redux/clinic/actions';
import MasterServices from '../../../ApiServices/MasterServices'


const OutSourcedClinic = () => {
    const pages = [{ title: "Outsourced Clinic List", href: "/outsourced-clinic", module_id: 14 }];
    const [loading, setLoading] = useState(false);
    const [outsourceClinicList, setOutsourceClinicList] = useState([])

    async function getOutsourceClinicList() {
        setLoading(true)
        try {
            const clinicsFromApi = await MasterServices.getClinicList();
            setOutsourceClinicList(clinicsFromApi.data.data)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    useEffect(() => {
        getOutsourceClinicList();
    }, [])

    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8">
                <Breadcrumb pages={pages} />
                <div className="">
                    <h1 className="text-xl font-semibold text-gray-900">Clinic List</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the Outsourced Clinic.</p>
                </div>
                <div className="mt-4 flex">
                    <Link
                        to="/add-outsourced-clinic"
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
                    >
                        Add
                    </Link>
                </div>
                {
                    loading ? (
                        <FallingLinesLoader />
                    ) :
                        <Table
                            columns={outsource_clinic_columns()}
                            data={outsourceClinicList}
                        />
                }
            </div>
        </>
    )
}

export default OutSourcedClinic