import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { outclinic_invoice_columns } from "../../../components/tables/tableheader";
import ReportServices from "../../../ApiServices/ReportServices";
import { toast } from "react-toast";
import Select from "react-select";
import MasterServices from "../../../ApiServices/MasterServices";

const initialInvoiceFilters = {
  // start_date: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
  // end_date: moment(new Date()).format("YYYY-MM-DD"),
  ocs: [],
  // interval: "MONTHLY"
};

// const payment_interval = ["MONTHLY", "QUARTERLY", "BI-ANNUALLY", "YEARLY"];

const Invoice = () => {
  const pages = [{ title: "Invoices List", href: "/invoice", module_id: 17 }];

  const [isLoading, setIsLoading] = useState(true);
  // const [filterFlag, setFilterFlag] = useState(false);
  const [outSourceReservationReportList, setOutSourceReservationReportList] =
    useState([]);

  const [invoiceFilters, setInvoiceFilters] = useState(initialInvoiceFilters);
  const [outsourceClinicList, setOutsourceClinicList] = useState(false);
  const [ocLoading, setOCLoading] = useState(true);
  const [selectedOCs, setSelectedOCs] = useState(false);

  useEffect(() => {
    // get all clinics
    getOutsourceClinicList();
    getOSReservationList();
  }, []);

  const getOSReservationList = (payload) => {
    setIsLoading(true);
    ReportServices.getAllORReportList(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          setOutSourceReservationReportList(data.data.RangeClinicData);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = "Fatal Error Ousource Reservation Report List";
        toast.error(msg);
        setIsLoading(false);
      });
  };

  const generateOSInvoiceReport = async (payload) => {
    payload.TestPackListData = [];
    let res = await ReportServices.generateOSInvoiceReport(payload);
    console.log("reached hererererere");

    if (res.status == 200) {
      window.open(res.data.data, "_blank");
      toast.success(res.data.message);
      getOSReservationList();
    } else {
      toast.error(res.data.message);
    }
  };

  async function getOutsourceClinicList() {
    setOCLoading(true);
    try {
      const { data } = await MasterServices.getClinicList();
      if (data.status) {
        let newOCs = data.data;
        let OCs = newOCs.map((el) => {
          let obj = { ...el, label: el.mc_name, value: el.mc_id };
          return obj;
        });
        setOutsourceClinicList(OCs);
      }

      setOCLoading(false);
    } catch (error) {
      console.log(error);
      setOCLoading(false);
    }
  }

  const handleMultiOCs = (event) => {
    setSelectedOCs(event);
    let ocs = [];
    for (let i = 0; i < event.length; i++) {
      ocs.push(event[i].mc_id);
    }
    setInvoiceFilters((prev) => {
      return {
        ...prev,
        ocs,
      };
    });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Invoices List</h1>
        <p className="mt-2 text-sm text-gray-700">
          A list of all the Invoices.
        </p>
      </div>

      {/* Clinic and Date Range Filter */}
      <div className="mt-5 flex gap-3 items-center">
        {/* start date */}
        {/* <div className=" sm:mt-0">
          <label
            htmlFor="fk_location_ids"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Date From
          </label>

          <input
            disabled={isLoading}
            className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
            type="date"
            name="start_date"
            id="start_date"
            value={invoiceFilters.start_date}
            onChange={(e) => {
              setInvoiceFilters((prev) => {
                if (prev.end_date < e.target.value) {
                  return {
                    ...prev,
                    end_date: e.target.value,
                    start_date: prev.end_date,
                  };
                }
                return {
                  ...prev,
                  start_date: e.target.value,
                };
              });
            }}
          />
        </div> */}

        {/* end date */}
        {/* <div className=" sm:mt-0">
          <label
            htmlFor="fk_location_ids"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Date To
          </label>

          <input
            disabled={isLoading}
            className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
            type="date"
            name="end_date"
            id="end_date"
            value={invoiceFilters.end_date}
            onChange={(e) => {
              setInvoiceFilters((prev) => {
                if (prev.start_date > e.target.value) {
                  return {
                    ...prev,
                    end_date: prev.start_date,
                    start_date: e.target.value,
                  };
                }
                return {
                  ...prev,
                  end_date: e.target.value,
                };
              });
            }}
          />
        </div> */}

        {!ocLoading ? (
          <div className="">
            <label
              htmlFor="mc_ids"
              className="w-60 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Clinics
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Select
                // defaultValue={testList[0]}
                isMulti
                name="mc_ids"
                id="mc_ids"
                value={selectedOCs}
                onChange={(e) => handleMultiOCs(e)}
                options={outsourceClinicList}
                className="basic-multi-select"
                classNamePrefix="Select Test"
              />
            </div>
          </div>
        ) : null}

        {/* <div>
          <div className="basis-1/3">
            <label
              htmlFor="mc_interval"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Payment Interval
            </label>
            <select
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
              name="mc_interval"
              id="mc_interval"
              value={invoiceFilters.interval}
              onChange={(e) => {
                setInvoiceFilters((p) => {
                  return { ...p, interval: e.target.value };
                });
              }}
              placeholder="Select Interval"
            >
              <option disabled={true} selected defaultValue={""}>
                {" "}
                Select Interval{" "}
              </option>
              {payment_interval?.map((data, i) => (
                <option
                  selected={data === invoiceFilters.interval ? "selected" : ""}
                  key={i}
                  value={data}
                >
                  {data}
                </option>
              ))}
            </select>
          </div>
        </div> */}

        <button
          disabled={isLoading}
          onClick={() => {
            getOSReservationList(invoiceFilters);
          }}
          className={`ml-3 h-10 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 mt-7 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${isLoading ? "opacity-40" : "opacity-100"
            }`}
        >
          Get Data
        </button>
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : outSourceReservationReportList.length > 0 ? (
        <Table
          zoom='zoom-075'
          columns={outclinic_invoice_columns({ generateOSInvoiceReport })}
          data={outSourceReservationReportList}
        />
      ) : (
        <TableEmpty />
      )}
    </div>
  );
};

export default Invoice;
