import React, { useEffect, useState } from "react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import moment from "moment";
import RegionResBarChart from "../../../components/charts/regionResBarChart";

export default function RegionChart({
	isRegionDataLoading,
	rSalesChartFilters,
	setRSalesChartFilters,
	getRSalesData,
	regionSalesData,
	regionSalesLabel,
	norrs,
	dateFilters,
	isLoading,
}) {

	const [showCustomDates, setShowCustomDates] = useState(false)

	useEffect(() => {
		getRSalesData();
	}, [rSalesChartFilters]);


	const handleDateFilterChange = (e) => {
		if (e.target.value == 1 && e.target[e.target.value - 1].innerHTML == "Today") {
			setShowCustomDates(false);
			setRSalesChartFilters({
				start_date: moment(new Date()).format("YYYY-MM-DD"),
				end_date: moment(new Date()).format("YYYY-MM-DD")
			});
		} else if (e.target.value == 2 && e.target[e.target.value - 1].innerHTML == "Yesterday") {
			setShowCustomDates(false);
			setRSalesChartFilters({
				start_date: moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
				end_date: moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
			});
		} else if (e.target.value == 3 && e.target[e.target.value - 1].innerHTML == "Last Month") {
			setShowCustomDates(false);
			setRSalesChartFilters({
				start_date: moment(new Date()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
				end_date: moment(new Date()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
			});
		} else if (e.target.value == 4 && e.target[e.target.value - 1].innerHTML == "This Month") {
			setShowCustomDates(false);
			setRSalesChartFilters({
				start_date: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
				end_date: moment(new Date()).endOf('month').format('YYYY-MM-DD')
			});
		} else if (e.target.value == 5 && e.target[e.target.value - 1].innerHTML == "Last Week") {
			setShowCustomDates(false);
			setRSalesChartFilters({
				start_date: moment(new Date()).subtract(1, 'week').startOf('week').subtract(1, "day").format('YYYY-MM-DD'),
				end_date: moment(new Date()).subtract(1, 'week').endOf('week').subtract(1, "day").format('YYYY-MM-DD'),
			});
		}
		else if (e.target.value == 6 && e.target[e.target.value - 1].innerHTML == "This Week") {
			setShowCustomDates(false);
			setRSalesChartFilters({
				start_date: moment(new Date()).startOf('week').subtract(1, "day").format('YYYY-MM-DD'),
				end_date: moment(new Date()).endOf('week').subtract(1, "day").format('YYYY-MM-DD'),
			});
		}
		else if (e.target.value == 7 && e.target[e.target.value - 1].innerHTML == "Custom") {
			setShowCustomDates(true);
		}
	}

	return (
		<div
			className={`overflow-hidden bg-white shadow sm:rounded-lg mt-5 ${isRegionDataLoading ? "text-gray-300" : "text-gray-500"
				}`}
		>
			<div className="px-4 py-5 sm:px-6">
				<h3 className="text-lg font-medium leading-6 text-gray-900">
					Regional Reservations and Sales
				</h3>
			</div>

			<div className="border-t border-gray-200 px-4 py-5 sm:p-0">
				<div className="mt-5 flex justify-end items-center px-4 sm:px-6 gap-3">

					<select
						className="rounded-md border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50 w-60"
						name='date_filter'
						id='date_filter'
						// value={}
						defaultValue={1}
						onChange={(e) => {
							//   setFilter(e.target.value || undefined);
							handleDateFilterChange(e)
						}}
					>
						{dateFilters.map((option, i) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>

					{/* Custome Dates */}
					{
						showCustomDates ?
							<>

								<div className="">
									<input
										disabled={isRegionDataLoading}
										className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-full sm:text-sm"
										type="date"
										name="start_date"
										id="start_date"
										value={rSalesChartFilters.start_date}
										onChange={(e) => {
											setRSalesChartFilters((prev) => {
												if (
													prev.end_date < e.target.value
												) {
													return {
														...prev,
														end_date: e.target.value,
														start_date: prev.end_date,
													};
												}
												return {
													...prev,
													start_date: e.target.value,
												};
											});
										}}
									/>
								</div>

								{/* end date */}
								<div className="">
									<input
										disabled={isRegionDataLoading}
										className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-full sm:text-sm"
										type="date"
										name="end_date"
										id="end_date"
										value={rSalesChartFilters.end_date}
										onChange={(e) => {
											setRSalesChartFilters((prev) => {
												if (
													prev.start_date > e.target.value
												) {
													return {
														...prev,
														end_date: prev.start_date,
														start_date: e.target.value,
													};
												}
												return {
													...prev,
													end_date: e.target.value,
												};
											});
										}}
									/>
								</div>

								{/* <button
									disabled={isRegionDataLoading}
									onClick={() => {
										getRSalesData();
									}}
									className={`ml-3 h-10 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${isRegionDataLoading
										? "opacity-40"
										: "opacity-100"
										}`}
								>
									Get Data
								</button> */}
							</>
							: null
					}
				</div>

				{
					!showCustomDates ? <div className="flex px-8 pt-2 justify-end gap-3">
						<div>
							{moment(rSalesChartFilters.start_date).format('ddd')}, {moment(rSalesChartFilters.start_date).format("MMMM Do YYYY")} - {moment(rSalesChartFilters.end_date).format('ddd')}, {moment(rSalesChartFilters.end_date).format("MMMM Do YYYY")}
						</div>

					</div> : null
				}


				{/* Region Reservation Chart */}
				{regionSalesData && !isRegionDataLoading ? (
					<div className="text-center pl-8 py-8 h-auto">
						{/* <div className="basis-1/2"> */}
						<div className="mx-auto">
							<RegionResBarChart
								salesData={regionSalesData}
								numberOfReservationsData={norrs}
								salesLabel={regionSalesLabel}
							/>
							{/* </div> */}
						</div>

					</div>
				) : (
					<FallingLinesLoader />
				)}
			</div>
		</div>
	);
}
