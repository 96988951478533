//With live server
//export const BASEURL = window.location.hostname.includes("localhost") ? process.env.LOCAL_API_URL : process.env.LIVE_API_URL;
//Without .env
// export const BASEURL = "https://api-tabib.enpointe.io/";
// // Project Name
// export const dashboardName = "Labib App";
//With live server
//export const BASEURL = window.location.hostname.includes("localhost") ? process.env.LOCAL_API_URL : process.env.LIVE_API_URL;
//Without .env
// export const BASEURL = window.location.hostname.includes("localhost") ? ("http://localhost:8004") : (window.location.hostname.includes("tabibdesk")?"https://api.tabibdesk.com/":"https://api-tabib.enpointe.io/");
// export const BASEURL = window.location.hostname.includes("localhost") ? ("http://api-prod.mylabib.com") : (window.location.hostname.includes("tabiib")?"https://api-labs.tabiib.com":"https://api-tabib.enpointe.io/");
// export const BASEURL = window.location.hostname.includes("localhost") ? ("http://localhost:8004") : (window.location.hostname.includes("tabiib")?"https://api-labs.tabiib.com":"https://api-tabib.enpointe.io/");
// export const BASEURL = window.location.hostname.includes("localhost") ? "http://localhost:8004" : "https://api-tabib.enpointe.io/";
// export const Live_BASEURL= "https://api-prod.mylabib.com"
// export const BASEURL= "http://localhost:8009"
export const BASEURL = process.env.REACT_APP_API_URL;
export const timeZone = "Asia/Riyadh";
// Project Name
export const dashboardName = "Labib App";

//With live server
//export const BASEURL = window.location.hostname.includes("localhost") ? process.env.LOCAL_API_URL : process.env.LIVE_API_URL;

//Without .env
// export const BASEURL = "https://api-tabib.enpointe.io/";

// // Project Name
// export const dashboardName = "Labib App";

//With live server
//export const BASEURL = window.location.hostname.includes("localhost") ? process.env.LOCAL_API_URL : process.env.LIVE_API_URL;
//Without .env
// export const BASEURL = window.location.hostname.includes("localhost") ? ("http://localhost:8004") : (window.location.hostname.includes("tabibdesk")?"https://api.tabibdesk.com/":"https://api-tabib.enpointe.io/");
// export const BASEURL = window.location.hostname.includes("localhost") ? ("http://api-prod.mylabib.com") : (window.location.hostname.includes("tabiib")?"https://api-labs.tabiib.com":"https://api-tabib.enpointe.io/");
// export const BASEURL= "https://api-prod.mylabib.com"
// export const BASEURL = window.location.hostname.includes("localhost") ? "http://localhost:8004" : "https://api-prod.mylabib.com";
// export const timeZone = "Asia/Riyadh";
// Project Name
// export const dashboardName = "Labib App";
