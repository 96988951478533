export const GET_ALL_QUESTIONNAIRE_BEGIN = "GET_ALL_QUESTIONNAIRE_BEGIN"
export const GET_ALL_QUESTIONNAIRE_SUCCESS = "GET_ALL_QUESTIONNAIRE_SUCCESS"
export const GET_ALL_QUESTIONNAIRE_ERROR = "GET_ALL_QUESTIONNAIRE_ERROR"

export const ADD_QUESTIONNAIRE_BEGIN = "ADD_QUESTIONNAIRE_BEGIN"
export const ADD_QUESTIONNAIRE_SUCCESS = "ADD_QUESTIONNAIRE_SUCCESS"
export const ADD_QUESTIONNAIRE_ERROR = "ADD_QUESTIONNAIRE_ERROR"

export const GET_QUESTIONNAIRE_BEGIN = "GET_QUESTIONNAIRE_BEGIN"
export const GET_QUESTIONNAIRE_SUCCESS = "GET_QUESTIONNAIRE_SUCCESS"
export const GET_QUESTIONNAIRE_ERROR = "GET_QUESTIONNAIRE_ERROR"

export const UPDATE_QUESTIONNAIRE_BEGIN = "UPDATE_QUESTIONNAIRE_BEGIN"
export const UPDATE_QUESTIONNAIRE_SUCCESS = "UPDATE_QUESTIONNAIRE_SUCCESS"
export const UPDATE_QUESTIONNAIRE_ERROR = "UPDATE_QUESTIONNAIRE_ERROR"

export const DELETE_QUESTIONNAIRE_BEGIN = "DELETE_QUESTIONNAIRE_BEGIN"
export const DELETE_QUESTIONNAIRE_SUCCESS = "DELETE_QUESTIONNAIRE_SUCCESS"
export const DELETE_QUESTIONNAIRE_ERROR = "DELETE_QUESTIONNAIRE_ERROR"