import React, { useRef, useState } from "react";
import Button from "../Button";
import * as Yup from "yup";
import { Formik } from "formik";
const EditTooptip = ({ fieldName, CellId, qres_id, func, Nfunc }) => {
  const [btnText, setBtnText] = useState("Save");
  const initialValues = {
    name: "",
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        name: Yup.string().required("value is required"),
      })}
      enableReinitialize={true}
      onSubmit={(values) => {
        setBtnText("Saving...");
        if (qres_id) {
          const body = {
            [fieldName]: values.name,
            fk_qres_id: CellId,
            qres_id: parseInt(qres_id),
          };
          func(body);
          setBtnText("Saved !");
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <div className="flex w-max gap-2 Z-[9999]">
          <form className="flex gap-2" onSubmit={handleSubmit}>
            <div>
              <input
                className={` w-[90%]appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm`}
                placeholder="Enter Correction Value"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
              />
              {errors.name && touched.name && (
                <p
                  className="text-red-500 text-sm
              "
                >
                  {errors.name}
                </p>
              )}
            </div>
            <Button type="submit">{btnText}</Button>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default EditTooptip;
