import { useState } from 'react'
import { read, utils, writeFile } from 'xlsx';
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import TableEmpty from '../../../components/tables/tableEmpty';
import Table from '../../../components/tables/table';
import { clinic_refferal, coupon_code_list } from '../../../components/tables/tableheader';

export default function CouponCodes({ couponCodeData, isLoading }) {

    const handleCouponCodeDownload = () => {
        if (couponCodeData.length > 0) {

            let newSheetdata = [];
            couponCodeData.map((obj, index) => {
                const newData = {
                    Coupon_Code: obj.cpc_code,
                }
                newSheetdata.push(newData);
            })
            let fileName = 'CouponCodes.xlsx';

            const headings = [['Coupon_Codes']];
            const wb = utils.book_new();
            const ws = utils.json_to_sheet([]);

            const columnWidths = [
                { wch: 15 }
            ];
            ws['!cols'] = columnWidths;

            utils.sheet_add_aoa(ws, headings);
            utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
            utils.book_append_sheet(wb, ws, 'Report');

            const range = utils.decode_range(ws['!ref']);
            
            for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
                const hasData = ws[utils.encode_cell({ r: range.s.r + 1, c: colIndex })];
                if (hasData) {
                    const columnAddress = utils.encode_col(colIndex);
                    ws[`${columnAddress}1`].s = { border: { bottom: { style: 'thin', color: { rgb: '000000' } } } };
                }
            }

            writeFile(wb, fileName); //'PriceListDetails.xlsx'
        }
    }


    return (
        couponCodeData && !isLoading ? (
            <div>
                <div className='mt-5 py-3 h-auto overflow-y-auto'>
                    {/* <h3 className="text-base font-semibold leading-6 text-gray-900">Coupon Codes</h3> */}
                    {/* <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-5">
                        {couponCodeData.map((cc) => {
                            return (
                                <div key={cc.cpc_id} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"> */}
                                    {/* <dt className="truncate text-sm font-medium text-gray-500">{cc.cpc_id}</dt> */}
                                    {/* <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{cc.cpc_code}</dd>
                                </div>
                            )
                        })} 
                    </dl> */}
                                    {
                    isLoading ? (
                        <FallingLinesLoader />
                    ) : ((couponCodeData.length > 0) ? (
                        <Table
                            columns={coupon_code_list({})}
                            data={couponCodeData}
                        />
                    ) : <TableEmpty />)
                }
                </div>
                <button
                    className="mt-4 inline-flex  items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
                    onClick={handleCouponCodeDownload}
                >
                    Download Coupon Codes
                </button>
            </div>
        )
            : <div>
                Loading Coupon Codes
            </div>
    )
}
