/* eslint-disable */
import { toast } from 'react-toast'
import {
  GET_ALL_NOTIFICATION_BEGIN,
  GET_ALL_NOTIFICATION_SUCCESS,
  GET_ALL_NOTIFICATION_ERROR,
  ADD_NOTIFICATION_BEGIN,
  ADD_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION_ERROR,
  UPDATE_NOTIFICATION_STATUS_BEGIN,
  UPDATE_NOTIFICATION_STATUS_SUCCESS,
  UPDATE_NOTIFICATION_STATUS_ERROR
} from './constants'
import MasterServices from '../../ApiServices/MasterServices'

export const getAllNotifications = (take) => async (dispatch) => {
  dispatch({
    type: GET_ALL_NOTIFICATION_BEGIN
  })
  MasterServices.getNotificationList(take)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        if (Array.isArray(data.data) && data.data.length > 0) {
          data.data = data.data.map((v) => ({...v, index: [0, v.notify_status? 1 : 2]}))
        }
        dispatch({
          type: GET_ALL_NOTIFICATION_SUCCESS,
          data: data.data.length > 0 ? data.data : []
        })
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error;
      toast.error(msg)
      dispatch({
        type: GET_ALL_NOTIFICATION_ERROR,
        error: msg
      })
    });
}

export const addNotification = (body) => async (dispatch) => {
  dispatch({
    type: ADD_NOTIFICATION_BEGIN
  })
  try {
    const { data, status } = await MasterServices.saveNotification(body);
    if (status == 200) {
      dispatch({
        type: ADD_NOTIFICATION_SUCCESS,
        data: data
      })
      if(body.mc_id) {
        toast.success("Notification updated successfully!")
      } else {
        toast.success("Notification added successfully!")
      }
    } else {
      toast.error("Fatal Error Please Contact Admin")
    }
  } catch (error) {
    const msg = error;
    toast.error(msg)
    dispatch({
      type: ADD_NOTIFICATION_ERROR,
      error: msg
    })
  }
}

export const updateNotificationStatus = (body) => async (dispatch) => {
  dispatch({
    type: UPDATE_NOTIFICATION_STATUS_BEGIN
  })
  try {
    const { data, status } = await MasterServices.updateNotificationStatus(body);
    if (status == 200) {
      if (Array.isArray(data.data) && data.data.length > 0) {
        data.data = data.data.map((v) => ({...v, index: [0, v.notify_status? 1 : 2]}))
      }
      dispatch({
        type: UPDATE_NOTIFICATION_STATUS_SUCCESS,
        data: data?.data.length > 0 ? data.data : []
      })
      if(body.notify_id) {
        toast.success("Notification status updated successfully!")
      } else {
        toast.success("Notification status added successfully!")
      }
    } else {
      toast.error("Fatal Error Please Contact Admin")
    }
  } catch (error) {
    const msg = error;
    toast.error(msg)
    dispatch({
      type: UPDATE_NOTIFICATION_STATUS_ERROR,
      error: msg
    })
  }
}
