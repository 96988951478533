import { useEffect } from "react";
import {
  Movement_columns,
  goods_received_columns,
  goods_return_columns,
} from "../../../components/tables/tableheader";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Modal from "../../../components/Modal";
import InputBox from "../../../components/InputBox";
import Button from "../../../components/Button";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import ConfirmationModal from "../../../components/ConfirmationModal";
const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    purchase_order_id: "",
    storage_location_id: "",
    quantity: "",
    item_id: "",
  },
};

function GoodsReturn() {
  const pages = [{ title: "GRN (Goods Return)", href: "/goods-return", module_id: 33 }];
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(initialModalState);
  const [itemsList, setItemsList] = useState([]);
  const [purchaseorderList, setPurchaseOrderList] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    grn_return_id: null,
  });
  const [storageLocations, setStorageLocations] = useState({
    loading: false,
    data: [],
  });
  const [grns, setGrn] = useState({
    loading: false,
    data: [],
  });

  const navigate = useNavigate();

  useEffect(() => {
    getAllItems();
    getAllPurchaseOrder();
    getAllGRNs();
    getAllStorageLocations();
    document.title = "New Labib | GRN (Goods Return)";
  }, []);
  useEffect(() => {
    if (modal.edit_id !== "") {
      getGRNById(modal.edit_id);
    }
  }, [modal.edit_id]);
  const getAllItems = async () => {
    setLoading(true);
    await MasterServices.getItemsList()
      .then((response) => {
        const { data, status } = response;
        if (status === 200) {
          setItemsList(data.data);
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
    setLoading(false);
  };

  const createGRN = async (body) => {
    await MasterServices.createGrnGoodsReturn(body)
      .then((res) => {
        const { data, status } = res;
        if (status === 201) {
          getAllGRNs();
          toast.success("GRN Goods received Added Successfully");
          setModal((prev) => ({ ...prev, data: {}, state: false }));
        }
      })
      .catch((err) => {
        toast.error(err.data.message);
        console.log(err);
      });
  };
  const getAllPurchaseOrder = () => {
    MasterServices.getPurchaseOrder().then((response) => {
      const { data, status } = response;
      if (status == 200) {
        if (data?.data?.length > 0) {
          setPurchaseOrderList(data.data);
          console.log(data.data);
        } else {
          toast.error("PurchaseOrder Not Found");
        }
      } else {
        toast.error("Fatal Error Please Contact Admin");
      }
      setLoading(false);
    });
  };
  const getAllStorageLocations = async () => {
    setStorageLocations((prev) => ({ ...prev, loading: true }));
    await MasterServices.getAllStorageLocation()
      .then((res) => {
        const { data, status } = res;
        console.log(res);
        if (status === 201) {
          setStorageLocations({
            loading: false,
            data: data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllGRNs = async () => {
    setGrn((prev) => ({ ...prev, loading: true }));
    await MasterServices.getGrnGoodsReturn()
      .then((res) => {
        const { data, status } = res;
        console.log(res);
        if (status === 201) {
          setGrn({
            loading: false,
            data: data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGRNById = async (id) => {
    await MasterServices.getOneGrnGoodsReturn(id)
      .then((res) => {
        const { data, status } = res;
        console.log(res);
        if (status === 201) {
          setModal((prev) => ({
            ...prev,
            type: "edit",
            data: {
              purchase_order_id: data.data.purchase_order_id,
              storage_location_id: data.data.storage_location_id,
              quantity: data.data.quantity,
              item_id: data.data.item_id,
            },
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateGrn = async (id, body) => {
    await MasterServices.updateGrnGoodsReturn(id, body)
      .then((res) => {
        const { data, status } = res;
        if (status === 201) {
          getAllGRNs();
          toast.success("GRN Goods Received Updated Successfully");
          setModal((prev) => ({
            ...prev,
            data: {},
            type: "",
            edit_id: "",
            state: false,
          }));
        }
      })
      .catch((err) => {
        toast.error(err.data.message);
        console.log(err);
      });
  };
  const onDeleteOpen = (id) => {
    setConfirmationModal({ grn_return_id: id, status: true });
  };

  const onDeleteGRN = async (id) => {
    await MasterServices.deleteGrnGoodsReturn(id)
      .then((res) => {
        const { data, status } = res;
        if (status === 201) {
          getAllGRNs();
          toast.success("GRN Deleted Successfully");
          setConfirmationModal({ grn_id: null, status: false });
        }
      })
      .catch((err) => {
        toast.error(err.data.message);
        console.log(err);
      });
  };
  const renderModal = () => {
    const { type, state, data, edit_id } = modal;

    return (
      <Formik
        initialValues={data}
        validationSchema={Yup.object({
          purchase_order_id: Yup.string().required("Please select the order"),
          storage_location_id: Yup.string().required(
            "Please select the storage location"
          ),
          quantity: Yup.string().required("Please enter the quantity"),
          item_id: Yup.string().required("Please select the item"),
        })}
        enableReinitialize={true}
        onSubmit={(values, action) => {
          const body = {
            purchase_order_id: parseInt(values.purchase_order_id),
            storage_location_id: parseInt(values.storage_location_id),
            quantity: parseInt(values.quantity),
            item_id: parseInt(values.item_id),
          };
          if (modal.type === "edit") {
            updateGrn(modal.edit_id, body);
          } else {
            createGRN(body);
            action.resetForm();
          }
          action.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Modal
            title={`${
              modal.type === "edit" ? "Update" : "Add"
            } GRN (Goods Return)`}
            open={state}
            setOpen={() => setModal((prev) => ({ ...prev, state: false }))}
          >
            <form onSubmit={handleSubmit}>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="analyzer_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Purchase Order
                </label>
                <select
                  id="purchase_order_id"
                  name="purchase_order_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.purchase_order_id}
                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                >
                  <option>Select the Order</option>
                  {
                    purchaseorderList.map((order,idx) => (
                      <option key={idx} value={order.fk_wpo_id}>
                        {order.fk_wpo_id}
                      </option>
                    ))
                  }
                </select>
                {touched.purchase_order_id && (
                  <p className="text-red-700 error_msg">
                    {errors.purchase_order_id}
                  </p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="storage_location_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Storage Location
                </label>
                <select
                  id="storage_location_id"
                  name="storage_location_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.storage_location_id}
                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                >
                  <option>Select the location</option>
                  {storageLocations.data.map((item, index) => (
                    <option key={index} value={item.sl_id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {errors.storage_location_id && (
                  <p className="text-red-700 error_msg">
                    {errors.storage_location_id}
                  </p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <InputBox
                  name="quantity"
                  label="Quantity"
                  type="number"
                  value={values.quantity}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={true}
                  placeholder="Quantity"
                />
                {touched.quantity && (
                  <p className="text-red-700 error_msg">{errors.quantity}</p>
                )}
              </div>

              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="item_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  item
                </label>
                <select
                  id="item_id"
                  name="item_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.item_id}
                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                  defaultValue="Canada"
                >
                  <option>Select the item</option>
                  {itemsList.map((item, index) => {
                    return (
                      <option key={index} value={item.witem_id}>
                        {item.witem_name_en}
                      </option>
                    );
                  })}
                </select>
                {touched.analyzer_name && (
                  <p className="text-red-700 error_msg">{errors.item_id}</p>
                )}
              </div>

              <div className="mt-4"></div>
              <Button disabled={isSubmitting} type="submit">
                {modal.type === "edit" ? "Update" : "Add"} GRN (Goods Return)
              </Button>
            </form>
          </Modal>
        )}
      </Formik>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      {renderModal()}
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteGRN(confirmationModal.grn_return_id)}
      />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">
          GRN (Goods Return)
        </h1>
      </div>
      <div className="mt-4 flex">
        <button
          type="button"
          onClick={() =>
            setModal((prev) => ({
              data: {},
              type: "",
              edit_id: "",
              state: true,
            }))
          }
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </button>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={goods_return_columns({
            setModal,
            onDeleteOpen,
          })}
          data={grns.data}
        />
      )}
    </div>
  );
}
export default GoodsReturn;
