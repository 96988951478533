import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Select from "react-select";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { test_params_rr_lab_columns } from "../../../components/tables/tableheader";

const AddRefrenceRange = () => {
  const getCurretnDateTimeDefault = new Date().getTime();
  const { ttp_id: ttp_id } = useParams();

  const [loading, setLoading] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    tp_rr_lab_id: null,
  });
  const [testData, settestData] = useState({});
  const [linkedRR, setLinkedRR] = useState(false);
  const [referenceRangeList, setReferenceRangeList] = useState([]);
  const [labAnalyzers, setLabAnalyzers] = useState([]);
  const [inputList, setInputList] = useState([
    {
      machine: "",
      reference_range: "",
      id: getCurretnDateTimeDefault,
    },
  ]);

  const machine = [
    {
      label: "machine1",
      value: "machine1",
    },
    {
      label: "machine2",
      value: "machine2",
    },
    {
      label: "machine3",
      value: "machine3",
    },
    {
      label: "machine4",
      value: "machine4",
    },
  ];

  const reference_range = [
    {
      label: "reference_range1",
      value: "reference_range1",
    },
    {
      label: "reference_range2",
      value: "reference_range2",
    },
    {
      label: "reference_range3",
      value: "reference_range3",
    },
    {
      label: "reference_range4",
      value: "reference_range4",
    },
  ];

  const handleAddClick = () => {
    const getCurretnDateTime = new Date().getTime();
    setInputList([
      ...inputList,
      {
        machine: "",
        reference_range: "",
        id: getCurretnDateTime,
      },
    ]);
  };

  const handleInputChange = (e, key, index) => {
    // console.log(e.target.name, e.target.value, "e.target");
    let list = [...inputList];
    if (e.value) {
      list[index][key] = e;
    } else {
      list[index][e.target.name] = e.target.value;
    }
    setInputList(list);
  };

  const handleRemoveClick = (index) => {
    let list = inputList;
    const finalList = inputList.filter((ele, i) => i !== index);
    console.log(finalList, "finalList");
    // list.splice(index, 0);
    setInputList([...finalList]);
  };

  const getReferenceRangeList = async () => {
    MasterServices.getAllReferenceRange()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            let refRanges = [];
            data?.data.forEach((el) => {
              let obj = { ...el, label: el.mrr_name_en, value: el.mrr_id };
              refRanges.push(obj);
            });

            setReferenceRangeList(refRanges);
          } else {
            toast.error("Reference Ranges Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = "Error in Reference Ranges";
        toast.error(msg);
      });
  };

  const getLabAnalyzerList = async (test_id) => {
    MasterServices.getAllLabAnalyzersByTest(test_id)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            let labAnalyzer = [];
            data?.data.forEach((el) => {
              let obj = {
                ...el,
                label:
                  el.lab_analyzer_name_en +
                  "( " +
                  el.lab_analyzer_name_en +
                  " )",
                value: el.lab_analyzer_id,
              };
              labAnalyzer.push(obj);
            });

            setLabAnalyzers(labAnalyzer);
          } else {
            toast.error("Reference Ranges Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = "Error in Reference Ranges";
        toast.error(msg);
      });
  };

  const handleSubmit = async () => {
    const filteredArray = inputList.filter(
      (obj) => obj.machine !== "" && obj.reference_range !== ""
    );
    let sendData = {
      test_param_id: ttp_id ? ttp_id : null,
      mapper: filteredArray ? filteredArray : [],
    };
    console.log("handleSubmit", sendData);

    if (filteredArray.length > 0) {
      MasterServices.linkRRandLab(sendData)
        .then((response) => {
          const { data, status, message } = response;
          if (status == 200) {
            toast.success(message ? message : "Success Saved");
            getAllLinkedRRand();
          }
        })
        .catch((error) => {
          const msg = error.response.data.message;
          toast.error(msg);
        });
    }
  };

  //getAllLinkedRRand

  useEffect(() => {
    getReferenceRangeList();
    getSingleSubTest();
    if (ttp_id) {
      getAllLinkedRRand();
    }
  }, []);

  const getAllLinkedRRand = () => {
    /* Get All Linked RR & Lab */
    if (ttp_id) {
      MasterServices.getAllLinkedRRand(ttp_id)
        .then((response) => {
          const { data, status } = response;
          if (status === 200) {
            if (data.data.length > 0) {
              setLinkedRR(data?.data);
              console.log("setLinkedRR", data.data);
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          const msg = "Sorry";
          toast.error(msg);
          setLoading(false);
        });
    }
  };

  const getSingleSubTest = () => {
    MasterServices.getSingleSubTest(ttp_id)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          settestData(data.data);
          if(data.data?.fk_test_id){
            getLabAnalyzerList(data.data?.fk_test_id);
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error.response.data.message;
        toast.error(msg);
        setLoading(false);
      });
  };

  const onDeleteRRLinked = (tp_rr_lab_id) => {
    setLoading(true);
    console.log("onDeleteRRLinked", tp_rr_lab_id);
    const sendData = {tp_rr_lab_id: tp_rr_lab_id}
    MasterServices.deleteRRandLab(sendData)
        .then((response) => {
          const { data, status } = response;
          if (status === 200) {
            getAllLinkedRRand();
          }
          setLoading(false);
        })
        .catch((error) => {
          const msg = "Sorry";
          toast.error(msg);
          setLoading(false);
        });

  };

  const onDeleteOpen = (id) => {
    setConfirmationModal({ status: true, tp_rr_lab_id: id });
    console.log("onDeleteOpen", id);
  };

  return (
    <div>
      <ConfirmationModal
        title={"Are you sure want to delete Ref. range & Lab"}
        confirmationButtonText={"Delete"}
        description={"Do you really want to delete Ref. range & Lab"}
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteRRLinked(confirmationModal.tp_rr_lab_id)}
      />

      {loading ? (
        <FallingLinesLoader />
      ) : (
        <form className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-6 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5 sm:pt-5">
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >
                    Test Parameter
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="test_parameter"
                      id="test_parameter"
                      autoComplete="off"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      disable
                      readOnly
                      defaultValue={testData?.ttp_name_ar}
                    />
                  </div>
                </div>

                {inputList.map((x, index) => (
                  <div
                    key={x.id}
                    className="mt-1 w-full flex sm:col-span-2 sm:mt-0"
                    id={x.id}
                  >
                    <div className="block w-full mr-4 max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm">
                      <Select
                        name="machine"
                        id="machine"
                        options={labAnalyzers}
                        onChange={(e) => handleInputChange(e, "machine", index)}
                        className="block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                        placeholder="Choose Lab Analyzer (Machine)"
                      />
                    </div>
                    <div className="block w-full mr-4 max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm">
                      <Select
                        name="reference_range"
                        id="reference_range"
                        options={referenceRangeList}
                        onChange={(e) =>
                          handleInputChange(e, "reference_range", index)
                        }
                        className="block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                        placeholder="Choose Refrance Range"
                      />
                    </div>

                    <button
                      type="button"
                      className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-"
                      onClick={() => handleRemoveClick(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="w-1/3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-"
                  onClick={handleAddClick}
                >
                  Add more
                </button>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => {
                  handleSubmit(e)
                  setInputList([
                    {
                      machine: "",
                      reference_range: "",
                      id: getCurretnDateTimeDefault,
                    },
                  ])
                }}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}

      {loading ? (
        <FallingLinesLoader />
      ) : linkedRR.length > 0 ? (
        <Table
          columns={test_params_rr_lab_columns({ onDeleteOpen })}
          data={linkedRR}
        />
      ) : (
        <TableEmpty />
      )}
    </div>
  );
};

export default AddRefrenceRange;
