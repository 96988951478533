import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import InputBox from "../../../components/InputBox";
import Modal from "../../../components/Modal";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import { Vendors_Contact_list_colums } from "../../../components/tables/tableheader";
import * as Yup from "yup";
import Button from "../../../components/Button";
import { useParams } from "react-router-dom";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import makeAnimated from 'react-select/animated';
import Select from "react-select";
const Contact_list = () => {
  const { vendor_id: VId } = useParams();
  const animatedComponents = makeAnimated();
  const [loading, setLoading] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [filteredCountryList, setFilteredCountryList] = useState([]);
  const [showError, setShowError] = useState(false);


  const initialModalState = {
    type: "",
    state: false,
    index: null,
    edit_id: "",
    data: {},
  };
  useEffect(() => {
    getAllContacts(VId);
    getCountryList();
  }, []);

  const getAllContacts = async (id) => {
    setLoading(true);
    await MasterServices.getVendorContactListByVid(id)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setContactList(data.data);
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

  const getCountryList = () => {
    MasterServices.getCountryList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            // setCountryList(data.data);
            let tempArr = []
            if (data.data.length > 0) {
              let indexOfSA = data.data.findIndex(country => {
                return country.country_id == 203
              });
              // console.log("indexOFSA", indexOfSA)
              tempArr = array_move(data.data, indexOfSA, 0)
              // console.log(tempArr)
              setFilteredCountryList(tempArr)
            }
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "getCountryList catch Error"; //error.response.data.message;
        toast.error(msg);
      });
  }


  const AddVendorContact = (payload) => {
    setLoading(true);
    MasterServices.addVendorContact(payload)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          if (data.status === true){
            toast.success(data.message);
          }else{
            toast.error(data.message);
          }
          
          setLoading(false);
          getAllContacts(VId);
          setModal((prev) => ({ ...prev, data: {}, state: false }));
          getAllContacts(VId);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };

  const getContactById = async (id) => {
    await MasterServices.getOneVendorContact(id)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setModal((prev) => ({
            ...prev,
            state: true,
            data: data.data,
            type: "edit",
            edit_id: id,
          }));
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };

  const UpdateVendorStatusContact = (id, payload) => {
    MasterServices.updateVendorContactStatus(id, payload)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          toast.success(data.message);
          getAllContacts(VId);
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };

  const UpdateVendorContact = (id, payload) => {
    MasterServices.updateVendorContact(id, payload)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          toast.success(data.message);
          getAllContacts(VId);
          setModal((prev) => ({ ...prev, data: {}, state: false }));
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };

  const [modal, setModal] = useState(initialModalState);
  const renderModal = () => {
    const { type, state, data, edit_id } = modal;

    return (
      <Formik
        initialValues={data}
        validationSchema={Yup.object({
          vc_first_name: Yup.string().required("First Name is required"),
          vc_last_name: Yup.string().required("Last Name is required"),
          vc_email: Yup.string().email().required("Email is required"),
          vc_mobile: Yup.string().required("Mobile is required"),
          vc_country: Yup.string().required("Country is required"),
          job_title: Yup.string().required("Job title is required")
        })}
        enableReinitialize={true}
        onSubmit={(values, action) => {
          const body = {
            ...values,
            fk_vendor_id: parseInt(VId),
          };
          if (modal.edit_id) {
            UpdateVendorContact(edit_id, body);
          } else {
            AddVendorContact(body);
            setModal((prev) => ({ ...prev, state: false }));
          }

          action.setSubmitting(false);
          action.resetForm();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Modal
            title="Add Contact"
            open={state}
            setOpen={() => {
              setShowError(false);
              setSelectedLocationId('');
              setModal((prev) => ({ ...prev, state: false }));
            }}
          >
            <form onSubmit={e => {
              setShowError(true)
              handleSubmit(e);
            }}>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <input
                  name="vc_first_name"
                  label="First Name"
                  type="text"
                  id="vc_first_name"
                  value={values.vc_first_name}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter First Name"
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                />
                {errors.vc_first_name && showError && (
                  <p className="text-red-700 error_msg">
                    {errors.vc_first_name}
                  </p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <input
                  name="vc_last_name"
                  label="Last Name"
                  type="text"
                  id="vc_last_name"
                  value={values.vc_last_name}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Last Name"
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                />
                {errors.vc_last_name && showError && (
                  <p className="text-red-700 error_msg">
                    {errors.vc_last_name}
                  </p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <input
                  name="vc_email"
                  label="Email"
                  type="text"
                  id="vc_email"
                  value={values.vc_email}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Email"
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                />
                {errors.vc_email && showError && (
                  <p className="text-red-700 error_msg">{errors.vc_email}</p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <input
                  name="vc_mobile"
                  label="Mobile"
                  type="text"
                  id="vc_mobile"
                  value={values.vc_mobile}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Mobile"
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                />
                {errors.vc_mobile && showError && (
                  <p className="text-red-700 error_msg">{errors.vc_mobile}</p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <Select
                  name="country_name"
                  id="country_id"
                  placeholder="Select Locations"
                  value={selectedLocationId}
                  onChange={(e) => {
                    values.vc_country = e.label;
                    setSelectedLocationId(e)
                  }}
                  // onBlur={handleBlur}
                  components={animatedComponents}
                  options={filteredCountryList ? filteredCountryList.map(v => ({value: v.country_id, label: v.country_name})) : []}
                  classNamePrefix="select"
                  className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                />
                {errors.vc_country && showError && (
                  <p className="text-red-700 error_msg">{errors.vc_country}</p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <input
                  name="job_title"
                  label="Job Title"
                  type="text"
                  id="job_title"
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Job Title"
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                />
                {errors.job_title && showError && (
                  <p className="text-red-700 error_msg">{errors.job_title}</p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <input
                  name="notes"
                  label="Notes"
                  type="text"
                  id="notes"
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Notes"
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                />
                {errors.notes && showError && (
                  <p className="text-red-700 error_msg">{errors.notes}</p>
                )}
              </div>

              <div className="mt-4"></div>
              <Button type="submit">
                {isSubmitting
                  ? "Saving..."
                  : `${edit_id ? "update" : "Add"} Contact`}
              </Button>
            </form>
          </Modal>
        )}
      </Formik>
    );
  };

  return (
    <div>
      {renderModal()}
      <div className="mt-4 flex">
        <button
          type="button"
          onClick={() =>
            setModal((prev) => ({ ...prev, data: {}, state: true, edit_id: "" }))
          }
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add Contact
        </button>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : loading == false ? (
        <Table
          columns={Vendors_Contact_list_colums({
            UpdateVendorStatusContact,
            VId,
            setModal,
            getContactById,
          })}
          data={contactList}
        />
      ) : null}
    </div>
  );
};

export default Contact_list;
