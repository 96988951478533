import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { locTestSchema } from "../../../schemas";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { location_test_columns } from "../../../components/tables/tableheader";
import { getAllLocations } from "../../../redux/location/actions";
import { getAllTests } from "../../../redux/test/actions";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import Multiselect from "multiselect-react-dropdown";

function LabTestList() {
  const { loc_id: loc_id } = useParams();
  const pages = [{ title: "Lab Test List", href: `/loc-test/${loc_id}`, module_id: 1 }];
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [loc_test_id, setLocTestID] = useState("");
  const [locName, setLocName] = useState("");
  const [hosName, setHosName] = useState("");
  const [hos_id, setHosID] = useState("");
  const [locationTestList, setLocationTestList] = useState(false)
  const [locationList, setLocationList] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [submitIsLoading, setSubmitIsLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    loc_test_id: null,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (loc_id) {
      MasterServices.getSingleLocation(loc_id)
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            setHosID(data?.data?.m_hospital_relation?.hos_id);
            setHosName(data?.data?.m_hospital_relation?.hos_name_en);
          } else {
            toast.error("Fatal Error Please Contact Admin")
          }
        })
        .catch((error) => {
          const msg = error.response.data.message;
          toast.error(msg)
        });
    }
  }, [loc_id]);
  const {
    // location: { list: locationList },
    test: { list: testList }
  } = useSelector((state) => state);

  useEffect(() => {
    document.title = "Labib | Lab Tests";
    if (hos_id) {
      getAllLocationList(hos_id);
    }
    dispatch(getAllTests('active'));
    getAllLocationTestList(loc_id);
  }, [hos_id]);

  const getAllLocationTestList = (loc_id) => {
    setIsLoading(true)
    MasterServices.getLocationTestList(loc_id)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setLocationTestList(data.data);
          } else {
            // toast.error("LocationTestList Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = "getAllLocationTestList catch Error";
        toast.error(msg);
        setIsLoading(false);
      });
  }

  const getAllLocationList = (hos_id) => {
    MasterServices.getLocationList(hos_id)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            let locListData = data.data;
            if (locListData.length > 0) {
              locListData.map((location, i) => {
                if (location.loc_id == loc_id) {
                  setLocName(location.loc_name_en);
                }
              });
            }
            setLocationList(data.data);
          } else {
            toast.error("Location List Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = "getAllLocationTestList catch Error";
        toast.error(msg);
        setIsLoading(false);
      });
  }

  useEffect(() => {
  }, []);

  const initialValues = {
    loc_test_id: "",
    fk_test_id: "",
    fk_loc_id: loc_id,
    fk_test_ids: []
  };

  const [formLocTest, setFormLocTest] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    if ((type === "edit", id)) {
      setLocTestID(id);
      let initialValuesobj = {
        loc_test_id: obj.loc_test_id,
        fk_test_id: obj.fk_test_id,
        fk_loc_id: obj.fk_loc_id,
      };
      setFormLocTest(initialValuesobj);
    } else {
      setLocTestID("");
      setFormLocTest(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onDeleteOpen = (loc_test_id, loc_test_is_active) => {
    setConfirmationModal({ loc_test_id, loc_test_is_active, status: true });
  };

  const onDeleteLocTest = async (loc_test_id, loc_test_is_active) => {
    let Newloc_test_is_active = false;
    Newloc_test_is_active = loc_test_is_active == true ? false : true;
    let text = "Activated";
    if (Newloc_test_is_active == true) {
      text = "Activated";
    } else {
      text = "In-Activated";
    }
    MasterServices.setLocationTestStatus({
      loc_test_id,
      loc_test_is_active: Newloc_test_is_active
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Location Test " + text + " Successfully!")
          setConfirmationModal({ loc_test_id: null, status: false });
          getAllLocationTestList(loc_id);
          navigate(`/lab-test/${loc_id}`);
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error.response.data.message;
        toast.error(msg)
      });
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formLocTest,
      validationSchema: locTestSchema,
      onSubmit: async (values, action) => {
        let body = {
          loc_test_id: loc_test_id,
          fk_test_id: values.fk_test_id,
          fk_loc_id: values.fk_loc_id,
          fk_test_ids: values.fk_test_ids.map(v => v.test_id)
        };
        if (body.fk_test_id != undefined || body.fk_test_id != null || body.fk_test_id != "" || body.fk_loc_id != undefined || body.fk_loc_id != null || body.fk_loc_id != "") {
          setSubmitIsLoading(true);
          setIsLoading(true);
          let res = await MasterServices.saveLocationTest(body);
          setSubmitIsLoading(false)
          setIsLoading(false);
          if (res.status) {
            // if (res.data.data = "Exist") {
            //   toast.warn(res.data.message);
            // } else {
            toast.success(res.data.message);
            // }
            if (modalOpenFlage === true) {
              setmodalOpenFlage(false);
              action.resetForm();
              dispatch(getAllLocationTestList(loc_id));

            }
          }
        }
        else
          action.resetForm();
        if (modalOpenFlage === true) {
          dispatch(getAllLocationTestList(loc_id));
          setmodalOpenFlage(false);
        }
        navigate("/user-list");
      },
    });
  return (
    <div className="px-4 sm:px-6 lg:px-8">

      <ConfirmationModal
        title={"Are you sure you want to " + (confirmationModal.loc_test_is_active ? "Disable " : "Activate") + " this LocTest?"}
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        onDelete={() => onDeleteLocTest(confirmationModal.loc_test_id, confirmationModal.loc_test_is_active)}
        setOpen={setConfirmationModal}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Lab Test List (Hospital Name : {hosName})
          (Location Name : {locName})
        </h1>
      </div>
      {/* <div className="mt-4 flex">
        <Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </Link>
      </div> */}

      {
        isLoading ? (
          <FallingLinesLoader />
        ) : ((locationTestList.length > 0) ? (
          <Table
            columns={location_test_columns({ onDeleteOpen, handleDrawer })}
            data={locationTestList}
          />
        ) : <TableEmpty />)
      }


      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-cyan-700 py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {loc_test_id ? "Update" : "Add"}
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-1 flex-col justify-between">
                            <div className="divide-y divide-gray-200 p-4 sm:p-6">
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  Location
                                </label>
                              </div>
                              {

                                locationList && locationList.length > 0 ?
                                  <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                    <select
                                      disabled
                                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                      name="loc_id"
                                      id="loc_id"
                                      value={values.fk_loc_id}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <option> Select Location </option>
                                      {locationList.map((location, i) => (
                                        <option
                                          selected={
                                            location.loc_id ===
                                              values.fk_loc_id
                                              ? "selected"
                                              : ""
                                          }
                                          key={i}
                                          value={location.loc_id}
                                        >
                                          {location.loc_name_en}
                                        </option>
                                      ))}
                                    </select>

                                    {errors.fk_loc_id &&
                                      touched.fk_loc_id ? (
                                      <div className="text-sm text-red-600">
                                        {errors.fk_loc_id}
                                      </div>
                                    ) : null}
                                  </div>

                                  : null
                              }
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  Test
                                </label>
                              </div>
                              {
                                testList && testList.length > 0 ?

                                  <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                    
                                    {loc_test_id != '' ?
                                    <>
                                      <select
                                      disabled={loc_test_id == '' ? false : true}
                                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                      name="fk_test_id"
                                      id="fk_test_id"
                                      value={values.fk_test_id}
                                      onChange={handleChange}
                                        onBlur={handleBlur}
                                      >
                                        <option value={null}> Select Test </option>
                                        {testList?.map((test, i) => (
                                          <option
                                            selected={
                                              test.test_id === values.fk_test_id
                                                ? "selected"
                                                : ""}
                                            key={i}
                                            value={test.test_id}
                                          >
                                            {test.test_name_en}
                                          </option>
                                        ))}
                                      </select>
                                      {errors.fk_test_id && touched.fk_test_id ? (
                                        <div className="text-sm text-red-600">
                                          {errors.fk_test_id}
                                        </div>
                                      ) : null}
                                      </>
                                    : 
                                    <>
                                    <Multiselect
                                        dataKey="test_id"
                                        selectedValues={values.fk_test_ids}
                                        onSelect={(list) => {
                                          values.fk_test_ids = list
                                        }}
                                        onRemove={(list) => {
                                          values.fk_test_ids = list
                                        }}
                                        displayValue="test_name_en"
                                        options={testList}
                                    />
                                    {errors.fk_test_ids && touched.fk_test_ids ? (
                                        <div className="text-sm text-red-600">
                                          {errors.fk_test_ids}
                                        </div>
                                      ) : null}
                                  </>
                                    }

                                  </div>
                                  : null
                              }



                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={submitIsLoading}
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            {loc_test_id ? "Update" : "Add"}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
export default LabTestList;