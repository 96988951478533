export const GET_ALL_PRICELIST_BEGIN = "GET_ALL_PRICELIST_BEGIN"
export const GET_ALL_PRICELIST_SUCCESS = "GET_ALL_PRICELIST_SUCCESS"
export const GET_ALL_PRICELIST_ERROR = "GET_ALL_PRICELIST_ERROR"

export const ADD_PRICELIST_BEGIN = "ADD_PRICELIST_BEGIN"
export const ADD_PRICELIST_SUCCESS = "ADD_PRICELIST_SUCCESS"
export const ADD_PRICELIST_ERROR = "ADD_PRICELIST_ERROR"

export const GET_PRICELIST_BEGIN = "GET_PRICELIST_BEGIN"
export const GET_PRICELIST_SUCCESS = "GET_PRICELIST_SUCCESS"
export const GET_PRICELIST_ERROR = "GET_PRICELIST_ERROR"

export const UPDATE_PRICELIST_BEGIN = "UPDATE_PRICELIST_BEGIN"
export const UPDATE_PRICELIST_SUCCESS = "UPDATE_PRICELIST_SUCCESS"
export const UPDATE_PRICELIST_ERROR = "UPDATE_PRICELIST_ERROR"

export const DELETE_PRICELIST_BEGIN = "DELETE_PRICELIST_BEGIN"
export const DELETE_PRICELIST_SUCCESS = "DELETE_PRICELIST_SUCCESS"
export const DELETE_PRICELIST_ERROR = "DELETE_PRICELIST_ERROR"