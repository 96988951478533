import React, { useState, Fragment, useRef, useEffect, } from 'react'
import { Link } from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import TableEmpty from '../../../components/tables/tableEmpty';
import { clinic_refferal, clinic_refferal_reservations } from '../../../components/tables/tableheader';
import MasterServices from '../../../ApiServices/MasterServices'
import Modal from '../../../components/Modal';
import ConfirmationModal from '../../../components/ConfirmationModal';

const initialModalStateReservationsUsed = {
    state: false,
    data: [],
  }

const ClinicRefferal = () => {
    const pages = [{ title: "Clinic Refferal", href: "/clinic-refferal", module_id: 25 }];
    const [ loading, setLoading ] = useState(false);
    const [clinicRefferalList, setClinicRefferalList] = useState([])
    const [modalReservationsUsed, setModalReservationsUsed] = useState(initialModalStateReservationsUsed);
    const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		resv_ref_id: null,
	});


    async function getClinicRefferalList() {
        setLoading(true)
        try {
          const {data, status} = await MasterServices.getClinicRefferal();
            if(status === 200) {
                setClinicRefferalList(data.data)
            }
          setLoading(false)
        } catch(error) {
          console.log(error)
          setLoading(false)
        }
    }
    function calculateFinalPayment(data) {
        let total = 0;
        data.rev_total_discount = data.rev_total_discount ? data.rev_total_discount : 0;
        data.rev_amount = data.rev_amount ? data.rev_amount : 0;
        data.rev_discounted_amount = data.rev_discounted_amount ? data.rev_discounted_amount : 0;
        data.rev_vat_amount = data.rev_vat_amount ? data.rev_vat_amount : 0;
        total = parseFloat((parseFloat(data.rev_amount) + parseFloat(data.rev_vat_amount)) - (parseFloat(data.rev_total_discount) + parseFloat(data.rev_discounted_amount))).toFixed(2);
        return total
      }
    async function deleteClinicRefferal(payload) {
        setLoading(true)
        try {
          const {data, status} = await MasterServices.deleteClinicRefferal(payload);
          setConfirmationModal({ resv_ref_id: null, status: false });
            if(status === 200) {
                await getClinicRefferalList()
            }
          setLoading(false)
        } catch(error) {
          console.log(error)
          setLoading(false)
        }
    }
    const renderReservationsUsedModel = () => {
        const { state, data } = modalReservationsUsed;
    
        return (
            <Modal
                title="Coupon Code List"
                open={state}
                size="max-w-3xl relative"
                setOpen={() => setModalReservationsUsed((prev) => ({ ...prev, state: false }))}
            >
            <Table
              columns={clinic_refferal_reservations({calculateFinalPayment})}
              data={data}
            />   
            </Modal>
        );
    };
    async function openViewModal(data) {
        setModalReservationsUsed({
            data: data,
            state: true
        })
    }
	const onDeleteOpen = (resv_ref_id) => {
		setConfirmationModal({ resv_ref_id, status: true });
	};

    useEffect(() => {
        getClinicRefferalList();
    }, [])


    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8">
                <ConfirmationModal
                    open={confirmationModal.status}
                    setOpen={setConfirmationModal}
                    onDelete={() => deleteClinicRefferal(confirmationModal.resv_ref_id)}
                />
                <Breadcrumb pages={pages} />
                <div className="">
                    <h1 className="text-xl font-semibold text-gray-900">Clinic Refferal List</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the Clinic Refferal.</p>
                </div>
                <div className="mt-4 flex">
                    <Link
                        to="/add-clinic-refferal"
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
                    >
                        Add
                    </Link>
                </div>
                {
                    loading ? (
                        <FallingLinesLoader />
                    ) : ((clinicRefferalList.length > 0) ? (
                        <Table
                            columns={clinic_refferal({onDeleteOpen, openViewModal})}
                            data={clinicRefferalList}
                        />
                    ) : <TableEmpty />)
                }
            {renderReservationsUsedModel()}
            </div>
        </>
    )
}

export default ClinicRefferal