import PageHeaderWithBreadcrumb from "../../components/common/PageHeaderWithBreadcrumb";
import UserList from "../../components/user/UserList";
const Users = () => {
  return (
    <>
      <PageHeaderWithBreadcrumb
        pageDetails={[{ title: "User List", href: "/users", module_id: 2 }]}
        title={"User List"}
        subtitle={"A list of all the Users."}
      />
      <UserList />
    </>
  );
};

export default Users;
