/* eslint-disable */
import { toast } from 'react-toast'
import {
  GET_ALL_CATEGORY_BEGIN,
  GET_ALL_CATEGORY_SUCCESS,
  GET_ALL_CATEGORY_ERROR,
  ADD_CATEGORY_BEGIN,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_ERROR,
  GET_CATEGORY_BEGIN,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_ERROR,
  UPDATE_CATEGORY_BEGIN,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERROR,
  DELETE_CATEGORY_BEGIN,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERROR
} from './constants'
import MasterServices from '../../ApiServices/MasterServices'

export const getAllCategorys = (payload) => async (dispatch) => {
  dispatch({
    type: GET_ALL_CATEGORY_BEGIN
  })
  MasterServices.getCategoryList(payload)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        dispatch({
          type: GET_ALL_CATEGORY_SUCCESS,
          data: data.data.length > 0 ? data.data : []
        })
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error.response.data.message;
      toast.error(msg)
      dispatch({
        type: GET_ALL_CATEGORY_ERROR,
        error: msg
      })
    });
}

export const addCategory = (body) => async (dispatch) => {
  dispatch({
    type: ADD_CATEGORY_BEGIN
  })
  MasterServices.saveCategory(body)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        dispatch({
          type: ADD_CATEGORY_SUCCESS,
          data: data
        })
        toast.success("Category added successfully!")
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error.response.data.message;
      toast.error(msg)
      dispatch({
        type: ADD_CATEGORY_ERROR,
        error: msg
      })
    });
}

export const updateCategory = (category_id, body) => async (dispatch) => {
  dispatch({
    type: UPDATE_CATEGORY_BEGIN
  })

  MasterServices.saveCategory(body)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        dispatch({
          type: UPDATE_CATEGORY_SUCCESS,
          data: data
        })
        toast.success("Category updated successfully!")
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error.response.data.message;
      toast.error(msg)
      dispatch({
        type: UPDATE_CATEGORY_ERROR,
        error: msg
      })
    });
}

export const deleteCategory = (category_id, category_is_active) => async (dispatch) => {
  dispatch({
    type: DELETE_CATEGORY_BEGIN
  })
  let Newcategory_is_active = false;
  Newcategory_is_active = category_is_active == true ? false : true;
  let text = "Activated";
  if (Newcategory_is_active == true) {
    text = "Activated";
  } else {
    text = "In-Activated";
  }
  MasterServices.setCategoryStatus({
    category_id,
    category_is_active: Newcategory_is_active
  })
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        toast.success("Category " + text + " successfully!")
        dispatch({
          type: DELETE_CATEGORY_SUCCESS,
          data: category_id
        })
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error.response.data.message;
      toast.error(msg)
      dispatch({
        type: DELETE_CATEGORY_ERROR,
        error: msg
      })
    });
}