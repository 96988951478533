import Table from '../../../components/tables/table';
import React, { useState } from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import TableEmpty from '../../../components/tables/tableEmpty';
import {  patient_data_columns } from '../../../components/tables/tableheader';

const ContractPricing = () => {
  const pages = [{ title: "Contract Pricing", href: "/contract-pricing", module_id: 27 }];
  const [loading, setLoading] = useState(false);
  const patientData = [
    {
      test: "CBC",
      p_name: "Nitesh",
      phone:"098765432",
      identity: "National Card",
      id_no: "9876543223",
      country: "India",
      dob: "12-02-1999",
      gender: "Male",
      questionnaire: "Question -1",
    },
    {
      test: "CBC",
      p_name: "Nitesh",
      phone:"098765432",
      identity: "National Card",
      id_no: "9876543223",
      country: "India",
      dob: "12-02-1999",
      gender: "Male",
      questionnaire: "Question -1",
    },
    {
      test: "CBC",
      p_name: "Nitesh",
      phone:"098765432",
      identity: "National Card",
      id_no: "9876543223",
      country: "India",
      dob: "12-02-1999",
      gender: "Male",
      questionnaire: "Question -1",
    }
  ];
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Contract Pricing</h1>
        <p className="mt-2 text-sm text-gray-700">A list of all the Contract Pricing.</p>
      </div>
      <div className="flex gap-5 mt-5">
        <div className="basis-1/2">
          <label htmlFor="medical_account" className="block text-sm font-medium leading-6 text-gray-900">
            Medical Complex Account
          </label>
          <div className="mt-1">
            <select
              id="medical_account"
              name="medical_account"
              autoComplete="medical_account"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
              defaultValue="Select Qc"
            >

              <option disabled>Test hos Account</option>
              <option>test-2 Account</option>
              <option>Test-3 Account</option>
              <option>Test-4 Account</option>
            </select>
          </div>
        </div>
        <div className="basis-1/2">
          <label htmlFor="booking_type" className="block text-sm font-medium leading-6 text-gray-900">
            Booking Type
          </label>
          <div className="mt-1">
            <select
              id="booking_type"
              name="booking_type"
              autoComplete="booking_type"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
              defaultValue="Normal"
            >

              <option disabled>Normal</option>
              <option>Home</option>
            </select>
          </div>
        </div>
      </div>
      <div className="mt-5 flex gap-5 justify-end">
        <div>
          <button

            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
          >
            Excel Importing
          </button>
        </div>
        <div>
          <button

            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
          >
            Add Patient
          </button>
        </div>
      </div>
      {
        loading ? (
          <FallingLinesLoader />
        ) : ((patientData.length > 0) ? (
          <Table
          columns={patient_data_columns()}
          data={patientData}
          />
        ) : <TableEmpty />)
      }
    </div>
  )
}

export default ContractPricing