import React, { useEffect, useState, Fragment, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { subtest_columns } from "../../../components/tables/tableheader";
import { toast } from 'react-toast'
import { Dialog, Transition, } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {
  XMarkIcon,
} from "@heroicons/react/24/outline";
import MasterServices from '../../../ApiServices/MasterServices'
import { read, utils, writeFile } from 'xlsx';

function TestParameterList() {
  // const pages = [{ title: "Test-Parameter List", href: "/test" }];
  const { test_id: test_id } = useParams();
  const pages = [{ title: "Test-Parameter List", href: `/test-tab/${test_id}` }];
  const [ttp_id, setTestParameterid] = useState('');
  const [loading, setLoading] = useState(true)
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    ttp_id: null,
    ttp_is_active: false,
  });
  const [subTestConfirmationModal, setSubTestConfirmationModal] = useState({
    status: false,
    ttp_id: null,
    ttp_name_en: '',
  });
  const [subTestList, setTestParameterList] = useState(false)
  const [referanceList, setReferanceList] = useState(false);
  const [testList, setTestList] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    getTestParameterList(test_id);
    getReferenceRangeList();
    getAllTests('active');
    document.title = "Labib | TestParameters";
    console.log("subTestList=>", subTestList)
  }, []);

  const getAllTests = (payload) => {
    MasterServices.getTestListForReservation(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {

          let updatedTests = data?.data

          updatedTests?.forEach((element) => {
            element.label = ((element.test_name_en).trim()).toLowerCase();
            element.value = element.test_id
          });

          if (data?.data?.length > 0) {
            setTestList(data?.data);
          }
        }
      })
      .catch((error) => {
        const msg = "Fatal Error Please Contact Admin";
        toast.error(msg)
      });
  }
  const getReferenceRangeList = async () => {
    MasterServices.getAllReferenceRange()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            let updatedRange = data?.data

            updatedRange?.forEach((element) => {
              element.label = ((element.mrr_name_en).trim()).toLowerCase();
              element.value = element.mrr_id
            });

            if (data?.data?.length > 0) {
              setReferanceList(updatedRange);
            }

          }
        }
      })
      .catch((error) => {
        const msg = "Error in Reference Ranges";
        toast.error(msg)
      });

  }
  const getTestParameterList = async (test_id) => {
    MasterServices.getSubTestList(test_id)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            // toast.success("TestParameter Found");
            setTestParameterList(data.data);
          } else {
            toast.error("TestParameter Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error.response.data.message;
        toast.error(msg)
        setLoading(false);
      });

  }

  const onDeleteOpen = (ttp_id, ttp_is_active) => {
    setConfirmationModal({ ttp_id, ttp_is_active, status: true });
  };

  const onSubTestDeleteOpen = (ttp_id, ttp_name_en) => {
    setSubTestConfirmationModal({ ttp_id, ttp_name_en, status: true });
  };

  useEffect(() => {
    console.log("subTestList 1=>", subTestList)
  }, [subTestList]);

  const onDeleteTestParameter = async (ttp_id, ttp_is_active) => {
    let Newttp_is_active = false;
    Newttp_is_active = ttp_is_active == true ? false : true;
    let text = "Activated";
    if (Newttp_is_active == true) {
      text = "Activated";
    } else {
      text = "In-Activated";
    }
    MasterServices.setSubTestStatus({
      ttp_id,
      ttp_is_active: Newttp_is_active
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("TestParameter " + text + " Successfully!")
          setConfirmationModal({ ttp_id: null, status: false });
          getTestParameterList(test_id);
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error.response.data.message;
        toast.error(msg)
      });
  };



  const onDeleteSubTestParameter = async (ttp_id) => {

    MasterServices.deleteSubTestStatus({
      ttp_id,
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success(data?.message || "Sub test deleted successfully")
          setSubTestConfirmationModal({ ttp_id: null, status: false });
          getTestParameterList(test_id);
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error.response.data.message;
        toast.error(msg)
      });
  };


  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          console.log("Test handleImport: ", rows);
          let newData = [];
          if (rows.length > 0) {
            rows.map((obj, index) => {
              if (obj.parameter_name_english != undefined || obj.hospital_price != null || obj.hospital_price != '') {
                const newObj = {
                  ttp_name_en: obj.parameter_name_english ? obj.parameter_name_english : obj.parameter_name_english,
                  ttp_name_ar: obj.parameter_name_arabic ? obj.parameter_name_arabic : obj.parameter_name_arabic,
                  ttp_test_code: obj.parameter_code ? obj.parameter_code.toString() : '',
                  ttp_smart_report_description_en: obj.parameter_report_description_english,
                  ttp_smart_report_description_ar: obj.parameter_report_description_arabic,
                  ttp_short_description_en: obj.parameter_short_description_english,
                  ttp_short_description_ar: obj.parameter_short_description_arabic,
                  fk_rr_id: filterReferanceRange(obj.reference_range.toLowerCase()),
                  fk_test_id: filterTest(obj.test_name_english.toLowerCase())
                };
                newData.push(newObj);
              }
            });
            console.log("newArray: ", newData);
            const filteredArray = newData.filter(obj => obj.fk_rr_id !== undefined && obj.fk_test_id !== undefined);
            console.log("filteredArray: ", filteredArray);
            if (filteredArray.length > 0) {
              importTestParams(filteredArray);
            }
          } else {
            toast.success("Excel is empty")
          }
        }
      }
      reader.readAsArrayBuffer(file);
    }
  }

  const importTestParams = (data) => {
    const payload = { excel_data: data }
    console.log("Params=>", payload);
    // return false;
    MasterServices.importTestParams(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Params Imported Successfully");
        } else {
          toast.error(data.message)
        }
        getTestParameterList(test_id);
      })
      .catch((error) => {
        const msg = "Error while saving";
        toast.error(msg)
      });
  }

  const filterReferanceRange = (referance) => {
    console.log("referanceList=>", referanceList)
    console.log("referance=>", referance)
    const result = referanceList.filter(obj => obj.label === referance);
    let resp = null;
    if (result.length > 0) {
      resp = result[0].mrr_id
    } else {
      resp = undefined;
    }
    return resp;
  }

  const filterTest = (test_name) => {
    console.log("testList=>", testList)
    console.log("test_name=>", test_name)
    const result = testList.filter(obj => obj.label === test_name);
    let resp = null;
    if (result.length > 0) {
      resp = result[0].test_id
    } else {
      resp = undefined;
    }
    return resp;
  }
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        title={"Are you sure want to " + (confirmationModal.ttp_is_active ? "In-Activate? " : "Activate?") + " Test-Parameter"}
        description={"Do you really want to " + (confirmationModal.ttp_is_active ? "In-Activate" : "Activate") + " Test-Parameter"}
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteTestParameter(confirmationModal.ttp_id, confirmationModal.ttp_is_active)}
      />
      <ConfirmationModal
        title={"Are you sure want to " + `'${subTestConfirmationModal.ttp_name_en}'` + " Test-Parameter"}
        description={"Do you really want to " + `'${subTestConfirmationModal.ttp_name_en}'` + " Test-Parameter"}
        open={subTestConfirmationModal.status}
        setOpen={setSubTestConfirmationModal}
        onDelete={() => onDeleteSubTestParameter(subTestConfirmationModal.ttp_id)}
      />
      {test_id ? null : (
        <Breadcrumb pages={pages} />)}
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">TestParameter List</h1>

      </div>
      <div className="mt-4 flex">
        <input type="file"
          onChange={handleImport}
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
          placeholder="Import File"
        />
        <Link
          to={`/add-subtest/${test_id}`}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </Link>
        {/* <Link
          to={`/uploads/sub_tests/test_params.xlsx`}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
          target="_blank"
        >
          Download Excel Formate
        </Link> */}
      </div>
      {
        loading ? (
          <FallingLinesLoader />
        ) : ((subTestList.length > 0) ? (
          <Table
            columns={subtest_columns({ onDeleteOpen, onSubTestDeleteOpen })}
            data={subTestList}
          />
        ) : <TableEmpty />)
      }
    </div >
  );
}
export default TestParameterList;