import React from 'react'
import { Line } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    ArcElement,
    LineElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    PieController,
    LineController,
    PolarAreaController,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
} from 'chart.js'

ChartJS.register(
    BarElement,
    LinearScale,
    CategoryScale,
    /*
      BarController,
      ArcElement,
      LineElement,
      PointElement,
      BubbleController,
      DoughnutController,
      LineController,
      PieController,
      PolarAreaController,
      RadarController,
      ScatterController,
      LogarithmicScale,
      RadialLinearScale,
      TimeScale,
      TimeSeriesScale,
      Decimation,
      Filler,
      */
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    SubTitle,
    Tooltip,
    Title
)

export default function ReservationChart({ numberOfReservationsData, salesLabel, yText }) {

    const options = {
        // responsive: true,

        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: 'Reservation Data',
            },
        },

        scales: {
            y: {
                beginAtZero: true,
                // display: false,
                ticks: {
                    display: true,
                },
                title: {
                    display: true,
                    //   text: yText,
                },
            },
            //       x: {
            //         title: {
            //           display: true,
            //           text: 'Host App',
            //         },
            //       },
        },

    };

    let labels = salesLabel;

    const data = {
        labels,
        datasets: [
            {
                label: 'Number of Reservations',
                data: numberOfReservationsData,

                borderColor: 'rgb(0, 172, 193)',
                backgroundColor: 'rgba(0, 172, 193, 0.5)',
            },
        ],
    };

    return (
        <div className="chart-div">
            {/* <Bar data={data} options={options} /> */}
            <Line options={options} data={data} />
        </div>
    )
}


//   const data = {
//     // labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
//     labels: usageData.labels,
//     datasets: [
//       {
//         label: label,
//         data: usageData.labelUsage,
//         fill: false,
//         backgroundColor: [
//           'rgba(255, 99, 132, 0.6)',
//           'rgba(255, 159, 64, 0.6)',
//           'rgba(255, 205, 86, 0.6)',
//           'rgba(75, 192, 192, 0.6)',
//           'rgba(54, 162, 235, 0.6)',
//           'rgba(153, 102, 255, 0.6)',
//           'rgba(201, 203, 207, 0.6)',
//         ],
//         borderColor: [
//           'rgb(255, 99, 132)',
//           'rgb(255, 159, 64)',
//           'rgb(255, 205, 86)',
//           'rgb(75, 192, 192)',
//           'rgb(54, 162, 235)',
//           'rgb(153, 102, 255)',
//           'rgb(201, 203, 207)',
//         ],
//         borderWidth: 1,
//       },
//     ],
//   }

//   const options = {
//     scales: {
//       y: {
//         beginAtZero: true,
//         // display: false,
//         ticks: {
//           display: true,
//         },
//         title: {
//           display: true,
//           text: yText,
//         },
//       },
//       x: {
//         title: {
//           display: true,
//           text: 'Host App',
//         },
//       },
//     },
//   }