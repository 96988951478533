import React, { useState, Fragment, useRef, useEffect, } from 'react'
import { Link } from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import TableEmpty from '../../../components/tables/tableEmpty';
import { test_color_code } from '../../../components/tables/tableheader';
import MasterServices from '../../../ApiServices/MasterServices'


const TestColor = () => {
    const pages = [{ title: "Test Color List", href: "/test-color", module_id: 0 }];
    const [ loading, setLoading ] = useState(false);
    const [testColorList, setTestColorList] = useState([])

    async function getTestColorList() {
        setLoading(true)
        try {
          const testColorsFromApi = await MasterServices.getTestColorList();
          setTestColorList(testColorsFromApi.data.data)
          setLoading(false)
        } catch(error) {
          console.log(error)
          setLoading(false)
        }
    }
    useEffect(() => {
        getTestColorList();
    }, [])


    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8">
                <Breadcrumb pages={pages} />
                <div className="">
                    <h1 className="text-xl font-semibold text-gray-900">Test Color List</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the Test Color.</p>
                </div>
                <div className="mt-4 flex">
                    <Link
                        to="/add-test-color"
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
                    >
                        Add
                    </Link>
                </div>
                {
                    loading ? (
                        <FallingLinesLoader />
                    ) : ((testColorList.length > 0) ? (
                        <Table
                            columns={test_color_code()}
                            data={testColorList}
                        />
                    ) : <TableEmpty />)
                }
            </div>
        </>
    )
}

export default TestColor