import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { locationSchema } from "../../../schemas";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { location_columns } from "../../../components/tables/tableheader";
import { getAllHospitals } from "../../../redux/hospital/actions";
import { Dialog, Transition, } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Select from 'react-select'
import { toast } from 'react-toast'
import MasterServices from '../../../ApiServices/MasterServices'
import { singleUploadFiles } from "../../../helper/multiUploadHelper";
import queryString from 'query-string';

function LocationList() {
  const { hos_id: hos_id } = useParams();
  const pages = [{ title: "Location List", href: `/hospital-tab/${hos_id}`, module_id: 23 }];
  const [loading, setLoading] = useState(true);
  const [locationList, setLocationList] = useState(false)
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [progress, setProgress] = useState();
  const [fileName, setFile] = useState(false);
  const [fileNamePath, setFilePath] = useState('');
  const [regionList, setRegionList] = useState([])
  const [cityList, setCityList] = useState([])

  const [selectMutiRegion, setMutiRegion] = useState(false);
  const [multiRegionId, setMultiRegionId] = useState([]);

  const [loc_id, setLocationid] = useState('');
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    loc_id: null,
    loc_is_active: false,
  });

  const HospList = [];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { list: hospitalList } = useSelector(
    (state) => state.hospital
  );


  useEffect(() => {
    document.title = "Labib | Locations";
    dispatch(getAllHospitals());
    if (hos_id) {
      getAllLocationList(hos_id);
      getAllRegions();
      getAllCitys(queryString.stringify({
        search: 1
      }));
    }
  }, [hos_id]);

  const getAllRegions = async () => {
    MasterServices.getRegionList(1)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setRegionList(data.data);
            let regionnew = data.data;
            let regions = [];
            regionnew.forEach((element) => {
              let obj = {
                label: element.rgn_name_en,
                value: element.rgn_id,
                rgn_name_ar: element.rgn_name_ar,
                rgn_is_active: element.rgn_is_active,
                rgn_loc_id: 0,
              }

              regions.push(obj);
            })

            setMutiRegion(regions);
          } else {
            toast.error("getRegionList Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = "getRegionList catch Error";
        toast.error(msg);
        setLoading(false);
      });
  };

  const getAllCitys = async (payload) => {
    MasterServices.getCity(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setCityList(data.data);
          } else {
            toast.error("getCityList Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = "getCityList catch Error";
        toast.error(msg);
        setLoading(false);
      });
  };


  const getAllLocationList = (hos_id) => {
    MasterServices.getLocationList(hos_id)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setLocationList(data.data);
          } else {
            toast.error("LocationList Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = "getAllLocationList catch Error"; //error.response.data.message;
        toast.error(msg);
        setLoading(false);
      });
  }

  useEffect(() => {
    hospitalList.forEach((ele) => {
      let obj = {
        label: ele.hos_name_en,
        value: ele.hos_id
      }
      HospList.push(obj);
    })

  });
  let initialValues = {
    loc_id: "",
    loc_name_ar: "",
    loc_name_en: "",
    loc_long: "",
    loc_branch_code: "",
    loc_lat: "",
    loc_add: "",
    loc_pincode: "",
    fk_city_id: "",
    loc_logo_url: "",
    fk_hos_id: hos_id,
    fk_region_id: "",
    // fk_region_id: [],
    loc_is_active: true,
  };
  const handleFileChange = async (e) => {
    const response = await singleUploadFiles(e.target.files[0], 'locations');
    setFilePath(response.data);
    setProgress(response.progress);
    // setFile(e.target.files[0]);
  };

  const [formLocation, setFormLocation] = useState(initialValues);


  const handleDrawer = (type, id, obj) => {
    if (type === "edit", id) {
      setLocationid(id);
      let initialValues = {
        loc_id: obj.loc_id,
        loc_name_ar: obj.loc_name_ar,
        loc_name_en: obj.loc_name_en,
        loc_logo_url: obj.loc_logo_url,
        loc_long: obj.loc_long,
        loc_branch_code: obj.loc_branch_code,
        loc_lat: obj.loc_lat,
        loc_add: obj.loc_add,
        loc_pincode: obj.loc_pincode,
        fk_city_id: obj.fk_city_id,
        fk_hos_id: hos_id,
        fk_region_id: obj.fk_region_id,
        loc_is_active: obj.loc_is_active,
      };
      setFilePath(obj.loc_logo_url ? obj.loc_logo_url : '');
      // setMultiRegionId(obj.fk_region_id);
      setFormLocation(initialValues);
    } else {
      setLocationid("")
      setFormLocation(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  // const multiRegion = (event) => {
  //   initialValues.fk_region_id = JSON.stringify(event);

  //   setMultiRegionId(event)
  //   console.log(initialValues.fk_region_id);
  // }


  const onDeleteOpen = (loc_id, loc_is_active) => {
    setConfirmationModal({ loc_id, loc_is_active, status: true });
  };

  const onDeleteLocation = (loc_id, loc_is_active) => {
    let Newloc_is_active = false;
    Newloc_is_active = loc_is_active == true ? false : true;
    let text = "Activated";
    if (Newloc_is_active == true) {
      text = "Activated";
    } else {
      text = "In-Activated";
    }
    MasterServices.setLocationStatus({
      loc_id,
      loc_is_active: Newloc_is_active
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Location " + text + " Successfully!")
          setConfirmationModal({ loc_id: null, status: false });
          getAllLocationList(hos_id);
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error.response.data.message;
        toast.error(msg)
      });
    setConfirmationModal({ loc_id: null, status: false });
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({

      enableReinitialize: true,
      initialValues: formLocation,
      validationSchema: locationSchema,
      onSubmit: async (values, action) => {
        console.log("values", values);
        let fileRes
        if (fileName) {
          fileRes = await singleUploadFiles(fileName, 'locations');
        }
        let body = {
          loc_id: loc_id,
          loc_is_active: true,
          loc_name_ar: values.loc_name_ar,
          loc_name_en: values.loc_name_en,
          loc_long: values.loc_long,
          loc_branch_code: values.loc_branch_code,
          loc_lat: values.loc_lat,
          loc_add: values.loc_add,
          loc_pincode: values.loc_pincode,
          fk_city_id: values.fk_city_id,
          loc_logo_url: fileRes ? fileRes : fileNamePath,
          fk_hos_id: parseInt(hos_id),
        }

        // body.fk_region_id = multiRegionId;
        body.fk_region_id = values.fk_region_id;
        console.log("body=>", body);
        // if (body.fk_region_id.length <= 0) {
        //   setmodalOpenFlage(false);
        //   toast.error("Please select at least 1 Region");
        //   return false;
        // }
        MasterServices.saveLocation(body)
          .then((response) => {
            const { data, status } = response;
            if (status == 200) {
              if (loc_id != 0 || loc_id != '') {
                toast.success("Location Updated Successfully");
              } else {
                toast.success("Location Added Successfully");
              }
              getAllLocationList(hos_id);
            } else {
              toast.error("Fatal Error Please Contact Admin")
            }
          })
          .catch((error) => {
            const msg = "saveLocation Catch Error" //error.response.data.message;
            toast.error(msg)
          });
        action.resetForm();
        if (modalOpenFlage === true) {
          setmodalOpenFlage(false);
          navigate(`/hospital-tab/${hos_id}`);
        };
        console.log("locationList", locationList);
        // navigate(`/location/${hos_id}`);
      }
    });

  return (
    <div className="px-4 sm:px-6 lg:px-8 ">
      <ConfirmationModal
        title={"Are you sure want to " + (confirmationModal.loc_is_active ? "In-Activate? " : "Activate?") + " Location"}
        confirmationButtonText={confirmationModal.loc_is_active ? "In-Activate" : "Activate"}
        description={"Do you really want to " + (confirmationModal.loc_is_active ? "In-Activate" : "Activate") + " Location"}
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteLocation(confirmationModal.loc_id, confirmationModal.loc_is_active)}
      />
      {hos_id ? null : (
        <Breadcrumb pages={pages} />)}
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Location List</h1>

      </div>
      <div className="mt-4 flex">
        <Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </Link>
      </div>

      {
        loading ? (
          <FallingLinesLoader />
        ) : ((locationList.length > 0) ? (
          <Table
            columns={location_columns({ onDeleteOpen, handleDrawer })}
            data={locationList}
          />
        ) : <TableEmpty />)
      }


      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form onSubmit={handleSubmit} className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">

                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-cyan-700 py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {loc_id ? "Update" : "Add"} Location
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="divide-y divide-gray-200 px-4 sm:px-6">
                              <div className="sm:col-span-2 sm:mt-1">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Hospital Name
                                  </label>
                                </div>
                                <select
                                  disabled
                                  value={values.fk_hos_id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                  name="fk_hos_id"
                                  id="fk_hos_id"
                                >
                                  <option >Select Location</option>
                                  {hospitalList.map((hospital, i) => (
                                    <option
                                      selected={hospital.hos_id === values.fk_hos_id ? "selected" : ""}
                                      key={i}
                                      value={hospital.hos_id}
                                    >
                                      {hospital.hos_name_en}
                                    </option>
                                  ))}
                                </select>
                                {/* <Select
                                  name="fk_hos_id"
                                  id="fk_hos_id"
                                  value={values.fk_hos_id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  options={HospList}
                                  className="basic-multi-select"
                                  classNamePrefix="Select Test"
                                /> */}
                                {errors.fk_hos_id && touched.fk_hos_id ? (
                                  <p className="text-red-600 text-sm">{errors.fk_hos_id}</p>
                                ) : null}
                              </div>
                              <div className="sm:col-span-2 sm:mt-1">
                                <div>
                                  <label
                                    htmlFor="fk_region_id"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Region
                                  </label>
                                </div>
                                <div className="sm:col-span-2 sm:mt-1">
                                  <select

                                    value={values.fk_region_id}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                    name="fk_region_id"
                                    id="fk_region_id"
                                  >
                                    <option >Select Region</option>
                                    {regionList.map((region, i) => (
                                      <option selected={region.rgn_id === values.fk_region_id ? "selected" : ""} key={i} value={region.rgn_id}>
                                        {region.rgn_name_en}
                                      </option>
                                    ))}
                                  </select>
                                  {/* <Select
                                    isMulti
                                    name="fk_region_id"
                                    id="fk_region_id"
                                    value={multiRegionId}
                                    onChange={(e) => multiRegion(e)}
                                    onBlur={handleBlur}
                                    options={selectMutiRegion}
                                    className="basic-multi-select"
                                    classNamePrefix="Select Region"
                                  /> */}
                                  {errors.fk_region_id && touched.fk_region_id ? (
                                    <p className="text-red-600 text-sm">{errors.fk_region_id}</p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="sm:col-span-2 sm:mt-1">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Location Name (EN)
                                  </label>
                                </div>
                                <div className="sm:col-span-2 sm:mt-1">
                                  <input
                                    value={values.loc_name_en}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Location Name"
                                    name="loc_name_en"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.loc_name_en && touched.loc_name_en ? (
                                    <p className="text-red-600 text-sm">{errors.loc_name_en}</p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="sm:col-span-2 sm:mt-1">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Location Name (AR)
                                  </label>
                                </div>
                                <div className="sm:col-span-2 sm:mt-1">
                                  <input
                                    value={values.loc_name_ar}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Location Name"
                                    name="loc_name_ar"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.loc_name_ar && touched.loc_name_ar ? (
                                    <p className="text-red-600 text-sm">{errors.loc_name_ar}</p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="sm:col-span-2 sm:mt-1">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Latitude
                                  </label>
                                </div>
                                <div className="sm:col-span-2 sm:mt-1">
                                  <input
                                    value={values.loc_lat}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Latitude"
                                    name="loc_lat"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.loc_lat && touched.loc_lat ? (
                                    <p className="text-red-600 text-sm">{errors.loc_lat}</p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="sm:col-span-2 sm:mt-1">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Longitude
                                  </label>
                                </div>
                                <div className="sm:col-span-2 sm:mt-1">
                                  <input
                                    value={values.loc_long}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Longitude"
                                    name="loc_long"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.loc_long && touched.loc_long ? (
                                    <p className="text-red-600 text-sm">{errors.loc_long}</p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="sm:col-span-2 sm:mt-1">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Branch Code
                                  </label>
                                </div>
                                <div className="sm:col-span-2 sm:mt-1">
                                  <input
                                    value={values.loc_branch_code}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Branch Code"
                                    name="loc_branch_code"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.loc_branch_code && touched.loc_branch_code ? (
                                    <p className="text-red-600 text-sm">{errors.loc_branch_code}</p>
                                  ) : null}
                                </div>
                              </div>

                              {/* Add Add,CIty,Pincode */}
                              <div className="sm:col-span-2 sm:mt-1">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Address
                                  </label>
                                </div>
                                <div className="sm:col-span-2 sm:mt-1">
                                  <input
                                    value={values.loc_add}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Address"
                                    name="loc_add"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.loc_add && touched.loc_add ? (
                                    <p className="text-red-600 text-sm">{errors.loc_add}</p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="sm:col-span-2 sm:mt-1">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Pincode
                                  </label>
                                </div>
                                <div className="sm:col-span-2 sm:mt-1">
                                  <input
                                    value={values.loc_pincode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Pincode"
                                    name="loc_pincode"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.loc_pincode && touched.loc_pincode ? (
                                    <p className="text-red-600 text-sm">{errors.loc_pincode}</p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="sm:col-span-2 sm:mt-1">
                                <div>
                                  <label
                                    htmlFor="fk_city_id"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    City
                                  </label>
                                </div>
                                <div className="sm:col-span-2 sm:mt-1">
                                  <select

                                    value={values.fk_city_id}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                    name="fk_city_id"
                                    id="fk_city_id"
                                  >
                                    <option >Select City</option>
                                    {cityList.map((city, i) => (
                                      <option selected={city.city_id === values.fk_city_id ? "selected" : ""} key={i} value={city.city_id}>
                                        {city.city_name_en}
                                      </option>
                                    ))}
                                  </select>
                                  {errors.fk_city_id && touched.fk_city_id ? (
                                    <p className="text-red-600 text-sm">{errors.fk_city_id}</p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="sm:col-span-2 sm:mt-1">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Logo
                                  </label>
                                </div>
                                <div className="sm:col-span-2 sm:mt-1">
                                  <input type="file" accept="image/*" onChange={handleFileChange} />
                                  {fileNamePath ? (<img width={40} height={20} src={fileNamePath} alt={values.loc_name_en} />) : (<></>)}
                                  {progress ? (<><div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2">
                                    <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full mt-1"> {`${progress}%`}</div>
                                  </div></>) : (<></>)}
                                  {errors.loc_logo_url && touched.loc_logo_url ? (
                                    <p className="text-red-600 text-sm">{errors.loc_logo_url}</p>
                                  ) : null}
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false)
                            }
                            }
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            {loc_id ? 'Update' : 'Add'}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div >
  );
}
export default LocationList;