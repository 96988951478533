import { useEffect, useRef, useState } from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import { GeoapifyGeocoderAutocomplete } from '@geoapify/react-geocoder-autocomplete';
import L from 'leaflet';
import DraggableMarker from './DragableMarker';

// GEOAPIFY
const GEOAPIFY_KEY = "45ea0bd22eca470b91b3b43474105c92"

export default function AddressMap({ latLong, setLatLong }) {
    const newIcon = new L.Icon({
        iconUrl: `https://api.geoapify.com/v1/icon/?type=material&color=%230097a7&icon=place&iconType=awesome&apiKey=${GEOAPIFY_KEY}`,
        iconSize: [30, 50],
        iconAnchor: [15, 30],
    });

    const [readyToRender, setReadyToRender] = useState(false)

    const mapRef = useRef(null);

    let centerPosition = latLong ? L.latLng(...latLong) : L.latLng(24.7573, 46.7318)

    const handleLatLongChange = (e) => {
        // setPosition(e.latlng);
        centerPosition = latLong ? L.latLng(...latLong) : L.latLng(24.7573, 46.7318)
        // Recenter the map to the new marker position
        if (mapRef.current !== null) {
            mapRef.current.setView(centerPosition, mapRef.current.getZoom());
        }
    };


    useEffect(() => {
        handleLatLongChange()
    }, [latLong])

    setTimeout(() => {
        setReadyToRender(true);
    }, 200);

    return (
        <div>
            {/* Geoapify Map */}
            {
                readyToRender && (
                    <MapContainer center={centerPosition} zoom={17} ref={mapRef}>
                        {/* Use the TileLayer component to set the map tiles */}
                        <TileLayer className="tile_layer" url={`https://maps.geoapify.com/v1/tile/carto/{z}/{x}/{y}.png?&apiKey=${GEOAPIFY_KEY}`} />

                        {/* Use the GeoapifyGeocoderAutocomplete component to add a search box */}
                        <GeoapifyGeocoderAutocomplete apiKey={GEOAPIFY_KEY} placeholder="Search address" />

                        {/* Add markers or other components as needed */}
                        <DraggableMarker latLong={latLong} centerPosition={centerPosition} newIcon={newIcon} setLatLong={setLatLong} />

                    </MapContainer>
                )
            }
        </div>
    )
}