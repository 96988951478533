import React, { useEffect, useState, Fragment, useRef } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { priceListSchema } from "../../../schemas";
import { XMarkIcon } from "@heroicons/react/24/outline";
import TableEmpty from "../../../components/tables/tableEmpty";
import { Dialog, Transition, } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import { toast } from 'react-toast'
import MasterServices from '../../../ApiServices/MasterServices'

const typeList = ["Sales", "Contract"];

function AddUpdatePriceList() {

  const initialValues = { price_id: "", price_name_ar: "", price_name_en: "", price_type: "" };
  const pages = [{ title: "Price List", href: "/price-list" }];

  //const [price_id, setPriceListId] = useState('')
  const [formState, setFormState] = useState(initialValues);
  const [priceList, setPriceList] = useState(false)
  const { price_id: price_id } = useParams();

  useEffect(() => {
    document.title = "Price List | Information";
    if (price_id) {
      getPriceListById(price_id)
    }
  }, [price_id]);

  const getPriceListById = (price_id) => {
    MasterServices.getSinglePriceList(price_id)
      .then((response) => {
        const { data, status } = response;
        if (status) {
          console.log("setPriceList: ", data)
          if (data?.data != null) {
            setPriceList(data.data);
            const initialValues = {
              price_id: data.data.price_id,
              price_name_en: data.data.price_name_en,
              price_name_ar: data.data.price_name_ar,
              price_type: data.data.price_type
            };
            setFormState(initialValues);
            console.log("setPriceList: ", data.data)

          } else {
            toast.error("PriceList Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "getAllPriceList catch Error"; //error.response.data.message;
        toast.error(msg);
      });
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      validationSchema: priceListSchema,
      onSubmit: (values, action) => {
        let body = {
          price_id: price_id,
          price_name_en: values.price_name_en,
          price_name_ar: values.price_name_ar,
          price_type: values.price_type
        }
        if (price_id === undefined || price_id === null || price_id === "") { } else {
          body.price_id = price_id;
        }

        MasterServices.savePriceList(body)
          .then((response) => {
            const { data, status } = response;
            if (status == 200) {
              if (price_id != 0 || price_id != '') {
                toast.success("PriceList Updated Successfully");
              } else {
                toast.success("PriceList Added Successfully");
              }
            } else {
              toast.error("Fatal Error Please Contact Admin")
            }
          })
          .catch((error) => {
            const msg = "savePriceList Catch Error" //error.response.data.message;
            toast.error(msg)
          });

      },
    });

  return (
    <div className="bg-white px-4 py-5 sm:px-6 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
        className="space-y-6 divide-gray-200"
      >
        <div className="grid grid-cols-3 gap-3 lg:grid-cols-3 lg:gap-3 md:grid-cols-3 md:gap-3 sm:grid-cols-12 sm:gap-0 mt-5">

          <div className="">
            <label htmlFor="test_name_ar" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Test Name(EN)</label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <input
                value={values.price_name_en}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                placeholder="Enter Price Name"
                name="price_name_en"
                autoComplete="off"
                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
              />
              {errors.price_name_en && touched.price_name_en ? (
                <p className="text-red-600 text-sm">{errors.price_name_en}</p>
              ) : null}
            </div>
          </div>

          <div className="">
            <label htmlFor="test_name_ar" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Test Name(AR)</label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <input
                value={values.price_name_ar}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                placeholder="Enter Price Name"
                name="price_name_ar"
                autoComplete="off"
                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
              />
              {errors.price_name_ar && touched.price_name_ar ? (
                <p className="text-red-600 text-sm">{errors.price_name_ar}</p>
              ) : null}
            </div>
          </div>

          <div className="">
            <label htmlFor="fk_hos_id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Hospital</label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <select
                value={values.price_type}
                onChange={handleChange}
                onBlur={handleBlur}
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                name="price_type"
                id="price_type"
              >
                <option >Select Type</option>
                {typeList.map((name, i) => (
                  <option selected={name === values.price_type ? "selected" : ""} key={i} value={name}>
                    {name}
                  </option>
                ))}
              </select>
              {errors.price_type && touched.price_type ? (
                <p className="text-red-600 text-sm">{errors.price_type}</p>
              ) : null}
            </div>
          </div>
        </div>

        <hr className="mt-3" />
        <div className="flex justify-end mt-3">
          <button
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
          >
            Update
          </button>
        </div>

      </form>

    </div>
  )
}

export default AddUpdatePriceList;