/* eslint-disable import/no-anonymous-default-export */
import Api from './Api';

export default {
	// Organism Section Start

	async getAllOrganismList(payload) {
		const response = await Api().get(`api/dashboard/masters/organism/list?search=${payload}`);
		return response;
	},
	async getAllAntibioticList(payload) {
		const response = await Api().get(`api/dashboard/masters/antibiotic/main/list?search=${payload}`);
		return response;
	},
	async getAllAntibioticResultTypeList(payload) {
		const response = await Api().get(`api/dashboard/masters/antibiotic-rt/list?search=${payload}`);
		return response;
	},
	// Organism Section End
};
