import React, { useState, Fragment, useRef, useEffect, } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import TableEmpty from '../../../components/tables/tableEmpty';
import { clinic_refferal, clinic_refferal_reservations, coupon_code_list, coupon_code_res_logs } from '../../../components/tables/tableheader';
import MasterServices from '../../../ApiServices/MasterServices'
import Modal from '../../../components/Modal';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';

const initialModalStateCouponCode = {
    state: false,
    data: [],
  }

const ClinicRefferal = () => {
    const pages = [{ title: "Coupon Codes", href: "/coupons/:id", module_id: 25 }];
    const navigate = useNavigate();
    const [ loading, setLoading ] = useState(false);
    const [couponCodeList, setCouponCodeList] = useState([])
    const [modalCouponCode, setModalCouponCode] = useState(initialModalStateCouponCode);
    const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		resv_ref_id: null,
	});
    const { id: ID } = useParams();


    async function getCouponCodeList() {
        setLoading(true)
        try {
          const {data, status} = await MasterServices.getCouponCodesByCouponID(ID);
            if(status === 200) {
                setCouponCodeList(data.data)
            }
          setLoading(false)
        } catch(error) {
          console.log(error)
          setLoading(false)
        }
    }
    
    const renderCouponCodeModel = () => {
        const { state, data } = modalCouponCode;
    
        return (
            <Modal
                title="Coupon Code Logs"
                open={state}
                size="max-w-3xl relative"
                setOpen={() => setModalCouponCode((prev) => ({ ...prev, state: false }))}
            >
            <Table
              columns={coupon_code_res_logs({})}
              data={data}
            />   
            </Modal>
        );
    };
    async function openViewModal(data) {
        console.log(data)
        setModalCouponCode({
            data: data,
            state: true
        })
    }

    useEffect(() => {
        getCouponCodeList();
    }, [])


    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8">
                <Breadcrumb pages={pages} />
                <div className="">
                    <h1 className="text-xl font-semibold text-gray-900">Coupon Code List</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the Coupon Codes.</p>
                </div>
                <div className="mt-4 flex">
                    <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                    >
                        <span className="w-5 mr-2">
                            <ArrowUturnLeftIcon size={18} />
                        </span>{" "}
                        Back
                    </button>
                </div>
                {
                    loading ? (
                        <FallingLinesLoader />
                    ) : ((couponCodeList.length > 0) ? (
                        <Table
                            columns={coupon_code_list({ openViewModal })}
                            data={couponCodeList}
                        />
                    ) : <TableEmpty />)
                }
            {renderCouponCodeModel()}
            </div>
        </>
    )
}

export default ClinicRefferal