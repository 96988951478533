import React from 'react'
import Breadcrumb from '../../../components/Breadcrumb'

const QuestionnairePreview = () => {
    const pages = [{ title: "Questionnaire Preview", href: "/questionnaire-preview" }];

    return (
        <div>
            <Breadcrumb pages={pages} />
            <div className="space-y-6">
                <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Questionnaire Preview</h3>
                </div>
                <form className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                            <div>
                                <h3 className="text-center font-semibold leading-6 text-gray-900">Heading</h3>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                        Input
                                    </label>
                                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            name="first-name"
                                            id="first-name"
                                            autoComplete="given-name"
                                            className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="number" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                        Number
                                    </label>
                                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="number"
                                            name="number"
                                            id="number"
                                            autoComplete="off"
                                            className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="date" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                        Date
                                    </label>
                                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="date"
                                            name="date"
                                            id="date"
                                            autoComplete="off"
                                            className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                        Select
                                    </label>
                                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                                        <select
                                            id="country"
                                            name="country"
                                            autoComplete="country-name"
                                            className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                        >
                                            <option>United States</option>
                                            <option>Canada</option>
                                            <option>Mexico</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="text-area" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                        Text Area
                                    </label>
                                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                                        <textarea
                                            id="text-area"
                                            name="text-area"
                                            rows={3}
                                            className="block w-full max-w-lg rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:py-1.5 sm:text-sm sm:leading-6"
                                            defaultValue={''}
                                        />
                                    </div>
                                </div>
                                <div role="group" aria-labelledby="label-notifications">
                                    <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
                                        <div>
                                            <div className="text-sm font-semibold leading-6 text-gray-900" id="label-notifications">
                                                Redio
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <div className="max-w-lg">
                                                <p className="text-sm text-gray-500">These are delivered via SMS to your mobile phone.</p>
                                                <div className="mt-4 space-y-4">
                                                    <div className="flex items-center">
                                                        <input
                                                            id="push-everything"
                                                            name="push-notifications"
                                                            type="radio"
                                                            className="h-4 w-4 border-gray-300 text-cyan-600 focus:ring-cyan-600"
                                                        />
                                                        <label
                                                            htmlFor="push-everything"
                                                            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                                                        >
                                                            Everything
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <input
                                                            id="push-email"
                                                            name="push-notifications"
                                                            type="radio"
                                                            className="h-4 w-4 border-gray-300 text-cyan-600 focus:ring-cyan-600"
                                                        />
                                                        <label
                                                            htmlFor="push-email"
                                                            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                                                        >
                                                            Same as email
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <input
                                                            id="push-nothing"
                                                            name="push-notifications"
                                                            type="radio"
                                                            className="h-4 w-4 border-gray-300 text-cyan-600 focus:ring-cyan-600"
                                                        />
                                                        <label
                                                            htmlFor="push-nothing"
                                                            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                                                        >
                                                            No push notifications
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-5">
                        <div className="flex justify-end gap-x-3">
                            <button
                                type="button"
                                className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="inline-flex justify-center rounded-md bg-cyan-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default QuestionnairePreview