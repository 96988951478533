/* eslint-disable import/no-anonymous-default-export */
import Api from './Api';
import { BASEURL } from "../helper/constant.js";

export default {
	// Sample Section Start
	async getAllMicroSampleProcessList(payload) {
		const response = await Api().get(`api/dashboard/micro-sample/list/${payload.page}/${payload.pageSize}?${payload.filter}`);
		return response;
	},

	async saveSampleTestResult(payload) {
		const response = await Api().post(`api/dashboard/micro-sample/`, payload);
		return response;
	},
	
	async generateReport(payload) {
		const response = await Api().post(`api/dashboard/micro-sample/generate_report`,payload);
		return response;
	},
	async getAllReservationSamplesResult(payload) {
		const response = await Api().get(`api/dashboard/micro-sample/processing/list/get-test-result/${payload}`);
		return response;
	},

};
