import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import { Switch } from "@headlessui/react";
import { Formik } from "formik";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import { useNavigate, useParams } from "react-router-dom";

const AddItems = () => {
  const { item_id: Iid } = useParams();
  const navigate = useNavigate();
  const cartridgeList = [{name: 'Fixed'}, {name: 'Kit'}];
  const pages = [{ title: "Items", href: "/items", module_id:18 }];
  const [loading, setLoading] = useState(false);
  const [optUnitList, setOptUnitList] = useState([]);
  const [itemData, setItemData] = useState({});
  const [itemCategories, seItemCategories] = useState({
    loading: false,
    data: [],
  });
  const [formstate, setFormstate] = useState({
    witem_name_en: "",
    witem_name_ar: "",
    witem_lead_time: "",
    witem_price: "",
    fk_unit_id: "",
    witem_type: "",
    witem_number_test: "",
    fk_wic_id: "",
    wic_id: "",
  });
  useEffect(() => {
    document.title = "Labib | Add Items";
    if (Iid) getItemById(Iid);

    getAllUnitsOptions();
    getAllItemsCategory();
  }, [Iid]);

  useEffect(() => {
    if (Iid && itemData) {
      const newFormstate = {
        witem_name_en: itemData.witem_name_en,
        witem_name_ar: itemData.witem_name_ar,
        witem_lead_time: itemData.witem_lead_time,
        witem_price: itemData.witem_price,
        fk_unit_id: itemData.fk_unit_id,
        witem_type: itemData.witem_type,
        witem_number_test: itemData.witem_number_test,
        fk_wic_id: itemData.fk_wic_id,
        wic_name: itemData.wic_name,
        witem_item_code: itemData.witem_item_code
      };
      setFormstate(newFormstate);
    }
  }, [Iid, itemData]);
  const getAllUnitsOptions = async () => {
    setLoading(true);
    await MasterServices.getUnitsList()
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setOptUnitList(data.data);
          // toast.success("Items Fetched Successfully");
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };

  const getItemById = (id) => {
    setLoading(true);
    MasterServices.getOneItems(id)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setItemData(data.data);
          // toast.success("Items Fetched Successfully");
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };

  const getAllItemsCategory = async () => {
    seItemCategories((prev) => ({ ...prev, loading: true }));
    await MasterServices.getWarehouseItemCategory()
      .then((res) => {
        const { data, status } = res;
        console.log("Item Categories", data.data);
        if (status === 200) {
          seItemCategories({
            loading: false,
            data: data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addItem = async (payload) => {
    setLoading(true);
    await MasterServices.addItems(payload)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          toast.success("Item Added Successfully");
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
    navigate("/items");
  };

  const updateItem = async (id, payload) => {
    setLoading(true);
    await MasterServices.updateItems(id, payload)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          toast.success("Item Updated Successfully");
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
    navigate("/items");
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Add Items</h1>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Formik
          initialValues={formstate}
          enableReinitialize={true}
          onSubmit={async (values, { setSubmitting }) => {
            // console.log(values);
            const body = {
              witem_name_en: values.witem_name_en,
              witem_name_ar: values.witem_name_ar,
              witem_lead_time: parseInt(values.witem_lead_time),
              witem_price: parseInt(values.witem_price),
              fk_unit_id: parseInt(values.fk_unit_id),
              witem_type: values.witem_type,
              witem_number_test: values.witem_number_test,
              fk_wic_id: parseInt(values.fk_wic_id),
              witem_item_code: values.witem_item_code,
            };
            if (Iid) {
              updateItem(Iid, body);
            } else {
              // console.log("Add Item body payload", body)
              addItem(body);
            }
            // navigate("/items");
            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            witem_name_en: Yup.string().required("Please enter en name"),
            witem_name_ar: Yup.string().required("Please enter ar name"),
            witem_lead_time: Yup.string().required("Please enter lead time"),
            witem_price: Yup.string().required("Please enter price "),
            fk_unit_id: Yup.string().required("Please select a unit "),
            witem_type: Yup.string().required("Please select type"),
            witem_number_test: Yup.string().required("Please select number test")
          })}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form
              onSubmit={handleSubmit}
              className="space-y-8 divide-y divide-gray-200"
            >
              <div className="space-y-6 sm:space-y-5">
                <div className="space-y-6 sm:space-y-5 sm:pt-5">
                  <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="location_name"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Name English
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          type="text"
                          name="witem_name_en"
                          id="witem_name_en"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.witem_name_en}
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                        {errors.witem_name_en && touched.witem_name_en ? (
                          <p className="text-red-700">{errors.witem_name_en}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="location_name"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Name Arabic
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          type="text"
                          name="witem_name_ar"
                          id="witem_name_ar"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.witem_name_ar}
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                        {errors.witem_name_ar && touched.witem_name_ar ? (
                          <p className="text-red-700">{errors.witem_name_ar}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="location-code"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Lead Time
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          type="text"
                          name="witem_lead_time"
                          id="witem_lead_time"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.witem_lead_time}
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                        {errors.witem_lead_time && touched.witem_lead_time ? (
                          <p className="text-red-700">
                            {errors.witem_lead_time}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="location-code"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Item Price
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          type="text"
                          name="witem_price"
                          id="witem_price"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.witem_price}
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                        {errors.witem_price && touched.witem_price ? (
                          <p className="text-red-700">{errors.witem_price}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="location-code"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Unit
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <select
                          id="fk_unit_id"
                          name="fk_unit_id"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fk_unit_id}
                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                        >
                          <option value="all">Select a Unit</option>

                          {optUnitList?.map((item, index) => {
                            return (
                              <option value={item.unit_id}>
                                {item.unit_name_en}
                              </option>
                            );
                          })}
                        </select>
                        {errors.fk_unit_id && touched.fk_unit_id ? (
                          <p className="text-red-600 text-sm">
                            {errors.fk_unit_id}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="location-code"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Item Type
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <select
                          id="witem_type"
                          name="witem_type"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.witem_type}
                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                        >
                          <option value="all">Select a Type</option>

                          {cartridgeList?.map((item, index) => {
                            return (
                              <option value={item.name}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                        {errors.witem_type && touched.witem_type ? (
                          <p className="text-red-600 text-sm">
                            {errors.witem_type}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="location-code"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Item Category
                      </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <select
                        id="fk_wic_id"
                        name="fk_wic_id"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.fk_wic_id}
                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                      >
                        <option value="">Select the Category</option>
                        {Array.isArray(itemCategories?.data) && itemCategories?.data.length > 0 && itemCategories.data.map((item, index) => (
                          <option key={index} value={item.wic_id}>
                            {item.wic_name}
                          </option>
                        ))}
                      </select>
                      {errors.fk_wic_id && (
                        <p className="text-red-700 error_msg">
                          {errors.fk_wic_id}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="location-code"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Item Code
                      </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <input
                        name="witem_item_code"
                        label="witem_item_code"
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                        type="text"
                        value={values.witem_item_code}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="item code"
                      />
                      {errors.witem_item_code && (
                        <p className="text-red-700 error_msg">
                          {errors.witem_item_code}
                        </p>
                      )}
                    </div>
                  </div>
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="location_name"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Number Of Tests
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          type="number"
                          name="witem_number_test"
                          id="witem_number_test"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.witem_number_test}
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                        {errors.witem_number_test && touched.witem_number_test ? (
                          <p className="text-red-700">{errors.witem_number_test}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="location-code"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        has Line Item
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <Switch
                          // onBlur={handleBlur}
                          name="enable"
                          className={
                            true
                              ? "bg-cyan-600 relative inline-flex h-Switch6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              : "bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          }
                        >
                          <span className="sr-only">Use setting</span>
                          <span
                            aria-hidden="true"
                            className={
                              true
                                ? "translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                : "translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                            }
                          />
                        </Switch>
                        {/* {errors.inventory_type && touched.inventory_type ? (
                  <p className="text-red-600 text-sm">{errors.inventory_type}</p>
                ) : null} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-5">
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default AddItems;
