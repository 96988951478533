import React, { useState, Fragment, useRef, useEffect, } from 'react'
import { Link } from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import TableEmpty from '../../../components/tables/tableEmpty';
import { warehouse_item_category } from '../../../components/tables/tableheader';
import MasterServices from '../../../ApiServices/MasterServices'
import Modal from '../../../components/Modal';
import ConfirmationModal from '../../../components/ConfirmationModal';

const initialModalStateReservationsUsed = {
    state: false,
    data: [],
  }

const WarehouseItemCategory = () => {
    const pages = [{ title: "Category List", href: "/warehouse-item-category", module_id: 25 }];
    const [ loading, setLoading ] = useState(false);
    const [warehouseItemCategoryList, setWarehouseItemCategoryList] = useState([])
    const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		wic_id: null,
	});


    async function getWarehouseItemCategoryList() {
        setLoading(true)
        try {
          const {data, status} = await MasterServices.getWarehouseItemCategory();
            if(status === 200) {
                setWarehouseItemCategoryList(data.data)
            }
          setLoading(false)
        } catch(error) {
          console.log(error)
          setLoading(false)
        }
    }

    async function deleteWarehouseItemCategory(payload) {
        setLoading(true)
        try {
          const {data, status} = await MasterServices.deleteWarehouseItemCategory(payload);
          setConfirmationModal({ wic_id: null, status: false });
            if(status === 200) {
                await getWarehouseItemCategoryList()
            }
          setLoading(false)
        } catch(error) {
          console.log(error)
          setLoading(false)
        }
    }
	const onDeleteOpen = (wic_id) => {
		setConfirmationModal({ wic_id, status: true });
	};

    useEffect(() => {
        getWarehouseItemCategoryList();
    }, [])


    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8">
                <ConfirmationModal
                    open={confirmationModal.status}
                    setOpen={setConfirmationModal}
                    onDelete={() => deleteWarehouseItemCategory(confirmationModal.wic_id)}
                />
                <Breadcrumb pages={pages} />
                <div className="">
                    <h1 className="text-xl font-semibold text-gray-900">Category List</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the Categories.</p>
                </div>
                <div className="mt-4 flex">
                    <Link
                        to="/add-warehouse-item-category"
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
                    >
                        Add
                    </Link>
                </div>
                {
                    loading ? (
                        <FallingLinesLoader />
                    ) : ((warehouseItemCategoryList.length > 0) ? (
                        <Table
                            columns={warehouse_item_category({onDeleteOpen})}
                            data={warehouseItemCategoryList}
                        />
                    ) : <TableEmpty />)
                }
            </div>
        </>
    )
}

export default WarehouseItemCategory