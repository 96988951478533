import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Modal from "../../../components/Modal";
import InputBox from "../../../components/InputBox";
import Button from "../../../components/Button";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import {
  Units_colums,
} from "../../../components/tables/tableheader";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import { Warehouse } from "@mui/icons-material";
import ConfirmationModal from "../../../components/ConfirmationModal";
const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    unit_type: "",
    unit_name_en: "",
    unit_name_ar: "",
    unit_capacity: 0,
  },
};

function Units() {
  const pages = [{ title: "Units", href: "units", module_id: 36 }];
  const [loading, setLoading] = useState(false);
  const [packageList, setPackageList] = useState(false);
  const [unitList, setUnitList] = useState([]);
  const [controlList, setControlList] = useState([]);
  const [filterUnit, setFilterUnit] = useState('');
  const [modal, setModal] = useState(initialModalState);
  const unitType = [
    {
      label: "Warehouse",
      value: "WAREHOUSE",
    },
    {
      label: "Test",
      value: "TEST",
    }
  ]
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    unit_id: null,
  });
  

  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Labib | Units";
    getAllUnits();
  }, []);
  const getAllUnits = async () => {
    setLoading(true);
    await MasterServices.getUnitsList()
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setUnitList(data.data);
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };
  const AddUnit = (payload) => {
    setLoading(true);
    MasterServices.addUnits(payload)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          getAllUnits();
          toast.success(data.message);
          setLoading(false);
          setModal((prev) => ({ ...prev, state: false }));
          getAllUnits();
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };

  // const getUnitById = async (id) => {
  //   await MasterServices.getOneUnits(id)
  //     .then((res) => {
  //       const { data, status } = res;
  //       if (status === 200) {
  //         setModal((prev) => ({
  //           ...prev,
  //           state: true,
  //           data: data.data,
  //           type: "edit",
  //           edit_id: id,
  //         }));
  //         setLoading(false);
  //       } else {
  //         toast.error("Fatal Error Please Contact Admin");
  //       }
  //     })
  //     .catch((err) => {
  //       const msg = err;
  //       toast.error(msg);
  //       setLoading(false);
  //     });
  // };

  const openEdit = (data) => {
    setModal({
      type: "",
      state: true,
      index: null,
      edit_id: data?.unit_id,
      data: {
        unit_type: data?.unit_type,
        unit_name_en: data?.unit_name_en,
        unit_name_ar: data?.unit_name_ar,
        unit_capacity: data?.unit_capacity,
      },
    })
  }

  const UpdateUnitStatus = (id, payload) => {
    MasterServices.updateUnitsStatus(id, payload)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          toast.success(data.message);
          getAllUnits();
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };

  const UpdateUnits = (id, payload) => {
    MasterServices.updateUnits(id, payload)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          getAllUnits();
          toast.success(data.message);
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };

  const onDeleteOpen = (unit_id) => {
		setConfirmationModal({ unit_id, status: true });
	};


  const deleteUnit = (payload) => {
    MasterServices.deleteUnit(payload)
      .then((response) => {
        const { data, status } = response;
        setConfirmationModal({ unit_id: null, status: false });
        if (status == 200) {
          toast.success("Unit Deleted Successfully");
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        getAllUnits();
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  const renderModal = () => {
    const { type, state, data, edit_id } = modal;

    return (
      <Formik
        initialValues={data}
        validationSchema={Yup.object({
          unit_type: Yup.string().required("Unit Type is required"),
          unit_name_en: Yup.string().required("Unit name En is required"),
          unit_name_ar: Yup.string().required("Unit name Ar is required"),
          unit_capacity: Yup.number().required("Capacity is required"),
        })}
        enableReinitialize={true}
        onSubmit={(values, action) => {
          console.log(values);
          const payload = {
            unit_type: values.unit_type,
            unit_name_en: values.unit_name_en,
            unit_name_ar: values.unit_name_ar,
            unit_capacity: parseInt(values.unit_capacity),
          }
          if(modal.edit_id) {
            payload.unit_id = modal.edit_id;
          }
          console.log(payload, "payload nitesh")
          AddUnit(payload);
          setModal({  
            type: "",
            state: false,
            index: null,
            edit_id: "",
            data: {
              unit_type: "",
              unit_name_en: "",
              unit_name_ar: "",
              unit_capacity: 0,
            },})
            action.setSubmitting(false);
            action.resetForm();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Modal
            title="Add Unit"
            open={state}
            setOpen={() => setModal((prev) => ({ ...prev, state: false }))}
          >
            <form onSubmit={handleSubmit}>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label htmlFor="unit_type" className="block text-sm font-medium text-gray-700">
                  Type
                </label>
                {unitType ?
                  (<div>
                    <select
                      id="unit_type"
                      name="unit_type"
                      value={values.unit_type}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                    >
                      <option> Select Unit Type </option>
                      {unitType.map((unit, key) => (
                        <option
                          selected={
                            unit.value ===
                              values.unit_type
                              ? "selected"
                              : ""
                          }
                          key={key}
                          value={unit.value}
                        >
                          {unit.label}
                        </option>
                      ))}
                    </select>
                    {touched.unit_type && (
                      <p className="text-red-700 error_msg">{errors.unit_type}</p>
                    )}
                  </div>) : null}

              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <InputBox
                  name="unit_name_en"
                  label="Unit Name En"
                  type="text"
                  value={values.unit_name_en}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={true}
                  placeholder="Enter Unit English Name"
                />
                {touched.unit_name_en && (
                  <p className="text-red-700 error_msg">{errors.unit_name_en}</p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <InputBox
                  name="unit_name_ar"
                  label="Unit Name Ar"
                  type="text"
                  value={values.unit_name_ar}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={true}
                  placeholder="Enter Unit Arabic Name"
                />
                {touched.unit_name_ar && (
                  <p className="text-red-700 error_msg">{errors.unit_name_ar}</p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <InputBox
                  name="unit_capacity"
                  label="Unit Capacity"
                  type="number"
                  value={values.unit_capacity}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={true}
                  placeholder="Enter Unit Capacity"
                />
                {touched.unit_capacity && (
                  <p className="text-red-700 error_msg">{errors.unit_capacity}</p>
                )}
              </div>

              <div className="mt-4"></div>
              <Button type="submit">
                {isSubmitting ? "Saving..." : "Add Unit"}
              </Button>
            </form>
          </Modal>
        )}
      </Formik>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      {renderModal()}
      <ConfirmationModal
          open={confirmationModal.status}
          setOpen={setConfirmationModal}
          onDelete={() => deleteUnit({unit_id: confirmationModal.unit_id})}
      />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Unit List</h1>
      </div>
      <div className="mt-4 flex">
        <button
          type="button"
          onClick={() => setModal({  
            type: "",
            state: true,
            index: null,
            edit_id: "",
            data: {
              unit_type: "",
              unit_name_en: "",
              unit_name_ar: "",
              unit_capacity: "",
            },})}
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </button>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : loading === false ? (
        <Table
          columns={Units_colums({ UpdateUnitStatus, UpdateUnits, openEdit, onDeleteOpen })}
          data={unitList}
        />
      ) : (
        <TableEmpty />
      )}
    </div>
  );
}
export default Units;
