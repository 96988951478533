import {
  GET_ALL_SAMPLETYPE_BEGIN,
  GET_ALL_SAMPLETYPE_SUCCESS,
  GET_ALL_SAMPLETYPE_ERROR,
} from "./constants";

const initState = {
  list: [],
  loading: false,
  error: null,
};

const SampleTypeReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case GET_ALL_SAMPLETYPE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_SAMPLETYPE_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_ALL_SAMPLETYPE_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };

    default:
      return state;
  }
};

export default SampleTypeReducer;
