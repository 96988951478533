import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { outclinic_invoice_monthwise_columns } from "../../../components/tables/tableheader";
import ReportServices from "../../../ApiServices/ReportServices";
import { toast } from "react-toast";
import { useParams } from "react-router-dom";
import Modal from "../../../components/Modal";
import { useFormik } from "formik";
import { makeMonthlyPaymentSchema } from "../../../schemas";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllPaymentModes } from "../../../redux/paymentMode/actions";

const initialModalStatePurchaseItems = {
  state: false,
  data: [],
}

const InvoiceByMonth = () => {
  const pages = [{ title: "OC Invoice MonthWise List", href: "/invoice/:oc_id", module_id: 17 }];
  const {
    paymentMode: { list: paymentModeList },
  } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [modalItems, setModalItems] = useState(initialModalStatePurchaseItems);
  const [outsourceClinicResList, setOutsourceClinicResList] = useState(false);
  const [cashAmt, setCashAmt] = useState('');
  const [cardAmt, setCardAmt] = useState('');
  const [OCDetails, setOCDetails] = useState(false);
  const { oc_id: oc_id } = useParams();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getAllPaymentModes("active"));
    getOCByMonth(parseInt(oc_id));
  }, []);


  const getOCByMonth = (payload) => {
    setIsLoading(true);
    ReportServices.getOCByMonth(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          setOutsourceClinicResList(data?.data?.dataByMonth);
          setOCDetails(data?.data?.oc_details);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = "Fatal Error Ousource Reservation Report List";
        toast.error(msg);
        setIsLoading(false);
      });
  };

  const makePayment = async (monthYearData, form) => {
    
    try {
      let payment_data = {};
      if(form.payment_id == 1) {
        payment_data = {
          cashAmt: parseFloat(modalItems?.data?.totalAmount).toFixed(2)
        }
      }
      if(form.payment_id == 2) {
        payment_data = {
          cardAmt: parseFloat(modalItems?.data?.totalAmount).toFixed(2)
        }
      }
      if(form.payment_id == 3) {
        payment_data = {
          cashAmt: cashAmt, 
          cardAmt: cardAmt,
        }
      }
      if(form.payment_id == 4) {
        payment_data = {
          bankTrasnAmt: parseFloat(modalItems?.data?.totalAmount).toFixed(2)
        }
      }

      payment_data.timestatmp = new Date();
      const { data } = await ReportServices.ocMonthlyPayment(
        {
          month: monthYearData.monthYear, 
          mc_id: OCDetails.mc_id, 
          mc_name: OCDetails.mc_name, 
          payment_id: form.payment_id, 
          payment_data: payment_data
        }
      );
      if(data?.data) {
        getOCByMonth(parseInt(oc_id));
      }
    } catch (error) {
        console.log(error)
    }
  }

  const downloadMonthlyReport = async (monthYearData) => {
    try {
        const { data } = await ReportServices.generateOCInvoiceMonthlyReport({month: monthYearData.monthYear, mc_id: OCDetails.mc_id, mc_name: OCDetails.mc_name});
        if(data.data) {
            window.open(data.data, "_blank");
            toast.success(data.message);
            console.log(data.data);
        }
    } catch (error) {
        console.log(error)
    }
  }

  let formMonthlyPayment = {
    payment_id: '',
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
        enableReinitialize: true,
        initialValues: formMonthlyPayment,
        validationSchema: makeMonthlyPaymentSchema,
        onSubmit: (values, action) => {
          console.log(values);
          makePayment(modalItems.data, values)
        },
    });

  const renderPurchaseItemsModel = () => {
    const { state, data } = modalItems;
    return (
        <Modal
            title={`Payment Of ${data?.monthYear}`}
            open={state}
            size="max-w-3xl relative"
            setOpen={() => setModalItems((prev) => ({ ...prev, state: false }))}
        >
            <form
              onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
              }}
              className="space-y-8 divide-y divide-gray-200"
            >
              <div className="m-5">
                <h3>Select Payment Option</h3>
                <select
                  value={values.payment_id}
                  onChange={handleChange}
                  className="block w-full inline-flex m-5 justify-center max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                  name="payment_id"
                  id="payment_id"
                >
                  <option value='' >Select Payment Mode</option>
                  {paymentModeList.filter(v => v.pay_mode_id != 5).filter(v =>  v.pay_mode_id != 3).map((paymentMode, i) => (
                    <option
                      key={i}
                      value={paymentMode.pay_mode_id}
                      >
                      {paymentMode.pay_mode_name_en}
                    </option>
                  ))}
                </select>
                {errors.payment_id && touched.payment_id ? (
                    <p className="text-red-600 text-sm">{errors.payment_id}</p>
                ) : null}
              {values.payment_id == 3 && (
                <>
                  <input
                    value={cashAmt}
                    onChange={e => setCashAmt(e.target.value)}
                    id="cash"
                    name="cash"
                    type="number"
                    autoComplete="off"
                    placeholder="Please Enter Cash Amount"
                    className="block w-full inline-flex justify-center m-5 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                  />
                  <input
                    value={cardAmt}
                    onChange={e => setCardAmt(e.target.value)}
                    id="card"
                    name="card"
                    type="number"
                    autoComplete="off"
                    placeholder="Please Enter Card Amount"
                    className="block w-full inline-flex justify-center m-5 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                  />
                  </>
                )
              }
                <h3>Total Amount: <span className="text-gray-700">{parseFloat(data?.totalAmount).toFixed(2)}</span></h3>
                <button
                  disabled={values.payment_id == 3 && (parseFloat(cashAmt) + parseFloat(cardAmt)) != parseFloat(data?.totalAmount).toFixed(2)}
                  onClick={handleSubmit}
                  type="button"
                  className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                >
                  Pay
                </button>
              </div>
            </form>
        </Modal>
    );
};

  const openMakePaymentModal = (data) => {
    setModalItems({
      data: data,
      state: true
    })
  }


  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      {renderPurchaseItemsModel()}
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Month Wise List Of {OCDetails?.mc_name}</h1>
        <p className="mt-2 text-sm text-gray-700">
          A list of all the Invoices By Month.
        </p>
      </div>
      {isLoading ? (
        <FallingLinesLoader />
      ) : outsourceClinicResList.length > 0 ? (
        <Table
          zoom='zoom-075'
          columns={outclinic_invoice_monthwise_columns({ downloadMonthlyReport, openMakePaymentModal })}
          data={outsourceClinicResList}
        />
      ) : (
        <TableEmpty />
      )}
    </div>
  );
};

export default InvoiceByMonth;
