// Imports
import { useEffect, useState } from "react";
import CustomMMUITable from "../../components/common/CustomMUITable";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../redux/user/actions";
import CustomSwitch from "../common/CustomSwitch";
import CustomButton from "../common/CustomButton";
import CustomInput from "../common/CustomInput";
import CustomDialog from "../common/CustomDialog";
import Drawer from "@mui/material/Drawer";
import { toast } from "react-toast";

import MasterServices from "../../ApiServices/MasterServices";
import UserForm from "./UserForm";

const UserList = () => {
  // State Variables
  const [showStatusDialog, setShowStatusDialog] = useState(false);
  const [showUserFormDrawer, setShowUserFormDrawer] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  // Other variables
  const {
    user: { loading: userLoading, list: userList },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const header = [
    {
      field: "user_first_name",
      label: "Name",
      render(value, data) {
        return `${value} ${data.user_last_name}`;
      },
    },
    {
      field: "user_email",
      label: "Email",
    },
    {
      field: "user_mobile",
      label: "Mobile",
      render(value, data) {
        return `+${data.user_dial_code} ${value}`;
      },
    },
    {
      field: "user_is_active",
      label: "Status",
      render(value, data) {
        return (
          <CustomSwitch
            checked={value}
            onChange={() => toggleUserStatus(data)}
          />
        );
      },
    },
    {
      field: "actions",
      label: "Actions",
      render: (item, data) => {
        return <CustomButton onClick={() => alert(data)}>Edit</CustomButton>;
      },
    },
  ];

  // Functions/Methods
  const toggleUserStatus = (data) => {
    setSelectedUser(data);
    setShowStatusDialog(true);
  };

  const confirmStatusChange = () => {
    MasterServices.setMUserStatus({
      user_id: selectedUser.user_id,
      user_is_active: !selectedUser.user_is_active,
    })
      .then((response) => {
        if (response.data.status) {
          dispatch(getAllUsers());
          closeStatusConfirmDialog();
          return toast.success(response.data.message);
        }
        return toast.warning(response.data.message);
      })
      .catch((error) => {
        console.error(error);
        return toast.error(error.message || "Something went wrong");
      });
  };

  const closeStatusConfirmDialog = () => {
    setSelectedUser(null);
    setShowStatusDialog(false);
  };

  const closeDrawer = () => {
    setShowUserFormDrawer(false);
    setSelectedUser({});
  };

  // Lifecycle hooks
  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  return (
    <>
      <div className="flex flex-col sm:flex-row items-center justify-between my-5">
        <CustomInput placeholder="Search" />
        <CustomButton onClick={() => setShowUserFormDrawer(true)}>
          Add User
        </CustomButton>
      </div>
      <CustomMMUITable headers={header} data={userList} />

      {/* Status changing popup */}
      <CustomDialog
        open={showStatusDialog}
        title="Are you sure you want to change status?"
        okText="Yes"
        cancelText="Cancel"
        handleOK={confirmStatusChange}
        handleClose={closeStatusConfirmDialog}
      />

      {/* User Form Drawer */}
      <Drawer open={showUserFormDrawer} onClose={closeDrawer} anchor={"right"}>
        <UserForm />
      </Drawer>
    </>
  );
};

export default UserList;
