import Api from "./Api";

export default {
  getLabSampleExcel() {
    return Api().get("/api/excel/getLabSampleExcel", { responseType: "blob" });
  },

  getStateSampleExcel() {
    return Api().get("/api/excel/getStateSampleExcel", {
      responseType: "blob",
    });
  },

  getCitySampleExcel() {
    return Api().get("/api/excel/getCitySampleExcel", {
      responseType: "blob",
    });
  },

  getLabAnalyzerSampleExcel() {
    return Api().get("/api/excel/getLabAnalyzerSampleExcel", {
      responseType: "blob",
    });
  },

  getOrganismSampleExcel() {
    return Api().get("/api/excel/getOrganismSampleExcel", {
      responseType: "blob",
    });
  },

  getMethodologySampleExcel() {
    return Api().get("/api/excel/getMethodologySampleExcel", {
      responseType: "blob",
    });
  },

  getPriceListSampleExcel() {
    return Api().get("/api/excel/getPriceListSampleExcel", {
      responseType: "blob",
    });
  },

  downloadLabExcel() {
    return Api().get("/api/excel/downloadLabExcel", {
      responseType: "blob",
    });
  },
  downloadLabAnalyzerExcel() {
    return Api().get("/api/excel/downloadLabAnalyzerExcel", {
      responseType: "blob",
    });
  },
  downloadMethodologiesExcel() {
    return Api().get("/api/excel/downloadMethodologiesExcel", {
      responseType: "blob",
    });
  },

  downloadOrganismExcel() {
    return Api().get("/api/excel/downloadOrganismExcel", {
      responseType: "blob",
    });
  },
  downloadCityExcel() {
    return Api().get("/api/excel/downloadCityExcel", {
      responseType: "blob",
    });
  },

  downloadPriceListSampleExcel() {
    return Api().get("/api/excel/downloadPriceListSampleExcel", {
      responseType: "blob",
    });
  },
  downloadAntibioticsExcel() {
    return Api().get("/api/excel/downloadAntibioticsExcel", {
      responseType: "blob",
    });
  },



  importLabExcel(formData) {
    return Api().post("/api/excel/importLabExcel", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
