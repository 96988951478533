/* eslint-disable */
import { toast } from 'react-toast'
import {
  GET_ALL_HOSPITAL_BEGIN,
  GET_ALL_HOSPITAL_SUCCESS,
  GET_ALL_HOSPITAL_ERROR,
  ADD_HOSPITAL_BEGIN,
  ADD_HOSPITAL_SUCCESS,
  ADD_HOSPITAL_ERROR,
  GET_HOSPITAL_BEGIN,
  GET_HOSPITAL_SUCCESS,
  GET_HOSPITAL_ERROR,
  UPDATE_HOSPITAL_BEGIN,
  UPDATE_HOSPITAL_SUCCESS,
  UPDATE_HOSPITAL_ERROR,
  DELETE_HOSPITAL_BEGIN,
  DELETE_HOSPITAL_SUCCESS,
  DELETE_HOSPITAL_ERROR
} from './constants'
import MasterServices from '../../ApiServices/MasterServices'

export const getAllHospitals = () => async (dispatch) => {
  dispatch({
    type: GET_ALL_HOSPITAL_BEGIN
  })
  MasterServices.getHospitalList()
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        dispatch({
          type: GET_ALL_HOSPITAL_SUCCESS,
          data: data.data.length > 0 ? data.data : []
        })
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error;
      toast.error(msg)
      dispatch({
        type: GET_ALL_HOSPITAL_ERROR,
        error: msg
      })
    });
}

export const addHospital = (body) => async (dispatch) => {
  dispatch({
    type: ADD_HOSPITAL_BEGIN
  })
  MasterServices.saveHospital(body)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        dispatch({
          type: ADD_HOSPITAL_SUCCESS,
          data: data
        })
        toast.success("Hospital added successfully!")
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error;
      toast.error(msg)
      dispatch({
        type: ADD_HOSPITAL_ERROR,
        error: msg
      })
    });
}

export const updateHospital = (hos_id, body) => async (dispatch) => {
  dispatch({
    type: UPDATE_HOSPITAL_BEGIN
  })

  MasterServices.saveHospital(body)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        dispatch({
          type: UPDATE_HOSPITAL_SUCCESS,
          data: data
        })
        toast.success("Hospital updated successfully!")
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error;
      toast.error(msg)
      dispatch({
        type: UPDATE_HOSPITAL_ERROR,
        error: msg
      })
    });
}

export const getHospitalWithId = (hos_id) => async (dispatch) => {
  dispatch({
    type: GET_HOSPITAL_BEGIN
  })

  MasterServices.getSingleHospital(hos_id)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        dispatch({
          type: GET_HOSPITAL_SUCCESS,
          data: data.data ? data.data : null
        })
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error;
      toast.error(msg)
      dispatch({
        type: GET_HOSPITAL_ERROR,
        error: msg
      })
    });
}

export const deleteHospital = (hos_id, hos_is_active) => async (dispatch) => {
  dispatch({
    type: DELETE_HOSPITAL_BEGIN
  })
  let Newhos_is_active = false;
  Newhos_is_active = hos_is_active == true ? false : true;
  let text = "Activated";
  if (Newhos_is_active == true) {
    text = "Activated";
  } else {
    text = "In-Activated";
  }
  MasterServices.setHospitalStatus({
    hos_id,
    hos_is_active: Newhos_is_active
  })
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        toast.success("Hospital " + text + " Successfully!")
        dispatch({
          type: DELETE_HOSPITAL_SUCCESS,
          data: hos_id
        })
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error;
      toast.error(msg)
      dispatch({
        type: DELETE_HOSPITAL_ERROR,
        error: msg
      })
    });
}