/* eslint-disable */
import { toast } from 'react-toast'
import {
  GET_ALL_LOCATION_BEGIN,
  GET_ALL_LOCATION_SUCCESS,
  GET_ALL_LOCATION_ERROR,
  ADD_LOCATION_BEGIN,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_ERROR,
  GET_LOCATION_BEGIN,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_ERROR,
  UPDATE_LOCATION_BEGIN,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_ERROR,
  DELETE_LOCATION_BEGIN,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_ERROR
} from './constants'
import MasterServices from '../../ApiServices/MasterServices'

export const getAllLocations = (hos_id) => async (dispatch) => {
  dispatch({
    type: GET_ALL_LOCATION_BEGIN
  })
  MasterServices.getLocationList(hos_id)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {

        let updatedLocations = data?.data

        updatedLocations?.forEach((element) => {
          element.label = element.loc_name_en
          element.value = element.loc_id
        });

        dispatch({
          type: GET_ALL_LOCATION_SUCCESS,
          data: updatedLocations?.length > 0 ? updatedLocations : [],
        })
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error.response.data.message;
      toast.error(msg)
      dispatch({
        type: GET_ALL_LOCATION_ERROR,
        error: msg
      })
    });
}

export const addLocation = (body) => async (dispatch) => {
  dispatch({
    type: ADD_LOCATION_BEGIN
  })
  MasterServices.saveLocation(body)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        dispatch({
          type: ADD_LOCATION_SUCCESS,
          data: data
        })
        toast.success("Location added successfully!")
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error.response.data.message;
      toast.error(msg)
      dispatch({
        type: ADD_LOCATION_ERROR,
        error: msg
      })
    });
}

export const updateLocation = (loc_id, body) => async (dispatch) => {
  dispatch({
    type: UPDATE_LOCATION_BEGIN
  })

  MasterServices.saveLocation(body)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        dispatch({
          type: UPDATE_LOCATION_SUCCESS,
          data: data
        })
        toast.success("Location updated successfully!")
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error.response.data.message;
      toast.error(msg)
      dispatch({
        type: UPDATE_LOCATION_ERROR,
        error: msg
      })
    });
}

export const deleteLocation = (loc_id, loc_is_active) => async (dispatch) => {

  dispatch({
    type: DELETE_LOCATION_BEGIN
  })
  let Newloc_is_active = false;
  Newloc_is_active = loc_is_active == true ? false : true;
  let text = "Activated";
  if (Newloc_is_active == true) {
    text = "Activated";
  } else {
    text = "In-Activated";
  }
  MasterServices.setLocationStatus({
    loc_id,
    loc_is_active: Newloc_is_active,
  })
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        toast.success("Location " + text + " successfully!")
        dispatch({
          type: DELETE_LOCATION_SUCCESS,
          data: loc_id
        })
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error.response.data.message;
      toast.error(msg)
      dispatch({
        type: DELETE_LOCATION_ERROR,
        error: msg
      })
    });
}