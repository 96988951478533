import { combineReducers } from 'redux';
import authReducer from './auth/reducers';
import TestReducer from './test/reducers';
import SampleTypeReducer from './sampletype/reducers';
import PackageReducer from './package/reducers';
import PriceReducer from './price_list/reducers';
import HospitalReducer from './hospital/reducers';
import LocationReducer from './location/reducers';
import CategoryReducer from './category/reducers';
import GenderReducer from './gender/reducers';
import CountryReducer from './country/reducers';
import RoleReducer from './role/reducers';
import UserReducer from './user/reducers';
import PaymentModeReducer from './paymentMode/reducers';
import ClinicReducer from './clinic/reducers';
import QuestionnaireReducer from './questionnaire/reducers';
import NotificationReducer from './notification/reducers';
import LoginUserInfoReducer from './userInfo/reducers';
import patientUserListReducer from './patientUserList/reducers';
import spCheckboxesReducer from './spCheckBox/reducers';


const rootReducers = combineReducers({

    auth: authReducer,
    package: PackageReducer,
    price: PriceReducer,
    hospital: HospitalReducer,
    location: LocationReducer,
    category: CategoryReducer,
    role: RoleReducer,
    user: UserReducer,
    test: TestReducer,
    sampletype: SampleTypeReducer,
    gender: GenderReducer,
    country: CountryReducer,
    paymentMode: PaymentModeReducer,
    clinic: ClinicReducer,
    questionnaire: QuestionnaireReducer,
    notification: NotificationReducer,
    loginUserInfo: LoginUserInfoReducer,
    patientUsersList: patientUserListReducer,
    spCheckboxes: spCheckboxesReducer,
});

export default rootReducers;
