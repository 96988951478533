
import Api from './Api';

export default {

	async getAllORReportList(payload) {
		console.log("payload", payload)
		const response = await Api().post(`api/dashboard/reports/ocInvoices`, { oc_ids: payload?.ocs});
		return response;
	},

	async getOCByMonth(payload) {
		console.log("payload", payload)
		const response = await Api().post(`api/dashboard/reports/getOCByMonth`, { oc_id: payload});
		return response;
	},

	async generateOCInvoiceMonthlyReport(payload) {
		console.log("payload", payload)
		const response = await Api().post(`api/dashboard/reports/generateOCInvoiceMonthlyReport`, payload);
		return response;
	},

	async ocMonthlyPayment(payload) {
		console.log("payload", payload)
		const response = await Api().post(`api/dashboard/reservation/make-payment-monthly`, payload);
		return response;
	},


	async generateOSInvoiceReport(payload) {
		const response = await Api().post(`api/dashboard/reports/generateOSInvoice`,payload);
		return response;
	},

	async getAllAccumulatedReservationReport(payload) {
		const response = await Api().get(`api/dashboard/reports/accumulatedRes?${payload}`);
		return response;
	},

	async generateInvestigationReport(payload) {
		const response = await Api().post(`api/dashboard/reports/generateInvestigationReport`,payload);
		return response;
	},
	
	async getAllReservationMonthlyRevenue(payload) {
		const response = await Api().get(`api/dashboard/reports/monthlyRevenueReport/?${payload}`);
		return response;
	},

};