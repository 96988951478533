import React, { useState } from 'react';
import { PencilIcon, ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import './FileUploadColumn.css';
import axios from 'axios';
import { BASEURL } from "../../helper/constant.js";
import { toast } from 'react-toast'
import { singleUploadFilesWithUpdate } from '../../helper/multiUploadHelper';

//function FileUploadColumn(props) {
const FileUploadColumn = ({ id, originalCell, saveUpdateFunction }) => {
    const [selectedFile, setSelectedFile] = useState(null);
  
    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
      console.log("handleFileChange", {id});
    };
  
    const handleUpload = async () => {

      let { sample_id, reservation_id, test_id, ttp_id, test_result } = originalCell
      console.log("originalCell File Upload",originalCell);

      let fk_subtest_id = null;
      if (ttp_id) {
        fk_subtest_id = ttp_id
      }

      if (sample_id && test_id && reservation_id) {

          const data = {
              fk_sample_id: sample_id,
              fk_test_id: test_id,
              fk_reservation_id: reservation_id,
              ssp_id: test_result.length>0 ? test_result[0]?.ssp_id : null,
              fk_subtest_id,
              allData:originalCell
          };

          //let data = {id: id, row_data: originalCell};

          const response = await singleUploadFilesWithUpdate(data, selectedFile, 'results');
          console.log("response", response);
          if(response.status){
            toast.success(' Uploaded SuccessFully')
            saveUpdateFunction();
          }else{
            toast.error('Failed Upload')
          }

      }


    };
  
    return (
      <td>
        <label htmlFor={`file-upload-${id}`} className="file-upload-label">
          <span>Upload File</span>
        </label>
        <input
          id={`file-upload-${id}`}
          type="file"
          onChange={handleFileChange}
          className="file-upload-input"
        />
        <button onClick={handleUpload}>Upload</button>
      </td>
    );
  }
  
  export default FileUploadColumn;



  {/* <Tippy
              theme="light"
              placement="left"
              trigger="click"
              allowHTML={true}
              interactive={true}
              content={
                <EditResultTooptip
                  //fieldName="ssp_value"
                  fieldName={resultFieldName ? resultFieldName : 'ssp_value'}
                  originalCell={originalCell}
                  saveFunc={handleSaveResult}
                  updateFunc={handleUpdateResult}
                  //prevValue={result_value}
                  prevValue={
                    originalCell.subTestDetails
                      ? originalCell.subTestDetails?.test_result[0]?.ssp_value
                      : originalCell?.test_result[0]?.ssp_value
                        ? originalCell?.test_result[0]?.ssp_value
                        : null
                  }
                />

              }
            >
              <button>
                {originalCell.subTestDetails?.test_result?.length > 0 ? (
                  <div className="inline-flex p-2 text-xs underline underline-offset-2">
                    {result_value + ' '}
                    <span>
                      <PencilIcon className="w-4 h-4 ml-2" />
                    </span>
                  </div>
                ) : (
                  <div className="inline-flex p-2 text-xs underline underline-offset-2">
                    {originalCell.subTestDetails?.test_result && originalCell.subTestDetails?.test_result?.length > 0 ? result_value : "Add Result"}<span><PencilIcon className="w-4 h-4 ml-2" /></span>
                  </div>
                )}
              </button>
            </Tippy> */}