import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Table from "../../../../components/tables/table";
import TableEmpty from "../../../../components/tables/tableEmpty";
import { qc_test_columns } from "../../../../components/tables/tableheader";
import { toast } from "react-toast";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import MasterServices from "../../../../ApiServices/MasterServices";
import QcServices from "../../../../ApiServices/QcServices";
import { Formik } from "formik";
import * as Yup from "yup";
import Modal from "../../../../components/Modal";
import InputBox from "../../../../components/InputBox";
import Button from "../../../../components/Button";
import { MultiSelect } from "react-multi-select-component";
import DropDownSelector from "../../../../components/multiSelectDropdown";
import Multiselect from "multiselect-react-dropdown";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import queryString from 'query-string';
const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {},
};

function QCTestList() {
  const pages = [{ title: "Control List", href: "/qc/control-list" }];
  const { id: qcId } = useParams();
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [selected, setSelected] = useState([]);
  const [packageList, setPackageList] = useState(false);
  const [testList, setTestList] = useState([]);
  console.log("setTestList=>",testList)
  const [QctestListArr, setQcTestListArr] = useState([]);
  const [modal, setModal] = useState(initialModalState);
  const [QcData, setQcData] = useState({});
  const [QcanalyzerList, setQcAnalyzerList] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    qc_id: null,
    test_id: null,
  });

  const getAllQcAnalyser = (qcId) => {
    setLoading(true);
    MasterServices.getTestAnalyzerList(qcId)
      .then((response) => {
        const { data, status } = response;

        console.log("data here", response);
        if (status == 200) {
          let analyzerData = data.data;
          setQcAnalyzerList(data.data);
          console.log("analyzerData=>",analyzerData)
          getAllAnalyzerQcTest(analyzerData.length>0?analyzerData[0].fk_lab_analyzer_id:0);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };
  //Added By Priyanshu Singh
  const getAllAnalyzerQcTest = (fk_lab_analyzer_id) => {
    if(fk_lab_analyzer_id>0){
    let payload = {
      search:1,
      analyzer_id:fk_lab_analyzer_id,
    }
    QcServices.getAllAnalyzerTestList(queryString.stringify(payload))
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data.data && Array.isArray(data.data) && data.data.length > 0) {
            let qctestlistarr = transformData(QctestListArr)
            let newTests = data.data;

            newTests = removeDuplicateObjects(qctestlistarr, newTests)
            setTestList(newTests);
          }

        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
    }else{
      console.log("Lab Analyzer Data Not Found")
    }
  };

  //Delete QC Test
  const deleteQCTest = async (qc_id, test_id) => {
    try {
      let deleteQC = await MasterServices.deleteQcTest({ qc_id, test_id });
      if (!deleteQC.data.status) {
        toast.error("Fatal Error Please Contact Admin")
      } else {
        setQcTestListArr(prev => {
          let newQCTestList = prev.filter(v => v.test_id !== test_id)
          return newQCTestList
        })
        toast.success('QC test deleted successfully.')
      }

      // setContryList(contriesFromApi.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const onDeleteTest = (qc_id, test_id) => {
    deleteQCTest(qc_id, test_id)
    setConfirmationModal({ qc_id: null, test_id: null, status: false });
  };

  const onDeleteOpen = (qc_id, test_id) => {
    console.log(qc_id)
    setConfirmationModal({ qc_id, test_id, status: true });
  };



  const onSelect = (selectedList, selectedItem) => {
    setSelected(selectedList)
  }

  const onRemove = (selectedList, removedItem) => {
    setSelected(selectedList)
  }
  const getQcbyIDs = (qid) => {
    MasterServices.getQcById(qid)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          setQcData(response.data.data);
          console.log("data fetched");
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  const removeDuplicateObjects = (array1, array2, key) => {

    return array2.filter(item => {
      for (let i = 0; i < array1.length; i++) {
        console.log(array1[i].id == item.id)
        if (array1[i].id == item.id) {
          return false;
        }
      }
      return true;
    })
  }

  const getAllQcTest = () => {
    MasterServices.getTestList('active')
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data.data && Array.isArray(data.data) && data.data.length > 0) {
            let qctestlistarr = transformData(QctestListArr)
            let newTests = transformData(data.data)

            newTests = removeDuplicateObjects(qctestlistarr, newTests)
            // setTestList(newTests);
          }

        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };



  const getAllTestListById = (id) => {
    // setLoading(true);
    MasterServices.getQcById(id)
      .then((response) => {
        const { data, status } = response;
        console.log("data", response.data);
        if (status == 200) {
          setQcTestListArr(data.data.tests);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  // To Modify data for multi select
  function transformData(list) {
    return list.map((item) => ({
      name: item.test_name_en,
      id: item.test_id,
    }));
  }
  // Modifing data for multi select
  // const testListData = transformData(testList);

  // Remoding data for sending it to backend
  function transformDataForBackend(list) {
    return list.map((item) => ({ test_id: parseInt(item.id) }));
  }
  console.log(transformDataForBackend(selected));
  const navigate = useNavigate();
  useEffect(() => {
    onPackageList();
    getAllQcTest();
    getQcbyIDs(qcId);
    getAllTestListById(qcId);
    getAllQcAnalyser(qcId);
    document.title = "Labib | Control List";
  }, [modal.state]);
  

  const UpdateQCtest = (payload) => {
    // setLoading(true);
    MasterServices.updateQcTest(payload)
      .then(async (response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Test Updated");
          await getAllTestListById(qcId);
          setModal((prev) => ({ ...prev, state: false }));
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        getAllTestListById(qcId);
        toast.error(msg);
        setLoading(false);
      });
  };

  const onPackageList = async () => {
    MasterServices.getPackageList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            // toast.success("Package Found");
            setPackageList(data.data);
          } else {
            toast.error("Package Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  const renderModal = () => {
    const { type, state, data, edit_id } = modal;
    return (
      <Formik
        initialValues={data}
        enableReinitialize={true}
        onSubmit={async (values, action) => {
          setModal((prev) => ({ ...prev, state: false }))
          const body = {
            ...values,
            fk_test_ids: transformDataForBackend([...selected, ...transformData(QctestListArr)]),
            qc_id: qcId,
            qc_name_en: QcData.qc.qc_name_en,
            qc_name_ar: QcData.qc.qc_name_ar,
            qc_stability: QcData.qc.qc_stability,
          };
          delete body.selected;
          // console.log("sending data");
          await UpdateQCtest(body);
          action.setSubmitting(false);
          setSelected([]);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Modal
            title="Add Test"
            open={state}
            setOpen={() => {
              setModal((prev) => ({ ...prev, state: false }));
              setSelected([]);
            }}
          >
            <form onSubmit={handleSubmit} className="">
              <div className="text-left   mt-4 z-[999] h-44">
                <Multiselect
                  dataKey="id"
                  selectedValues={selected}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  displayValue="name"
                  options={testList}
                />
                {submitted === true && selected.length < 1 && (
                  <p className="text-red-600 text-sm">Please select the test</p>
                )}
              </div>
              <div className="mt-8"></div>

              <div className="h-40 flex items-end">
                <Button
                  onClick={() => {
                    setSubmitted(true);
                  }}
                  disabled={Formik.isSubmitting}
                  type="submit"
                >
                  {Formik.isSubmitting ? "Saving..." : "Submit"}
                </Button>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteTest(confirmationModal.qc_id, confirmationModal.test_id)}
      />
      <div className="mt-4 flex">
        <button
          type="button"
          onClick={() => setModal((prev) => ({ ...prev, state: true }))}
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </button>
      </div>
      {renderModal()}
      {loading ? (
        <FallingLinesLoader />
      ) : packageList.length > 0 ? (
        <Table columns={qc_test_columns({ onDeleteOpen, qcId })} data={QctestListArr} />
      ) : (
        <TableEmpty />
      )}
    </div>
  );
}
export default QCTestList;
