import {
  GET_ALL_CATEGORY_BEGIN,
  GET_ALL_CATEGORY_SUCCESS,
  GET_ALL_CATEGORY_ERROR,
  ADD_CATEGORY_BEGIN,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_ERROR,
  GET_CATEGORY_BEGIN,
  GET_CATEGORY_ERROR,
  GET_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_BEGIN,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERROR,
  DELETE_CATEGORY_BEGIN,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERROR,
} from "./constants";

const initState = {
  list: [],
  loading: false,
  error: null,
};

const CategoryReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case GET_ALL_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_CATEGORY_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_ALL_CATEGORY_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case ADD_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, data],
      };
    case ADD_CATEGORY_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case GET_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
        categoryData: "",
      };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryData: data?data:[],
      };
    case GET_CATEGORY_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case UPDATE_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((ele) => {
          if (ele.category_id === data.category_id) {
            return { ...data };
          } else return ele;
        }),
      };
    case UPDATE_CATEGORY_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case DELETE_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((ele) => ele.category_id !== data),
      };
    case DELETE_CATEGORY_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    default:
      return state;
  }
};

export default CategoryReducer;
