import React from 'react'
import { useState, useEffect } from 'react';
import OTPInput, { ResendOTP } from "otp-input-react";
import { VerifiedOutlined } from '@mui/icons-material';

const successColor = '#00CB51';
const failColor = '#ff4444';

const inputStyle = {
  width: '40px', 
  height: '40px',
  borderRadius: '0.375rem',
}

const inputStyleFail = {
  width: '40px', 
  height: '40px',
  borderRadius: '0.375rem',
  border: `2px solid ${failColor}`,
}
const inputStyleSuccess = {
  width: '40px', 
  height: '40px',
  borderRadius: '0.375rem',
  border: `2px solid ${successColor}`,
  color: 'gray'
}

const resendButtonStyleTimerOn = {
  color: 'gray',
}

const resendButtonStyleTimerOff = {
  textDecoration: 'underline'
}


const OTPComponent = ({sendOTP, verifyOTP}) => {
  const [OTP, setOTP] = useState("");
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [otpFail, setOtpFail] = useState(false);
  const [isInputDisable, setIsInputDisable] = useState(false);

  const onChangeOTP = async (value) => {
    setOTP(value);
    setOtpSuccess(false);
    setOtpFail(false)
    if(value?.length === 4) {
      const isOtpVerified = await verifyOTP(value);
      console.log(isOtpVerified);
      if(isOtpVerified) {
        setOtpSuccess(true);
        setIsInputDisable(true);
      } else {
        setOtpFail(true)
      }

      console.log(OTP)
    }
  }

  const renderButton = (buttonProps) => {
    return <button style={buttonProps.remainingTime !== 0 ? resendButtonStyleTimerOn : resendButtonStyleTimerOff} {...buttonProps}>Resend</button>;
  };
  const renderTime = (remainingTime) => {
    return <span>{remainingTime} seconds remaining</span>;
  };

  return (
    <>
      <OTPInput value={OTP} inputStyles={otpSuccess ? inputStyleSuccess : otpFail ? inputStyleFail : inputStyle} onChange={onChangeOTP} autoFocus OTPLength={4} otpType="number" disabled={isInputDisable} />
      {!otpSuccess ?
        <ResendOTP renderButton={renderButton} renderTime={renderTime} onResendClick={() => sendOTP()} />
      :
        <p style={{color: successColor, marginTop: '10px'}}>OTP verified <VerifiedOutlined/></p>
      }
    </>
  )
}

export default OTPComponent;