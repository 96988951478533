import React, { useEffect, useState } from "react";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Table from "../../../../components/tables/table";
import { ot_reservation_report_columns } from "../../../../components/tables/tableheader";
import Breadcrumb from "../../../../components/Breadcrumb";
import ReservationServices from "../../../../ApiServices/ReservationServices";
import queryString from "query-string";
import moment from "moment";
import { utils, writeFile } from "xlsx";
import { toast } from "react-toast";
import { capitalise } from "../../../../utilities/utilities";
import { convertToAnotherTimezone } from "../../../../helper/commonHelper";

const initialFilters = {
  start_date: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
  end_date: moment(new Date()).format("YYYY-MM-DD"),
  users: []
}
const InsuOTReservationReport = () => {
  const pages = [
    { title: "Report List", href: "/reservationlist", module_id: 8 },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [resOTReservationReportFilters, setresOTReservationReportFilters] = useState(initialFilters)
  const [resOTReservationReportData, setresOTReservationReportData] = useState([])

  const printRef = React.useRef();

  useEffect(() => {
    document.title = "Labib | OT Resrvation Report List";
    getOTReservationReport()
  }, []);

  async function getOTReservationReport() {
    setIsLoading(true)
    try {
      let payload = {
        start_date: resOTReservationReportFilters.start_date || '1949-05-26',
        end_date: resOTReservationReportFilters.end_date || (moment().add(1, 'days').format('YYYY-MM-DD')),
        mc_ids: []
        // mc_ids: [30]
      }
      const { data, status } = await ReservationServices.getOutSourceReservationFilteredList(payload);
      if (status === 200) {
        setresOTReservationReportData(data?.data)
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const handleExport = (excel_type) => {
    if (resOTReservationReportData?.length > 0) {
      let newSheetdata = [];
      resOTReservationReportData.map((obj, index) => {
        const newData = {
          SN: parseInt(index)+1,
          reservation_id: obj.reservation_id,
          rev_patient_parent_fname: obj.rev_patient_parent_fname,
          rev_patient_parent_phone: obj.rev_patient_parent_phone,
          loc_name_en: obj.collection_loc_relation?.loc_name_en,
          mc_name: obj.m_outsourcing_clinic_relation?.mc_name,
          Date: moment(convertToAnotherTimezone(obj.reservation_created_at)).format('DD-MM-YYYY'),
          test_count: obj?.t_res_patient_test_pack_map?.length,
          rev_amount: obj?.rev_amount,
          rev_payable_amount: obj?.rev_payable_amount,
          bupa_libility: (parseFloat(obj?.rev_amount) - parseFloat(obj?.rev_payable_amount)),
          rev_vat_amount: obj?.rev_vat_amount,
          final_amt: ((parseFloat(obj?.rev_amount) - parseFloat(obj?.rev_payable_amount)) + parseFloat(obj?.rev_vat_amount)),
        
          payment_status: obj.is_payment_completed ? "Completed" : "Pending",
          payment_type: obj.getAllReservationPayment?.pay_mode_name_en ? obj.getAllReservationPayment?.pay_mode_name_en : "-",
          pay_amt: capitalise(parseFloat(obj?.rev_payable_amount).toFixed(2)),
          received_by: capitalise(obj?.pay_collected_relation?.user_first_name),
          status: obj.reservation_is_active == true ? 'Active' : 'Cancelled'
        }
        newSheetdata.push(newData);
      })
      let fileName = 'OT_Reservation_Report_From_' + moment(resOTReservationReportFilters.start_date).format("DD-MM-YYYY") + "_To_" + moment(resOTReservationReportFilters.end_date).format("DD-MM-YYYY") + '.' + excel_type;

      const headings = [
        [
          'SN',
          'Reservation Id',
          'User',
          'Mobile No.',
          'Branch Name',
          'Clinic Name',
          'Reservation Date',
          'Test Count',
          'Reservation Amount',
          'Deductible Collected',
          'BUPA Liability',
          'VAT',
          'Total Liability',
          'Payment Status',
          'Payment Type',
          'Payable Amount',
          'Received By',
          'Status',
        ]];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  }

  return (
    <>
      <div className="px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
        <div className="sales-booking-head">
          <Breadcrumb pages={pages} />
          <h1 className="text-xl font-semibold text-gray-900">
            OT Reservations Report
          </h1>
        </div>
      </div>

      <div className="px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
        <div className="mt-5 flex gap-3 items-center">
          {/* start date */}
          <div className=" sm:mt-0">
            <label
              htmlFor="fk_location_ids"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Date From
            </label>

            <input
              disabled={isLoading}
              className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
              type="date"
              name="start_date"
              id="start_date"
              value={resOTReservationReportFilters.start_date}
              onChange={(e) => {
                setresOTReservationReportFilters((prev) => {
                  if (prev.end_date < e.target.value) {
                    return {
                      ...prev,
                      end_date: e.target.value,
                      start_date: prev.end_date,
                    };
                  }
                  return {
                    ...prev,
                    start_date: e.target.value,
                  };
                });
              }}
            />
          </div>

          {/* end date */}
          <div className=" sm:mt-0">
            <label
              htmlFor="fk_location_ids"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Date To
            </label>

            <input
              disabled={isLoading}
              className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
              type="date"
              name="end_date"
              id="end_date"
              value={resOTReservationReportFilters.end_date}
              onChange={(e) => {
                setresOTReservationReportFilters((prev) => {
                  if (prev.start_date > e.target.value) {
                    return {
                      ...prev,
                      end_date: prev.start_date,
                      start_date: e.target.value,
                    };
                  }
                  return {
                    ...prev,
                    end_date: e.target.value,
                  };
                });
              }}
            />
          </div>


          <button
            disabled={isLoading}
            onClick={() => {
              getOTReservationReport();
            }}
            className={`ml-3 h-10 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 mt-7 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${isLoading ? "opacity-40" : "opacity-100"
              }`}
          >
            Get Data
          </button>
        </div>

        <div className="mt-5 flex gap-1 items-center">
          <button
            onClick={() => handleExport("csv")}
            className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
          >
            CSV
          </button>

          <button
            onClick={() => handleExport("xlsx")}
            className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
          >
            Excel
          </button>

        </div>
      </div>

      <div className="px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none">
        <div className="flex flex-col">
          <div>

            {isLoading ? (
              <FallingLinesLoader />
            ) : (
              <Table
                zoom='zoom-09'
                printRef={printRef}
                columns={ot_reservation_report_columns()}
                data={resOTReservationReportData}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InsuOTReservationReport;
