import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { packageSchema } from "../../../schemas";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { Switch } from "@headlessui/react";
import { getAllHospitals } from "../../../redux/hospital/actions";
import { getAllCategorys } from "../../../redux/category/actions";
import { getAllTests } from "../../../redux/test/actions";
import { toast } from 'react-toast'
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import Breadcrumb from "../../../components/Breadcrumb";
import { EditorState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MasterServices from '../../../ApiServices/MasterServices'
import Select from 'react-select'
import { singleUploadFiles } from "../../../helper/multiUploadHelper";
import moment from "moment";

function className(...location) {
  return location.filter(Boolean).join(' ')
}

const AddPackage = () => {
  const pages = [
    { title: "Packages", href: "/package", module_id: 6 },
  ];
  const [selectMutiTest, setMutiTest] = useState(false);
  const [multiTestId, setMultiTestId] = useState();

  const [enProgress, setenProgress] = useState();
  const [imgEnfileName, setEnFile] = useState(false);
  const [imgEnfileNamePath, setEnFilePath] = useState('');
  const [arProgress, setarProgress] = useState();
  const [imgArfileName, setArFile] = useState(false);
  const [imgArfileNamePath, setArFilePath] = useState('');


  const handleEnFileChange = async (e) => {
    const response = await singleUploadFiles(e.target.files[0], 'packages');
    setEnFilePath(response.data)
    console.log("response=>", response)
    setenProgress(response.progress);
    // setEnFile(e.target.files[0]);
  };
  const handleArFileChange = async (e) => {
    const response = await singleUploadFiles(e.target.files[0], 'packages');
    setArFilePath(response.data)
    setarProgress(response.progress);
    // setArFile(e.target.files[0]);
  };

  let initialValues = {
    package_id: "",
    package_name_ar: "",
    package_name_en: "",
    package_img_en: "",
    package_img_ar: "",

    package_price: "",
    package_long_description_en: "",
    package_long_description_ar: "",

    package_short_description_en: "",
    package_short_description_ar: "",
    package_sub_category: "",
    fk_test_ids: "",

    from_availability_date: "",
    to_availability_date: "",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(false)
  const { package_id: package_id } = useParams();
  const {
    loading,
    hospital: { list: hospitalList },
    category: { list: categoryList },
    test: { list: testList }
  } = useSelector((state) => state);

  useEffect(() => {
    document.title = "Labib | Packages";
    dispatch(getAllHospitals());
    dispatch(getAllCategorys());
    dispatch(getAllTests());
  }, []);

  useEffect(() => {
    let testnew = testList;
    let tests = [];
    testnew.forEach((element) => {
      let obj = {
        label: element.test_name_en,
        value: element.test_id,
        test_min_price: element.test_min_price,
        hos_price: element.hos_price,
        test_pack_id: 0,
      }

      tests.push(obj);
    })
    // testnew.map((element) => {
    //   element.label = element.test_name_en
    //   element.value = element.test_id
    //   element.test_min_price = element.test_min_price
    //   element.hos_price = element.hos_price
    //   console.log("tests element : ", element);
    // });

    setMutiTest(tests);
  }, [testList]);
  const [smartReportDescriptionEn, setSmartReportDescriptionEn] = useState(
    () => EditorState.createEmpty(),
  );

  const [formState, setFormState] = useState(initialValues);
  const [packageData, setpackageData] = useState(false);

  useEffect(() => {
    if (package_id) {
      MasterServices.getSinglePackage(package_id)
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            // console.log("data.data=>",data.data.packagedata)
            setpackageData(data.data.packagedata);
          } else {
            toast.error("Fatal Error Please Contact Admin")
          }
        })
        .catch((error) => {
          const msg = error.response.data.message;
          toast.error(msg)
        });
    }
  }, [package_id]);

  const multiTest = (event) => {
    initialValues.fk_test_ids = JSON.stringify(event);

    setMultiTestId(event)
    console.log(initialValues.fk_test_ids);
  }

  useEffect(() => {
    console.log("Happy=>", packageData)
    document.title = "Labib | Add-Package";
    if (package_id && packageData) {
      setMultiTestId()
      const newFormState = {
        package_name_ar: packageData.package_name_ar,
        package_name_en: packageData.package_name_en,
        package_img_en: packageData.package_img_en,
        package_img_ar: packageData.package_img_ar,

        package_price: packageData.package_price,
        package_long_description_en: packageData.package_long_description_en,
        package_long_description_ar: packageData.package_long_description_ar,
        package_short_description_en: packageData.package_short_description_en,
        package_short_description_ar: packageData.package_short_description_ar,
        package_sub_category: packageData.package_sub_category,
        fk_test_ids: packageData.fk_test_ids,

        // from_availability_date: packageData.from_availability_date,
        // to_availability_date: packageData.to_availability_date,
        from_availability_date: (packageData?.from_availability_date) ? new Date(packageData?.from_availability_date).toISOString().split('T')[0] : null,
        to_availability_date: (packageData?.to_availability_date) ? new Date(packageData?.to_availability_date).toISOString().split('T')[0] : null,
      };
      // setFormState(newFormState);
      setMultiTestId(packageData.fk_test_ids);

      setEnFilePath(packageData.package_img_en ? packageData.package_img_en : '');
      setArFilePath(packageData.package_img_ar ? packageData.package_img_ar : '');
      // setMultiTestId(JSON.parse(packageData.fk_test_ids))
      setFormState(newFormState);
      console.log("newFormState=>", newFormState)
    }
  }, [package_id, packageData]);

  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      // enableReinitializing,
      onSubmit: async (values, action) => {
        const body = { ...values };
        body.fk_test_ids = multiTestId;
        // body.package_long_description_en=smartReportDescriptionEn;
        let fileResEn
        let fileResAr
        if (imgEnfileName) {
          fileResEn = await singleUploadFiles(imgEnfileName, 'packages');
        }
        if (imgArfileName) {
          fileResAr = await singleUploadFiles(imgArfileName, 'packages');
        }
        body.package_img_en = fileResEn ? fileResEn : imgEnfileNamePath;
        body.package_img_ar = fileResAr ? fileResAr : imgArfileNamePath;
        console.log("body=>", body)
        if (body.fk_test_ids?.length == 0) {
          toast.error("Please select at least 1 Test")
        }
        if (package_id > 0) {
          body.package_id = package_id;
        }
        MasterServices.savePackage(body)
          .then((response) => {
            const { data, status } = response;
            if (status == 200 && data?.status) {
              if (package_id > 0) {
                toast.success("Package Updated Successfully");
              } else {
                toast.success("Package Inserted Successfully");
              }
              navigate("/package");
            } else {
              toast.error(data?.message)
            }
          })
          .catch((error) => {
            const msg = error;
            toast.error(msg)
          });
        action.resetForm();
        navigate("/package");
      },

      initialValues: formState,
      validationSchema: packageSchema,
    });

  return (
    <div>
      <Breadcrumb pages={pages} />
      <button
        type="button"
        onClick={() => navigate(-1)}
        className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
      >
        <span className="w-5 mr-2"><ArrowUturnLeftIcon size={18} /></span>   Back
      </button>
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-lg font-medium leading-6 text-gray-900">
              {package_id ? "Update Package" : "Add Package"}
            </h1>
          </div>
        </div>
        {loading ? (
          <FallingLinesLoader />
        ) : (
          // onSubmit={handleSubmit}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
            className="divide-gray-200"
          >
            <div className="flex flex-row mt-5 gap-5">

              <div className="basis-3/4">
                <label htmlFor="package_name_en" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Package Name(EN)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Enter Package Name(EN)"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="package_name_en"
                    id="package_name_en"
                    value={values.package_name_en}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.package_name_en && touched.package_name_en ? (
                    <p className="text-red-600 text-sm">{errors.package_name_en}</p>
                  ) : null}
                </div>
              </div>

              <div className="basis-3/4">
                <label htmlFor="package_name_ar" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Package Name(AR)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Enter Package Name(AR)"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="package_name_ar"
                    id="package_name_ar"
                    value={values.package_name_ar}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.package_name_ar && touched.package_name_ar ? (
                    <p className="text-red-600 text-sm">{errors.package_name_ar}</p>
                  ) : null}
                </div>
              </div>

              <div className="basis-3/4">
                <label htmlFor="fk_test_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Tests</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Select
                    // defaultValue={testList[0]}
                    isMulti
                    name="fk_test_ids"
                    id="fk_test_ids"
                    value={multiTestId}
                    onChange={(e) => multiTest(e)}
                    onBlur={handleBlur}
                    options={selectMutiTest}
                    className="basic-multi-select"
                    classNamePrefix="Select Test"
                  />
                  {errors.fk_test_ids && touched.fk_test_ids ? (
                    <p className="text-red-600 text-sm">{errors.fk_test_ids}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex flex-row mt-2 gap-5">

              {/* Avalibility Start To Date */}
              <div className="basis-3/4">
                <label htmlFor="from_availability_date" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">From Availability</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Enter Package From Availability"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="date"
                    name="from_availability_date"
                    id="from_availability_date"
                    value={values.from_availability_date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.from_availability_date && touched.from_availability_date ? (
                    <p className="text-red-600 text-sm">{errors.from_availability_date}</p>
                  ) : null}
                </div>
              </div>
              <div className="basis-3/4">
                <label htmlFor="to_availability_date" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">To Availability</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Enter To Availability"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="date"
                    name="to_availability_date"
                    id="to_availability_date"
                    value={values.to_availability_date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.to_availability_date && touched.to_availability_date ? (
                    <p className="text-red-600 text-sm">{errors.to_availability_date}</p>
                  ) : null}
                </div>
              </div>

              {/* Avalibility Start To Date */}

              <div className="basis-3/4">
                <label htmlFor="package_sub_category" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Sub-Category</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Enter Sub-Category"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="package_sub_category"
                    id="package_sub_category"
                    value={values.package_sub_category}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.package_sub_category && touched.package_sub_category ? (
                    <p className="text-red-600 text-sm">{errors.package_sub_category}</p>
                  ) : null}
                </div>
              </div>


            </div>
            <div className="flex flex-row mt-2 gap-5">
              <div className="basis-3/4">
                <label htmlFor="package_price" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Package Price</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Enter Package Price"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="number"
                    name="package_price"
                    id="package_price"
                    value={values.package_price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.package_price && touched.package_price ? (
                    <p className="text-red-600 text-sm">{errors.package_price}</p>
                  ) : null}
                </div>
              </div>



              <div className="basis-3/4">
                <label htmlFor="package_long_description_en" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Smart Report Description(EN)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <textarea
                    placeholder="Enter Smart Report Description(EN)"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="package_long_description_en"
                    id="package_long_description_en"
                    value={values.package_long_description_en}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.package_long_description_en && touched.package_long_description_en ? (
                    <p className="text-red-600 text-sm">{errors.package_long_description_en}</p>
                  ) : null}
                </div>
              </div>
              <div className="basis-3/4">
                <label htmlFor="package_long_description_ar" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Smart Report Description(AR)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <textarea
                    placeholder="Enter Smart Report Description(AR)"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="package_long_description_ar"
                    id="package_long_description_ar"
                    value={values.package_long_description_ar}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.package_long_description_ar && touched.package_long_description_ar ? (
                    <p className="text-red-600 text-sm">{errors.package_long_description_ar}</p>
                  ) : null}
                </div>
              </div>

            </div>

            <div className="flex flex-row mt-2 gap-5">

              <div className="basis-3/4">
                <label htmlFor="package_short_description_en" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Short Description(EN)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <textarea
                    placeholder="Enter Package Short Description(EN)"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="package_short_description_en"
                    id="package_short_description_en"
                    value={values.package_short_description_en}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.package_short_description_en && touched.package_short_description_en ? (
                    <p className="text-red-600 text-sm">{errors.package_short_description_en}</p>
                  ) : null}
                </div>
              </div>

              <div className="basis-3/4">
                <label htmlFor="package_short_description_ar" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Short Description(AR)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <textarea
                    placeholder="Enter Package Short Description(AR)"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="package_short_description_ar"
                    id="package_short_description_ar"
                    value={values.package_short_description_ar}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.package_short_description_ar && touched.package_short_description_ar ? (
                    <p className="text-red-600 text-sm">{errors.package_short_description_ar}</p>
                  ) : null}
                </div>
              </div>
              <div className="basis-3/4">
              </div>
            </div>
            <div className="flex flex-row mt-2 gap-5">
              <div className="basis-3/4">
                <div className="sm:col-span-6">
                  <label htmlFor="img_ar" className="block text-sm font-medium leading-6 text-gray-900">
                    Image AR
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="img_ar"
                            className="relative cursor-pointer rounded-md bg-white font-medium text-cyan-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-cyan-500 focus-within:ring-offset-2 hover:text-cyan-500"
                          >
                            <span>Upload a file</span>
                            <input id="img_ar" name="img_ar" type="file" accept="image/*" onChange={handleArFileChange} className="sr-only" />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                      </div>
                    </div>
                  </div>
                </div>
                {imgArfileNamePath ? (
                  <img width={40} height={20} src={imgArfileNamePath} alt={values.package_name_ar} />) : (<></>)}
                {arProgress ? (<><div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2">
                  <div className="bg-cyan-600 text-xs font-medium text-cyan-100 text-center p-0.5 leading-none rounded-full mt-1"> {`${arProgress}%`}</div>
                </div></>) : (<></>)}
              </div>
              <div className="basis-3/4">
                <div className="sm:col-span-6">
                  <label htmlFor="img_en" className="block text-sm font-medium leading-6 text-gray-900">
                    Image EN
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="img_en"
                            className="relative cursor-pointer rounded-md bg-white font-medium text-cyan-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-cyan-500 focus-within:ring-offset-2 hover:text-cyan-500"
                          >
                            <span>Upload a file</span>
                            <input id="img_en" name="img_en" type="file" accept="image/*" onChange={handleEnFileChange} className="sr-only" />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                      </div>
                    </div>
                  </div>
                </div>
                {imgEnfileNamePath ? (
                  <img width={40} height={20} src={imgEnfileNamePath} alt={values.package_name_en} />) : (<></>)}

                {enProgress ? (<><div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2">
                  <div className="bg-cyan-600 text-xs font-medium text-cyan-100 text-center p-0.5 leading-none rounded-full mt-1"> {`${enProgress}%`}</div>
                </div></>) : (<></>)}
              </div>
              <div className="basis-3/4">
              </div>
            </div>
            {/* <div className="flex flex-row mt-2 gap-5">
              <div className="basis-3/4">
                <label htmlFor="hos_long" className="block text-sm font-medium text-gray-700">Img AR : </label>
                <div className="mt-1 ">
                  <input type="file" accept="image/*" onChange={handleArFileChange} />
                  {imgArfileNamePath ? (
                    <img width={40} height={20} src={imgArfileNamePath} alt={values.package_name_ar} />) : (<></>)}
                  {arProgress ? (<><div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2">
                    <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full mt-1"> {`${arProgress}%`}</div>
                  </div></>) : (<></>)}
                </div>
              </div>
              <div className="basis-3/4">
                <label htmlFor="hos_long" className="block text-sm font-medium text-gray-700">Img EN : </label>
                <div className="mt-1 ">
                  <input type="file" accept="image/*" onChange={handleEnFileChange} />
                  {imgEnfileNamePath ? (
                    <img width={40} height={20} src={imgEnfileNamePath} alt={values.package_name_en} />) : (<></>)}

                  {enProgress ? (<><div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2">
                    <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full mt-1"> {`${enProgress}%`}</div>
                  </div></>) : (<></>)}
                </div>
              </div>
            </div> */}
            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div >
  );
};

export default AddPackage;
