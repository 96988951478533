import {
    GET_ALL_QUESTIONNAIRE_BEGIN,
    GET_ALL_QUESTIONNAIRE_SUCCESS,
    GET_ALL_QUESTIONNAIRE_ERROR,
    GET_QUESTIONNAIRE_BEGIN,
    GET_QUESTIONNAIRE_SUCCESS,
    GET_QUESTIONNAIRE_ERROR,
    ADD_QUESTIONNAIRE_BEGIN,
    ADD_QUESTIONNAIRE_SUCCESS,
    ADD_QUESTIONNAIRE_ERROR,
  } from "./constants";
  
  const initState = {
    list: [],
    loading: false,
    error: null,
  };
  
  const QuestionnaireReducer = (state = initState, action) => {
    const { type, data, error } = action;
    switch (type) {
      case GET_QUESTIONNAIRE_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case GET_QUESTIONNAIRE_SUCCESS:
        return {
          ...state,
          list: data,
          loading: false,
        };
      case GET_QUESTIONNAIRE_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case GET_ALL_QUESTIONNAIRE_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case GET_ALL_QUESTIONNAIRE_SUCCESS:
        return {
          ...state,
          list: data,
          loading: false,
        };
      case GET_ALL_QUESTIONNAIRE_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case ADD_QUESTIONNAIRE_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case ADD_QUESTIONNAIRE_SUCCESS:
        return {
          ...state,
          loading: false,
          list: [],
        };
      case ADD_QUESTIONNAIRE_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default QuestionnaireReducer;
  