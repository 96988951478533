import React from "react";
import PropTypes from "prop-types";
import { Switch } from "@headlessui/react";

const CustomSwitch = ({ checked, onChange, label, ...props }) => {
  return (
    <Switch.Group>
      <div className="flex items-center">
        {label && <Switch.Label className="mr-4">{label}</Switch.Label>}
        <Switch
          checked={checked}
          onChange={onChange}
          className={`${
            checked ? "bg-[#0891b2]" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full`}
          {...props}
        >
          <span
            className={`${
              checked ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
};

CustomSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default CustomSwitch;
