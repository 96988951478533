import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import Breadcrumb from "../../../components/Breadcrumb";
import { toast } from "react-toast";
import OrganismAntiServices from "../../../ApiServices/OrganismAntiServices";
import SampleMicroServices from "../../../ApiServices/SampleMicroServices";
import queryString from "query-string";

import { FallingLinesLoader } from "../../../components/spinners/Spinner";

let filterInitialValues = {
  filterFrom: "",
  filterTo: "",
  filterStatus: "",
  filterHos: "",
  filterBkType: "",
  filterOrg: "",
  filterSampleId: "",
  filterPatientMob: "",
  filterPatientIdentification: "",
  filterPatientName: "",
  filterPatientPassport: "",
  filterTestType: "",
  filterPatientId: "",
  filterReservationId: "",
  filterMrn: "",
};

const bookingType = [
  { name: "Normal", id: "1" },
  { name: "OPD", id: "2" },
  { name: "Emergancy", id: "3" },
  { name: "ICU", id: "4" },
];
const CRMMicrobiologyTab = () => {
  const [query, setQuery] = useState("");
  const [selectedOrganism, setSelectedOrganism] = useState([]);
  const [selectedAntibiotic, setSelectedAntibiotic] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const [organismModal, setOrganismModal] = useState(false);
  const [antibioticModal, setantibioticModal] = useState(false);

  const pageSize = 50;
  const {
    hospital: { list: hospitalList },
  } = useSelector((state) => state);
  const pages = [
    {
      title: "Sample Processing",
      href: `/sample-processing-tab#microbiology`,
      module_id: 12,
    },
  ];
  const [organismList, setOrganismList] = useState(false);
  const [antibioticList, setAntibioticList] = useState(false);
  const [antibioticResultTypeList, setAntibioticResultTypeList] =
    useState(false);
  const [selectedOrganismIndex, setSelectedOrganismIndex] = useState(null);
  const [selectedAntIndex, setSelectedAntIndex] = useState(null);
  const [items, setItems] = useState(false);

  const [filterFlag, setFilterFlag] = useState(false);
  const [filterFrom, setFilterFrom] = useState("");
  const [filterTo, setFilterTo] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterHos, setFilterHos] = useState("");
  const [filterBkType, setFilterBkType] = useState("");
  const [filterOrg, setFilterOrg] = useState("");
  const [filterSampleId, setFilterSampleId] = useState("");
  const [filterPatientMob, setFilterPatientMob] = useState("");
  const [filterPatientIdentification, setFilterPatientIdentification] =
    useState("");
  const [filterPatientName, setFilterPatientName] = useState("");
  const [filterPatientPassport, setFilterPatientPassport] = useState("");
  const [filterTestType, setFilterTestType] = useState("");
  const [filterPatientId, setFilterPatientId] = useState("");
  const [filterReservationId, setFilterReservationId] = useState("");
  const [filterMrn, setFilterMrn] = useState("");
  const [pageArr, setPageArr] = useState("");
  const [currentPage, setCurrentPage] = useState("");
  const [loading, setLoading] = useState(true);
  const [sampleList, setSampleList] = useState(false);
  const [maxVisiblePages, setMaxVisiblePages] = useState(1);

  useEffect(() => {
    console.log("selectedOrganism", selectedOrganism);
  }, [selectedOrganism]);

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const filteredOrganism =
    query === "" || query === null
      ? organismList
      : organismList.filter((person) => {
          return person.org_name_en.toLowerCase().includes(query.toLowerCase());
        });
  const filteredAntibiotic =
    query === "" || query === null
      ? antibioticList
      : antibioticList.filter((person) => {
          return person.atb_main_name_en
            .toLowerCase()
            .includes(query.toLowerCase());
        });
  const addOrganism = (organism, index) => {
    const itemToPush = organismList[index];
    setSelectedOrganism((prevArray) => [...prevArray, itemToPush]);

    // Remove the item from the original array
    setOrganismList((prevOrganisms) => {
      const updatedOrganisms = [...prevOrganisms];
      updatedOrganisms.splice(index, 1);
      return updatedOrganisms;
    });

    let newObj = {
      ...organismList[index],
      antibioticList: [],
    };
    const updatedItems = [...items];
    updatedItems[selectedOrganismIndex].organismList.push(newObj);
    setItems(updatedItems);
  };
  const addAntibiotic = (antibiotic, index, orgIndex, selectedAntIndex) => {
    const itemToPush = antibioticList[index];
    setSelectedAntibiotic((prevArray) => [...prevArray, itemToPush]);

    const updatedItems = [...items];

    // Button Show Code Start
    // Function to check if antibioticList exists and has length > 0
    let organismData = updatedItems[selectedOrganismIndex].organismList;
    const isAntibioticListValid = (organismData) =>
      organismData?.antibioticList && organismData.antibioticList.length > 0;

    // Check if each organism in the items array has valid antibioticList
    const allOrganismsValid = items.every(
      (item) =>
        item?.organismList && item.organismList.every(isAntibioticListValid)
    );

    // Check if items array exists and has length > 0
    const isItemsValid = items && items.length > 0;

    // Show the submit button if all conditions are met
    const showSubmitButton = allOrganismsValid && isItemsValid;

    updatedItems[selectedOrganismIndex].showSubmitButton = showSubmitButton;
    let antObj = {
      ...itemToPush,
      anti_result_id: null,
    };
    updatedItems[selectedOrganismIndex].organismList[
      selectedAntIndex
    ].antibioticList.push(antObj);
    setItems(updatedItems);
    // Button Show Code End

    // Remove the item from the original array
    setAntibioticList((prevAntibiotics) => {
      const updatedAntibiotics = [...prevAntibiotics];
      updatedAntibiotics.splice(index, 1);
      return updatedAntibiotics;
    });
  };

  const removeOrganism = (org_item, item_index, org_index) => {
    const updatedItems = [...items];
    updatedItems[item_index]?.organismList?.splice(org_index, 1);
    // setItems(updatedItems);

    // Button Show Code Start
    // Function to check if antibioticList exists and has length > 0
    let organismData = updatedItems[item_index].organismList;
    const isAntibioticListValid = (organismData) =>
      organismData?.antibioticList && organismData.antibioticList.length > 0;

    // Check if each organism in the items array has valid antibioticList
    const allOrganismsValid = items.every(
      (item) =>
        item?.organismList && item.organismList.every(isAntibioticListValid)
    );

    // Check if items array exists and has length > 0
    const isItemsValid = items && items.length > 0;

    // Show the submit button if all conditions are met
    const showSubmitButton = allOrganismsValid && isItemsValid;

    updatedItems[item_index].showSubmitButton = showSubmitButton;
    setItems(updatedItems);
    // Button Show Code End
  };
  const removeAntibiotic = (antibiotic, item_index, org_index, ant_index) => {
    const updatedItems = [...items];
    updatedItems[item_index]?.organismList[org_index]?.antibioticList?.splice(
      ant_index,
      1
    );

    // Button Show Code Start
    // Function to check if antibioticList exists and has length > 0
    let organismData = updatedItems[item_index].organismList;
    const isAntibioticListValid = (organismData) =>
      organismData?.antibioticList && organismData.antibioticList.length > 0;

    // Check if each organism in the items array has valid antibioticList
    const allOrganismsValid = items.every(
      (item) =>
        item?.organismList && item.organismList.every(isAntibioticListValid)
    );

    // Check if items array exists and has length > 0
    const isItemsValid = items && items.length > 0;

    // Show the submit button if all conditions are met
    const showSubmitButton = allOrganismsValid && isItemsValid;

    updatedItems[item_index].showSubmitButton = showSubmitButton;
    setItems(updatedItems);
    // Button Show Code End
  };

  const updateComment = (item, item_index, comment) => {
    const updatedItems = [...items];
    updatedItems[item_index].comment = comment;
    setItems(updatedItems);
  };

  const updateResult = (
    org_item,
    item_index,
    org_index,
    ant_index,
    anti_result_id
  ) => {
    const updatedItems = [...items];
    updatedItems[item_index].organismList[org_index].antibioticList[
      ant_index
    ].anti_result_id = anti_result_id;
    setItems(updatedItems);
  };

  const saveResult = async (item, index) => {
    let payload = {
      sample_id:
        item.sample_id && !isNaN(item.sample_id)
          ? parseFloat(item.sample_id)
          : 0,
      sp_gen_id:
        item.sp_gen_id && !isNaN(item.sp_gen_id)
          ? parseFloat(item.sp_gen_id)
          : 0,
      fk_test_id:
        item.fk_test_id && !isNaN(item.fk_test_id)
          ? parseFloat(item.fk_test_id)
          : 0,
      comment: item.comment,
      mtcr_test_name_en: item.test_name_en,
      mtcr_test_name_ar: item.test_name_ar,
      result_data: [],
    };
    if (item.organismList.length > 0) {
      item.organismList.map((org_val, org_ind) => {
        org_val.anti_data = [];
        if (org_val.antibioticList.length > 0) {
          org_val.antibioticList.map((ant_val, ant_ind) => {
            let antObj = {
              anti_id:
                ant_val.atb_main_id && !isNaN(ant_val.atb_main_id)
                  ? parseFloat(ant_val.atb_main_id)
                  : 0,
              anti_result_id:
                ant_val.anti_result_id && !isNaN(ant_val.anti_result_id)
                  ? parseFloat(ant_val.anti_result_id)
                  : 0,
              mtcr_id:
                ant_val?.mtcr_id && !isNaN(ant_val?.mtcr_id)
                  ? parseFloat(ant_val?.mtcr_id)
                  : 0,
            };
            org_val.anti_data.push(antObj);
          });
        }
        let orgObj = {
          org_id:
            org_val.org_id && !isNaN(org_val.org_id)
              ? parseFloat(org_val.org_id)
              : 0,
          anti_data: org_val.anti_data,
        };
        payload.result_data.push(orgObj);
      });
    }
    console.log("payload", payload);
    let res = await SampleMicroServices.saveSampleTestResult(payload);
    if (res.status == 200) {
      toast.success(res.data.message);
      let sampl_payload = {
        page: 1,
        pageSize: pageSize,
        filter: queryString.stringify(filterInitialValues),
      };
      getAllSampleList(sampl_payload);
    } else {
      toast.error(res.data.message);
    }
  };
  useEffect(() => {
    document.title = "Labib | Microbiology";
    getAllOrganismList(1);
    getAllAntibioticList(1);
    getAllAntibioticResultTypeList(1);
    setTimeout(() => {
      let payload = {
        page: 1,
        pageSize: pageSize,
        filter: queryString.stringify(filterInitialValues),
      };
      getAllSampleList(payload);
    }, 500);
  }, []);
  const generateReport = async (item) => {
    const newOrganismList = item.organismList.map((org) => ({ ...org }));
    const newAntibioticList = item.organismList.map((org) =>
      org.antibioticList.map((atb) => ({ ...atb }))
    );
    const singleArray = [].concat(...newAntibioticList);
    let payload = {
      ...item,
      newOrganismList: newOrganismList,
      newAntibioticList: singleArray,
    };
    let res = await SampleMicroServices.generateReport(payload);
    if (res.status == 200) {
      toast.success(res.data.message);
      let sample_payload = {
        page: 1,
        pageSize: pageSize,
        filter: queryString.stringify(filterInitialValues),
      };
      getAllSampleList(sample_payload);
    } else {
      toast.error(res.data.message);
    }
  };
  const onFilter = () => {
    filterInitialValues = {
      filterFlag: true,
      filterFrom: filterFrom ? filterFrom : "",
      filterTo: filterTo ? filterTo : "",
      filterStatus: filterStatus ? filterStatus : "",
      filterHos: filterHos ? filterHos : "",
      filterBkType: filterBkType ? filterBkType : "",
      filterOrg: filterOrg ? filterOrg : "",
      filterSampleId: filterSampleId ? filterSampleId : "",
      filterPatientMob: filterPatientMob ? filterPatientMob : "",
      filterPatientIdentification: filterPatientIdentification
        ? filterPatientIdentification
        : "",
      filterPatientName: filterPatientName ? filterPatientName : "",
      filterPatientPassport: filterPatientPassport ? filterPatientPassport : "",
      filterTestType: filterTestType ? filterTestType : "",
      filterPatientId: filterPatientId ? filterPatientId : "",
      filterReservationId: filterReservationId ? filterReservationId : "",
      filterMrn: filterMrn ? filterMrn : "",
    };
    let payload = {
      page: 1,
      pageSize: pageSize,
      filter: queryString.stringify(filterInitialValues),
    };
    getAllSampleList(payload);
  };
  const onFilterClear = () => {
    setFilterFrom("");
    setFilterTo("");
    setFilterStatus("");
    setFilterHos("");
    setFilterBkType("");
    setFilterOrg("");
    setFilterSampleId("");
    setFilterPatientMob("");
    setFilterPatientIdentification("");
    setFilterPatientName("");
    setFilterPatientPassport("");
    setFilterTestType("");
    setFilterPatientId("");
    setFilterReservationId("");
    setFilterMrn("");
    let payload = {
      page: 1,
      pageSize: pageSize,
      filter: queryString.stringify(filterInitialValues),
    };
    getAllSampleList(payload);
  };
  const getAllSampleList = (pay_index) => {
    let payload = {
      page: pay_index.page,
      pageSize: pageSize,
      filter: queryString.stringify(filterInitialValues),
    };
    SampleMicroServices.getAllMicroSampleProcessList(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.all_lists?.length > 0) {
            let allsampleList = data?.data?.all_lists;
            allsampleList.map((val, key) => {
              if (!val.organismList) {
                val.organismList = [];
                val.showSubmitButton = false;
                val.comment = "";
              }
            });
            setSampleList(allsampleList);
            setItems(allsampleList);
            setMaxVisiblePages(data.data?.maxVisiblePages);
            setPageArr(data.data?.paginationArray);
            setCurrentPage(data.data?.currentPage);
          } else {
            toast.error("SampleList Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = "getAllSampleList catch Error"; //error.response.data.message;
        toast.error(msg);
        setLoading(false);
      });
  };

  const getAllOrganismList = (payload) => {
    OrganismAntiServices.getAllOrganismList(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setOrganismList(data.data);
          } else {
            toast.error("OrganismList Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = "getAllOrganismList catch Error";
        toast.error(msg);
      });
  };

  const getAllAntibioticList = (payload) => {
    OrganismAntiServices.getAllAntibioticList(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setAntibioticList(data.data);
          } else {
            toast.error("AntibioticList Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = "getAllAntibioticList catch Error";
        toast.error(msg);
      });
  };

  const getAllAntibioticResultTypeList = (payload) => {
    OrganismAntiServices.getAllAntibioticResultTypeList(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setAntibioticResultTypeList(data.data);
          } else {
            toast.error("AntibioticResultTypeList Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = "getAllAntibioticResultTypeList catch Error";
        toast.error(msg);
      });
  };

  return (
    <>
      <div className="px-4 py-5 mb-2 bg-white rounded-md shadow-lg sm:px-6 ring-1 ring-white ring-opacity-5 focus:outline-none">
        <div className="sales-booking-head">
          <Breadcrumb pages={pages} />
          <div className="flex justify-between py-3 pl-2">
            <div className="flex items-center space-x-2">
              <div className="relative">
                <button
                  onClick={() => {
                    setFilterFlag(filterFlag ? false : true);
                  }}
                  className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1"
                >
                  <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-md sm:py-2">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-3 h-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                        />
                      </svg>
                    </div>
                    <div className="hidden sm:block">
                      Filters {filterFlag ? "On" : "Off"}
                    </div>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {filterFlag ? (
        <div className="px-4 py-5 mb-2 bg-white rounded-md shadow-lg sm:px-6 ring-1 ring-white ring-opacity-5 focus:outline-none">
          <div className="space-y-6 sm:space-y-5">
            {loading ? (
              <FallingLinesLoader />
            ) : (
              <div className="grid grid-cols-3 gap-3 mt-5 lg:grid-cols-3 lg:gap-3 md:grid-cols-3 md:gap-3 sm:grid-cols-12 sm:gap-0">
                <div className="basis-3/4"></div>
                <div className="basis-3/4"></div>
                <button
                  type="button"
                  className="px-1 py-1 font-bold text-right text-red-500 rounded hover:text-red-700"
                  onClick={() => setFilterFlag(filterFlag ? false : true)}
                >
                  X
                </button>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterFrom"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Reservation From
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="date"
                      name="filterFrom"
                      id="filterFrom"
                      value={filterFrom}
                      onChange={(e) => {
                        setFilterFrom(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterTo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Reservation To
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="date"
                      name="filterTo"
                      id="filterTo"
                      value={filterTo}
                      onChange={(e) => {
                        setFilterTo(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor=""
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Hospital
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      value={filterHos}
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      name="fk_hos_id"
                      id="fk_hos_id"
                      onInput={(e) => {
                        setFilterHos(e.target.value);
                      }}
                    >
                      <option>Select Hospital</option>
                      {hospitalList.map((hospital, i) => (
                        <option
                          selected={
                            hospital.hos_id === filterInitialValues.filterHos
                              ? "selected"
                              : ""
                          }
                          key={i}
                          value={hospital.hos_id}
                        >
                          {hospital.hos_name_en}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="basis-3/4">
                  <label
                    htmlFor=""
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Organization
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      value={filterOrg}
                      onInput={(e) => {
                        setFilterOrg(e.target.value);
                      }}
                      name="filterOrg"
                      id="filterOrg"
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    >
                      <option>Select Organization</option>
                      <option>Organization 1</option>
                      <option>Organization 2</option>
                    </select>
                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor=""
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Reservation Type
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      value={filterBkType}
                      onInput={(e) => {
                        setFilterBkType(e.target.value);
                      }}
                      name="filterBkType"
                      id="filterBkType"
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    >
                      <option>Select Reservation Type</option>
                      {bookingType.map((bkType, i) => (
                        <option
                          selected={
                            bkType.name === filterInitialValues.filterBkType
                              ? "selected"
                              : ""
                          }
                          key={i}
                          value={bkType.name}
                        >
                          {bkType.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="basis-3/4">
                  <label
                    htmlFor="filterStatus"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Status
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      value={filterStatus}
                      onInput={(e) => {
                        setFilterStatus(e.target.value);
                      }}
                      name="filterStatus"
                      id="filterStatus"
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    >
                      <option>Select Status</option>
                      <option value="1">Active</option>
                      <option value="2">In-Active</option>
                    </select>
                  </div>
                </div>

                <div className="basis-3/4">
                  <label
                    htmlFor="filterPatientName"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Name
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      value={filterPatientName}
                      onInput={(e) => {
                        setFilterPatientName(e.target.value);
                      }}
                      placeholder="Search By Name"
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterPatientName"
                      id="filterPatientName"
                    />
                  </div>
                </div>

                <div className="basis-3/4">
                  <label
                    htmlFor="filterPatientPassport"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Passport
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Passport"
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterPatientPassport"
                      id="filterPatientPassport"
                      value={filterPatientPassport}
                      onInput={(e) => {
                        setFilterPatientPassport(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterPatientId"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Patient ID
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Patient ID"
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterPatientId"
                      id="filterPatientId"
                      value={filterPatientId}
                      onInput={(e) => {
                        setFilterPatientId(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterPatientMob"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Mobile No.
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Mobile No."
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterPatientMob"
                      id="filterPatientMob"
                      value={filterPatientMob}
                      onInput={(e) => {
                        setFilterPatientMob(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterPatientIdentification"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Identification
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Identification"
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterPatientIdentification"
                      id="filterPatientIdentification"
                      value={filterPatientIdentification}
                      onInput={(e) => {
                        setFilterPatientIdentification(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="basis-3/4">
                  <label
                    htmlFor="filterReservationId"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Reservation No.
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Reservation No."
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterReservationId"
                      id="filterReservationId"
                      value={filterReservationId}
                      onInput={(e) => {
                        setFilterReservationId(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterSampleId"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Sample ID.
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Sample ID."
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterSampleId"
                      id="filterSampleId"
                      value={filterSampleId}
                      onInput={(e) => {
                        setFilterSampleId(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterMrn"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    MRN.
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By MRN."
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterMrn"
                      id="filterMrn"
                      value={filterMrn}
                      onInput={(e) => {
                        setFilterMrn(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="basis-3/4">
                  <button
                    type="button"
                    className={`rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                    onClick={() => {
                      onFilter();
                    }}
                    size="default"
                    variant="outlined"
                  >
                    Search
                  </button>
                  <button
                    type="button"
                    className={`rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-1`}
                    onClick={() => {
                      onFilterClear();
                    }}
                    size="default"
                    variant="outlined"
                  >
                    Clear
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="mt-4 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr className="border rounded-md">
                    <th
                      scope="col"
                      className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Patient
                    </th>
                    <th
                      scope="col"
                      className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Res. ID
                    </th>
                    <th
                      scope="col"
                      className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Sample Id
                    </th>
                    <th
                      scope="col"
                      className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Test Name
                    </th>
                    <th
                      scope="col"
                      className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Mobile No
                    </th>
                    <th
                      scope="col"
                      className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {items?.length > 0
                    ? items?.map((item, index) => (
                        <>
                          <tr
                            key={index}
                            onClick={() => toggleAccordion(index)}
                          >
                            <td
                              className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`}
                            >
                              {item.buttonFlag
                                ? item.patient_first_name +
                                  "" +
                                  item.patient_last_name
                                : null}
                            </td>
                            <td
                              className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`}
                            >
                              <span className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
                                {item.reservation_id}
                              </span>
                            </td>
                            <td
                              className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`}
                            >
                              <span className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
                                {item.sample_id}
                              </span>
                            </td>
                            <td
                              className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`}
                            >
                              {item.test_name_en}
                            </td>
                            <td
                              className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`}
                            >
                              {item.patient_mobile}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {item.sp_status_name_en}
                            </td>
                          </tr>
                          {openIndex === index && (
                            <tr className="mt-2">
                              <td colSpan="6" className="py-4 pl-4 pr-3">
                                <div>
                                  <label
                                    htmlFor={"remark" + index}
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                  >
                                    Remark
                                  </label>
                                  <div className="mt-1">
                                    <textarea
                                      rows={2}
                                      name={"remark_" + index}
                                      id={"remark_" + index}
                                      className="block w-80 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                                      value={item.comment}
                                      onChange={(e) => {
                                        item.comment = e.target.value;

                                        updateComment(
                                          item,
                                          index,
                                          item.comment
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="mt-2">
                                  <div className="flex gap-5 items-center">
                                    Organism
                                    <span
                                      className="cursor-pointer bg-cyan-500 text-white p-1 rounded-md font-bold"
                                      onClick={() => {
                                        setOrganismModal(true);
                                        setSelectedOrganismIndex(index);
                                      }}
                                    >
                                      {" "}
                                      <PlusIcon
                                        strokeWidth={3}
                                        className="w-5 h-5"
                                      />{" "}
                                    </span>
                                  </div>
                                  <div className="mt-3">
                                    {item?.organismList?.length > 0
                                      ? item?.organismList?.map(
                                          (org_item, org_index) => (
                                            <div className="bg-white divide-y divide-gray-200 border border-gray-100">
                                              <div className="divide-y divide-gray-200">
                                                <div className="flex items-center w-full justify-between p-3 bg-gray-50">
                                                  <div>
                                                    {org_item.org_name_en}
                                                  </div>
                                                  <div className="flex gap-3">
                                                    <span
                                                      className="cursor-pointer inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
                                                      onClick={() => {
                                                        setantibioticModal(
                                                          true
                                                        );
                                                        setSelectedAntIndex(
                                                          org_index
                                                        );
                                                      }}
                                                    >
                                                      Add
                                                    </span>
                                                    <span
                                                      className="cursor-pointer inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
                                                      onClick={() => {
                                                        removeOrganism(
                                                          org_item,
                                                          index,
                                                          org_index
                                                        );
                                                      }}
                                                    >
                                                      Remove
                                                    </span>
                                                  </div>
                                                </div>
                                                {org_item?.antibioticList
                                                  ?.length > 0
                                                  ? org_item?.antibioticList?.map(
                                                      (ant_item, ant_index) => (
                                                        <div>
                                                          <div className="grid grid-cols-3 gap-4 px-3 py-2">
                                                            <div className="flex items-center">
                                                              {
                                                                ant_item?.atb_main_name_en
                                                              }
                                                            </div>
                                                            <div>
                                                              <select
                                                                name={
                                                                  "anti_result_id_" +
                                                                  ant_index
                                                                }
                                                                id={
                                                                  "anti_result_id_" +
                                                                  ant_index
                                                                }
                                                                value={
                                                                  ant_item?.anti_result_id
                                                                }
                                                                className="block w-[200px0] mx-auto rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-cyan-600 sm:text-sm sm:leading-6"
                                                                // defaultValue={ant_item?.anti_result_id}
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  ant_item.anti_result_id =
                                                                    e.target.value;
                                                                  updateResult(
                                                                    org_item,
                                                                    index,
                                                                    org_index,
                                                                    ant_index,
                                                                    ant_item?.anti_result_id
                                                                  );
                                                                }}
                                                              >
                                                                <option value="">
                                                                  All
                                                                </option>
                                                                {antibioticResultTypeList?.length >
                                                                0
                                                                  ? antibioticResultTypeList?.map(
                                                                      (
                                                                        option,
                                                                        i
                                                                      ) => (
                                                                        <option
                                                                          key={
                                                                            i
                                                                          }
                                                                          value={
                                                                            option.mtrt_id
                                                                          }
                                                                        >
                                                                          {
                                                                            option.mtrt_name_en
                                                                          }
                                                                        </option>
                                                                      )
                                                                    )
                                                                  : null}
                                                              </select>
                                                            </div>
                                                            <div className="flex justify-center items-center">
                                                              <span
                                                                className="cursor-pointer inline-flex px-2 text-xs leading-5 rounded-full text-red-600 bg-red-100"
                                                                onClick={() => {
                                                                  removeAntibiotic(
                                                                    ant_item,
                                                                    index,
                                                                    org_index,
                                                                    ant_index
                                                                  );
                                                                }}
                                                              >
                                                                Remove
                                                              </span>
                                                            </div>
                                                          </div>{" "}
                                                        </div>
                                                      )
                                                    )
                                                  : null}
                                              </div>{" "}
                                            </div>
                                          )
                                        )
                                      : null}
                                  </div>
                                  <div className="m-4 sm:mt-6 text-center">
                                    {item?.organismList?.length > 0 &&
                                    item?.showSubmitButton ? (
                                      <button
                                        onClick={() => {
                                          saveResult(item, index);
                                        }}
                                        type="button"
                                        disabled={
                                          item?.organismList?.length > 0 &&
                                          item?.showSubmitButton
                                            ? false
                                            : true
                                        }
                                        className="inline-flex w-16 justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:text-sm"
                                      >
                                        Submit
                                      </button>
                                    ) : null}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Transition.Root show={organismModal} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={setOrganismModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 booking-quotation-table-w">
                  <div>
                    <div className="flex justify-between p-3 bg-cyan-600 text-white">
                      Organism{" "}
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          setOrganismModal(false);
                        }}
                      >
                        <XMarkIcon className="w-6 h-6" />
                      </span>
                    </div>
                    <div className="h-80 overflow-auto p-3">
                      <div className="flex gap-3 flex-wrap">
                        {selectedOrganism.length > 0
                          ? selectedOrganism.map((item, index) => (
                              <div class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                <span>{item.org_name_en}</span>
                                <span className="bg-gray-100">
                                  <XMarkIcon className="w-5 h-5" />
                                </span>
                              </div>
                            ))
                          : null}
                      </div>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="filter"
                          onChange={(e) => setQuery(e.target.value)}
                          id="filter"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                          placeholder="Organism"
                        />
                      </div>
                      <div className="">
                        <ul className="divide-y divide-gray-100">
                          {filteredOrganism.length > 0
                            ? filteredOrganism.map((item, index) => (
                                <li
                                  key={index}
                                  onClick={() => addOrganism(item, index)}
                                  className="py-3 cursor-pointer"
                                >
                                  {item.org_name_en}
                                </li>
                              ))
                            : null}
                        </ul>
                      </div>
                    </div>
                    <div className="m-4 sm:mt-6">
                      <button
                        type="button"
                        disabled={selectedOrganism.length > 0 ? false : true}
                        onClick={() => setOrganismModal(false)}
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:text-sm"
                      >
                        Add Organism
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={antibioticModal} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={setantibioticModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 booking-quotation-table-w">
                  <div>
                    <div className="flex justify-between p-3 bg-cyan-600 text-white">
                      Antibiotic{" "}
                      <span
                        className="cursor-pointer"
                        onClick={() => setantibioticModal(false)}
                      >
                        <XMarkIcon className="w-6 h-6" />
                      </span>
                    </div>
                    <div className="h-80 overflow-auto p-3">
                      <div className="mt-2">
                        <input
                          type="text"
                          name="filter"
                          onChange={(e) => setQuery(e.target.value)}
                          id="filter"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                          placeholder="Antibiotic"
                        />
                      </div>
                      <ul className="divide-y divide-gray-100">
                        {filteredAntibiotic.length > 0
                          ? filteredAntibiotic.map((item, index) => (
                              <li
                                key={index}
                                onClick={() =>
                                  addAntibiotic(
                                    item,
                                    index,
                                    selectedOrganismIndex,
                                    selectedAntIndex
                                  )
                                }
                                className="py-3 cursor-pointer"
                              >
                                {item.atb_main_name_en}
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                    <div className="m-4 sm:mt-6">
                      <button
                        type="button"
                        disabled={selectedAntibiotic.length > 0 ? false : true}
                        onClick={() => setantibioticModal(false)}
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:text-sm"
                      >
                        Add Antibiotic
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default CRMMicrobiologyTab;
