/* eslint-disable */
import { toast } from 'react-toast'
import {
  GET_ALL_QUESTIONNAIRE_BEGIN,
  GET_ALL_QUESTIONNAIRE_SUCCESS,
  GET_ALL_QUESTIONNAIRE_ERROR,
  ADD_QUESTIONNAIRE_BEGIN,
  ADD_QUESTIONNAIRE_SUCCESS,
  ADD_QUESTIONNAIRE_ERROR,
  GET_QUESTIONNAIRE_SUCCESS,
  GET_QUESTIONNAIRE_BEGIN,
  GET_QUESTIONNAIRE_ERROR,
} from './constants'
import MasterServices from '../../ApiServices/MasterServices'

export const getAllQuestionnaires = () => async (dispatch) => {

  dispatch({
    type: GET_ALL_QUESTIONNAIRE_BEGIN
  })
  try {
    const { data, status } = await MasterServices.getQuestionnaireList();
    if (status == 200) {
      dispatch({
        type: GET_ALL_QUESTIONNAIRE_SUCCESS,
        data: data.data.length > 0 ? data.data : []
      })
    } else {
      toast.error("Fatal Error Please Contact Admin")
    }
  } catch(error) {
    const msg = error;
    toast.error(msg)
    dispatch({
      type: GET_ALL_QUESTIONNAIRE_ERROR,
      error: msg
    })
  }
}

export const getSingleQuestionnaire = (qstn_id) => async (dispatch) => {
  dispatch({
    type: GET_QUESTIONNAIRE_BEGIN
  })
  MasterServices.getSingleQuestionnaire(qstn_id)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        dispatch({
          type: GET_QUESTIONNAIRE_SUCCESS,
          data: data.data ? data.data : {}
        })
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error;
      toast.error(msg)
      dispatch({
        type: GET_QUESTIONNAIRE_ERROR,
        error: msg
      })
    });
}

export const addQuestionnaire = (body) => async (dispatch) => {
  dispatch({
    type: ADD_QUESTIONNAIRE_BEGIN
  })
  try {
    const { data, status } = await MasterServices.saveQuestionnaire(body);
    if (status == 200) {
      dispatch({
        type: ADD_QUESTIONNAIRE_SUCCESS,
        data: []
      })
      if(body.qstn_id) {
        toast.success("Questionnaire updated successfully!")
      } else {
        toast.success("Questionnaire added successfully!")
      }
    } else {
      toast.error("Fatal Error Please Contact Admin")
    }
  } catch (error) {
    const msg = error;
    toast.error(msg)
    dispatch({
      type: ADD_QUESTIONNAIRE_ERROR,
      error: msg
    })
  }
}
