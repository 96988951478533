import React, { useState, useEffect } from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import TableEmpty from '../../../components/tables/tableEmpty';
import { incentive_report_columns } from '../../../components/tables/tableheader';
import MasterServices from '../../../ApiServices/MasterServices'
import moment from 'moment';
import Select from 'react-select'
import { toast } from 'react-toast';
import { utils, writeFile } from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../../../redux/user/actions';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const initialIncentiveReportFilters = {
    start_date: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
    end_date: moment(new Date()).format("YYYY-MM-DD"),
    user: false
}

export default function IncentiveReport() {

    const pages = [{ title: "Incentive Report", href: "/incentive-report" }];
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)
    const [incentiveReportData, setIncentiveReportData] = useState([])
    const [incentiveReportFilters, setIncentiveReportFilters] = useState(initialIncentiveReportFilters)
    const [selectedUser, setSelectedUser] = useState(false)
    const printRef = React.useRef();

    const {
        user: { loading: userLoading, list: userList },
    } = useSelector((state) => state);

    useEffect(() => {
        dispatch(getAllUsers());
    }, [])

    async function getIncentiveReport() {
        setIsLoading(true)
        try {
            const { data, status } = await MasterServices.getIncentiveReportWithFilters(incentiveReportFilters);
            if (status === 200) {
                setIncentiveReportData(data.data)
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const handleUserSelect = (event) => {
        setSelectedUser(event)
        let user = event.user_id

        setIncentiveReportFilters((prev) => {
            return {
                ...prev,
                user,
            }
        })
    }

    const handleExport = (excel_type) => {
        if (incentiveReportData?.length > 0) {
            let newSheetdata = [];
            incentiveReportData.map((obj, index) => {
                const newData = {
                    TestPackageName: obj.test_or_package_name.toUpperCase(),
                    TestPackage: obj.test_or_package == "T" ? "Test" : "Package",
                    TotalIncentive: obj.total_incentive,
                }
                newSheetdata.push(newData);
            })
            let fileName = 'Incentive_Report_From_' + moment(incentiveReportFilters.start_date).format("DD-MM-YYYY") + "_To_" + moment(incentiveReportFilters.end_date).format("DD-MM-YYYY") + "_for_" + selectedUser.user_first_name + '.' + excel_type;

            const headings = [['Test/Package Name', 'Test/Package', 'Total Incentive Value']];
            const wb = utils.book_new();
            const ws = utils.json_to_sheet([]);
            utils.sheet_add_aoa(ws, headings);
            utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
            utils.book_append_sheet(wb, ws, 'Report');
            writeFile(wb, fileName);
        } else {
            toast.error("Data Not Found!");
        }
    }

    const handlePdfGenerate = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);

        let fileName = 'Incentive_Report_From_' + moment(incentiveReportFilters.start_date).format("DD-MM-YYYY") + "_To_" + moment(incentiveReportFilters.end_date).format("DD-MM-YYYY") + "_for_" + selectedUser.user_first_name + '.pdf';

        pdf.save(fileName);
    }

    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8">

                <Breadcrumb pages={pages} />
                <div className="">
                    <h1 className="text-xl font-semibold text-gray-900">Incentive Report</h1>
                </div>

                <div className="mt-5 flex gap-3 items-center">

                    {/* start date */}
                    <div className=" sm:mt-0">
                        <label htmlFor="fk_location_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Date From</label>

                        <input
                            disabled={isLoading}
                            className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
                            type="date"
                            name="start_date"
                            id="start_date"
                            value={incentiveReportFilters.start_date}
                            onChange={(e) => {
                                setIncentiveReportFilters((prev) => {
                                    if (prev.end_date < e.target.value) {
                                        return {
                                            ...prev,
                                            end_date: e.target.value,
                                            start_date: prev.end_date
                                        }
                                    }
                                    return {
                                        ...prev,
                                        start_date: e.target.value,
                                    }
                                })
                            }
                            }
                        />
                    </div>

                    {/* end date */}
                    <div className=" sm:mt-0">
                        <label htmlFor="fk_location_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Date To</label>

                        <input
                            disabled={isLoading}
                            className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
                            type="date"
                            name="end_date"
                            id="end_date"
                            value={incentiveReportFilters.end_date}
                            onChange={(e) => {
                                setIncentiveReportFilters((prev) => {
                                    if (prev.start_date > e.target.value) {
                                        return {
                                            ...prev,
                                            end_date: prev.start_date,
                                            start_date: e.target.value
                                        }
                                    }
                                    return {
                                        ...prev,
                                        end_date: e.target.value,
                                    }
                                })
                            }
                            }
                        />
                    </div>

                    {
                        !userLoading ?
                            <div className="">
                                <label
                                    htmlFor="fk_location_ids"
                                    className="w-60 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">User</label>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <Select
                                        name="fk_user_ids"
                                        id="fk_user_ids"
                                        value={selectedUser}
                                        onChange={(e) => handleUserSelect(e)}
                                        options={userList}
                                        className="basic-multi-select"
                                        classNamePrefix="Select Test"
                                    />
                                </div>
                            </div>
                            : null
                    }

                    <button
                        disabled={isLoading}
                        onClick={() => {
                            getIncentiveReport()
                        }}
                        className={`ml-3 h-10 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 mt-7 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${isLoading ? "opacity-40" : "opacity-100"}`}
                    >
                        Get Data
                    </button>
                </div>

                <div className='mt-5 flex gap-1 items-center'>
                    <button
                        onClick={() => handleExport('csv')}
                        className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                        CSV
                    </button>

                    <button
                        onClick={() => handleExport('xlsx')}
                        className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                        Excel
                    </button>

                    <button
                        onClick={() => handlePdfGenerate()}
                        className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                        PDF
                    </button>

                </div>
            </div>
            {
                isLoading ? (
                    <FallingLinesLoader />
                ) : ((incentiveReportData.length > 0) ? (
                    <Table
                        printRef={printRef}
                        columns={incentive_report_columns()}
                        data={incentiveReportData}
                    />
                ) : <TableEmpty />)
            }

        </>
    )

}