import React, { useState, Fragment, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import { outsource_clinic_credit_spend_columns } from "../../../components/tables/tableheader";
import MasterServices from "../../../ApiServices/MasterServices";

const OCCreditSpendDetails = () => {
  const { id: clinic_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [outsourceClinicCreditLogList, setOutsourceClinicCreditLogList] =
    useState([]);

  useEffect(() => {
    getOutsourceClinicCreditSpendList();
  }, []);

  async function getOutsourceClinicCreditSpendList() {
    setLoading(true);
    try {
      const clinicsCreditLogsFromApi =
        await MasterServices.getSpendCreditLogsByClinicId(clinic_id);
      setOutsourceClinicCreditLogList(clinicsCreditLogsFromApi.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between">
          <h1 className="text-xl font-semibold text-gray-900">
            Credit Details List :
            <span className="ml-4 bg-green-200 py-2 px-4 rounded-lg">
              {" "}
              Balance -{" "}
              {
                outsourceClinicCreditLogList?.current_credit_balance
                  ?.mc_credit_limit
              }
            </span>
          </h1>

        </div>

        {loading ? (
          <FallingLinesLoader />
        ) : (
          <Table
            columns={outsource_clinic_credit_spend_columns()}
            data={
              outsourceClinicCreditLogList.clinic_credit_logs?.length > 0
                ? outsourceClinicCreditLogList.clinic_credit_logs
                : []
            }
          />
        )}
      </div>
    </>
  );
};

export default OCCreditSpendDetails;
