import React, { useEffect } from 'react'
import Breadcrumb from '../../../components/Breadcrumb';
import { useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { warehouseItemCategorySchema } from "../../../schemas";
import MasterServices from '../../../ApiServices/MasterServices';
import { toast } from 'react-toast';


let initialValues = {
  wic_name: "",
};

const AddWarehouseItemCategory = () => {
  const pages = [{ title: "Add Category", href: "/add-warehouse-item-category", module_id: 26 }];
  const [loading, setLoading] = useState(false);
  const { wic_id: WITID } = useParams();

  const [formState, setFormState] = useState(initialValues);

  const navigate = useNavigate();
  useEffect(() => {
    if (WITID) getWarehouseItemCategoryByID(WITID);

  }, [WITID]);

  const getWarehouseItemCategoryByID = (id) => {
    setLoading(true);
    MasterServices.getSingleWarehouseItemCategory(id)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setFormState({
            wic_name: data.data.wic_name,
          })
          console.log(data.data)
          // toast.success("Items Fetched Successfully");
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };

  const addWarehouseItemCategory = async (payload) => {
    try {
      const {data, status} = await MasterServices.saveWarehouseItemCategory(payload);
      if (status === 200) {
        console.log(data.data)
        toast.success("Category added successfully");
        setLoading(false);
      } else {
        toast.error("Fatal Error Please Contact Admin");
      }
    } catch(err) {
        const msg = err.data.message;
        toast.error(msg);
        setLoading(false);
    }
  };

  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      validationSchema: warehouseItemCategorySchema,
      onSubmit: async (values, action) => {
        const body = { ...values };
        if(WITID) {
          body.wic_id = WITID;
        }
        console.log(body)
        await addWarehouseItemCategory(body);
        navigate("/warehouse-item-category");
      },
    });
    

  return (
    <div>
      <Breadcrumb pages={pages} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
        className="space-y-8 divide-y divide-gray-200"
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">

          <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
            <div>
              <h3 className="text-base font-semibold leading-6 text-gray-900">Add Category</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
               add Category
              </p>
            </div>
            <div className="flex sm:border-t sm:border-gray-200 sm:pt-5 gap-5">
              <div className="basis-1/3">
                <label htmlFor="account-name" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Category Name
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0 w-80">
                  <input
                    type="text"
                    name="wic_name"
                    id="wic_name"
                    autoComplete="ref"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    value={values.wic_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.wic_name && touched.wic_name ? (
                    <p className="text-red-600 text-sm">{errors.wic_name}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end gap-x-3">
            <button
              type="button"
              onClick={e => navigate('/warehouse-item-category')}
              className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex justify-center rounded-md bg-cyan-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
            >
              {WITID ? 'Edit' : 'Save'}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AddWarehouseItemCategory