import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState, Fragment } from "react";
import { useFormik } from "formik";
import { couponSchema, editCouponSchema } from "../../../schemas";
import Table from "../../../components/tables/table";
import { coupon_columns } from "../../../components/tables/tableheader";
import { Dialog, Switch, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../ApiServices/MasterServices";
import ReservationServices from "../../../ApiServices/ReservationServices";
import moment from "moment";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getAllTests } from "../../../redux/test/actions";
import { getAllPackages } from "../../../redux/package/actions";
import Modal from "../../../components/Modal";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toast";
import CouponCodes from "./CouponCodes";
import { getAllLocations } from "../../../redux/location/actions";

function categoryStatusToggle(...categoryStatus) {
  return categoryStatus.filter(Boolean).join(" ");
}

const initialModalStateCoupon = {
  state: false,
};

function Coupon() {
  const pages = [{ title: "Coupons", href: "/coupons", module_id: 28 }];
  const [couponList, setCouponList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [couponId, setCouponId] = useState(null);
  const [selectedTests, setSelectedTests] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    couponId: null,
  });
  const [isPercentage, setIsPercentage] = useState(false);

  const [testList, setTestList] = useState(false);
  const [originalTestList, setOriginalTestList] = useState([]);
  const [userList, setUserList] = useState(false);
  const [originalUserList, setOriginalUserList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [originalPackageList, setOriginalPackageList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [originalLocationList, setOriginalLocationList] = useState([]);
  const [multiTestId, setMultiTestId] = useState();
  const [multiUserId, setMultiUserId] = useState();
  const [multiPackageId, setMultiPackageId] = useState();

  const [modalCoupon, setModalCoupon] = useState(initialModalStateCoupon);
  const [couponCodeList, setCouponCodeList] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    loading,
    test: { list: tList },
    package: { list: pList },
    location: { list: l_List },
  } = useSelector((state) => state);

  useEffect(() => {
    document.title = "Labib | Coupons";
    getAllUsers();
    getAllCoupons();
    dispatch(getAllTests());
    dispatch(getAllLocations());
    dispatch(getAllPackages("active"));
  }, []);

  useEffect(() => {
    handleTestLoad();
  }, [tList]);

  useEffect(() => {
    handlePackageLoad();
  }, [pList]);

  useEffect(() => {
    handleLocLoad();
  }, [l_List]);

  const handleLocLoad = () => {
    console.log("location are available in store: ", l_List);
    setLocationList(l_List);
    setOriginalLocationList(l_List);
  };

  const handleTestLoad = () => {
    setTestList(tList);
    setOriginalTestList(tList);
  };

  const handlePackageLoad = () => {
    setPackageList(pList);
    setOriginalPackageList(pList);
  };

  const getAllUsers = async () => {
    try {
      setIsLoading(true);
      const { data } = await ReservationServices.getAllUserList();
      // console.log("All Users Data", data)
      if (data.status) {
        let newUsers = data.data;
        if (Array.isArray(newUsers)) {
          console.log("newUsers", newUsers);
          setUserList(newUsers);
          setOriginalUserList(newUsers);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getAllCoupons = async () => {
    try {
      setIsLoading(true);
      const { data } = await MasterServices.getAllCoupons();
      if (data?.status) {
        setCouponList(data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const addCoupon = async (payload, id) => {
    try {
      setIsLoading(true);
      // const res = await Api().post(`/api/dashboard/masters/state`, couponId !== undefined || couponId !== null || couponId !== "" ? { ...body, couponId } : body);
      const res = await MasterServices.updateCoupon(payload, couponId);
      if (res.status) {
        getAllCoupons();
        setIsPercentage(false);
        toast.success("Coupon Updated Successfully");
      }
      // console.log("Add State Response", res)
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Coupon not updated successfully");
      setIsLoading(false);
    }
  };

  const onSelect = (selectedList, type) => {
    if (type == "fk_test_ids") {
      values.fk_test_ids = selectedList.map((v) => v.id);
      setSelectedTests(selectedList);
    } else if (type == "fk_user_ids") {
      values.fk_user_ids = selectedList.map((v) => v.id);
      setSelectedUsers(selectedList);
    } else if (type == "fk_package_ids") {
      values.fk_package_ids = selectedList.map((v) => v.id);
      setSelectedPackages(selectedList);
    } else if (type == "fk_loc_ids") {
      values.fk_loc_ids = selectedList.map((v) => v.id);
      setSelectedLocations(selectedList);
    }
  };

  const onRemove = (selectedList, type) => {
    if (type == "fk_test_ids") {
      values.fk_test_ids = selectedList.map((v) => v.id);
      setSelectedTests(selectedList);
    } else if (type == "fk_user_ids") {
      values.fk_user_ids = selectedList.map((v) => v.id);
      setSelectedUsers(selectedList);
    } else if (type == "fk_package_ids") {
      values.fk_package_ids = selectedList.map((v) => v.id);
      setSelectedPackages(selectedList);
    } else if (type == "fk_loc_ids") {
      values.fk_loc_ids = selectedList.map((v) => v.id);
      setSelectedLocations(selectedList);
    }
  };

  const removeDuplicateObjects = (array1, array2, key) => {
    return array2.filter((item) => {
      for (let i = 0; i < array1.length; i++) {
        console.log(array1[i].id == item.id);
        if (array1[i].id == item.id) {
          return false;
        }
      }
      return true;
    });
  };

  // To Modify data for multi select
  function transformData(list, type) {
    console.log(type);
    if (type == "test_id") {
      return list.map((item) => ({
        name: item.test_name_en,
        id: item.test_id ? item.test_id : item,
      }));
    } else if (type == "user_id") {
      return list.map((item) => ({
        name: item.user_first_name,
        id: item.user_id ? item.user_id : item,
      }));
    } else if (type == "package_id") {
      return list.map((item) => ({
        name: item.package_name_en,
        id: item.package_id ? item.package_id : item,
      }));
    } else if (type == "loc_id") {
      return list.map((item) => ({
        name: item.loc_name_en,
        id: item.loc_id ? item.loc_id : item,
      }));
    }
  }

  const initialValues = {
    cp_toDate: "",
    cp_fromDate: "",
    fk_test_ids: [],
    fk_user_ids: [],
    fk_package_ids: [],
    fk_loc_ids: [],
  };

  const [formCoupon, setFormCoupon] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    console.log(type, obj);
    if ((type === "edit", id)) {
      setCouponId(id);
      //Test Arr Sorting..
      let currentTestsArr = [];
      if (
        Array.isArray(obj?.coupon_code_map) &&
        obj.coupon_code_map.length > 0
      ) {
        currentTestsArr = obj.coupon_code_map
          .filter((v) => !v.cpm_delete)
          .map((v) => v.fk_test_id)
          .filter((v) => v !== null);
        let qctestlistarr = transformData(
          originalTestList.filter((v) => currentTestsArr.includes(v.test_id)),
          "test_id"
        );
        setSelectedTests(qctestlistarr);
        let newTests = transformData(originalTestList, "test_id");
        console.log(qctestlistarr, newTests);
        newTests = removeDuplicateObjects(qctestlistarr, newTests);
        setTestList(newTests);
      }
      //User Arr Sorting..
      let currentUserArr = [];
      if (
        Array.isArray(obj?.coupon_code_map) &&
        obj.coupon_code_map.length > 0
      ) {
        currentUserArr = obj.coupon_code_map
          .filter((v) => !v.cpm_delete)
          .map((v) => v.fk_user_id)
          .filter((v) => v !== null);
        console.log("currentUserArr", currentUserArr);
        console.log("originalUserList", originalUserList);
        let userlistarr = transformData(
          originalUserList.filter((v) => currentUserArr.includes(v.user_id)),
          "user_id"
        );
        console.log("userlistarr", userlistarr);
        setSelectedUsers(userlistarr);
        let newUsers = transformData(originalUserList, "user_id");
        console.log(userlistarr, newUsers);
        newUsers = removeDuplicateObjects(userlistarr, newUsers);
        setUserList(newUsers);
      }
      //Package Arr Sorting..
      let currentPackageArr = [];
      if (
        Array.isArray(obj?.coupon_code_map) &&
        obj.coupon_code_map.length > 0
      ) {
        currentPackageArr = obj.coupon_code_map
          .filter((v) => !v.cpm_delete)
          .map((v) => v.fk_package_id)
          .filter((v) => v !== null);
        console.log("currentPackageArr", currentPackageArr);
        console.log("originalPackageList", originalPackageList);
        let packagelistarr = transformData(
          originalPackageList.filter((v) =>
            currentPackageArr.includes(v.package_id)
          ),
          "package_id"
        );
        console.log("packagelistarr", packagelistarr);
        setSelectedPackages(packagelistarr);
        let newPackages = transformData(originalPackageList, "package_id");
        console.log(packagelistarr, newPackages);
        newPackages = removeDuplicateObjects(packagelistarr, newPackages);
        setPackageList(newPackages);
      }

      //Location Arr Sorting..
      let currentLocationArr = [];
      if (
        Array.isArray(obj?.coupon_code_map) &&
        obj.coupon_code_map.length > 0
      ) {
        currentLocationArr = obj.coupon_code_map
          .filter((v) => !v.cpm_delete)
          .map((v) => v.fk_loc_id)
          .filter((v) => v !== null);
        console.log("currentLocationArr", currentLocationArr);
        console.log("originalLocationList", originalLocationList);
        let locationlistarr = transformData(
          originalLocationList.filter((v) =>
            currentLocationArr.includes(v.loc_id)
          ),
          "loc_id"
        );
        console.log("locationlistarr", locationlistarr);
        setSelectedLocations(locationlistarr);
        let newLocations = transformData(originalLocationList, "loc_id");
        console.log(locationlistarr, newLocations);
        newLocations = removeDuplicateObjects(locationlistarr, newLocations);
        setLocationList(newLocations);
      }

      const editValues = {
        cp_fromDate: obj.cp_fromDate
          ? moment(obj.cp_fromDate).format("YYYY-MM-DD")
          : "",
        cp_toDate: obj.cp_toDate
          ? moment(obj.cp_toDate).format("YYYY-MM-DD")
          : "",
        fk_test_ids: currentTestsArr,
        fk_user_ids: currentUserArr,
        fk_package_ids: currentPackageArr,
        fk_loc_ids: currentLocationArr,
        // cp_code: obj.cp_code,
        // cp_description: obj.cp_description,
        // cp_discountValue: obj.cp_discountValue,
        // cp_expiresAt: moment(obj.cp_expiresAt).format("YYYY-MM-DD"),
        // fk_user_id: obj.fk_user_id,
      };
      // setIsPercentage(obj.cp_value_is_percentage)
      setFormCoupon(editValues);
    } else {
      setCouponId("");
      setFormCoupon(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };
  const onDeleteOpen = (couponId) => {
    setConfirmationModal({ couponId, status: true });
  };
  // TODO:
  const onDeleteCoupon = async (cellId) => {
    setConfirmationModal({ couponId: null, status: false });
    try {
      let payload = {};
      // const res = await Api().post(`/api/dashboard/masters/state`, couponId !== undefined || couponId !== null || couponId !== "" ? { ...body, couponId } : body);
      const res = await MasterServices.deleteCoupon(cellId, payload);
      if (res.status) {
        getAllCoupons();
        setIsPercentage(false);
        toast.success("Coupon Deleted Successfully");
      }
      // console.log("Add State Response", res)
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Coupon not deleted successfully");
      setIsLoading(false);
    }
    //    let payload = {
    //     cp_is_active: true
    //    }
    //     MasterServices.deactivateCoupon(couponId);
  };

  const onStatusActiveOpen = async (cellId, e) => {
    try {
      let payload = {
        cp_id: cellId,
        cp_is_active: e,
      };
      // const res = await Api().post(`/api/dashboard/masters/state`, couponId !== undefined || couponId !== null || couponId !== "" ? { ...body, couponId } : body);
      const res = await MasterServices.statusChangeCoupon(cellId, payload);
      if (res.status) {
        getAllCoupons();
        setIsPercentage(false);
        toast.success("Coupon Updated Successfully");
      }
      // console.log("Add State Response", res)
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Coupon not updated successfully");
      setIsLoading(false);
    }
  };
  // const onStatusActiveCoupon = (couponId) => {
  //     setConfirmationModal({ couponId: null, status: false });
  //     //    let payload = {
  //     //     cp_is_active: true
  //     //    }
  //     //     MasterServices.deactivateCoupon(couponId);
  // };

  const handleMultiTest = (event) => {
    initialValues.fk_test_ids = JSON.stringify(event);

    setMultiTestId(event);
    console.log(initialValues.fk_test_ids);
  };

  const handleMultiUser = (event) => {
    initialValues.fk_user_ids = JSON.stringify(event);

    setMultiUserId(event);
    console.log(initialValues.fk_user_ids);
  };

  const handleMultiPackage = (event) => {
    initialValues.fk_package_ids = JSON.stringify(event);

    setMultiPackageId(event);
    console.log(initialValues.fk_package_ids);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formCoupon,
      validationSchema: editCouponSchema,
      onSubmit: (values, action) => {
        // console.log("Submit Clicked", { ...values, cp_value_is_percentage: isPercentage });
        console.log(values);
        let payload = {
          cp_fromDate: values.cp_fromDate,
          cp_toDate: values.cp_toDate,
          fk_test_ids: values.fk_test_ids,
          fk_user_ids: values.fk_user_ids,
          fk_package_ids: values.fk_package_ids,
          fk_loc_ids: values.fk_loc_ids,
        };
        addCoupon(payload);
        // console.log("Update State")
        action.resetForm();
        if (modalOpenFlage === true) {
          // getAllCoupons()
          setmodalOpenFlage(false);
        }
        // navigate("/coupons");
      },
    });

  const getAllCouponCodes = (id) => {
    setIsLoading(true);
    MasterServices.getCouponCodesByCouponID(id)
      .then((response) => {
        const { data, status } = response;
        // console.log("data", response.data);
        if (status == 200) {
          setCouponCodeList(data.data);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setIsLoading(false);
      });
  };

  const toggleModal = (cp_id) => {
    getAllCouponCodes(cp_id);
    // console.log("cp_id", cp_id);
    setModalCoupon((prev) => ({ ...prev, state: true }));
  };

  const renderCouponCodeModel = () => {
    const { state } = modalCoupon;

    return (
      <Modal
        title="Coupon Code List"
        open={state}
        size="max-w-3xl relative"
        setOpen={() => setModalCoupon((prev) => ({ ...prev, state: false }))}
      >
        {loading ? (
          <FallingLinesLoader />
        ) : (
          <CouponCodes couponCodeData={couponCodeList} isLoading={isLoading} />
        )}
      </Modal>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteCoupon(confirmationModal.couponId)}
        confirmationButtonText="Delete Coupon"
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Coupons</h1>
        <p className="mt-2 text-sm text-gray-700">A list of all the Coupons.</p>
      </div>
      <div className="mt-4 flex">
        <Link
          // onClick={() => {
          //     setMultiTestId(false)
          //     setMultiPackageId(false)
          //     setMultiUserId(false)
          //     handleDrawer("add", "", {})
          // }
          // }
          to="/add-coupon"
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add Coupon
        </Link>
      </div>

      {renderCouponCodeModel()}

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          zoom="zoom-075"
          columns={coupon_columns({
            onDeleteOpen,
            handleDrawer,
            toggleModal,
            onStatusActiveOpen,
          })}
          data={couponList?.length > 0 && !isLoading ? couponList : []}
        />
      )}

      {isLoading ? (
        <></>
      ) : (
        <div>
          <Transition.Root show={modalOpenFlage} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-30"
              onClose={setmodalOpenFlage}
            >
              <div className="fixed inset-0" />
              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            // console.log(e);
                            handleSubmit(e);
                          }}
                          className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                        >
                          <div className="h-0 flex-1 overflow-y-auto">
                            <div className="bg-cyan-700 py-6 px-4 sm:px-6">
                              <div className="flex items-center justify-between">
                                <Dialog.Title className="text-lg font-medium text-white">
                                  {couponId ? "Update" : "Add"} Coupon
                                </Dialog.Title>
                                <div className="ml-3 flex h-7 items-center">
                                  <button
                                    type="button"
                                    className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                    onClick={() => setmodalOpenFlage(false)}
                                  >
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                {/* Coupon Code */}
                                {/* <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                    <div>
                                                                        <label
                                                                            htmlFor="cp_code"
                                                                            className="block text-sm font-medium text-gray-900"
                                                                        >
                                                                            Code
                                                                        </label>
                                                                    </div>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            value={values.cp_code}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            type="text"
                                                                            placeholder="Enter Coupon Code"
                                                                            name="cp_code"
                                                                            autoComplete="off"
                                                                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                                                        />
                                                                        {errors.cp_code &&
                                                                            touched.cp_code ? (
                                                                            <p className="text-red-600 text-sm">
                                                                                {errors.cp_code}
                                                                            </p>
                                                                        ) : null}
                                                                    </div>
                                                                </div> */}

                                {/* Description */}
                                {/* <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                    <div>
                                                                        <label
                                                                            htmlFor="cp_description"
                                                                            className="block text-sm font-medium text-gray-900"
                                                                        >
                                                                            Description
                                                                        </label>
                                                                    </div>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            value={values.cp_description}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            type="text"
                                                                            placeholder="Enter Coupon Description"
                                                                            name="cp_description"
                                                                            autoComplete="off"
                                                                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                                                        />
                                                                        {errors.cp_description &&
                                                                            touched.cp_description ? (
                                                                            <p className="text-red-600 text-sm">
                                                                                {errors.cp_description}
                                                                            </p>
                                                                        ) : null}
                                                                    </div>
                                                                </div> */}
                                <div className="mt-1 basis-1/2">
                                  <div>
                                    <label
                                      htmlFor="cp_fromDate"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      From Date
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.cp_fromDate}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="date"
                                      placeholder="Enter To Date"
                                      name="cp_fromDate"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:text-sm"
                                    />
                                    {errors.cp_fromDate &&
                                    touched.cp_fromDate ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.cp_fromDate}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                  <div>
                                    <label
                                      htmlFor="cp_expiresAt"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      To Date
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.cp_toDate}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="date"
                                      placeholder="Enter To Date"
                                      name="cp_toDate"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:text-sm"
                                    />
                                    {errors.cp_toDate && touched.cp_toDate ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.cp_toDate}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>

                                {/* <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                    <div>
                                                                        <label
                                                                            htmlFor="cp_discountValue"
                                                                            className="block text-sm font-medium text-gray-900"
                                                                        >
                                                                            Discount Value
                                                                        </label>
                                                                    </div>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            value={values.cp_discountValue}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            type="text"
                                                                            placeholder="Enter Discount Value"
                                                                            name="cp_discountValue"
                                                                            autoComplete="off"
                                                                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                                                        />
                                                                        {errors.cp_discountValue &&
                                                                            touched.cp_discountValue ? (
                                                                            <p className="text-red-600 text-sm">
                                                                                {errors.cp_discountValue}
                                                                            </p>
                                                                        ) : null}
                                                                    </div>
                                                                </div> */}

                                {/* <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                    <div>
                                                                        <label
                                                                            htmlFor="cp_value_is_percentage"
                                                                            className="block text-sm font-medium text-gray-900"
                                                                        >
                                                                            Is Percentage
                                                                        </label>
                                                                    </div>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <Switch
                                                                            name="cp_value_is_percentage"
                                                                            checked={isPercentage}
                                                                            onChange={(e) => {
                                                                                // console.log(e,)
                                                                                setIsPercentage(e)
                                                                            }}
                                                                            className={categoryStatusToggle(
                                                                                isPercentage ? 'bg-cyan-600' : 'bg-gray-200',
                                                                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
                                                                            )}
                                                                        >
                                                                            <span className="sr-only">Use setting</span>
                                                                            <span
                                                                                aria-hidden="true"
                                                                                className={categoryStatusToggle(
                                                                                    isPercentage ? 'translate-x-5' : 'translate-x-0',
                                                                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                                                )}
                                                                            />
                                                                        </Switch>
                                                                    </div>
                                                                </div> */}

                                {/* {
                                                                    userList && userList.length > 0 ? (<div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                                                        <select
                                                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                                                            name="fk_user_id"
                                                                            id="fk_user_id"
                                                                            value={values.fk_user_id}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                        >
                                                                            <option value={null}> Select User </option>
                                                                            {userList?.map((user, i) => (
                                                                                <option
                                                                                    defaultValue={couponId ? values.fk_user_id : ""
                                                                                    }
                                                                                    // selected={
                                                                                    //     country.country_id === values.fk_user_id
                                                                                    //         ? "selected"
                                                                                    //         : ""}
                                                                                    key={user.user_id}
                                                                                    value={user.user_id}
                                                                                >
                                                                                    {user.user_first_name}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                        {errors.fk_user_id && touched.fk_user_id ? (
                                                                            <div className="text-sm text-red-600">
                                                                                {errors.fk_user_id}
                                                                            </div>
                                                                        ) : null}
                                                                    </div>) : null
                                                                } */}

                                {/* User Multiselect */}
                                {/* <div className="basis-3/4">
                                                                    <label htmlFor="fk_user_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">User</label>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <Select
                                                                            // defaultValue={testList[0]}
                                                                            isMulti
                                                                            name="fk_user_ids"
                                                                            id="fk_user_ids"
                                                                            value={multiUserId}
                                                                            onChange={(e) => handleMultiUser(e)}
                                                                            onBlur={handleBlur}
                                                                            options={userList}
                                                                            className="basic-multi-select"
                                                                            classNamePrefix="Select User"
                                                                        />
                                                                        {errors.fk_user_ids && touched.fk_user_ids ? (
                                                                            <p className="text-red-600 text-sm">{errors.fk_user_ids}</p>
                                                                        ) : null}
                                                                    </div>
                                                                </div> */}
                                <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <div>
                                    <label
                                      htmlFor="lab_analyzer_name_en"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Select Users
                                    </label>
                                  </div>
                                  <div className="text-left   mt-4 z-[999] h-max">
                                    <Multiselect
                                      dataKey="id"
                                      selectedValues={selectedUsers}
                                      onSelect={(e) =>
                                        onSelect(e, "fk_user_ids")
                                      }
                                      onRemove={(e) =>
                                        onRemove(e, "fk_user_ids")
                                      }
                                      displayValue="name"
                                      options={userList}
                                    />
                                  </div>
                                  {errors.fk_user_ids && touched.fk_user_ids ? (
                                    <div className="text-sm text-red-600">
                                      {errors.fk_user_ids}
                                    </div>
                                  ) : null}
                                </div>

                                {/* Test Multiselect  */}
                                {/* <div className="basis-3/4">
                                                                    <label htmlFor="fk_test_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Test</label>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <Select
                                                                            // defaultValue={testList[0]}
                                                                            isMulti
                                                                            name="fk_test_ids"
                                                                            id="fk_test_ids"
                                                                            value={multiTestId}
                                                                            onChange={(e) => handleMultiTest(e)}
                                                                            onBlur={handleBlur}
                                                                            options={testList}
                                                                            className="basic-multi-select"
                                                                            classNamePrefix="Select Test"
                                                                        />
                                                                        {errors.fk_test_ids && touched.fk_test_ids ? (
                                                                            <p className="text-red-600 text-sm">{errors.fk_test_ids}</p>
                                                                        ) : null}
                                                                    </div>
                                                                </div> */}
                                <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <div>
                                    <label
                                      htmlFor="lab_analyzer_name_en"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Select Test
                                    </label>
                                  </div>
                                  <div className="text-left   mt-4 z-[999] h-max">
                                    <Multiselect
                                      dataKey="id"
                                      selectedValues={selectedTests}
                                      onSelect={(e) =>
                                        onSelect(e, "fk_test_ids")
                                      }
                                      onRemove={(e) =>
                                        onRemove(e, "fk_test_ids")
                                      }
                                      displayValue="name"
                                      options={testList}
                                    />
                                  </div>
                                  {errors.fk_test_ids && touched.fk_test_ids ? (
                                    <div className="text-sm text-red-600">
                                      {errors.fk_test_ids}
                                    </div>
                                  ) : null}
                                </div>

                                {/* Package Multiselect  */}
                                {/* <div className="basis-3/4">
                                                                    <label htmlFor="fk_package_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Package</label>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <Select
                                                                            // defaultValue={testList[0]}
                                                                            isMulti
                                                                            name="fk_package_ids"
                                                                            id="fk_package_ids"
                                                                            value={multiPackageId}
                                                                            onChange={(e) => handleMultiPackage(e)}
                                                                            onBlur={handleBlur}
                                                                            options={packageList}
                                                                            className="basic-multi-select"
                                                                            classNamePrefix="Select Package"
                                                                        />
                                                                        {errors.fk_package_ids && touched.fk_package_ids ? (
                                                                            <p className="text-red-600 text-sm">{errors.fk_package_ids}</p>
                                                                        ) : null}
                                                                    </div>
                                                                </div> */}
                                <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <div>
                                    <label
                                      htmlFor="lab_analyzer_name_en"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Select Packages
                                    </label>
                                  </div>
                                  <div className="text-left   mt-4 z-[999] h-max">
                                    <Multiselect
                                      dataKey="id"
                                      selectedValues={selectedPackages}
                                      onSelect={(e) =>
                                        onSelect(e, "fk_package_ids")
                                      }
                                      onRemove={(e) =>
                                        onRemove(e, "fk_package_ids")
                                      }
                                      displayValue="name"
                                      options={packageList}
                                    />
                                  </div>
                                  {errors.fk_package_ids &&
                                  touched.fk_package_ids ? (
                                    <div className="text-sm text-red-600">
                                      {errors.fk_package_ids}
                                    </div>
                                  ) : null}
                                </div>

                                <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <div>
                                    <label
                                      htmlFor="lab_analyzer_name_en"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Select Locations
                                    </label>
                                  </div>
                                  <div className="text-left   mt-4 z-[999] h-max">
                                    <Multiselect
                                      dataKey="id"
                                      selectedValues={selectedLocations}
                                      onSelect={(e) =>
                                        onSelect(e, "fk_loc_ids")
                                      }
                                      onRemove={(e) =>
                                        onRemove(e, "fk_loc_ids")
                                      }
                                      displayValue="name"
                                      options={locationList}
                                    />
                                  </div>
                                  {errors.fk_loc_ids && touched.fk_loc_ids ? (
                                    <div className="text-sm text-red-600">
                                      {errors.fk_loc_ids}
                                    </div>
                                  ) : null}
                                </div>

                                {/* <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                    <div>
                                                                        <label
                                                                            htmlFor="cp_code"
                                                                            className="block text-sm font-medium text-gray-900"
                                                                        >
                                                                            Code
                                                                        </label>
                                                                    </div>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            value={values.cp_code}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            type="text"
                                                                            placeholder="Enter Coupon Code"
                                                                            name="cp_code"
                                                                            autoComplete="off"
                                                                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                                                        />
                                                                        {errors.cp_code &&
                                                                            touched.cp_code ? (
                                                                            <p className="text-red-600 text-sm">
                                                                                {errors.cp_code}
                                                                            </p>
                                                                        ) : null}
                                                                    </div>
                                                                </div> */}
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            <button
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              onClick={() => {
                                setmodalOpenFlage(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            >
                              {couponId ? "Update" : "Add"}
                            </button>
                          </div>
                        </form>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      )}
    </div>
  );
}
export default Coupon;
