
import React, { useEffect, useState, Fragment, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { qualitycontroleSchema } from "../../../schemas";
import Breadcrumb from '../../../components/Breadcrumb';
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import TableEmpty from '../../../components/tables/tableEmpty';
import { result_list_columns, qc_lot_test_params_list_columns } from '../../../components/tables/tableheader';
import { Dialog, Transition } from '@headlessui/react'
import { toast } from 'react-toast'
import MasterServices from '../../../ApiServices/MasterServices'
import ConfirmationModal from "../../../components/ConfirmationModal";
import queryString from 'query-string';
import moment from "moment";

const initialDateFilters = {
    start_date: "",
    end_date: "",
}


const QualityControl = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pages = [{ title: "Quality Control", href: "/quality-control", module_id: 16 }];
    const [loading, setLoading] = useState(false);
    const [addResult, setAddResult] = useState(false)
    const cancelButtonRef = useRef(null);
    const printRef = React.useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [qcId, setQcId] = useState(false)
    const [lotId, setLotId] = useState(false)
    const [testId, setTestId] = useState(false)
    const [testParamsId, setTestParamsId] = useState(false)
    const [qCResultId, setQCResultId] = useState("");
    const [dateFilters, setDateFilters] = useState(initialDateFilters)

    const [filterQcId, setFilterQcId] = useState(false)
    const [filterLotId, setFilterLotId] = useState(false)
    const [filterTestId, setFilterTestId] = useState(false)
    const [filterTestParamsId, setFilterTestParamsId] = useState(false)

    const [qualityControleResultList, setQualityControleList] = useState([]);
    const [qcList, setQCList] = useState([]);
    const [qcLotList, setQcLotList] = useState([]);
    const [lotTestList, setLotTestList] = useState([]);
    const [testParamsList, setTestParamsList] = useState([]);
    const [confirmationModal, setConfirmationModal] = useState({
        status: false,
        qres_id: null,
    });
    console.log("PS lotTestList=>",lotTestList)

    useEffect(() => {
        getAllQualityControleResultList()
    }, [dateFilters])

    let filterInitialVal = {
        filterQcId: 0,
        filterLotId: 0,
        filterTestId: 0,
        filterTestParamsId: 0,
    }
    const updateQres_correction_value = (payload) => {
        console.log(payload);
        // return false;
        MasterServices.updateQCResultCorrectionVal(payload)
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    toast.success("Correction Result Updated Successfully");
                    getAllQualityControleResultList();
                } else {
                    toast.error("Fatal Error Please Contact Admin");
                }
                setLoading(false);
            })
            .catch((error) => {
                const msg = "Fatal Error Please Contact Admin";
                toast.error(msg);
                setLoading(false);
            });
    }

    const initialValues = {
        qres_id: '',
        fk_q_qc_id: '',
        fk_lot_id: '',
        fk_test_id: '',
        fk_test_param_id: '',
        qres_value: '',
        qres_correction_value: '',
        qres_justification: '',
        qres_time_of_result: moment(new Date()).format("YYYY-MM-DD"),
    }

    const [formQualityControl, setFormQualityControl] = useState(initialValues);

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, handleReset } =
        useFormik({
            enableReinitialize: true,
            initialValues: formQualityControl,
            validationSchema: qualitycontroleSchema,
            onSubmit: async (values, action) => {
                console.log("values=>", values)

                let res = await MasterServices.saveQualityControleResult(values);

                if (res.status) {
                    toast.success(res.data.message);
                    action.resetForm();
                    setAddResult(false);
                    getAllQualityControleResultList();
                }
            },
        });

    useEffect(() => {
        getAllQCList();
        getAllQualityControleResultList();
    }, []);

    const getFilterAllQualityControleResultList = () => {
        // if (filterInitialVal.filterQcId > 0) {
            console.log("helloe")
            getAllQualityControleResultList();
        // }
        console.log("here")

    }

    const handlePrint = async () => {
        let newSheetdata = [];
        if (qualityControleResultList?.length > 0) {
            qualityControleResultList.map((obj, index) => {
                const newData = {
                    qc_name_en: obj?.q_qc_relation?.qc_name_en || "-",
                    lot_name_en: obj?.q_lot_relation?.lot_name_en || "-",
                    test_name_en: obj?.t_test_relation?.test_name_en || "-",
                    params_name: obj?.t_test_params_relation?.ttp_name_en || "-",
                    mean: obj?.mean || "-",
                    sd: obj.sd || "-",
                    normal_range: obj?.normal_range || "-",
                    result: obj?.qres_value || "-",
                    result_time: obj?.qres_time_of_result || "-",
                    status: obj?.qres_is_active || "-",
                    createdBy: obj?.qres_cu_relation?.user_first_name || "-",
                    createdAt: obj?.qres_updated_at || "-",
                }
                newSheetdata.push(newData);
            })
        }
        let payload = {
            columns: ['QC NAME', 'LOT NAME', 'TEST NAME', 'PARAMS NAME', 'MEAN', 'SD', 'NORMAL RANGE', 'RESULT', 'RESULT TIME', 'STATUS', 'CREATED BY', 'CREATED AT'],
            pdf_name: 'QC_Result_Report_' + moment().format("DD-MM-YYYY"),
            rows: newSheetdata
    }
    const { data, status } = await MasterServices.generateObjectToHTML(payload);
    // console.log(data);
    // return
    let fileName = 'QC_Result_Report_' + moment().format("DD-MM-YYYY") + '.pdf';
    const printWindow = window.open('', fileName);
    const tableHtml = data?.data?.html || '';
        printWindow.document.write(`<html><head><title>${fileName}</title></head><body>${tableHtml}</body></html>`);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }

    const generateObjectToPdf = async () => {
        setIsLoading(true)
        try {
            let newSheetdata = [];
            if (qualityControleResultList?.length > 0) {
                qualityControleResultList.map((obj, index) => {
                    const newData = {
                        qc_name_en: obj?.q_qc_relation?.qc_name_en || "-",
                        lot_name_en: obj?.q_lot_relation?.lot_name_en || "-",
                        test_name_en: obj?.t_test_relation?.test_name_en || "-",
                        params_name: obj?.t_test_params_relation?.ttp_name_en || "-",
                        mean: obj?.mean || "-",
                        sd: obj.sd || "-",
                        normal_range: obj?.normal_range || "-",
                        result: obj?.qres_value || "-",
                        result_time: obj?.qres_time_of_result || "-",
                        status: obj?.qres_is_active || "-",
                        createdBy: obj?.qres_cu_relation?.user_first_name || "-",
                        createdAt: obj?.qres_updated_at || "-",
                    }
                    newSheetdata.push(newData);
                })
            }
            let payload = {
                columns: ['QC NAME', 'LOT NAME', 'TEST NAME', 'PARAMS NAME', 'MEAN', 'SD', 'NORMAL RANGE', 'RESULT', 'RESULT TIME', 'STATUS', 'CREATED BY', 'CREATED AT'],
                pdf_name: 'QC_Result_Report_' + moment().format("DD-MM-YYYY"),
                rows: newSheetdata
        }
            const { data, status } = await MasterServices.generateObjectToPDF(payload);
            console.log(data)
            if (status === 200) {
                if(data?.data?.pdf_url != '') {
                    // window.location.replace(data.data.pdf_url);
                    window.open(data.data.pdf_url, '__blank');
                }
                    // setResUserReportData(data.data)
            }
            // setIsLoading(false)
        } catch (error) {
            console.log(error)
            // setIsLoading(false)
        }
    }

    useEffect(() => {
        setFilterQcId(filterQcId); 
        filterInitialVal.filterQcId = filterQcId; 
        getAllQCLotList(filterQcId); 
        getFilterAllQualityControleResultList();
    }, [filterQcId])


    const getAllQualityControleResultList = () => {
        let qc_id = filterInitialVal.filterQcId && !isNaN(filterInitialVal.filterQcId) ? parseInt(filterInitialVal.filterQcId) : 0;
        let lot_id = filterInitialVal.filterLotId && !isNaN(filterInitialVal.filterLotId) ? parseInt(filterInitialVal.filterLotId) : 0;
        let test_id = filterInitialVal.filterTestId && !isNaN(filterInitialVal.filterTestId) ? parseInt(filterInitialVal.filterTestId) : 0;
        let test_param_id = filterInitialVal.filterTestParamsId && !isNaN(filterInitialVal.filterTestParamsId) ? parseInt(filterInitialVal.filterTestParamsId) : 0;
        let start_date = dateFilters.start_date;
        let end_date = dateFilters.end_date;
        let payload = {
            start_date,
            end_date,
            search: 3,

        }
        if(filterQcId != 0) {
            payload = {
                qc_id: qc_id > 0 ? qc_id : filterQcId,
                lot_id: lot_id > 0 ? lot_id : filterLotId,
                test_id: test_id,
                param_id: test_param_id,
                search: 3,
                start_date,
                end_date
            }
        }
        console.log(payload)
        setLoading(true);
        MasterServices.getAllQualityControleResultList(queryString.stringify(payload))
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    if (data?.data?.length > 0) {
                        setQualityControleList(data.data);
                    } else {
                        setQualityControleList([])
                        // toast.error("QualityControleList List Not Found")
                    }
                    setLoading(false);
                } else {
                    toast.error("Fatal Error Please Contact Admin")
                    setLoading(false);
                }
            })
            .catch((error) => {
                const msg = "getAllQualityControleResultList catch Error";
                toast.error(msg);
                setLoading(false);
            });
    }

    const getAllQCList = () => {
        let payload = {
            search: 1
        }
        MasterServices.getAllQualityControlsList(queryString.stringify(payload))
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    if (data?.data?.length > 0) {
                        setQCList(data.data);
                        setQcLotList([])
                        setLotTestList([])
                    } else {
                        toast.error("QC List Not Found")
                        setQcLotList([])
                        setLotTestList([])
                    }
                } else {
                    toast.error("Fatal Error Please Contact Admin")
                    setQcLotList([])
                    setLotTestList([])
                }
            })
            .catch((error) => {
                const msg = "getQulityControlList catch Error";
                setQcLotList([])
                setLotTestList([])
                toast.error(msg);
            });
    }
    const getAllQCLotList = (id) => {
        id = id && !isNaN(id) ? parseInt(id) : 0;
        let payload = {
            qc_id: id,
            search: 1,
        }
        MasterServices.getAllLotListByQcId(queryString.stringify(payload))
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    if (data?.data?.length > 0) {
                        setQcLotList(data.data);
                        setLotTestList([]);
                    } else {
                        // toast.error("getAllQCLotList Not Found")
                        setLotTestList([]);
                        setQcLotList([])
                    }
                } else {
                    toast.error("Fatal Error Please Contact Admin");
                    setLotTestList([]);
                    setQcLotList([])
                }
            })
            .catch((error) => {
                const msg = "getAllQCLotList catch Error";
                setLotTestList([])
                setQcLotList([])
                toast.error(msg);
            });
    }
    const getAllQCLotTestList = (id) => {
        // alert(id)
        setLotTestList((qcLotList.filter((v) => id == v.lot_id)));

        let qc_id = qcId && !isNaN(qcId) ? parseInt(qcId) : 0;
        if (qc_id <= 0) {
            qc_id = filterInitialVal.filterQcId && !isNaN(filterInitialVal.filterQcId) ? parseInt(filterInitialVal.filterQcId) : 0;

        }
        id = id && !isNaN(id) ? parseInt(id) : 0;
        qc_id = qc_id > 0 ? qc_id : filterQcId;
        id = id > 0 ? id : filterLotId;
        let payload = {
            qc_id: qc_id,
            lot_id: id,
            search: 1,
        }

        MasterServices.getAllTestListByQcIdLotId(queryString.stringify(payload))
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    if (data?.data?.length > 0) {
                        // setLotTestList(data.data);
                    } else {
                        // toast.error("QC Lot Test List Not Found")
                    }
                } else {
                    toast.error("Fatal Error Please Contact Admin")
                }
            })
            .catch((error) => {
                const msg = "getAllQCLotTestList catch Error";
                toast.error(msg);
            });
    }

    const getAllQCLotTestParamsList = (id) => {
        id = id && !isNaN(id) ? parseInt(id) : 0;
        let fk_q_qc_id = qcId && !isNaN(qcId) ? parseInt(qcId) : 0;
        let fk_lot_id = lotId && !isNaN(lotId) ? parseInt(lotId) : 0;
        let payload = {
            test_id: id,
            fk_q_qc_id: fk_q_qc_id,
            fk_lot_id: fk_lot_id,
            search: 1,
            type:"QC_Result"
        }
        MasterServices.getTestBasedParams(payload)
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    if (data?.data?.length > 0) {
                        console.log("Test paramtere List=>", data.data)
                        setTestParamsList(data.data);
                    } else {
                        // toast.error("getAllQCLotList Not Found")
                        setTestParamsList([]);
                    }
                } else {
                    toast.error("Fatal Error Please Contact Admin");
                    setLotTestList([]);
                    setQcLotList([])
                }
            })
            .catch((error) => {
                const msg = "getAllQCLotList catch Error";
                setLotTestList([])
                setQcLotList([])
                toast.error(msg);
            });
    }
    const getSingleQualityControleResult = async (rgn_id) => {
        MasterServices.getSingleQualityControleResult(rgn_id)
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                } else {
                    toast.error("Fatal Error Please Contact Admin")
                }
                setIsLoading(false);
            })
            .catch((error) => {
                const msg = "getqcList catch Error";
                toast.error(msg);
                setIsLoading(false);
            });
    };

    const onDeleteOpen = (qres_id, qres_is_active) => {
        setConfirmationModal({ qres_id, qres_is_active, status: true });
    };

    const onDeleteQCResult = async (qres_id, qres_is_active) => {
        let Newqres_is_active = false;
        Newqres_is_active = qres_is_active == true ? false : true;
        let text = "Activated";
        if (Newqres_is_active == true) {
            text = "Activated";
        } else {
            text = "In-Activated";
        }
        MasterServices.setQualityControleResultStatus({
            qres_id,
            qres_is_active: Newqres_is_active
        })
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    toast.success("QC Result " + text + " Successfully!")
                    setConfirmationModal({ qres_id: null, status: false });
                    getAllQualityControleResultList();
                } else {
                    toast.error("Fatal Error Please Contact Admin")
                }
            })
            .catch((error) => {
                const msg = "Fatal Error Please Contact Admin";
                toast.error(msg)
            });
    };

    const updateQCLotTestParamsResult = (payload) => {
        console.log("payload PHS=>", payload);
        let newPayload = {
            qres_id: payload?.qc_data?.qres_id,
            qres_value: (payload?.qres_value) ? (payload?.qres_value) : (payload?.qc_data?.qres_value),
            qres_correction_value: payload?.qc_data?.qres_correction_value,
            qres_justification: payload?.qc_data?.qres_justification,
            qres_time_of_result: (payload?.qres_time_of_result) ? (payload?.qres_time_of_result) : (payload?.qc_data?.qres_time_of_result),
            fk_q_qc_id: payload?.qc_data?.fk_q_qc_id,
            fk_lot_id: payload?.qc_data?.fk_lot_id,
            fk_test_id: payload?.qc_data?.fk_test_id,
            fk_test_param_id: payload?.qc_data?.ttp_id,
        };
        let id = (payload.qc_data.fk_test_id) && !isNaN(payload.qc_data.fk_test_id) ? parseInt(payload.qc_data.fk_test_id) : 0;
        let qCLotDetails = {
            test_id: id,
            search: 1,
        }
        // console.log("newPayload PHS=>", newPayload);
        // return false;
        MasterServices.saveQualityControleResult(newPayload)
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    toast.success("Test Params Result Updated Successfully");
                    getAllQCLotTestParamsList(qCLotDetails.test_id);

                } else {
                    toast.error("Fatal Error Please Contact Admin");
                }
                setLoading(false);
                getAllQualityControleResultList();
            })
            .catch((error) => {
                const msg = error;
                toast.error(msg);
                setLoading(false);
            });
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <ConfirmationModal
                title={"Are you sure you want to " + (confirmationModal.qres_is_active ? "Disable " : "Activate") + " this QC Result?"}
                confirmationButtonText="Yes"
                open={confirmationModal.status}
                onDelete={() => onDeleteQCResult(confirmationModal.qres_id, confirmationModal.qres_is_active)}
                setOpen={setConfirmationModal}
            />
            <Breadcrumb pages={pages} />
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Quality Control Result</h1>
            </div>
            <div className="flex gap-5 mt-5">
                <div className="basis-1/2">
                    <label htmlFor="qc" className="block text-sm font-medium leading-6 text-gray-900">
                        Qc
                    </label>
                    <div className="mt-1">

                        {qcList ? (<select
                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                            name="filter_qc_id"
                            id="filter_qc_id"
                            value={values.filter_qc_id}
                            onChange={(e) => { console.log(e.target.value); setFilterQcId(e.target.value) }}
                        >
                            <option value={0}> Select QC </option>
                            {qcList.map((qc, i) => (
                                <option
                                    selected={
                                        qc.qc_id ===
                                            values.filter_qc_id
                                            ? "selected"
                                            : ""
                                    }
                                    key={i}
                                    value={qc.qc_id}
                                >
                                    {qc.qc_name_en}
                                </option>
                            ))}
                        </select>) : null}
                    </div>
                </div>
                <div className="basis-1/2">
                    <label htmlFor="lot" className="block text-sm font-medium leading-6 text-gray-900">
                        Lot
                    </label>
                    <div className="mt-1">
                        {qcList ? (<select
                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                            name="filter_lot_id"
                            id="filter_lot_id"
                            value={values.filter_lot_id}
                            onChange={(e) => { setFilterLotId(e.target.value); filterInitialVal.filterLotId = e.target.value; getAllQCLotTestList(e.target.value); getAllQualityControleResultList(); }}
                        >
                            <option value={0}> Select Lot </option>
                            {qcLotList.map((lot, i) => (
                                <option
                                    selected={
                                        lot.lot_id ===
                                            values.filter_lot_id
                                            ? "selected"
                                            : ""
                                    }
                                    key={i}
                                    value={lot.lot_id}
                                >
                                    {lot.lot_name_en}
                                </option>

                            ))}
                        </select>) : null}
                    </div>
                </div>
                <div className="basis-1/2">
                    <label htmlFor="test" className="block text-sm font-medium leading-6 text-gray-900">
                        Test
                    </label>
                    <div className="mt-1">
                        {
                            lotTestList ?
                                (<select
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                    name="filter_test_id"
                                    id="filter_test_id"
                                    value={values.filter_test_id}
                                    onChange={(e) => { setFilterTestId(e.target.value); filterInitialVal.filterTestId = e.target.value; getAllQCLotTestParamsList(e.target.value); getAllQualityControleResultList(); }}
                                    onBlur={handleBlur}
                                >
                                    <option value={0}> Select Test </option>
                                    {lotTestList.map((lotTest, i) => (
                                        <option
                                            selected={
                                                lotTest?.t_test_relation?.test_id ===
                                                    values.filter_test_id
                                                    ? "selected"
                                                    : ""
                                            }
                                            key={i}
                                            value={lotTest?.t_test_relation?.test_id}
                                        >
                                            {lotTest?.t_test_relation?.test_name_en}
                                        </option>
                                    ))}
                                </select>) : null}
                    </div>
                    {/* <div className="mt-1">
                        {
                            qcLotList ?
                                (<select
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                    name="filter_test_id"
                                    id="filter_test_id"
                                    value={values.filter_test_id}
                                    onChange={(e) => { setFilterTestId(e.target.value); filterInitialVal.filterTestId = e.target.value; getAllQCLotTestParamsList(e.target.value); getAllQualityControleResultList(); }}
                                    onBlur={handleBlur}
                                >
                                    <option value={0}> Select Test </option>
                                    {qcLotList.map((lotTest, i) => (
                                        <option
                                            selected={
                                                lotTest?.t_test_relation?.test_id ===
                                                    values.filter_test_id
                                                    ? "selected"
                                                    : ""
                                            }
                                            key={i}
                                            value={lotTest?.t_test_relation?.test_id}
                                        >
                                            {lotTest?.t_test_relation?.test_name_en}
                                        </option>
                                    ))}
                                </select>) : null}
                    </div> */}
                    {/* <div className="mt-1">
                        {
                            lotTestList ?
                                (<select
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                    name="filter_test_id"
                                    id="filter_test_id"
                                    value={values.filter_test_id}
                                    onChange={(e) => { setFilterTestId(e.target.value);filterInitialVal.filterTestId = e.target.value; getAllQCLotTestList(e.target.value); getAllQualityControleResultList(); }}
                                    onBlur={handleBlur}
                                >
                                    <option value={0}> Select Test </option>
                                    {lotTestList.map((lotTest, i) => (
                                        <option
                                            selected={
                                                lotTest.test_id ===
                                                    values.filter_test_id
                                                    ? "selected"
                                                    : ""
                                            }
                                            key={i}
                                            value={lotTest.test_id}
                                        >
                                            {lotTest.test_name_en}
                                        </option>
                                    ))}
                                </select>) : null}
                    </div> */}
                </div>
                <div className="basis-1/2">

                    <label htmlFor="test" className="block text-sm font-medium leading-6 text-gray-900">
                        Test Params
                    </label>
                    <div className="mt-1">
                        {
                            testParamsList ?
                                (<select
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                    name="filter_param_id"
                                    id="filter_param_id"
                                    value={values.filter_param_id}
                                    onChange={(e) => { setFilterTestParamsId(e.target.value); filterInitialVal.filterTestParamsId = e.target.value; getAllQualityControleResultList(); }}
                                    onBlur={handleBlur}
                                >
                                    <option value={0}> Select Test Params</option>
                                    {testParamsList.map((lotTestParams, i) => (
                                        <option
                                            selected={
                                                lotTestParams?.ttp_id ===
                                                    values.filter_param_id
                                                    ? "selected"
                                                    : ""
                                            }
                                            key={i}
                                            value={lotTestParams?.ttp_id}
                                        >
                                            {lotTestParams?.ttp_name_en}
                                        </option>
                                    ))}
                                </select>) : null}
                    </div>

                </div>
                {/* <div className="basis-1/2">
                    <label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">
                        Status
                    </label>
                    <div className="mt-1">
                        <select
                            id="status"
                            name="status"
                            autoComplete="status-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                            defaultValue="Select status"
                        >

                            <option disabled>Select test</option>
                            <option>Approved</option>
                            <option>Pending</option>
                            <option>Process</option>
                        </select>
                    </div>
                </div> */}
            </div>
            <div className="flex gap-5 mt-5">
                {/* start date */}
                <div className=" sm:mt-0">
                    <label htmlFor="fk_location_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Date From</label>

                    <input
                        className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
                        type="date"
                        name="start_date"
                        id="start_date"
                        value={dateFilters.start_date}
                        onChange={(e) => {
                            setDateFilters((prev) => {
                                // if (prev.end_date < e.target.value) {
                                //     return {
                                //         ...prev,
                                //         end_date: e.target.value,
                                //         start_date: prev.end_date
                                //     }
                                // }
                                return {
                                    ...prev,
                                    start_date: e.target.value,
                                }
                            })

                        }
                        }
                    />
                </div>

                {/* end date */}
                <div className=" sm:mt-0">
                    <label htmlFor="fk_location_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Date To</label>

                    <input
                        className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
                        type="date"
                        name="end_date"
                        id="end_date"
                        value={dateFilters.end_date}
                        onChange={(e) => {
                            setDateFilters((prev) => {
                                // if (prev.start_date > e.target.value) {
                                //     return {
                                //         ...prev,
                                //         end_date: prev.start_date,
                                //         start_date: e.target.value
                                //     }
                                // }
                                return {
                                    ...prev,
                                    end_date: e.target.value,
                                }
                            })
                        }
                        }
                    />
                </div>
            </div>
            <div className="text-end mt-5">
                <button
                    onClick={() => setAddResult(true)}
                    type="button"
                    className="inline-flex items-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-500"
                >
                    Add Result
                </button>
            </div>
            <div className='mt-5 flex gap-1 items-center'>
                    {/* <button
                    onClick={() => handleExport('copy')}
                    className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mr-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                    Copy
                  </button> */}
                    {/* <button
                        onClick={() => handleExport('csv')}
                        className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                        CSV
                    </button>

                    <button
                        onClick={() => handleExport('xlsx')}
                        className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                        Excel
                    </button> */}

                    <button
                        onClick={() => generateObjectToPdf()}
                        // onClick={() => getAllLogsPDF()}
                        className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                        PDF
                    </button>
                    <button
                        onClick={() => handlePrint()}
                        className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                        Print
                    </button>
                </div>
            <div className="mt-8 flow-root">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div ref={printRef} className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        {
                            loading ? (
                                <FallingLinesLoader />
                            ) : ((qualityControleResultList.length > 0) ? (
                                <Table
                                    columns={result_list_columns(onDeleteOpen, updateQres_correction_value)}
                                    data={qualityControleResultList}
                                />
                            ) : <TableEmpty />)
                        }
                    </div>
                </div>
            </div>

            <Transition.Root show={addResult} as={Fragment}>
                <Dialog as="div" className="relative z-30" initialFocus={cancelButtonRef} onClose={setAddResult}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                                    {/* <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            handleSubmit(e);
                                        }}
                                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                                    > */}

                                    <h1 className="text-2xl pb-5 font-semibold leading-6 text-gray-900">
                                        {qCResultId ? "Update" : "Add"} Result
                                    </h1>
                                    <div className="flex">
                                        <div className="basis-1/2">
                                            <label htmlFor="qc" className="block text-sm font-medium leading-6 text-gray-900">
                                                Qc
                                            </label>
                                            <div className="mt-1">
                                                {qcList && qcList.length > 0 ?
                                                    <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                                        <select
                                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                                            name="fk_q_qc_id"
                                                            id="fk_q_qc_id"
                                                            value={values.fk_q_qc_id}
                                                            onChange={(e) => { handleChange(e); getAllQCLotList(e.target.value); setQcId(e.target.value) }}
                                                            onBlur={handleBlur}
                                                        >
                                                            <option value={0}> Select QC </option>
                                                            {qcList.map((qc, i) => (
                                                                <option
                                                                    selected={
                                                                        qc.qc_id ===
                                                                            values.fk_q_qc_id
                                                                            ? "selected"
                                                                            : ""
                                                                    }
                                                                    key={i}
                                                                    value={qc.qc_id}
                                                                >
                                                                    {qc.qc_name_en}
                                                                </option>
                                                            ))}
                                                        </select>

                                                        {errors.fk_q_qc_id &&
                                                            touched.fk_q_qc_id ? (
                                                            <div className="text-sm text-red-600">
                                                                {errors.fk_q_qc_id}
                                                            </div>
                                                        ) : null}
                                                    </div>

                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="basis-1/2">
                                            <label htmlFor="lot" className="block text-sm font-medium leading-6 text-gray-900">
                                                Lot
                                            </label>
                                            <div className="mt-1">
                                                {

                                                    qcLotList ?
                                                        <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                                            <select
                                                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                                                name="fk_lot_id"
                                                                id="fk_lot_id"
                                                                value={values.fk_lot_id}
                                                                onChange={(e) => { handleChange(e); getAllQCLotTestList(e.target.value); setLotId(e.target.value) }}
                                                                onBlur={handleBlur}
                                                            >
                                                                <option value={0}> Select Lot </option>
                                                                {qcLotList.map((lot, i) => (
                                                                    <option
                                                                        selected={
                                                                            lot.lot_id ===
                                                                                values.fk_lot_id
                                                                                ? "selected"
                                                                                : ""
                                                                        }
                                                                        key={i}
                                                                        value={lot.lot_id}
                                                                    >
                                                                        {lot.lot_name_en}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            {errors.fk_lot_id &&
                                                                touched.fk_lot_id ? (
                                                                <div className="text-sm text-red-600">
                                                                    {errors.fk_lot_id}
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-5">

                                        <div className="basis-1/2">
                                            <label htmlFor="test" className="block text-sm font-medium leading-6 text-gray-900">
                                                Test
                                            </label>
                                            <div className="mt-1">
                                                {
                                                    lotTestList ?
                                                        <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                                            <select
                                                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                                                name="fk_test_id"
                                                                id="fk_test_id"
                                                                value={values.fk_test_id}
                                                                onChange={(e) => { handleChange(e); getAllQCLotTestParamsList(e.target.value); setTestId(e.target.value) }}
                                                                onBlur={handleBlur}
                                                            >
                                                                <option value={0}> Select Test </option>
                                                                {lotTestList.map((lotTest, i) => (
                                                                    <option
                                                                        selected={
                                                                            lotTest?.t_test_relation?.test_id ===
                                                                                values.fk_test_id
                                                                                ? "selected"
                                                                                : ""
                                                                        }
                                                                        key={i}
                                                                        value={lotTest?.t_test_relation?.test_id}
                                                                    >
                                                                        {lotTest?.t_test_relation?.test_name_en}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            {errors.fk_test_id &&
                                                                touched.fk_test_id ? (
                                                                <div className="text-sm text-red-600">
                                                                    {errors.fk_test_id}
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        : null
                                                }
                                            </div>
                                        </div>

                                        {/* <div className="basis-1/2">
                                            <label htmlFor="test" className="block text-sm font-medium leading-6 text-gray-900">
                                                Test Params
                                            </label>
                                            <div className="mt-1">
                                                {
                                                    testParamsList ?
                                                        <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                                            <select
                                                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                                                name="fk_test_param_id"
                                                                id="fk_test_param_id"
                                                                value={values.fk_test_param_id}
                                                                onChange={(e) => { handleChange(e); setTestParamsId(e.target.value) }}
                                                                onBlur={handleBlur}
                                                            >
                                                                <option value={0}> Select Test Params </option>
                                                                {testParamsList.map((lotTestParams, i) => (
                                                                    <option
                                                                        selected={
                                                                            lotTestParams?.ttp_id ===
                                                                                values.fk_test_param_id
                                                                                ? "selected"
                                                                                : ""
                                                                        }
                                                                        key={i}
                                                                        value={lotTestParams?.ttp_id}
                                                                    >
                                                                        {lotTestParams?.ttp_name_en}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            {errors.fk_test_param_id &&
                                                                touched.fk_test_param_id ? (
                                                                <div className="text-sm text-red-600">
                                                                    {errors.fk_test_param_id}
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        : null
                                                }
                                            </div>
                                        </div> */}
                                        {/* <div className="basis-1/2">
                                            <label htmlFor="qres_value" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                Result
                                            </label>
                                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                <input
                                                    value={values.qres_value}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="number"
                                                    placeholder="Enter Result"
                                                    name="qres_value"
                                                    id="qres_value"
                                                    autoComplete="off"
                                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                                />
                                                {errors.qres_value &&
                                                    touched.qres_value ? (
                                                    <div className="text-sm text-red-600">
                                                        {errors.qres_value}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* <div className="flex">
                                        <div className="basis-1/2">
                                            <label htmlFor="qres_time_of_result" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                Time
                                            </label>
                                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                <input
                                                    value={values.qres_time_of_result}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="date"
                                                    placeholder="Enter Time"
                                                    name="qres_time_of_result"
                                                    id="qres_time_of_result"
                                                    autoComplete="off"
                                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                                />
                                                {errors.qres_time_of_result &&
                                                    touched.qres_time_of_result ? (
                                                    <div className="text-sm text-red-600">
                                                        {errors.qres_time_of_result}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="flex">
                                        {testId ? (
                                            <div>
                                                <label htmlFor="test" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Test Params
                                                </label>
                                                <div className="mt-1">
                                                    <Table columns={qc_lot_test_params_list_columns({ updateQCLotTestParamsResult, qcId })} data={testParamsList} />

                                                    {/* {
                                                        testParamsList ?
                                                            <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                                                <select
                                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                                                    name="fk_test_param_id"
                                                                    id="fk_test_param_id"
                                                                    value={values.fk_test_param_id}
                                                                    onChange={(e) => { handleChange(e); setTestParamsId(e.target.value) }}
                                                                    onBlur={handleBlur}
                                                                >
                                                                    <option value={0}> Select Test Params </option>
                                                                    {testParamsList.map((lotTestParams, i) => (
                                                                        <option
                                                                            selected={
                                                                                lotTestParams?.ttp_id ===
                                                                                    values.fk_test_param_id
                                                                                    ? "selected"
                                                                                    : ""
                                                                            }
                                                                            key={i}
                                                                            value={lotTestParams?.ttp_id}
                                                                        >
                                                                            {lotTestParams?.ttp_name_en}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                                {errors.fk_test_param_id &&
                                                                    touched.fk_test_param_id ? (
                                                                    <div className="text-sm text-red-600">
                                                                        {errors.fk_test_param_id}
                                                                    </div>
                                                                ) : null}
                                                            </div>

                                                            : null
                                                    } */}
                                                </div></div>) : (<></>)}
                                    </div>
                                    <div className="flex flex-shrink-0 gap-6 justify-end px-4 py-4">

                                        <button
                                            type="reset"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                            onClick={() => {
                                                setAddResult(false)
                                                handleReset()
                                            }
                                            }
                                            ref={cancelButtonRef}
                                        >
                                            Submit
                                        </button>

                                        {/* <button
                                                type="submit"
                                                className="inline-flex w-full justify-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600 sm:col-start-2"
                                            >
                                                {qCResultId ? "Update" : "Add"}

                                            </button> */}
                                    </div>
                                    {/* </form> */}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )
}

export default QualityControl